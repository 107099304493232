import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { CreateChecklistData } from "../../services/api/DataUpdating";
import ChecklistNameWidget from "./components/ChecklistNameWidget";
import ChecklistDataWidget from "./components/ChecklistDataWidget";

import cogoToast from "cogo-toast";
import {CustomToast} from "../CustomToast/CustomToast";

const ChecklistWidget = ({
  nameModal,
  setNameModal,
  dataModal,
  setDataModal,
  selectedChecklistData,
  setSelectedChecklistData
}) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  const [checklistName, setChecklistName] = useState("");

  
  let userName, userEmail, userId, userNmber , moeId
  userName = localStorage.getItem("userName")
  userEmail = localStorage.getItem("userEmail")
  userId = localStorage.getItem("userID")
  userNmber = localStorage.getItem("userNumber")
  moeId = localStorage.getItem("moeID")


  let componentData;
  if (isDesktopOrLaptop) {
    componentData = {
      title_class: "ubuntu-700w-20s-32h black-1E1E26-color mb-1",
      subTitle_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
      outlineCta_class:
        "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer whitespace-nowrap",
      secondary_text_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
      name_class: "open-600w-18s-28h black-1E1E26-color",
      icon_size: "icon-20",
      addList_class: "open-400w-16s-24h grey-2-78787d-color mb-0",
      subTitle_modal_class: "ubuntu-500w-20s-32h black-1E1E26-color mb-0",
    };
  } else {
    componentData = {
      title_class: "ubuntu-700w-18s-28h black-1E1E26-color mb-1",
      subTitle_class: "open-400w-12s-18h grey-2-78787d-color",
      outlineCta_class:
        "d-flex align-items-center " +
        " open-400w-12s-18h teal-2-00A095-color cursor-pointer ",
      filledCta_class: "",
      secondary_text_class: "open-400w-14s-22h grey-2-78787d-color mb-0",
      name_class: "open-700w-16s-24h black-1E1E26-color mb-0",
      icon_size: "icon-16",
      addList_class: "open-400w-12s-16h grey-2-78787d-color mb-0",
      subTitle_modal_class: "open-700w-16s-24h black-1E1E26-color mb-0",
    };
  }


  const checklistNameSubmissionHandler = () => {


           window.Moengage.track_event("profile_viewed", {
            "account_id": `${moeId === null ? "" : moeId}`,
            "platform": "web",
            "page_viewed": "yes",
            "fullname": `${userName === null ? "" : userName}`,
            "mobile_number": `${userNmber === null ? "" : userNmber}`,
            "email": `${userEmail === null ? "" : userEmail}`,
            "isloggedin": `${userId === null ? 'no' : 'yes'}`,
            "checkListName":`${checklistName}`
             });
             
    
    if(checklistName.trim(' ' ,'') === ''){
      cogoToast.error(
          <CustomToast
              heading={"Error"}
              message={'Please Enter Checklist Name'}
              type={"error"}
          />,
      );

    }
    else{
      
      CreateChecklistData(
        {
          name: checklistName,
        },
        (response) => {
          if (isDesktopOrLaptop) {
            setNameModal(false);
            setDataModal(true);
            setSelectedChecklistData({
              ...selectedChecklistData,
              name: checklistName,
              id:response?.checkList?.id
            })
            setChecklistName("")
          } else {
            navigate(`/checklist/${response?.checkList?.id}`);
          }
        },
        (err) => {
          cogoToast.error(  
            <CustomToast
                heading={"Error"}
                message={err[0]?.message || err.toString()}
                type={"error"}
            />,
        );
        }
      );
    }
  };

  return (
    <div className={""}>
      <ChecklistNameWidget
        nameModal={nameModal}
        setNameModal={setNameModal}
        checklistName={checklistName}
        setChecklistName={setChecklistName}
        componentData={componentData}
        checklistNameSubmissionHandler={checklistNameSubmissionHandler}
        selectedChecklistData={selectedChecklistData}
      />

      {isDesktopOrLaptop && (
        <ChecklistDataWidget
          dataModal={dataModal}
          setDataModal={setDataModal}
          componentData={componentData}
          checklistDataSubmissionHandler={checklistNameSubmissionHandler}
          selectedChecklistData={selectedChecklistData}
        />
      )}
    </div>
  );
};

export default ChecklistWidget;
