import React from 'react'
import './FeedbackReminderComponent.scss'
import {useMediaQuery} from "react-responsive";
import {store} from "../../index";
import {setSelectedBookingIdAction} from "../../store/Actions/CommonAction";
import {useNavigate} from "react-router";

//assets
const illustration = process.env.REACT_APP_CDN_LINK + `assets/images/feedback-reminder-illustration.png`

const FeedbackReminderComponent = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const {pendingReviewData} = props
    const navigate = useNavigate()

    let componentConstant
    if (isDesktopOrLaptop) {
        componentConstant = {
            heading_class: "ubuntu-700w-24s-36h black-1E1E26-color mb-4",
            illustration_box_heading: "ubuntu-700w-24s-36h black-1E1E26-color mb-3",
            illustration_box_secondary: "ubuntu-500w-18s-28h grey-2-78787d-color mb-0",
            secondary_text_class: "open-400w-16s-22h grey-2-78787d-color mb-0"
        }
    } else {
        componentConstant = {
            heading_class: "ubuntu-700w-18s-28h black-1E1E26-color mb-3 ",
            illustration_box_heading: "ubuntu-700w-16s-24h black-1E1E26-color mb-3",
            illustration_box_secondary: "open-400w-12s-18h grey-2-78787d-color mb-0",
            secondary_text_class: "open-400w-16s-22h grey-2-78787d-color margin-bottom-32"
        }
    }

    const submitHandler = () => {
        store.dispatch(setSelectedBookingIdAction(pendingReviewData.id))
        navigate('/ticket-view', {replace: false, state: "COMPLETED"})
    }

    return (
        <div className={'feedback-reminder-component'}>
            <p className={componentConstant.heading_class}>{pendingReviewData.sourceCityName} to {pendingReviewData.destinationCityName}</p>
            <div className={'illustration-box d-flex justify-content-between pb-0 mb-4'}>
                <div className={'my-auto'}>
                    <p className={componentConstant.illustration_box_heading}>
                        Rate your recent <br/> Journey
                    </p>
                    <p className={componentConstant.illustration_box_secondary + " mb-4"}>
                        Help us get better!
                    </p>
                </div>
                <div className={'d-flex justify-content-center align-items-end'}>
                    <img src={illustration} alt={'illustration'} className={'illustration'}/>
                </div>
            </div>
            <p className={componentConstant.secondary_text_class}>
                We are always trying to improve our services and your feedback is very important to us!
            </p>
            <div className={'d-flex justify-content-lg-end justify-content-center'}>
                <button className={'submit-button feedback-reminder-button'}
                        onClick={submitHandler}
                >
                    Rate Now
                </button>
            </div>

        </div>
    )
}

export default FeedbackReminderComponent
