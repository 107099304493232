/**
 * @generated SignedSource<<f6eb0af1520f8dfca7bea0158d324704>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "NewsletterUser",
    "kind": "LinkedField",
    "name": "newsletterUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsletterUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsletterUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b8827bf893ad1ee73c54c68fc13d3817",
    "id": null,
    "metadata": {},
    "name": "NewsletterUserMutation",
    "operationKind": "mutation",
    "text": "mutation NewsletterUserMutation(\n  $email: String!\n) {\n  newsletterUser(email: $email) {\n    status\n    message\n  }\n}\n"
  }
};
})();

node.hash = "6868172272dcfa7027df39cb1a97f8b3";

module.exports = node;
