import React, {useRef, useState} from "react";
import "../../CompletedTrip/CompletedTrip.scss";
import {useMediaQuery} from "react-responsive";
// import FreePikStar from "../../../assets/images/freepik--Stars--inject-38.png";
//import swiper
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {Navigation, Pagination} from "swiper/modules";
import RateUsPlayStore from '../../../assets/icons/playstoreIcon.png'

//assets
const backArrow = process.env.REACT_APP_CDN_LINK + `assets/images/backArrow.png`
const Thumbs = process.env.REACT_APP_CDN_LINK + `assets/images/thumbs.png`
const TripDestination = process.env.REACT_APP_CDN_LINK + `assets/images/tripdestination.png`
const LeftArrow = process.env.REACT_APP_CDN_LINK + `assets/images/leftArrow.png`
const RightArrow = process.env.REACT_APP_CDN_LINK + `assets/images/rightArrow.png`
const Component = process.env.REACT_APP_CDN_LINK + `assets/images/component.png`
const Luggage = process.env.REACT_APP_CDN_LINK + `assets/images/luggage.png`
const Fare = process.env.REACT_APP_CDN_LINK + `assets/images/fare.png`
const freePikStarImage = process.env.REACT_APP_CDN_LINK + `assets/images/freePikStarImage.webp`
const EmojiOne = process.env.REACT_APP_CDN_LINK + `assets/images/emojiOne.png`
const EmojiTwo = process.env.REACT_APP_CDN_LINK + `assets/images/emojiTwo.png`
const EmojiThree = process.env.REACT_APP_CDN_LINK + `assets/images/emojiThree.png`
const EmojiFour = process.env.REACT_APP_CDN_LINK + `assets/images/emojiFour.png`
const EmojiFive = process.env.REACT_APP_CDN_LINK + `assets/images/emojiFive.png`
const ActiveEmojiOne = process.env.REACT_APP_CDN_LINK + `assets/images/activeEmojiOne.png`
const DragText = process.env.REACT_APP_CDN_LINK + `assets/images/dragText.png`
const ContributionImage = process.env.REACT_APP_CDN_LINK + `assets/images/contributionImage.png`
const MobileContributionImage = process.env.REACT_APP_CDN_LINK + `assets/images/mobileDeviceEcoContribution.png`
const RateUsAppStore = process.env.REACT_APP_CDN_LINK + `assets/images/rateUsAppStore.png`


const CompletedTripDetails = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const sliderRef = useRef(null);
    const [activeEmoji, setActiveEmoji] = useState(5);
    const [feedBack, setFeedBack] = useState(false);


    const EcoContributionData = [
        {
            name: "Woah!",
            description: "Your journey helped us save 120 trees",
            image: ContributionImage,
            mobileImage: MobileContributionImage,
        },
        {
            name: "Woah!",
            description: "Your journey helped us save 120 trees",
            image: ContributionImage,
            mobileImage: MobileContributionImage,
        },
    ];

    const RateUsEmoji = [
        {image: EmojiOne, id: 1, ActiveEmoji: ActiveEmojiOne},
        {image: EmojiTwo, id: 2, ActiveEmoji: EmojiTwo},
        {image: EmojiThree, id: 3, ActiveEmoji: EmojiThree},
        {image: EmojiFour, id: 4, ActiveEmoji: EmojiFour},
        {image: EmojiFive, id: 5, ActiveEmoji: EmojiFive},
    ];

    return (
        <div>
            <div className="completed-trip-details">
                <div className="completed-trip-banner d-flex align-items-center  justify-content-center">
                    <img src={backArrow} alt=""/>
                    <p className="completed-trip-details-heading text-nowrap pt-3">
                        Completed Trip Details
                    </p>
                </div>
                <div className="text-center py-3">
                    <img src={Thumbs} alt="alt"/>
                </div>
                <p className="positive-comment pb-4">
                    {" "}
                    We hope you had a <span> Great journey!</span>
                </p>
                <div>
                    <div className="destop-devision">
                        <div className="left-side">
                            {isDesktopOrLaptop && (
                                <p className="desktop-devision-heading">Completed Trip</p>
                            )}
                            <div className="trip-card">
                                <div className="trip-card-part ">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img className="left-arrow" src={LeftArrow} alt="alt"/>
                                        <p className=" trip-pnr-number">
                                            PNR Number <br></br> <span> #10251200</span>
                                        </p>
                                        <img className="right-arrow" src={RightArrow} alt="alt"/>
                                    </div>

                                    <div className="trip-start-end ">
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex trip-destinations">
                                                <img
                                                    className="trip-destination"
                                                    src={TripDestination}
                                                    alt="alt"
                                                />
                                                <div>
                                                    <p className="city-name">
                                                        Vizag <br></br> <span> Mira Road</span>
                                                    </p>
                                                    <p className="city-name">
                                                        Bengaluru <br></br> <span> Vesu</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="trip-destinations">
                                                <div>
                                                    <p className="city-name">
                                                        11.00 AM <br></br> <span> 5 Feb</span>
                                                    </p>
                                                    <p className="city-name">
                                                        5.00 AM <br></br> <span> 6 Feb</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="hr-line-one"/>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="passenger-name">Sonam Vador (F), 32</p>
                                                <p className="passenger-name">Nupur Mehta (F), 33</p>
                                            </div>
                                            <div>
                                                <div className="d-flex component">
                                                    <img
                                                        src={Component}
                                                        className="component-img"
                                                        alt="alt"
                                                    />
                                                    <p className="component-name">B3</p>
                                                </div>
                                                <div className="d-flex component">
                                                    <img
                                                        src={Component}
                                                        className="component-img"
                                                        alt="alt"
                                                    />
                                                    <p className="component-name">B4</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="hr-line-two"></hr>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <div className="d-flex luggage ">
                                                    <img
                                                        src={Luggage}
                                                        className="luggage-img"
                                                        alt="alt"
                                                    />
                                                    <p className="luggage-name">Luggage Pieces</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex ">
                                                    <p className="per-guest">
                                                        2 <span> per Guest</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fare-details">
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <div className="d-flex fare ">
                                                    <p className="fare-name">Fare Details</p>
                                                    <img src={Fare} className="fare-img" alt="alt"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex ">
                                                    <p className="fare-amount">₹2150</p>
                                                </div>
                                            </div>
                                        </div>
                                        {" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* rate us */}
                        {feedBack === false && (
                            <div className="right-side">
                                <div className="d-flex opinion justify-content-between align-items-center">
                                    <div>
                                        <p className="opinion-heading">
                                            Your Opinion matters to us
                                        </p>
                                        <p className="opinion-help">Help us get better!</p>
                                    </div>
                                    <img alt="alt" src={freePikStarImage}/>
                                </div>
                                <div className="rate-us">
                                    <p className="rate-us-heading">Rate us?</p>
                                    <p className="rate-us-description">
                                        How was your overall travel experience with us?
                                    </p>
                                    <div className="d-flex rate-us-emoji flex-wrap ">
                                        {RateUsEmoji.map((data, index) => (
                                            <img
                                                key={index}
                                                className={
                                                    activeEmoji === data.id
                                                        ? "active-emoji"
                                                        : "inActive-emoji"
                                                }
                                                onClick={() => setActiveEmoji(data.id)}
                                                alt="alt"
                                                src={
                                                    activeEmoji === data.id
                                                        ? data.ActiveEmoji
                                                        : data.image
                                                }
                                            />
                                        ))}
                                    </div>

                                    {activeEmoji === 1 && (
                                        <div>
                                            <p className="rate-us-heading">Staff’s hospitality</p>
                                            <p className="rate-us-description">
                                                Were we able to help you out with everything?
                                            </p>
                                            <div className="d-flex rate-us-emoji flex-wrap ">
                                                {RateUsEmoji.map((data, index) => (
                                                    <img
                                                        key={index}
                                                        className={
                                                            activeEmoji === data.id
                                                                ? "active-emoji"
                                                                : "inActive-emoji"
                                                        }
                                                        alt="alt"
                                                        src={
                                                            activeEmoji === data.id
                                                                ? data.ActiveEmoji
                                                                : data.image
                                                        }
                                                    />
                                                ))}
                                            </div>
                                            <p className="rate-us-heading">Cleanliness</p>
                                            <p className="rate-us-description">
                                                Did you find everything clean?
                                            </p>
                                            <div className="d-flex rate-us-emoji flex-wrap ">
                                                {RateUsEmoji.map((data, index) => (
                                                    <img
                                                        key={index}
                                                        className={
                                                            activeEmoji === data.id
                                                                ? "active-emoji"
                                                                : "inActive-emoji"
                                                        }
                                                        // onClick={() => setActiveEmoji(data.id)}
                                                        alt="alt"
                                                        src={
                                                            activeEmoji === data.id
                                                                ? data.ActiveEmoji
                                                                : data.image
                                                        }
                                                    />
                                                ))}
                                            </div>

                                            <p className="rate-us-heading">Write a review</p>
                                            <p className="rate-us-description">
                                                Add photos and let our dear customers know more about
                                                your experience with GreenCelll.
                                            </p>

                                            <textarea className="feed-back-text-box"></textarea>

                                            {/* Image upload */}
                                            <div className="file-upload">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        className="file-upload-input"
                                                        type="file"
                                                        // onchange="readURL(this);"
                                                        accept="image/*"
                                                    />
                                                    <div className="d-flex align-items-center drag-text">
                                                        <img src={DragText} alt="alt"/>
                                                        <div>
                                                            <p className="drag-text-heading">
                                                                Upload an image
                                                            </p>
                                                            <p className="drag-text-description">
                                                                Supports .jpg or .png upto 3MB
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                        </div>
                                    )}

                                    <button
                                        onClick={() => setFeedBack(true)}
                                        className={
                                            isDesktopOrLaptop
                                                ? " m-0 rate-us-btn d-flex justify-content-start"
                                                : "rate-us-btn d-flex justify-content-center"
                                        }
                                    >
                                        Share Feedback
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* feedBack */}
                        {feedBack && (
                            <div className="right-side">
                                <div className="d-flex opinion justify-content-between align-items-center">
                                    <div>
                                        <p className="opinion-heading text-nowrap">
                                            Thank you for the feedback!
                                        </p>
                                        <p className="opinion-help">
                                            It will help us continue to improve our services
                                        </p>
                                    </div>
                                    <img alt="alt" src={freePikStarImage}/>
                                </div>
                                <div className="rate-us">
                                    <p className="rate-us-heading">Loved travelling with us?</p>
                                    <p className="rate-us-description">
                                        Tell everyone about your experience and rate us on the app store.
                                    </p>
                                </div>
                                <button className="d-flex align-items-center justify-content-center rate-us-app-store">
                                    <img src={RateUsPlayStore} alt="alt" className="icon-24"/>
                                    Rate us on Play store
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {!isDesktopOrLaptop && <hr className="hr-line"></hr>}
            <div className="eco-contribution-desktop">
                <div className="eco-contribution">
                    <p className="eco-heading">Your Eco Contribution</p>
                </div>

                {!isDesktopOrLaptop && (
                    <div className="eco-contribution-carousel">
                        <div className="carousel-inner">
                            <Swiper
                                className={"py-2 px-1"}
                                spaceBetween={isDesktopOrLaptop ? 32 : 16}
                                ref={sliderRef}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 1.2,
                                    },
                                    1: {
                                        slidesPerView: 1.2,
                                    },
                                }}
                            >
                                {EcoContributionData.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index} className="">
                                            <div className="d-flex justify-content-between eco-contribution-box">
                                                <div>
                                                    <p className="eco-contribution-box-heading">
                                                        {data.name}
                                                    </p>
                                                    <p className="eco-contribution-box-description">
                                                        {data.description}
                                                    </p>
                                                </div>
                                                <img
                                                    className="eco-contribution-box-image"
                                                    src={data.image}
                                                    alt="alt"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                )}
                <div className="d-flex eco-contrifution-boxes">
                    {isDesktopOrLaptop &&
                        EcoContributionData.map((data, index) => (
                            <div
                                key={index}
                                className="d-flex justify-content-between eco-contribution-box"
                            >
                                <div>
                                    <p className="eco-contribution-box-heading">{data.name}</p>
                                    <p className="eco-contribution-box-description">
                                        {data.description}
                                    </p>
                                </div>
                                <img
                                    className="eco-contribution-box-image"
                                    src={data.mobileImage}
                                    alt="alt"
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default CompletedTripDetails;
