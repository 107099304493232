/**
 * @generated SignedSource<<5179e59ff529240ede31af1d4c9ad82e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BookingType",
    "kind": "LinkedField",
    "name": "pendingReviews",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sourceCityName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "destinationCityName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingReviewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PendingReviewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b0389924c15bf1568f0946c3e64d46fe",
    "id": null,
    "metadata": {},
    "name": "PendingReviewQuery",
    "operationKind": "query",
    "text": "query PendingReviewQuery {\n  pendingReviews {\n    id\n    sourceCityName\n    destinationCityName\n  }\n}\n"
  }
};
})();

node.hash = "c4e64eafba89e8f6c28991e61bbc1ee7";

module.exports = node;
