import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
    mutation AddNewChecklistItemMutation(
        $checkListId:String!,
        $isAadhaar:Boolean!,
        $isPrescription:Boolean!,
        $checkListItems:[ChecklistItemInput]!,
    ){
        createCheckListItemAndReminder(
            checkListId:$checkListId,
            isAadhaar:$isAadhaar,
            isPrescription:$isPrescription,
            checkListItems:$checkListItems,
        ){
            status
            message
            checkList{
            id
            name
            isReminder
            isAadhaar
            isPrescription
            checklistitemSet{
                edges{
                node{
                    id
                    itemName
                    isChecked
                }
                }
            }
            checklistreminderSet{
                edges{
                node{
                    id
                    reminderDate
                    reminderTime
                }
                }
            }
            }
        }
    }
    `;

export default (variables, callback, errCallback) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.createCheckListItemAndReminder !== null) {
                    callback(response);
                } else {
                    errCallback(err);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    );
}
