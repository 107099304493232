import graphql from "babel-plugin-relay/macro";

const GetAvailableBusListingFilterQuery = graphql`
    query GetAvailableBusListingFilterQuery(
        $sourceId: Int!,
        $destinationId:Int!,
        $date: String!,
        $seatType:[BusSeatTypeEnum],
        $busType: [BusTypeEnum],
        $busTiming: [BusTimingEnum],
        $endPrice: Float,
        $startPrice: Float,
        $droppingPoints: [Int],
        $boardingPoints: [Int],
        $amenities: [Int],
        $sortBy: BusListingSortEnum,
    ) {
        getAvailableBusListingFilter(
            sourceId:$sourceId,
            destinationId:$destinationId,
            date:$date,
            seatType:$seatType,
            busType:$busType,
            busTiming:$busTiming,
            endPrice:$endPrice,
            startPrice:$startPrice,
            droppingPoints:$droppingPoints,
            boardingPoints:$boardingPoints,
            amenities:$amenities,
            sortBy:$sortBy,
        ) {
            busListing{
                ID
                RouteID
                TripNo
                ArrangementID
                RouteScheduleCode
                BookingDate
                BusType

                CityTime24
                ArrivalTime24

                FromCityId
                FromCityName

                ToCityId
                ToCityName

                RouteTime

                Kilometer

                CityTime
                ArrivalTime

                DurationMinutes

                EmptySeats

                HoldTime
                Duration

                BusSeatType

                PackageAmenities

                RouteAmenities{
                    id
                    title
                    imageData{
                        image
                    }
                }

                BoardingPoints
                DroppingPoints

                ReferenceNumber

                AcSeatRate
                NonAcSeatRate

                DisCountType
                DiscountRate

                IsSameDay
                RouteTimeID
                getPrice
                originalPrice

                CarbonEmission
                GreenMiles
            }
            droppingPoints{
                id
                name
                time
            }
            boardingPoints{
                id
                time
                name
            }
            amenities{
                id
                title
                description
                imageData{
                    image
                }
            }
        }
    }
`
export default GetAvailableBusListingFilterQuery