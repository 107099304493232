import {AUTH_MODAL_TYPE, LOGIN_DATA, SET_TOKEN_DATA, SET_USER_AUTH_DETAILS,
	SIGN_UP_DATA,SET_PROFILE_DATA} from "./index";

export const setAuthType = (data) => ({
	type: AUTH_MODAL_TYPE,
	payload: {
		data: data
	}
})

export const setLoginDataSubmission = (data) => ({
	type: LOGIN_DATA,
	payload: {
		data: data
	}
})
export const setSignupDataSubmission = (data) => ({
	type: SIGN_UP_DATA,
	payload: {
		data: data
	}
})
export const setUserAuthDetails = (data) => ({
	type: SET_USER_AUTH_DETAILS,
	payload: {
		data: data
	}
})

export const setTokenData = (data) => ({
	type: SET_TOKEN_DATA,
	payload: {
		data: data
	}
})

export const setProfileData = (data) => ({
	type: SET_PROFILE_DATA,
	payload: {
		data: data
	}
})



