import React, {useEffect} from 'react'
import {useNavigate} from "react-router";

const AddMoneyResultPage = () => {
	const navigate = useNavigate()

	useEffect(() => {

		if (window.location.href.includes("recharge")) {
			navigate("/wallet", {replace:false,state:{recharged:true}})
		} else {
			navigate("/wallet", {replace:false,state: {recharged: false}})
		}
		return () => {

		};
	}, []);


	return (
		<div>

		</div>
	)
}

export default AddMoneyResultPage
