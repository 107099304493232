import React, {useState} from 'react'
import {AnimatePresence, motion} from "framer-motion"
import {useMediaQuery} from "react-responsive";
import {store} from "../../../index";
import {sendTicketToMailData, sendTicketToSmsData} from "../../../services/api/DataFetching";
import {Link} from "react-router-dom"
import {useNavigate} from 'react-router';
// icons
import arrowDown from "../../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg"
import addButton from "../../../assets/icons/add-circle-icon-white.svg"
import rightArrow from "../../../assets/icons/green-arrow-right.svg"
import emailIcon from "../../../assets/icons/mail-black-icon.svg"
import whatsappIcon from "../../../assets/icons/whatsapp-black-icon.svg"
import smsIcon from "../../../assets/icons/sms-black-icon.svg"
import scanIconGreen from "../../../assets/icons/scan-icon-green.svg"
import CheckLuggageSizeUpcomingIcon from "../../../assets/icons/luggage-icon-gray.svg"
import BookingPolicyUpcomingIcon from "../../../assets/icons/booking-policy-gray-icon.svg"
import TravelGuideUpcomingIcon from "../../../assets/icons/travel-guide-icon.svg"
import HelpUpcomingIcon from "../../../assets/icons/help-gray-icon.svg"
import PoliciesComponent from '../../../components/PoliciesComponent/PoliciesComponent';
import {
	setTermsConditionsModalAction,
  } from "../../../store/Actions/CommonAction";

//assets
const luggageSizeIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/luggage-size-illustration.png`

const UpcomingAccordionTicketDetail = (props) => {
    const [expanded, setExpanded] = useState(null);
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const {setQrModal, qrModal} = props

    let pageConstant

    if (isDesktopOrLaptop) {
        pageConstant = {
            recieveText_class: "ubuntu-500w-18s-28h grey-2-78787d-color whitespace-nowrap mb-0",
            linkText_class: "open-600w-14s-22h mb-0"
        }
    } else {
        pageConstant = {
            recieveText_class: "open-600w-16s-24h grey-2-78787d-color whitespace-nowrap mb-0 ",
            linkText_class: "open-600w-14s-22h mb-0"
        }
    }

    const accordionComponentList = [
        {
            id: 1,
            title: "Check Luggage Size",
            icon: CheckLuggageSizeUpcomingIcon,
            component: <CheckLuggageSizeUpcomingTicketDetail/>
        }, {
            id: 2,
            title: "Booking Policy",
            icon: BookingPolicyUpcomingIcon,
            // component: <BookingPolicyUpcomingTicketDetail/>
            component: <PoliciesComponent/>
        },
        {
            id: 3,
            title: "Travel Guide",
            icon: TravelGuideUpcomingIcon,
            component: <TravelGuideUpcomingTicketDetail/>
        },
        {
            id: 4,
            title: "Help",
            icon: HelpUpcomingIcon,
            component: <HelpUpcomingTicketDetail/>
        }
    ]

    // methods
    const sendToSms = () => {
        let variable = {
            bookingId: store.getState().CommonReducer.selectedBookingId,
        }
        sendTicketToSmsData(variable)

    }

    const sendToMail = () => {
        let variable = {
            bookingId: store.getState().CommonReducer.selectedBookingId,
        }
        sendTicketToMailData(variable)

    }

    return (
        <div className={'upcoming-accordion-ticket-detail'}>
            {!isDesktopOrLaptop && <hr className={'mobile-section-divider'}/>}
            <div className={'receive-ticket-card d-lg-flex align-items-center justify-content-between gap-12'}>
                <p className={pageConstant.recieveText_class}>Receive ticket by</p>
                <div className={'d-flex gap-12 flex-wrap'}>
                    <div className={'d-flex media-box cursor-pointer'} onClick={sendToMail}>
                        <img src={emailIcon} alt={'email-icon'} className={'icon-24'}/>
                        <p className={pageConstant.linkText_class}>Email</p>
                    </div>
                    <div className={'d-flex media-box cursor-pointer'}>
                        <img src={whatsappIcon} alt={'whatsapp-icon'} className={'icon-24'}/>
                        <p className={pageConstant.linkText_class}>Whatsapp</p>
                    </div>
                    <div className={'d-flex media-box cursor-pointer'} onClick={sendToSms}>
                        <img src={smsIcon} alt={'sms-icon'} className={'icon-24'}/>
                        <p className={pageConstant.linkText_class}>SMS</p>
                    </div>
                </div>
            </div>
            {!isDesktopOrLaptop && <hr className={'mobile-section-divider'}/>}

            <button className={'secondary-button-teal gap-14 d-flex mt-3 align-items-center'}
                    onClick={() => setQrModal(true)}>
                <img src={scanIconGreen} alt={'scan-icon'} className={'scan-icon'}/>
                <p className={'open-600w-16s-24h teal-2-00A095-color mb-0'}>View Digital Ticket</p>
            </button>

            <div className={'accordion-wrap py-3'}>
                {
                    accordionComponentList.map((item, index) => {
                        return <div key={index} className={'custom-accordion'}>
                            <motion.div
                                initial={false}
                                className={(item.id === expanded ? 'header-active' : 'header')}
                                // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                                onClick={() => setExpanded(item.id === expanded ? null : item.id)}
                            >
                                <div className={'d-flex gap-12 align-items-center '}>
                                    <img src={item.icon} alt={'accordion-icon'} className={'icon-24'}/>
                                    <p>{item.title}</p>
                                </div>

                                <motion.div animate={{rotate: expanded === item.id ? 180 : 0}}>
                                    <img src={arrowDown} alt={'alt'} className={'icon-24'}/>
                                </motion.div>
                            </motion.div>
                            {/* <AnimatePresence initial={false}> */}
                                {/*{item.id === expanded && (*/}
                                <motion.div
                                    key="content" className={' py-3 '+(expanded === item.id ?"active-content":"inactive-content")}
                                    initial={false}
                                    animate={{opacity: expanded === item.id ? 1 : 0}}
                                    transition={{duration: 0.5, ease: "easeInOut"}}
                                >
                                    {item.component}
                                </motion.div>
                                {/*)}*/}
                            {/* </AnimatePresence> */}
                        </div>
                    })
                }

            </div>

        </div>
    )
}

const CheckLuggageSizeUpcomingTicketDetail = () => {
    let luggageCount = 2;
    return <div className={'mx-3 '}>
        <div className={'d-flex justify-content-between pt-4'}>
            <p className={'open-600w-14s-22h mb-0'}>Luggage Pieces</p>
            <p className={'open-400w-14s-22h mb-0'}><span
                className={'open-700w-14s-22h'}>{luggageCount}</span> per person</p>
        </div>
        <hr className={'horizontal-divider'}/>
        <div className={'d-flex justify-content-between '}>
            <p className={'open-400w-14s-22h w-75'}>the total combined dimensions (length + breadth +
                height) of
                both pieces
                should not exceed</p>
            <div className={'text-right'}>
                <p className={'open-700w-14s-24h mb-0'}>273 cm</p>
                <p className={'open-400w-14s-22h'}>107 in.</p>
            </div>
        </div>
        <img src={luggageSizeIllustration} alt={'alt'} className={'luggage-size-illustration'}/>
        <hr className={'horizontal-divider'}/>
        <div className={'d-flex justify-content-between'}>
            <p className={'open-400w-14s-22h w-75'}>The weight of each bag must not exceed</p>
            <div className={'text-right'}>
                <p className={'open-700w-14s-24h mb-0'}>20 kg
                </p>
                <p className={'open-400w-14s-22h'}>(50 lb)</p>
            </div>
        </div>
    </div>
}
// const BookingPolicyUpcomingTicketDetail = () => {
//     const [activeTab, setActiveTab] = useState('Refund');
//     const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
//     let componentConstant
//     if (isDesktopOrLaptop) {
//         componentConstant = {
//             tabHeading_class: "open-600w-14s-22h mb-0 ",
//             highlighted_class: "open-700w-16s-24h mb-0 black-1E1E26-color",
//             subText_class: "open-400w-14s-24h mb-0 ",
//             key_class: "key-elements open-400w-16s-24h mb-0"
//         }
//     } else {
//         componentConstant = {
//             tabHeading_class: "open-700w-14s-22h mb-0 ",
//             highlighted_class: "open-700w-14s-22h mb-0 black-1E1E26-color",
//             subText_class: "open-400w-12s-18h mb-0 ",
//             key_class: "key-elements open-400w-14s-22h mb-0"
//         }
//     }

//     return <div>
//         <div className={'d-flex tab-wrap align-items-center'}>
//             <div
//                 className={componentConstant.tabHeading_class + 'w-100 text-center ' + (activeTab === 'Refund' ? 'active-tab' : 'in-active-tab')}
//                 onClick={() => setActiveTab('Refund')}
//             >Refund Policy
//             </div>
//             <div
//                 className={componentConstant.tabHeading_class + 'w-100 text-center ' + (activeTab === 'Cancellation' ? 'active-tab' : 'in-active-tab')}
//                 onClick={() => setActiveTab('Cancellation')}
//             >Cancellation Policy
//             </div>
//         </div>
//         <div>
//             {
//                 activeTab === 'Refund' ?
//                     <div className={''}>
//                         <div className={'px-3 py-3'}>
//                             <div className={'row'}>
//                                 <div className={'col-6 d-lg-flex gap-12'}>
//                                     <p className={componentConstant.highlighted_class}>Rescheduling Time</p>
//                                     <p className={componentConstant.subText_class + " whitespace-nowrap"}>(Before
//                                         journey)</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.highlighted_class + " whitespace-nowrap"}>Refund
//                                         %</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.highlighted_class + " whitespace-nowrap"}>Refund
//                                         ₹</p>
//                                 </div>
//                             </div>
//                             <hr className={'line-seperator'}/>
//                             <div className={'row mb-3'}>
//                                 <div className={'col-6 d-flex'}>
//                                     <p className={componentConstant.key_class}>more than 48 hrs</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.key_class}>80%</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.highlighted_class}>₹1800</p>
//                                 </div>
//                             </div>
//                             <div className={'row mb-3'}>
//                                 <div className={'col-6 d-flex'}>
//                                     <p className={componentConstant.key_class}>25-48 hrs</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.key_class}>50%</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.highlighted_class}>₹1000</p>
//                                 </div>
//                             </div>
//                             <div className={'row mb-3'}>
//                                 <div className={'col-6 d-flex'}>
//                                     <p className={componentConstant.key_class}>0-24 hrs</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.key_class}>20%</p>
//                                 </div>
//                                 <div className={'col-3'}>
//                                     <p className={componentConstant.highlighted_class}>₹500</p>
//                                 </div>
//                             </div>
//                             <hr className={'line-seperator'}/>
//                         </div>
//                         <ul>
//                             {
//                                 [
//                                     'The penalty is calculated based on total seat worth 910.',
//                                     'Partial cancellation is not allowed for \n' +
//                                     'this ticket.',
//                                     'Cancellation amount shown above may also vary basis the non-refundable components of the ticket defined by the bus operator.'
//                                 ].map((item, index) => {
//                                     return <li key={index} className={componentConstant.key_class}>{item}</li>
//                                 })
//                             }
//                         </ul>
//                     </div>
//                     :
//                     <div></div>
//             }
//         </div>
//     </div>
// }
const TravelGuideUpcomingTicketDetail = () => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    let componentConstant
    if (isDesktopOrLaptop) {
        componentConstant = {
            showAddImageInButton: true,
            tabHeading_class: "open-600w-14s-22h mb-0 ",
            highlighted_class: "open-700w-16s-24h mb-0 black-1E1E26-color",
            button_class: "open-600w-16s-24h white-color mb-0 ",
            key_class: "key-elements open-400w-16s-24h mb-0",
            button_width_class: "w-fit-content"
        }
    } else {
        componentConstant = {
            showAddImageInButton: false,
            tabHeading_class: "open-700w-14s-22h mb-0 ",
            highlighted_class: "open-700w-14s-22h mb-0 black-1E1E26-color",
            button_class: "open-600w-16s-24h white-color mb-0 ",
            key_class: "key-elements open-400w-14s-22h mb-0",
            button_width_class: " w-100 "
        }
    }
    return <div>
        <ul>
            {
                [
                    'Please arrive at the bus stop 20 mins prior to the Boarding time.',
                    'Kindly carry your vaccination certificate or RTPCR report.',
                    'Carry your id card for identity proof.'
                ].map((item, index) => {
                    return <li key={index} className={componentConstant.key_class}>{item}</li>
                })
            }
        </ul>
        <div className={'d-flex justify-content-center justify-content-lg-start w-100 px-3'}>
            <Link to='/profile-detail'>
                <button className={'primary-button-teal ' + componentConstant.button_width_class}>
                    {componentConstant.showAddImageInButton &&
                        <img src={addButton} alt={'addButton'} className={'add-button'}/>}
                    <p className={componentConstant.button_class}>Create your own Checklist</p>
                </button>
            </Link>
        </div>

    </div>
}
const HelpUpcomingTicketDetail = () => {
    const navigate = useNavigate()
    let componentConstant
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    if (isDesktopOrLaptop) {
        componentConstant = {
            text_class: "open-600w-16s-24h mb-0 black-1E1E26-color",
        }
    } else {
        componentConstant = {
            text_class: "open-600w-14s-22h mb-0 black-1E1E26-color",
        }
    }

    const faqRedirectionHandler = () => {
        navigate('/faq',
            {
                state: {
                    faqDetailId: "2"
                }
            }
        )
    }

    const customerCareRedirection = () => {
        navigate('/faq',
            {
                state: {
                    focusOnCustomerCare: true
                }
            }
        )
    }

    const termsConditionsHandler = (path) => {
		if (isDesktopOrLaptop) {
		  store.dispatch(setTermsConditionsModalAction(true));
		} else {
		  if (path) {
			navigate(path, { replace: false });
		  }
		}
	  };

    return <div className={'px-3 px-lg-4'}>
        <div className={'d-flex justify-content-between align-items-center mb-4'} onClick={faqRedirectionHandler}>
            <p className={componentConstant.text_class}>Frequently Asked Questions</p>
            <img src={rightArrow} alt={'downArrow'} className={'down-arrow'}/>
        </div>

        <div className={'d-flex justify-content-between align-items-center mb-4'} onClick={() => termsConditionsHandler("/terms-conditions")}>
            <p className={componentConstant.text_class}>Terms & Conditions</p>
            <img src={rightArrow} alt={'downArrow'} className={'down-arrow'}/>
        </div>

        <div className={'d-flex justify-content-between align-items-center mb-4'}>
            <p className={componentConstant.text_class}>Travel Instructions</p>
            <img src={rightArrow} alt={'downArrow'} className={'down-arrow'}/>
        </div>

        <div className={'d-flex justify-content-between align-items-center'} onClick={customerCareRedirection}>
            <p className={componentConstant.text_class}>Contact Customer Service</p>
            <img src={rightArrow} alt={'downArrow'} className={'down-arrow'}/>
        </div>
    </div>
}

export default UpcomingAccordionTicketDetail
