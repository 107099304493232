import React from 'react'
import './PageNotFound.scss'
import Navbar from "../../components/Navbar/Navbar";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import {useMediaQuery} from "react-responsive";


// assets
const pageNotFoundIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/page-not-found-main-illustration.png`

const PageNotFound = (props) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    let pageConstants

    if (isDesktopOrLaptop) {
        pageConstants = {
            pageTitle_text: "ubuntu-700w-32s-48h",
            description_text: "ubuntu-700w-24s-32h"
        }
    } else {
        pageConstants = {
            pageTitle_text: "ubuntu-700w-24s-36h",
            description_text: "ubuntu-700w-14s-22h"

        }
    }

    return (

        <AuthModalLayout>
            <div className={'page-not-found black-1E1E26-bg'}>
                <div className={'navbar-wrapper margin-bottom-100'}>
                    <Navbar removeFixed={true}/>
                </div>

                <div className={'white-fold white-fold-padding'}>
                    <div className={'inner-div'}>
                        <p className={'mb-5 ' + pageConstants.pageTitle_text}>Booking Confirmation</p>
                        <div className={'d-flex justify-content-center text-center align-items-center'}>
                            <div>
                                <img src={pageNotFoundIllustration} alt="Page not found"
                                     className={'page-not-found-illustration'}/>
                                <p className={'' + pageConstants.description_text}>The page you’re looking for doesn’t
                                    exist</p>
                                <button className={'submit-button w-fit-content '}>
                                    Go To Home
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </AuthModalLayout>
    )
}

export default PageNotFound
