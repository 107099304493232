import React, {useEffect, useState} from 'react'
import './CouponList.scss'
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate} from "react-router";
import CouponListComponent from "../../components/CouponListComponent/CouponListComponent";
import {useMediaQuery} from "react-responsive";
import {ApplyCouponData} from "../../services/api/DataUpdating";
import {connect, useDispatch} from "react-redux";
import { setGreenMilesStateAction } from '../../store/Actions/CommonAction';

const CouponList = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const {bookingDataFromStore,passengerDataFromStore,
        contactDataFromStore} = props

    const [couponError, setCouponError] = useState(false);

    useEffect(() => {
        if (isDesktopOrLaptop) {
            navigate(-1)
        }
    }, [isDesktopOrLaptop]);

    const applyingCoupon = (discountId, couponCode) => {
        let variable = {
            bookingId: bookingDataFromStore.id,
            discountId: discountId,
            couponCode: couponCode,
            email: contactDataFromStore.email,
			mobileNo: contactDataFromStore.phoneNumber,
			passengerDetails: passengerDataFromStore
        }
        ApplyCouponData(variable, () => {
            setCouponError(false)
            dispatch(setGreenMilesStateAction(false))
            navigate(-1, {
                state: {
                    couponApplied: true,
                }
            })
        }, () => {
            setCouponError(true)
        })

    }

    return (
        <MobileSubScreenLayout back={() => navigate(-1)} title={"Select Coupon"}>
            <div className={'coupon-list-page'}>

                <CouponListComponent
                    referenceNo={bookingDataFromStore.referenceNo}
                    couponError={couponError}
                    setCouponError={setCouponError}
                    applyingCoupon={(discountId, couponCode) => applyingCoupon(discountId, couponCode)}/>

            </div>
        </MobileSubScreenLayout>
    )
}

const mapStateToProps = (state) => ({
    bookingDataFromStore: state.BookingReducer.setBookingData.booking,
    passengerDataFromStore: state.BookingReducer.passengerData, // setInsureAndDonateToStore: state.BookingReducer.insure,
	contactDataFromStore: state.BookingReducer.setContactData,
})

export default connect(mapStateToProps)(CouponList)
