import graphql from "babel-plugin-relay/macro";

const GetCancelDetailsQuery = graphql`
		query GetCancelDetailsQuery($bookingId: String!) {
			cancelDetails(bookingId: $bookingId) {
				totalRefund
				cancellationFee
				totalFare
				walletAmount
				paymentGatewayAmount
				cancellationCharges
				insuranceRefundAmount
				promotionalWalletRefund
				nonPromotionalWalletRefund

				booking {
				    passengerdetailSet {
				        id
				        name
				        age
				        gender
				    }
				}
			}
		}
	`

export default GetCancelDetailsQuery;