// AUTH
export const AUTH_MODAL_TYPE = "AUTH_MODAL_TYPE"
export const LOGIN_DATA = "LOGIN_DATA"
export const SIGN_UP_DATA = "SIGN_UP_DATA"
export const SET_USER_AUTH_DETAILS = "SET_USER_AUTH_DETAILS"
export const SET_TOKEN_DATA = "SET_TOKEN_DATA"
export const SET_PROFILE_DATA = "SET_PROFILE_DATA"

// BOOKING
export const SOURCE_POINT = "SOURCE_POINT"
export const DESTINATION_POINT = "DESTINATION_POINT"
export const SOURCE_DESTINATION_POINTS = "SOURCE_DESTINATION_POINTS"
export const ACTIVE_BUS_DETAIL = "ACTIVE_BUS_DETAIL"
export const SELECTED_SEATS = "SELECTED_SEATS"
export const SELECTED_SEATS_TYPE = "SELECTED_SEATS_TYPE"
export const SELECTED_SEAT_PRICE = "SELECTED_SEAT_PRICE"
export const SET_PASSENGER_DATA = "SET_PASSENGER_DATA"
export const SET_SOURCE_POINT_LIST = "SET_SOURCE_POINT_LIST"
export const SET_DESTINATION_POINT_LIST = "SET_DESTINATION_POINT_LIST"
export const SET_AVAILABLE_BUS_LIST = "SET_AVAILABLE_BUS_LIST"
export const SET_BOARDING_POINT = "SET_BOARDING_POINT"
export const SET_DROPPING_POINT = "SET_DROPPING_POINT"
export const SEAT_ARRANGEMENT = "SEAT_ARRANGEMENT"
export const SET_CONTACT_DATA = "SET_CONTACT_DATA"
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_BOOKING_DATA = "SET_BOOKING_DATA"
export const SET_PICKUP_DROP_LIST = "SET_PICKUP_DROP_LIST"
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const SET_INSURE_AND_DONATE = "SET_INSURE_AND_DONATE"
export const SET_SAVE_DETAIL_AND_INVOICE = "SET_SAVE_DETAIL_AND_INVOICE"
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD"
export const RAW_DATE = "RAW_DATE"
export const SOURCE_DESTINATION_ID = "SOURCE_DESTINATION_ID"
export const SET_DATE = "SET_DATE"
export const SET_PASSENGER_COUNT = "SET_PASSENGER_COUNT"
export const GET_CO_PASSENGERS_LIST = "GET_CO_PASSENGERS_LIST"
export const CHANGE_DATE_BUS_LISTING = "CHANGE_DATE_BUS_LISTING"
export const SET_COUPON_MESSAGE = 'SET_COUPON_MESSAGE'
export const ACTIVE_BUS_FEMALE_AVAILABLE = 'ACTIVE_BUS_FEMALE_AVAILABLE'
export const CLEAR_BOOKING_DATA_ON_LOGOUT = 'CLEAR_BOOKING_DATA_ON_LOGOUT'


// COMMON
export const SET_MOBILE_PAGE = "SET_MOBILE_PAGE"
export const CLEAR_ON_CHECKOUT = "CLEAR_ON_CHECKOUT"
export const CLEAR_USER_DATA = "CLEAR_USER_DATA"
export const POST_BOOKING_DATA = "POST_BOOKING_DATA"
export const FILTER_MODAL_ACTION = "FILTER_MODAL_ACTION"
export const SET_RESCHEDULE = "SET_RESCHEDULE"
export const SET_SELECTED_BOOKING_ID = 'SET_SELECTED_BOOKING_ID'
export const SET_RECENT_SEARCH = 'SET_RECENT_SEARCH'
export const SET_VERSION = 'SET_VERSION'
export const SET_TERMS_CONDITIONS_MODAL = 'SET_TERMS_CONDITIONS_MODAL'
export const SET_CHECKLIST_NAME = 'SET_CHECKLIST_NAME'
export const SET_LOADING_STATE = 'SET_LOADING_STATE'
export const SET_GREEN_MILES_STATE  = "SET_GREEN_MILES_STATE"

// FILTER
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const SET_BOARDING_POINT_FILTER = "SET_BOARDING_POINT_FILTER"
export const SET_DROPPING_POINT_FILTER = "SET_DROPPING_POINT_FILTER"
export const SET_AMENITIES_FILTER = "SET_AMENITIES_FILTER"