import React ,{useState,useEffect}from 'react'
import { Helmet } from 'react-helmet';
import './News.scss'
import NewsComponent from './components/NewsComponent';
import AnnouncementComponent from './components/AnnouncementComponent';
import MoreOnNuegoCard from './components/MoreOnNuegoCard';
import Navbar from "../../components/Navbar/Navbar";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/Footer/Footer";
import AuthModalLayout from '../../layout/AuthModalLayout/AuthModalLayout';
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import EnvFinanceCard from './components/EnvFinanceCard';
import TwitterComponent from './components/TwitterComponent';

import { useNavigate } from "react-router";
import { Link } from "react-router-dom"
import Loader from "../../components/Loader/Loader";

const News = () => {
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();
	

	return (
		<div className={"news-page"}>
		<Helmet>
				<title>NueGo's Bus Travel News | NueGo</title>
				<link rel="canonical" href="https://www.nuego.in/news" hreflang="en-IN" />
				<meta name="description" content="Get bus travel news from NueGo and stay updated with the latest travel trends." />
				<meta name="keywords" content="bus travel news, NueGo travel news "></meta>
				<meta name="robots" content="index,follow"/>
				<script type='application/ld+json'>
					{JSON.stringify({
					"@context": "http://schema.org",
					"@type": "WebPage",
					"url": "https://www.nuego.in/news",
					"name": "NueGo News",
					"description": "Get bus travel news from NueGo and stay updated with the latest travel trends.",
					"publisher": {
						"@type": "Organization",
						"name": "NueGo",
						"logo": {
						"@type": "ImageObject",
						"url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
						"width": 200,
						"height": 100
						}
					}
					})}
				</script>
		</Helmet>
		{

			isDesktopOrLaptop ? <AuthModalLayout>
				<MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
			</AuthModalLayout>
				:
				<MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo News"}>
					<MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
				</MobileSubScreenLayout>
		}
	</div>
		
	)
}

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);


    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>News</p>
                    </div>
                    }
                        
					<NewsComponent/>
				<div style={{marginTop:'36px', marginBottom:"36px"}}>
				<AnnouncementComponent/>
				</div>
				<MoreOnNuegoCard/>
				<TwitterComponent/>
				<EnvFinanceCard/> 
                 
                           
                    </div>

            }
            <Footer />
        </>
    );
}

export default News

