import React, { useState } from "react";
import "./ReviewCard.scss";
import { store } from "../../index";
import { useMediaQuery } from "react-responsive";
import { convertRawDateToDateAndMonth } from "../../utils/utils";
import uuid from "react-uuid";
// uber cab booking mutation
import { UberHookRedirection } from "../../services/api/DataUpdating";

//icons
import seatOutline from "../../assets/icons/seat-outline-icon.svg";
import luggageIcon from "../../assets/icons/luggage-outline-icon.svg";
import sourcePointIcon from "../../assets/icons/source-dot-review-card.svg";
import destinationPointIcon from "../../assets/icons/destionation-dot-review-card.svg";
import viewIconGreen from "../../assets/icons/view-invoice-green-icon.svg";
import leftArrowIcon from "../../assets/icons/white-arrow-left.svg";
import rightArrowIcon from "../../assets/icons/white-arrow-right.svg";
import refundStatusIcon from "../../assets/icons/refund-policy-grey-icon.svg";
import refundProcessedIcon from "../../assets/icons/destionation-dot-review-card.svg";
import infoIcon from "../../assets/icons/info-icon-green.svg";
import closeIcon from "../../assets/icons/cross-black.svg";
import NueMilesIcon from "../../assets/icons/nue-miles-icon.svg";
import { MDBModal, MDBModalBody } from "mdbreact";
import BottomTrayComponent from "../BottomTrayComponent/BottomTrayComponent";
import { DownloadInvoiceUrlData } from "../../services/api/DataFetching";

//assets
const BooKCab = process.env.REACT_APP_CDN_LINK + `assets/images/book-cab.svg`;

const ReviewCardV2 = (props) => {
  const { passengerDataForRescheduleFromStore, editGuest, data, ticketType } =
    props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });

  const activeBusDataFromStore =
    store.getState().BookingReducer.activeBusDetail;
  // const data = store.getState().BookingReducer.setBookingData.booking
  const passengerDataFromStore = store.getState().BookingReducer.passengerData;
  const selectedSeatsFromStore = store.getState().BookingReducer.selectedSeats;
  const [fareDetailModal, setFareDetailModal] = useState(false);
  const rescheduleFromStore = store.getState().CommonReducer.reschedule;
  const PostBooingDataFromStore =
    store.getState().CommonReducer.postBookingData;

  let componentConstant;

  if (isDesktopOrLaptop) {
    componentConstant = {
      city_class: "ubuntu-700w-24s-36h mb-1",
      place_class: "open-600w-16s-24h mb-0",
      time_class: "ubuntu-700w-20s-32h mb-1 text-nowrap",
      date_class: "open-600w-16s-24h",
      passengerDetail_class: "open-600w-18s-28h mb-2",
      seating_class: "open-700w-18s-28h",
      lugagge_class: "open-400w-16s-24h",
      guestCount_class: "open-700w-16s-24h",
      guest_class: "open-400w-16s-24h",
      pnrNumber_text_top_class: "open-400w-16s-24h white-color mb-0",
      pnrNumber_top_class: "ubuntu-700w-18s-28h white-color mb-0",
      refundStatusText_class: "open-600w-16s-24h mb-0",
      refundStatusView_class: "open-700w-14s-22h mb-0",
      refundStatusValue_class: "ubuntu-700w-12s-18h mb-0",
    };
  } else {
    componentConstant = {
      city_class: "open-700w-18s-28h mb-1",
      place_class: "open-600w-12s-18h mb-0",
      time_class: "open-700w-14s-22h mb-1 text-nowrap",
      date_class: "open-600w-12s-18h",
      passengerDetail_class: "open-600w-14s-22h mb-2",
      seating_class: "open-700w-14s-22h",
      lugagge_class: "open-600w-14s-22h",
      guestCount_class: "open-700w-14s-22h",
      guest_class: "open-400w-14s-22h",
      pnrNumber_text_top_class: " open-400w-12s-18h white-color  mb-0",
      pnrNumber_top_class: "open-400w-16s-24h white-color mb-0",
      refundStatusText_class: "open-600w-14s-22h mb-0",
      refundStatusView_class: "open-700w-14s-22h mb-0",
      refundStatusValue_class:
        "ubuntu-700w-12s-18h mb-0 whitespace-nowrap h-fit-content",
    };
  }

  const downloadInvoiceUrl = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };
    DownloadInvoiceUrlData(variable, (response) => {
      window.open(response.url);
    });
  };

  const BookACab = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };
    UberHookRedirection(variable);
  };

  return (
    <div className={"review-card-v2-component"}>
      <div
        className={
          "pnr-area d-flex justify-content-between align-items-center px-4 pt-4"
        }
      >
        <img src={leftArrowIcon} alt={"left-arrow"} className={"icon-24"} />
        <div className={"d-flex align-items-end gap-12"}>
          <p className={componentConstant.pnrNumber_text_top_class}>
            PNR Number
          </p>
          <p className={componentConstant.pnrNumber_top_class}>{data?.pnrNo}</p>
        </div>
        <img src={rightArrowIcon} alt={"right-arrow"} className={"icon-24"} />
      </div>
      <div className={"main-content-area py-4 "}>
        <div
          className={
            "position-relative route-data px-4  pb-2 " +
            (isDesktopOrLaptop ? " margin-bottom-32" : " mb-3")
          }
        >
          <div className={"left-circle"}>
            <div className={"left-circle-inner"} />
          </div>
          <div className={"right-circle"}>
            <div className={"right-circle-inner"} />
          </div>

          {/* source */}
          <div className={"d-flex w-100"}>
            <div className={"py-1 py-lg-2 pr-3"}>
              <img
                src={sourcePointIcon}
                alt={"alt"}
                className={"icon-24 pointing-icon"}
              />
            </div>
            <div className={"row w-100"}>
              <div className={"col-6 position-relative"}>
                {/*<img src={sourcePointIcon} alt={'alt'} className={'icon-24 pointing-icon'}/>*/}
                <hr className={"vertical-dashed-line"} />
                <p className={componentConstant.city_class}>
                  {data?.sourceCityName}
                </p>
                <p className={componentConstant.place_class}>
                  {data?.pickupName}
                </p>
              </div>
              <div className={"col-3"}></div>
              <div className={"col-3 px-0  px-lg-3 text-right"}>
                <p className={componentConstant.time_class}>
                  {data?.pickupTime}
                </p>
                <p className={componentConstant.date_class}>
                  {convertRawDateToDateAndMonth(data?.dateOfJourney)}
                </p>
              </div>
            </div>
          </div>

          {/*	destination*/}
          <div className={"d-flex w-100"}>
            <div className={"py-1 py-lg-2 pr-3"}>
              <img
                src={destinationPointIcon}
                alt={"alt"}
                className={"icon-24 pointing-icon"}
              />
            </div>
            <div className={"row w-100"}>
              <div className={"col-6 position-relative"}>
                {/*<img src={destinationPointIcon} alt={'alt'} className={'icon-24 pointing-icon'}/>*/}
                <p className={componentConstant.city_class}>
                  {data?.destinationCityName}
                </p>
                <p className={componentConstant.place_class}>
                  {data?.dropName}
                </p>
              </div>
              <div className={"col-3"}></div>
              <div className={"col-3 px-0 px-lg-3 text-right"}>
                <p className={componentConstant.time_class}>{data?.dropTime}</p>
                <p className={componentConstant.date_class}>
                  {convertRawDateToDateAndMonth(data?.expectedDropDatetime)}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* passenger details */}
        <div className={"w-100 pt-2 px-4"}>
          {PostBooingDataFromStore?.passengerdetailSet?.map((item, index) => {
            return (
              <div
                key={uuid()}
                className={
                  "d-flex justify-content-between align-items-center w-100"
                }
              >
                <div className={"d-flex"}>
                  <p
                    className={
                      componentConstant.passengerDetail_class + " mb-0"
                    }
                  >
                    {item.name}
                  </p>
                  <p
                    className={
                      componentConstant.passengerDetail_class +
                      "open-600w-14s-22h text-capitalize mb-0"
                    }
                  >
                    <span className={"ml-3 mr-0"}>({item.gender}),</span>{" "}
                    {item.age}
                  </p>
                </div>
                <div className={"d-flex"}>
                  <img src={seatOutline} alt={"alt"} className={"icon-24"}/>
                  <p className={componentConstant.seating_class}>
                    {PostBooingDataFromStore?.seatNo[index]}
                  </p>
                </div>
              </div>
            );
          })}

          {/* passengerDataFromStore?.map((item, index) => {
                return (
                  <div
                    key={uuid()}
                    className={
                      "d-flex justify-content-between align-items-center w-100"
                    }
                  >
                    <div className={"d-flex"}>
                      <p
                        className={
                          componentConstant.passengerDetail_class + " mb-0"
                        }
                      >
                        {item.name}
                      </p>
                      <p
                        className={
                          componentConstant.passengerDetail_class +
                          "open-600w-14s-22h text-capitalize mb-0"
                        }
                      >
                        <span className={"ml-3 mr-0"}>({item.gender}),</span>{" "}
                        {item.age}
                      </p>
                    </div>
                    <div className={"d-flex"}>
                      <img
                        src={seatOutline}
                        alt={"alt"}
                        className={"icon-24"}
                      />
                      <p className={componentConstant.seating_class}>
                        {selectedSeatsFromStore[index]}
                      </p>
                    </div>
                  </div>
                );
              })} */}
        </div>
        <div className={"px-4"}>
          <hr className={"line-seperator"} />
        </div>

        {ticketType === "CANCELLED" ? (
          <div
            className={"d-flex justify-content-between align-items-center px-4"}
          >
            <div className={"d-flex mr- gap-12 align-items-center"}>
              <img
                src={data.IsRefunded ? refundProcessedIcon : refundStatusIcon}
                alt={"alt"}
                className={"icon-24"}
              />
              <p className={componentConstant.refundStatusText_class}>
                Refund Status
              </p>
              <div className={"dot-seperator"} />
              {!data.IsRefunded && (
                <p className={componentConstant.refundStatusView_class}>
                  View Details
                </p>
              )}
            </div>
            {/* <p
              className={
                componentConstant.refundStatusValue_class +
                (data.IsRefunded
                  ? " grey-2-78787d-color "
                  : " yellow-pill black-1E1E26-color open-700w-12s-18h ")
              }
            >
              {(data.IsRefunded ? "Processed " : "In Progress").toUpperCase()}
            </p> */}
          </div>
        ) : (
          <div className={"d-flex px-4 "}>
            <div className={"d-flex mr-3"}>
              <img src={luggageIcon} alt={"alt"} className={"icon-24"} />
              <p className={componentConstant.lugagge_class}>Luggage Pieces</p>
            </div>
            <p className={componentConstant.guest_class}>
              <span className={componentConstant.guestCount_class + " mr-2"}>
                2
              </span>
              per person
            </p>
          </div>
        )}
        {ticketType === "UPCOMING" && (
          <div onClick={() => BookACab()}>
            <div>
              <hr className={"line-seperator"} />
            </div>
            <div
              className={
                "d-flex justify-content-center align-items-center book-cab cursor-pointer"
              }
            >
              <img src={BooKCab} alt={"cab"} />
              <p className={componentConstant.lugagge_class}>Book an Uber</p>
            </div>
          </div>
        )}
      </div>

      <div className={"review-content-area px-4 pb-2 pt-5"}>
        {isDesktopOrLaptop ? (
          <div className={"d-flex justify-content-between"}>
            <div className={"d-flex align-items-center"}>
              <p className={componentConstant.guest_class + " mb-0"}>Fare :</p>
              <p
                className={
                  componentConstant.guestCount_class +
                  " black-1E1E26-color mx-2 mb-0"
                }
              >
                ₹{data?.getTotalPaidAmount}
              </p>
              <img
                src={infoIcon}
                alt={"alt"}
                className={"icon-24 ml-2"}
                onClick={() => setFareDetailModal(true)}
              />
            </div>
            {!rescheduleFromStore && (
              <div
                className={"d-flex cursor-pointer"}
                onClick={downloadInvoiceUrl}
              >
                <img
                  src={viewIconGreen}
                  alt={"alt"}
                  className={"icon-24 mr-2"}
                />
                <p
                  className={
                    componentConstant.place_class + " teal-2-00A095-color mb-0"
                  }
                >
                  View Invoice
                </p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className={"d-flex justify-content-between"}>
              <p className={componentConstant.guest_class + " mb-0"}>Fare :</p>
              <p
                className={
                  componentConstant.guestCount_class +
                  " black-1E1E26-color mx-2 mb-0"
                }
              >
                ₹{data?.getTotalPaidAmount}
              </p>
              <img
                src={infoIcon}
                alt={"alt"}
                className={"icon-24 ml-2"}
                onClick={() => setFareDetailModal(true)}
              />
            </div>
          </div>
        )}
      </div>

      {!rescheduleFromStore && !isDesktopOrLaptop && (
        <div
          className={
            "d-flex mt-4 cursor-pointer green-outline-button justify-content-center align-items-center"
          }
          onClick={downloadInvoiceUrl}
        >
          <img src={viewIconGreen} alt={"alt"} className={"icon-24 mr-2"} />
          <p
            className={
              componentConstant.place_class + " teal-2-00A095-color mb-0"
            }
          >
            View Invoice
          </p>
        </div>
      )}
      {isDesktopOrLaptop && fareDetailModal && (
        <div className={"review-card-fare-detail-modal"}>
          <MDBModal
            isOpen={fareDetailModal}
            position={"center"}
            centered={true}
            size={"fluid"}
          >
            <MDBModalBody>
              <div className="mdb-data">
                <div className="d-flex justify-content-between align-items-center margin-bottom-32">
                  <p className="ubuntu-700w-24s-32h black-1E1E26-color mb-0">
                    Fare Detail
                  </p>
                  <img
                    src={closeIcon}
                    alt={"alt"}
                    className="cursor-pointer"
                    onClick={() => setFareDetailModal(false)}
                  />
                </div>
                <div className={"d-flex justify-content-between w-100  mb-3"}>
                  <p className={" open-400w-16s-24h mb-0"}>Base Fare</p>
                  <p className={" open-400w-16s-24h mb-0"}>
                    ₹{PostBooingDataFromStore?.totalBaseFare}
                  </p>
                </div>

                {parseFloat(PostBooingDataFromStore?.greenCashPromoAmount) >
                  0 && (
                  <>
                    <div
                      className={"d-flex justify-content-between w-100  mb-3"}
                    >
                      <div className={"d-flex"}>
                        <img
                          src={NueMilesIcon}
                          alt={"nue-miles-icon"}
                          className={"icon-24 mr-2"}
                        />
                        <p className={" open-400w-16s-24h mb-0"}>Green Miles</p>
                      </div>
                      <p
                        className={
                          " open-600w-16s-24h light-green-3AC070-color mb-0"
                        }
                      >
                        ₹{PostBooingDataFromStore?.greenCashPromoAmount}
                      </p>
                    </div>
                    <div
                      className={"d-flex justify-content-between w-100  mb-3"}
                    >
                      <p className={" open-600w-18s-24h mb-0"}>Net Base Fare</p>
                      <p className={" open-600w-16s-24h mb-0"}>
                        ₹
                        {Number(PostBooingDataFromStore?.totalBaseFare) -
                          Number(
                            PostBooingDataFromStore?.greenCashPromoAmount
                          )}{" "}
                      </p>
                    </div>
                  </>
                )}
                <div className={"d-flex justify-content-between w-100  mb-3"}>
                  <p className={" open-400w-16s-24h mb-0"}>GST</p>
                  <p className={" open-400w-16s-24h mb-0"}>
                    ₹{PostBooingDataFromStore?.gstAmount}
                  </p>
                </div>
                {rescheduleFromStore && (
                  <div className={"d-flex justify-content-between w-100  mb-3"}>
                    <p className={" open-400w-16s-24h mb-0"}>
                      Reschedule Charges
                    </p>
                    <p className={" open-600w-16s-24h  mb-0"}>
                      ₹{PostBooingDataFromStore?.rescheduleCharges}
                    </p>
                  </div>
                )}
                <hr className="horizontal-line" />
                <div className={"d-flex justify-content-between w-100 "}>
                  <p className={" open-400w-16s-24h mb-0"}>Total fare</p>
                  <p className={" open-700w-16s-24h mb-0"}>
                    ₹{PostBooingDataFromStore?.getTotalPaidAmount}
                  </p>
                </div>
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      )}
      {!isDesktopOrLaptop && fareDetailModal && (
        <div className={"review-card-fare-detail-bottom-tray"}>
          <BottomTrayComponent
            isOpen={fareDetailModal}
            closeHandler={() => setFareDetailModal(false)}
            title={"Fare Details"}
            hideClearAll={true}
          >
            <div className="p-3">
              <div className={"d-flex justify-content-between w-100  mb-3"}>
                <p className={" open-400w-16s-24h mb-0"}>Base Fare</p>
                <p className={" open-400w-16s-24h mb-0"}>
                  ₹{PostBooingDataFromStore?.totalBaseFare}
                </p>
              </div>

              {parseFloat(PostBooingDataFromStore?.greenCashPromoAmount) >
                0 && (
                <>
                  <div className={"d-flex justify-content-between w-100  mb-3"}>
                    <div className={"d-flex"}>
                      <img
                        src={NueMilesIcon}
                        alt={"nue-miles-icon"}
                        className={"icon-24 mr-2"}
                      />
                      <p className={" open-400w-16s-24h mb-0"}>Green Miles</p>
                    </div>
                    <p
                      className={
                        " open-600w-16s-24h light-green-3AC070-color mb-0"
                      }
                    >
                      ₹{PostBooingDataFromStore?.greenCashPromoAmount}
                    </p>
                  </div>
                  <div className={"d-flex justify-content-between w-100  mb-3"}>
                    <p className={" open-600w-18s-24h mb-0"}>Net Base Fare</p>
                    <p className={" open-600w-16s-24h mb-0"}>
                      ₹
                      {Number(PostBooingDataFromStore?.totalBaseFare) -
                        Number(
                          PostBooingDataFromStore?.greenCashPromoAmount
                        )}{" "}
                    </p>
                  </div>
                </>
              )}
              <div className={"d-flex justify-content-between w-100  mb-3"}>
                <p className={" open-400w-16s-24h mb-0"}>GST</p>
                <p className={" open-400w-16s-24h mb-0"}>
                  ₹{PostBooingDataFromStore?.gstAmount}
                </p>
              </div>
              {rescheduleFromStore && (
                <div className={"d-flex justify-content-between w-100  mb-3"}>
                  <p className={" open-400w-16s-24h mb-0"}>
                    Reschedule Charges
                  </p>
                  <p className={" open-600w-16s-24h  mb-0"}>
                    ₹{PostBooingDataFromStore?.rescheduleCharges}
                  </p>
                </div>
              )}
              <hr className="horizontal-line" />
              <div className={"d-flex justify-content-between w-100 "}>
                <p className={" open-400w-16s-24h mb-0"}>Total fare</p>
                <p className={" open-700w-16s-24h mb-0"}>
                  ₹{PostBooingDataFromStore?.getTotalPaidAmount}
                </p>
              </div>
            </div>
          </BottomTrayComponent>
        </div>
      )}
    </div>
  );
};

export default ReviewCardV2;
