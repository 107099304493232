import {useEffect, useRef} from "react";
// import * as schema from "../schema.graphql";
import schema from '../schema.json'
import {useLocation} from "react-router-dom";
import {CustomToast} from "../components/CustomToast/CustomToast";
import cogoToast from "cogo-toast";
import {clearUserData, postBookingDataAction} from "../store/Actions/CommonAction";
import {store} from "../index";
import {clearBookingDataOnLogoutAction} from "../store/Actions/BookingAction";
import axios from "axios";

export async function hashAndEncode(data, key) {
	const encoder = new TextEncoder();
	const dataBuffer = encoder.encode(data);
  
	// Import the key as an ArrayBuffer
	const keyBuffer = await crypto.subtle.importKey(
	  'raw',
	  encoder.encode(key),
	  { name: 'HMAC', hash: { name: 'SHA-256' } },
	  false,
	  ['sign']
	);
  
	// Generate the HMAC using subtle crypto API
	const signatureBuffer = await crypto.subtle.sign('HMAC', keyBuffer, dataBuffer);
  
	// Convert the signature to base64
	const hashedData = btoa(String.fromCharCode(...new Uint8Array(signatureBuffer)));
	return hashedData;
  }

  export function truncateString(str, num, ending = '...') {
	return str?.length > num ? str.slice(0, num) + ending : str;
  }

  export const  verifyHaptikAndSignUp = async (key) =>{

	try{
        const res = await axios.post('https://devtest.nuego.in/haptik/verify_haptik',{'mobile_number':key})
			window.HaptikSDK.signup({
			auth_id: res.data.auth_id,
			auth_code: res.data.auth_code,
			}, function (success, error, data) {
			if (success) {
			console.log('SIGNUP REQUEST SUCCEEDED!', data);
			} else {
			console.log('ERROR:', error);
			}
			});  
    	}
    catch(e){
        console.log(e)
    }

  }

  
export const secondsToTime = (secs) => {
	let hours = Math.floor(secs / (60 * 60));

	let divisor_for_minutes = secs % (60 * 60);
	let minutes = Math.floor(divisor_for_minutes / 60);

	let divisor_for_seconds = divisor_for_minutes % 60;
	let seconds = Math.ceil(divisor_for_seconds);

	let obj = {
		"h": hours,
		"m": minutes,
		"s": seconds
	};
	return obj;
}

export function addDaysToDate(date, days) {
	let res = new Date(date);
	res.setDate(res.getDate() + days);
	return res;
}

// DD-MM-YYYY
export const convertDateWithMonth = (str) => {

	let date = new Date(str),

		mnth = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
		day = ("0" + date.getDate()).slice(-2);

	return [day, mnth, date.getFullYear()].join("-");
}

export const convertDateWithMonth2 = (str) => {

	let date = new Date(str),

		mnth = (date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
		day = ("0" + date.getDate()).slice(-2);

	return [ date.getFullYear(),mnth,day].join("-");
}

export const fieldLevelValidation = (touched, errors) => {

	if (touched !== undefined) {
		if (errors !== undefined) {
			return " errorInput"
		} else {
			return " selected"
		}
	} else {
		return ""
	}
}

//check if element is in focus using element id
export const isInFocus = (id) => {
	return document.activeElement.id === id;
}

// string to float
export const stringToFloat = (str) => {
	return str ? parseFloat(str.replace(/,/g, '')) : 0;
}

// string to array by seperating by |
export const stringToArrayByPipe = (str) => {
	return str ? str.split("|") : [];
}

// sum of float array elements
export const sumOfArray = (arr) => {
	let s = 0.0;
	for (let i = 0; i < arr.length; i++) {
		s += parseFloat(arr[i]);
	}
	return s.toFixed(2);
}

export const sumOfFloatArray = (arr) => {
	return arr.length > 1 ? arr[0] : arr.reduce((a, b) => a + b, 0);
}

// converting dd-mm-yyyy converted date to yyyy-mm-dd
export const reconvertionDate = (str, time) => {

	let reversed = str.length > 0 && str.split("-").reverse().join("-");
//	reverse array
// 	reversed = reversed + " " + time + ":00";
	return reversed;
}

export const useFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {
		htmlElRef.current && htmlElRef.current.focus()
	}

	return [htmlElRef, setFocus]
}

export const floatAndFixed = (value) => {
	return value ? parseFloat(value).toFixed(2) : 0;
}

export const convertToDateAndMonth = (str) => {
	let month = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	]

	if (str) {
		let mnth = (str.getMonth()),
			day = ("0" + str.getDate()).slice(-2);
		return day + ", " + month[mnth]
	} else {
		let mnth = (new Date().getMonth()),
			day = ("0" + new Date().getDate()).slice(-2);
		return day + ", " + month[mnth]
	}

}

// list of next seven days from current date
export const getNextSevenDays = () => {

	let today = new Date();
	let nextSevenDays = [];
	for (let i = 0; i < 7; i++) {
		let date = new Date(today.getTime());
		date.setDate(today.getDate() + i);

		nextSevenDays.push({
			displayDate: convertToDateAndMonth(date),
			rawDate: date,
			convertedDate: convertDateWithMonth(date),
			dayDateMonth: convertDateToDayDateMonth(date)
		});
	}
	return nextSevenDays;
}
export const getNextThirtyDays = () => {

	let today = new Date();
	let nextThirtyDays = [];
	for (let i = 0; i < 30; i++) {
		let date = new Date(today.getTime());
		date.setDate(today.getDate() + i);

		nextThirtyDays.push({
			displayDate: convertToDateAndMonth(date),
			rawDate: date,
			convertedDate: convertDateWithMonth(date),
			dayDateMonth: convertDateToDayMonthDate(date)
		});
	}
	return nextThirtyDays;
}

// get current city name from lat long
export const getCityName = () => {
	const location = window.navigator && window.navigator.geolocation
	let lati
	let longi
	let res
	if (location) {
		location.getCurrentPosition((position) => {
			// gets ur current location
			lati = position.coords.latitude
			longi = position.coords.longitude

			// hard coded for chennai
			// lati = 13.066682
			// longi = 80.224741
			// console.log('lati', lati)

			let apiKey = "2f6321ca7428bfa812cf9ecf02af635c"
			// let url = "http://api.positionstack.com/v1/reverse?access_key=" + apiKey + "&query=" + lati + "," + longi;
			// https://apis.mapmyindia.com/advancedmaps/v1/3ee087f57991c93318d78acefb92e013/rev_geocode?lat=13.066682&lng=80.224741&region=IND
			let url = "https://apis.mapmyindia.com/advancedmaps/v1/" + apiKey + "/rev_geocode?lat=" + lati + "&lng=" + longi + "&region=IND";

			fetch(url)
				.then(response => response.json())
				.then(data => {

					// console.log(data.data[0].locality)
					res = data.results[0].city
					localStorage.setItem("currentLocation", res)
					return data.results[0].city;
				})
				.catch(error => {
					if (localStorage.getItem("currentLocation")) {
						localStorage.removeItem("currentLocation")
					}
					// console.log(error);
				});
			// })
		}, (error) => {
			if (localStorage.getItem("currentLocation")) {
				localStorage.removeItem("currentLocation")
			}
			// console.log(error)
			// this.setState({latitude: 'err-latitude', longitude: 'err-longitude'})
		})
	}
	return res;

}

export const getPointsInInt = (arr) => {
	if (arr.length > 0) {
		return arr.map(item => {
			return parseInt(item.id)
		})
	} else {
		return []
	}
}

export const capitalizeFirstLetter = s => s && s[0].toUpperCase() + s.slice(1).toLowerCase()

export const convertToDateFormat = (str) => {

	// convert 03-08-2022 to Wed Aug 03 2022 20:05:38 GMT+0530 (India Standard Time) (UTC+0530)
	let date = new Date(str);
	return date;
}

let enums = []
schema.data.__schema.types.filter(filter => {

	if (filter.kind === 'ENUM') {
		let value = filter.enumValues.map((item) => {
			return item.name
		})
		enums.push({
			feild: filter.name,
			value: value
		})
	}
	return enums
})

export const getValues = (field) => {
	let arr = enums.filter(item => {
		return item.feild === field
	})
	return arr[0]?.value
}

export const convertDateOfJourneyAsCalendar = (str) => {

	let originalDate = str.split("T")[0]
	let reverseOriginalDate = originalDate.split("-").reverse().join("-")
	return new Date(originalDate)

}

export const convertByPercentage = (value, percentage) => {
	return (value * percentage) / 100
}

export const minutesToHours = (minutes) => {
	// float to int
	let hours = Math.floor(minutes / 60)

	return hours
}

export const convertRawDateToDateAndMonth = (str) => {
	let originalDate = str?.split("T")[0]

	if (originalDate) {
		let reverseOriginalDate = originalDate.split("-")
		let date = reverseOriginalDate[2]
		// convert month from number to string

		let month = parseInt(reverseOriginalDate[1].replace(/^0+/, ''))
		let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month - 1]
		return date + " " + monthName
	} else {
		if (str) {
			originalDate.split("-")
		}

		return ""
	}

}

export const convertRawDateToDateMonthAndYear = (str) => {
	let originalDate = str?.split("T")[0]

	if (originalDate) {
		let reverseOriginalDate = originalDate.split("-")
		let date = reverseOriginalDate[2]
		// convert month from number to string

		let month = parseInt(reverseOriginalDate[1].replace(/^0+/, ''))
		let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month - 1]
		let year = reverseOriginalDate[0]
		return date + ", " + monthName + " " + year
	} else {
		if (str) {
			originalDate.split("-")
		}

		return ""
	}

}

export const datediff = (date) => {

	let today = new Date()
	let upcomingDate = new Date(date.split("T")[0])
	today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
	let millisecondsPerDay = 24 * 60 * 60 * 1000;
	return Math.round((upcomingDate - today) / millisecondsPerDay);
}

// dd-mm-yyyy to dd mon
export const convertHyphenDateToDateAndMonth = (str) => {
	let reverseOriginalDate = str.split("-")
	let date = reverseOriginalDate[0]
	// convert month from number to string

	let month = parseInt(reverseOriginalDate[1].replace(/^0+/, ''))
	let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month - 1]
	// let year = reverseOriginalDate[0]
	return date + ", " + monthName
}

export default function ScrollToTop({children}) {
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children || null;
}

export const logout = () => {
	localStorage.removeItem("token")
	localStorage.removeItem("userToken")
	localStorage.removeItem("userEmail")
	localStorage.removeItem("userNumber")
	localStorage.removeItem("userID")
	localStorage.removeItem("refreshToken")
	localStorage.removeItem("currentLocationLatLong")
	localStorage.removeItem("userName")
	localStorage.removeItem("signinORsignup")
	localStorage.removeItem("moeID")
	store.dispatch(clearUserData())
	store.dispatch(clearBookingDataOnLogoutAction())
	store.dispatch(postBookingDataAction({}))
	window.Moengage.destroy_session();
}

export const convertToIST = (str) => {

	if (str !== undefined) {
		let currentOffset = new Date(str).getTimezoneOffset();

		let ISTOffset = 330;   // IST offset UTC +5:30

		let ISTTime = new Date(new Date(str).getTime() + (ISTOffset + currentOffset) * 60000);

		// get hours in 12 hr format
		let hoursIST = ISTTime.getHours() > 12 ? ISTTime.getHours() - 12 : ISTTime.getHours();
		return hoursIST + ":" + ISTTime.getMinutes() + " " + (ISTTime.getHours() >= 12 ? "PM" : "AM")
	}

}

export const comingSoon = () => {
	return cogoToast.warn(<CustomToast type={"warn"} message={""}
	                               heading={"Coming Soon"}/>, {position: 'top-center', hideAfter: 5});
}

//convert Date to day, date month
export const convertDateToDayDateMonth = (str) => {
	let originalDate = new Date(str)
	let day = originalDate.getDay()
	let date = originalDate.getDate()
	let month = originalDate.getMonth()
	let dayString = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day]
	let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month]
	return dayString + ", " + date + " " + monthName
}

//convert Date to day, date
export const convertDateToDayDate = (str) => {
	let originalDate = new Date(str)
	let day = originalDate.getDay()
	let date = originalDate.getDate()
	let dayString = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day]
	return [dayString,date]
}

// convert date Month , day
export const convertDateToDayMonthDate = (str) => {
	let originalDate = new Date(str)
	let day = originalDate.getDay()
	let date = originalDate.getDate()
	let month = originalDate.getMonth()
	let dayString = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][day]
	let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][month]
	return date+ " " + monthName + ", " + dayString
}

// convert hh:mm to hh:mm AM/PM
export const convertTimeToAMPM = (str) => {
	let time = str.split(":")
	let hours = parseInt(time[0])
	let minutes = time[1]
	let ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	return hours + ':' + minutes + ' ' + ampm;
}

// if string of time contains AM/PM then convert to 24 hr format
export const convertAMPMTo24hr = (str) => {
	let time = str.split(" ")
	let hours = time[0].split(":")[0]
	let minutes = time[0].split(":")[1]
	let ampm = time[1]
	if (ampm === "PM") {
		hours = parseInt(hours) + 12
	}
	return hours + ":" + minutes
}

//  "Sat Jun 22 2024 00:00:00 GMT+0530 (India Standard Time) to dd/mm/yyyy
export function formatDateToDDMMYYYY(dateString) {
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const formattedDate = `${day}/${month}/${date.getFullYear()}`;
	return formattedDate;
  }

  // dd/mm/yyyy to yyyy-mm-dd
export const convertDateToISO = (str) => {
	let originalDate = str.split("/")
	let date = originalDate[0]
	let month = originalDate[1]
	let year = originalDate[2]
	return year + "-" + month + "-" + date
}