import React, {useEffect, useState} from 'react'
import {useMediaQuery} from "react-responsive";
import '../Careers.scss'
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../components/CustomToast/CustomToast";
// import {ApplyJobData} from "../../../services/api/DataUpdating";
// icons
import attachment from "../../../assets/icons/attachment.svg"

//assets
const jobApplication = process.env.REACT_APP_CDN_LINK + `assets/images/jobApplication.svg`


const JobApplication = ({applyFor}) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    let componentConstant
    if (isDesktopOrLaptop) {
        componentConstant = {
            heading: "ubuntu-700w-32s-48h black-1E1E26-color mb-3",
            subHeading: "open-400w-16s-24h grey-3-adadad-color mb-5",
            illustration: "jobApplication-illustration margin-bottom-32",
            submitButton: "open-600w-18s-28h white-color submit-button"
        }
    } else {
        componentConstant = {
            heading: "ubuntu-500w-18s-28h black-1E1E26-color mb-3",
            subHeading: "open-400w-14s-22h grey-3-adadad-color mb-0",
            illustration: "jobApplication-illustration mb-3",
            submitButton: "open-600w-16s-24h white-color submit-button"
        }
    }

    const [jobDetailData_local, setJobDetailData_local] = useState({});
    const [error, setError] = useState({
        name: false,
        email: false,
        mobileNumber: false,
        roleApplyingFor: false,
        coverLetter: false,
    });
    const [touched, setTouched] = useState([]);
    const [resume, setResume] = useState(null);
    const [countryCode, setCountryCode] = useState("+91")
    const [applied, setApplied] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // useEffect
    useEffect(() => {
        return () => {

        };
    }, [applied]);

    useEffect(() => {
        setApplied(false)
        let tempObj = {
            name: "",
            email: "",
            mobileNumber: "",
            roleApplyingFor: applyFor ? applyFor : "",
            coverLetter: "",

        }
        setJobDetailData_local(tempObj)
    }, [applyFor])

    // methods
    const onChangeHandler = (e, field) => {

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }

        if (field === 'name' || field === 'roleApplyingFor') {

            if (e.target.value === "") {
                setError({...error, [field]: 'error'})
                setJobDetailData_local({...jobDetailData_local, [field]: e.target.value})
            } else {
                if (e.target.value.match(/\d+/g) === null) {

                    setError({...error, [field]: null})
                    setJobDetailData_local({...jobDetailData_local, [field]: e.target.value})
                }
            }

        }

        if (field === "coverLetter") {
            setJobDetailData_local({...jobDetailData_local, [field]: e.target.value})
        }

        if (field === "email") {
            setJobDetailData_local({...jobDetailData_local, [field]: e.target.value})
            if (e.target.value.match(emailRegex) == null) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})

            }
        }

        if (field === "mobileNumber") {
            if (e.target.value.length < 11) {
                setJobDetailData_local({...jobDetailData_local, [field]: e.target.value})
            }

        }

    }

    const onSubmitHandler = () => {
        setIsSubmitting(true)
        let errorLength = Object.values(error).filter((item) => item === 'error').length;

        if (touched.length === 0) {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please update any field to save changes"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else if (errorLength !== 0) {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please update the highlighted fields"}
                heading={"Error"}
            />, {position: 'top-center'})
        }

        if (touched.length !== 0 && errorLength === 0) {
            let variables = {
                name: jobDetailData_local.name,
                email: jobDetailData_local.email,
                mobileNumber: jobDetailData_local.mobileNumber,
                roleApplyingFor: jobDetailData_local.roleApplyingFor,
                coverLetter: jobDetailData_local.coverLetter,
                countryCode: countryCode,
                resume: resume
            }
            if (resume.size > 2097152) {
                setIsSubmitting(false)
                cogoToast.error(<CustomToast
                    type={"error"} message={"File size should be less than 2 MB"}
                    heading={"Error"}
                />, {position: 'top-center'})
            } else {
                // ApplyJobData(variables, (response) => {
                //     setIsSubmitting(false)
                //     cogoToast.success(<CustomToast
                //         type={"success"} message={"Job application submitted successfully"}
                //         heading={"Success"}
                //     />, {position: 'top-center'})
                //     setApplied(true)

                // }, (error) => {
                //     setIsSubmitting(false)
                //     cogoToast.error(<CustomToast
                //         type={"error"} message={error}
                //         heading={"Error"}
                //     />, {position: 'top-center'})
                // })
            }

        }
    }

    

    const handleTouch = (field) => {

        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }
    }

    const handleBlur = (field) => {
        if (field === "mobileNumber") {
            if (jobDetailData_local.mobileNumber.length !== 10) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})
            }
        }
        if (touched.includes(field)) {
            if (jobDetailData_local[field] === "") setError({...error, [field]: 'error'})
        }
    }

    const handleResume = (e) => {
        setResume(e.target.files[0])
    }

    return (
        <div className={'global-container px-lg-4 py-4'}>
            <div className={'job-application-component '}>
                <div className={'text-center'}>
                    <img src={jobApplication} alt={'jobApplication'}
                         className={componentConstant.illustration}/>

                    {
                        applied ?
                            <div>
                                <p className={componentConstant.heading}>Application Successful</p>
                                <p className={componentConstant.subHeading}>Your application has been successfully
                                    submitted. We’re really happy that you’re considering joining us.</p>
                                <p className={componentConstant.subHeading}>At NueGo (Greencell Mobility), we take pride
                                    in our diversity and working environment, which sees you as more than just an
                                    individual with a skillset. NueGo provides a truly open culture that encourages all
                                    to voice their thoughts! It is a unique place to work in, blending complexity of
                                    working in a challenging workspace & fun and liveliness of a contemporary brand.</p>
                                <p className={componentConstant.subHeading}>Our employees, therefore, are hired with an
                                    objective of managing volatility, uncertainty, complexity, and ambiguity in an
                                    industry-space that requires high proprietary knowledge of the market and
                                    environment, and at the same time, bring with them a futuristic perspective through
                                    innovation and creativity, that rings a bell with millions of our customers!</p>
                            </div>
                            :
                            <div>
                                <p className={componentConstant.heading}>Job Application</p>
                                <p style={{color: " #1E1E26", fontWeight: "400"}}>Kindly connect with us to work with us
                                    and
                                    join our
                                    enthusiastic team.</p>
                            </div>
                    }

                </div>

                {
                    !applied ? <div className={'job-application-form mx-auto'}>
                            <div className={'d-lg-flex w-100 gap-24 mb-4'}>
                                <div className={'w-100'}>
                                    <label>Name *</label>
                                    <div
                                        className={'common-input-wrap input-name ' + (error.name === "error" && "common-input-wrap-error")}
                                        onClick={() => handleTouch("name")}>
                                        <input className="border-less-input w-100"
                                               value={jobDetailData_local.name}
                                               placeholder={'Enter your name'}
                                               onBlur={() => handleBlur("name")}
                                               onChange={(e) => {
                                                   // case 1 a
                                                   onChangeHandler(e, "name")
                                               }}
                                               type={"text"}/>
                                    </div>
                                    {error.name === "error" && <div>
                                        <p className={""}>Invalid Name</p>
                                    </div>}
                                </div>

                                <div className={'w-100'}>
                                    <label>Email *</label>
                                    <div onClick={() => handleTouch("email")}
                                         className={'common-input-wrap ' + (error.email === "error" && 'common-input-wrap-error')}>
                                        <input className="border-less-input w-100"
                                               type={"email"}
                                               onBlur={() => handleBlur("email")}
                                            // value={personalDetailData_local.email}
                                               onChange={(e) => onChangeHandler(e, "email")}
                                        />

                                    </div>
                                    {error.email === "error" && <div>
                                        <p className={''}>Invalid Email ID</p>
                                    </div>}
                                </div>

                            </div>
                            <div className={'d-lg-flex w-100 gap-24'}>
                                <div className={'d-flex gap-24 w-100'}>
                                    <div>
                                        <label>Code</label>
                                        <div className={'d-flex common-input-wrap align-items-center mb-4 w-100 '}>
                                            <select className={'input-box '}
                                                    onChange={e => setCountryCode(e.target.value)}>
                                                <option value={"+91"}>+91</option>
                                                <option value={"+92"}>+92</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={'w-100'}>
                                        <label>Mobile Number *</label>
                                        <div onClick={() => handleTouch("mobileNumber")}
                                             className={'common-input-wrap ' + (error.mobileNumber === "error" && 'common-input-wrap-error')}>
                                            <input
                                                min="0"
                                                className="border-less-input w-100 "
                                                type={"text"}
                                                // pattern="[0-9]{10}
                                                value={jobDetailData_local.mobileNumber}
                                                onKeyPress={(e) => e.target.value.length === 10 ? e.preventDefault() : null}
                                                onBlur={() => handleBlur("mobileNumber")}
                                                onChange={(e) => onChangeHandler(e, "mobileNumber")}
                                            />

                                        </div>
                                        {error.mobileNumber === "error" && <div>
                                            <p className={''}>Invalid Mobile Number</p>
                                        </div>}
                                    </div>

                                </div>

                                <div className={'w-100'}>
                                    <label>LinkedIn profile</label>
                                    <div className={'d-flex common-input-wrap align-items-center mb-4 w-100 '}>
                                        <input type={'text'} className={'input-box w-100'}
                                               placeholder={'Enter your qualification'}
                                               onChange={e => onChangeHandler(e.target.value, "qualification")}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className={'d-lg-flex w-100 gap-24'}>
                                <div className={'w-100'}>
                                    <label>Role Applied for</label>
                                    <div
                                        className={'common-input-wrap input-name ' + (error.roleApplyingFor === "error" && "common-input-wrap-error")}
                                        onClick={() => handleTouch("roleApplyingFor")}>
                                        <input className="border-less-input w-100"
                                               value={jobDetailData_local.roleApplyingFor}
                                               placeholder={'Enter Job Position'}
                                               onBlur={() => handleBlur("roleApplyingFor")}
                                               onChange={(e) => {
                                                   // case 1 a
                                                   onChangeHandler(e, "roleApplyingFor")
                                               }}
                                               type={"text"}/>
                                    </div>
                                    {error.roleApplyingFor === "error" && <div>
                                        <p className={""}>Invalid Role</p>
                                    </div>}
                                </div>

                                <div className={'w-100'}>
                                    <label>Upload Resume *</label>
                                    <div
                                        className={'d-flex justify-content-between align-items-center common-input-wrap position-relative mb-4 w-100 '}>
                                        <input type={'file'} className={'input-box w-100 upload-button'}
                                               placeholder={'choose file'}
                                               onChange={e => handleResume(e)}
                                        />
                                        <p className={'my-0 ' + (resume?.name ? " black-1E1E26-color " : "")}>{resume?.name ? resume.name : "Choose File"}</p>
                                        <img src={attachment} alt={'attachment'} className={'icon-24'}/>
                                    </div>
                                </div>

                            </div>
                            <div className={'d-lg-flex w-100 gap-24'}>
                                <div className={'w-100'}>
                                    <label>Cover Letter</label>
                                    <div className={'d-flex common-input-wrap align-items-center mb-4 w-100 '}>
					<textarea className={'input-box w-100'} placeholder={'Tell us why we should hire you?'}
                              rows={3}
                              onChange={e => onChangeHandler(e, "coverLetter")}
                    />
                                    </div>
                                </div>
                            </div>

                            <div className={'text-center'}>
                                <button className={componentConstant.submitButton}
                                        disabled={!resume || Object.values(error).filter((item) => item === "error").length > 0
                                            || !touched.includes("mobileNumber") ||
                                            !touched.includes("email") ||
                                            !touched.includes("name") || isSubmitting}
                                        onClick={onSubmitHandler}>Submit
                                </button>
                            </div>
                        </div>
                        :
                        <div>

                        </div>
                }

            </div>
        </div>

    )

    }
    


export default JobApplication
