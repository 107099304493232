import React, { useEffect, useState } from "react";
import "./style.scss";
import { setAuthType } from "../../store/Actions/AuthActions";
import { connect } from "react-redux";
import SignInDesktop from "./SignInDesktop";
import OtpVerificationDesktop from "./OtpVerificationDesktop";
import { useMediaQuery } from "react-responsive";
//icons
import cross from "../../assets/icons/cross-gray.svg";
import ManageBookingImage from "../../assets//images/manage-booking.png";
//assets

const LoginImage = process.env.REACT_APP_CDN_LINK + `assets/images/login_form_Img.png`


const DesktopAuthComponentLayout = (props) => {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });

	const { closeModal, typeFromStore, feedbackRemindersFetch } = props;

	useEffect(() => {
		// return () => {
		// 	effect
		// };
	}, [typeFromStore]);

	return (
		<div className={"auth-component"}>
			<div className={"closing-button m-2"}>
				{/*<p className={'ubuntu-700w-18s-28h teal-22BBB0-color'} onClick={() => closeModal()}>Enter as Guest</p>*/}
				<img
					src={cross}
					alt={"alt"}
					className={"icon-2"}
					onClick={() => closeModal()}
				/>
			</div>
			<div className={"row"}>
				<div
					style={{
						backgroundImage:
							typeFromStore === "guest"
								? `url(${ManageBookingImage})`
								: `url(${LoginImage})`,
					}}
					className={
						"col-6   auth-display-img-wrap blue-light-F2FAF9-bg position-relative"
					}
				>
					{/*<img src={authDisplayImage} alt={'alt'} className={'absolute-horizontal-center auth-display-img'}/>*/}
				</div>
				<div className={"col-6 content-area mt-lg-4"}>
					{isDesktopOrLaptop && typeFromStore === "d-signin" && (
						<SignInDesktop typeFromStore={typeFromStore} />
					)}
					{isDesktopOrLaptop && typeFromStore === "guest" && (
						<SignInDesktop typeFromStore={typeFromStore} />
					)}

					{isDesktopOrLaptop && typeFromStore === "d-signup" && (
						<SignInDesktop />
					)}
					{isDesktopOrLaptop && typeFromStore === "d-otp" && (
						<OtpVerificationDesktop
							feedbackRemindersFetch={feedbackRemindersFetch??false}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	typeFromStore: state.AuthReducer.type,
	mobileNumberFromStore: state.AuthReducer.mobileNumber,
});
const mapDispatchToStore = (dispatch) => ({
	setTypeToStore: (data) => dispatch(setAuthType(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToStore,
)(DesktopAuthComponentLayout);
