// import * as schema from '../../schema.graphql' ;

import {SET_AMENITIES_FILTER, SET_BOARDING_POINT_FILTER, SET_DROPPING_POINT_FILTER, SET_FILTER_DATA} from "../Actions";
import {filter_template} from "../../pages/Bookings/constants";

const initialState = {
	filter: filter_template,
	boardingPoints: [],
	droppingPoints: [],
	amenities: [],
}

const FilterReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_FILTER_DATA:
			return {
				...state,
				filter: action.payload.data,
			}
		case SET_BOARDING_POINT_FILTER:
			return {
				...state,
				boardingPoints: action.payload.data,
			}
		case SET_DROPPING_POINT_FILTER:
			return {
				...state,
				droppingPoints: action.payload.data,
			}
		case SET_AMENITIES_FILTER:
			return {
				...state,
				amenities: action.payload.data,
			}
		default:
			return state
	}
}

export default FilterReducer
