import React from 'react'
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules"; 
import XTwitter from "../../../assets/icons/TwitterX.svg"
import insuranceNew from "../../../assets/icons/whatsNewInsurance.svg";
import stats1 from "../../../assets/icons/Stats.svg";
import thread2 from "../../../assets/icons/Thread.svg";
import retweet3 from "../../../assets/icons/Retweet.svg";
import heart4 from "../../../assets/icons/Heart.svg";
import moreopt from "../../../assets/icons/More.svg";
import BlueTick from "../../../assets/icons/Check.svg";

import TwitterContainer from "../../../components/TwitterContainer/TwitterContainer";
const readMore = process.env.REACT_APP_CDN_LINK + `assets/images/read-more-icon.png`



function TwitterComponent() {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
  return (
    <div className='twitterComponent-outer-card'>
        <div className='container'> 
        <div className='d-flex justify-content-between mb-4'>
            <div className='d-flex justify-content-start align-items-center gap-2'>
                <img src={XTwitter} alt=""  className='icon-48'/>
                <h2 className='ubuntu-700w-32s-48h white-color mb-0'>Latest Tweets by NueGo</h2>
            </div>
            {/* <div className='d-flex justify-content-start align-items-center gap-2'>
               
                <h2 className='ubuntu-700w-18s-28h teal-22BBB0-color mb-0'>See All Tweets</h2>
                <img src={readMore} alt="" />
            </div> */}
        </div>
        {/* {
                isDesktopOrLaptop?(
        <div className='card-container'>
            
                <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                </div>
                
                ):(<Swiper
                    className={"py-2 px-1 card-container"}
                    spaceBetween={isDesktopOrLaptop ? 32 : 16}
                    // ref={sliderRef}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    breakpoints={{
                        1280: {
                            slidesPerView: 2.1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1: {
                            slidesPerView: 1.1,
                        },
                    }}
                >
                            <SwiperSlide key={""}>

                            <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                            </SwiperSlide>
                            <SwiperSlide key={""}>

                            <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                            </SwiperSlide>
                            <SwiperSlide key={""}>

                            <div className='child-component'>
                    <div className="into-div d-flex justify-content-between flex-row">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={insuranceNew} alt="" className='user-Image round-circle' />
                            <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <p className='open-600w-16s-24h black-1E1E26-color mb-0'>Sanjay Sharma</p>
                                <img src={BlueTick} alt="" />
                            </div>
                            <p className='open-400w-16s-24h grey-text mb-0'>@SanjaySharma</p>
    
                        </div>
    
                        </div>
                        <img src={moreopt} alt="" className='icon-24' />
                    </div>
                    <p className='tweeet-para'>Figma, Webflow, or Framer. Which one will take the lead in 2023 and be the go-to for digital design?</p>
                    <div className='d-flex justify-content-start align-items-center gap-2'>
                        <p className='open-400w-16s-24h grey-text mb-0'>1:27 PM</p>
                        <p className='open-400w-16s-24h grey-text mb-0'>Oct 4 2022</p>
                        <p className='open-400w-16s-24h blue-text mb-0'>Twitter for iPhone</p>
                    </div>
                    <hr className='m-0'/>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={stats1} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1.1 M</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={thread2} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>1240</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={retweet3} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>5579</p>
                        </div>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <img src={heart4} alt="" />
                            <p className='open-400w-16s-24h grey-text mb-0'>3987</p>
                        </div>
                       
                    </div>
                </div>
                            </SwiperSlide>
                      
                  
    
                </Swiper>
                )
            } */}

            <TwitterContainer/> 
           
        
        </div>
    </div>
  )
}

export default TwitterComponent