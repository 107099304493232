import React from 'react'
import './QRCodeModalComponent.scss'
import {useMediaQuery} from "react-responsive";
import {MDBModal, MDBModalBody} from "mdbreact";

//assets
import closeIcon from "../../assets/icons/cross-gray.svg"


const QRCodeModalComponent = (props) => {
    const {isOpen, children, closeModal} = props
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    return (
        <div className={'qr-code-modal-component'}>
            <MDBModal isOpen={isOpen} size={'md'} position={'center'} centered={true}>
                <MDBModalBody>
                    <div className={'content-area'}>
                        <div className={'d-flex justify-content-end'}>
                            <img src={closeIcon} alt={'alt'} className={'icon-24'}
                                 onClick={closeModal}
                            />
                        </div>
                        <div>
                            {children}
                        </div>
                    </div>
                </MDBModalBody>
            </MDBModal>
        </div>
    )
}

export default QRCodeModalComponent
