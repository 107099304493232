import environment from "../../environment";
import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";

const mutation = graphql`
	mutation ReleaseSeatsMutation($bookingId: String!) {
		releaseSeat(bookingId:$bookingId) {
		message
		}
	}
	`;

export default (data, callback, errCallback) => {
	const variables = {
		bookingId: data.bookingId,

	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.releaseSeats !== null) {

					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},

			onError: err => {
				errCallback(err);
			}
		}
	);
}