/**
 * @generated SignedSource<<ca47336a39fe785477f6f299a9e93fea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceNo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "referenceNo",
        "variableName": "referenceNo"
      }
    ],
    "concreteType": "SeatArrangementType",
    "kind": "LinkedField",
    "name": "seatArrangementDetails",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SeatNo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Row",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Column",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IsLadiesSeat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Available",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SeatType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "UpLowBerth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "BlockType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "RowSpan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ColumnSpan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SeatCategory",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "SeatRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "IsLowPrice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "OriginalSeatRate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetSeatArrangmentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetSeatArrangmentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "466742e06638664945ef940decb314ba",
    "id": null,
    "metadata": {},
    "name": "GetSeatArrangmentQuery",
    "operationKind": "query",
    "text": "query GetSeatArrangmentQuery(\n  $referenceNo: String!\n) {\n  seatArrangementDetails(referenceNo: $referenceNo) {\n    SeatNo\n    Row\n    Column\n    IsLadiesSeat\n    Available\n    SeatType\n    UpLowBerth\n    BlockType\n    RowSpan\n    ColumnSpan\n    SeatCategory\n    SeatRate\n    IsLowPrice\n    OriginalSeatRate\n  }\n}\n"
  }
};
})();

node.hash = "399515c4b8a580a5ac713c51fbbf56de";

module.exports = node;
