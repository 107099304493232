import React from 'react'
import {MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import './BottomTrayComponent.scss'
import {store} from "../../index";

//assets
import closingIcon from "../../assets/icons/white-cross-icon.svg"

const BottomTrayComponent = ({
                                 isOpen, closeHandler, title, children,
                                 removeBottomSpacing,
                                 clearAllHandler, hideClearAll
                             }) => {
    let local_title = title ? title : store.getState().CommonReducer.filterModal

    return (
        <div className={'bottom-tray-mobile-component'}>

            <MDBModal isOpen={isOpen} position={'bottom'} animation={'bottom'}>
                <MDBModalHeader>
                    <div className={'closing-icon icon-48'} onClick={closeHandler}>
                        <img src={closingIcon} alt={'alt'} className={'icon-20'}/>
                    </div>
                </MDBModalHeader>
                <MDBModalBody>
                    {/*<img className={'closing-icon'} src={closingIcon}*/}
                    {/*     onClick={closeHandler}*/}
                    {/*     alt={'alt'}/>*/}
                    <div className={'wrap'}>
                        {local_title !== "" &&
                            <div className={'header-section d-flex justify-content-between px-3'}>
                                <p className={'ubuntu-700w-18s-22h mb-0  text-capitalize p-3'}>{local_title}</p>
                                {!hideClearAll &&
                                    <p className={'open-700w-14s-22h mb-0 teal-2-00A095-color text-capitalize'}
                                       onClick={clearAllHandler}>Clear All</p>}
                            </div>}
                        <div className={'content-section pt-3'}
                             style={removeBottomSpacing ? {paddingBottom: "16px"} : {paddingBottom: '120px'}}>
                            {children}
                        </div>
                    </div>
                </MDBModalBody>
            </MDBModal>

        </div>
    )
}

export default BottomTrayComponent
