import graphql from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import environment from '../../environment';

const mutation = graphql`
	  mutation CreateChecklistNameMutation($name: String!) {
	    createCheckList(name: $name) {
	        checkList{
	            id
	        }
	    }
	  }`;

export default (data, callback, errCallback) => {
	const variables = {
		name: data.name
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.createCheckList !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}