import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";

// import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// local files
import AllRoutes from './combinedRoutes'

// mdb
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

//redux
import {Provider} from 'react-redux'
import {applyMiddleware, legacy_createStore as createStore} from 'redux'
import {rootReducer} from './store/Reducer'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistGate} from 'redux-persist/integration/react';
import {composeWithDevTools} from 'redux-devtools-extension';
import {thunk} from "redux-thunk"
//swiper
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const persistConfig = {
    key: 'root',
    storage,
};
const middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, 
    applyMiddleware(...middlewares),
    // other store enhancers if any
);

const persistor = persistStore(store);

const routing = (
    // <ReactPWAInstallProvider enableLogging>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <AllRoutes/>
            </Router>
        </PersistGate>
    </Provider>

);

// ReactDOM.render(
// 	routing,
// 	document.getElementById('root')
// );


const container = document.getElementById('root');
// const root = hydrateRoot(container, routing);
if (container.hasChildNodes()) {
    hydrateRoot(container, routing);
  } else {
    const root = createRoot(container);
    root.render(routing);
  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
