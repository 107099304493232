import React from "react";
import { cloudFlareSiteKey } from "../../utils/constants";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const TurnstileWidget = ({ onSuccess, forceCaptcha }) => {
	let turnstileSrcUrl = "https://challenges.cloudflare.com/turnstile/v0/api.js";
	
	// manual implementation
	useEffect(() => {
		window.turnstile.render("#turnstile-widget", {
			sitekey: cloudFlareSiteKey,
			size: "normal",
			appearance: "always",
			theme: "light",
			callback: function (token) {
				onSuccess(token);
			},
		});

		if (forceCaptcha) {
			turnstileSrcUrl =
				turnstileSrcUrl + "?render=explicit&onload=onloadTurnstileCallback";
			console.log("%c turnstile force captcha enabled ->" + forceCaptcha,'background: #222; color: #bada55')
			console.log(` %c turnstileSrcUrl ${turnstileSrcUrl}`,'background: #1fa59b; color: #1f1f1f; border-radius: 5px; padding: 5px');
		}
	}, [forceCaptcha]);

	try {
		return (
			<div id='turnstile-widget'>
				<Helmet>
					<script src={turnstileSrcUrl}></script>
				</Helmet>

				{/* <Turnstile
					className='my-3'
					siteKey={cloudFlareSiteKey}
					options={{
						appearance: forceCaptcha ? "always" : "interaction-only",
						// appearance: "always",
						size: "normal",
						theme: "light",
						execution: "render",
						refreshExpired: "manual",
						render: "explicit",
					}}
					scriptOptions={{
						appendTo: "body",
					}}
					onExpire={() => ref.current?.reset()}
					injectScript={true}
					onSuccess={(token) => {
						// onSuccess(token);
					}}
				/> */}
			</div>
		);
	} catch (error) {
		console.error(error);
		return <div>error</div>;
	}
};

export default TurnstileWidget;
