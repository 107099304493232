/**
 * @generated SignedSource<<8c758fcd57c9f06a277c96f4ace7369a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "amount"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      }
    ],
    "kind": "ScalarField",
    "name": "getMonthlyPassDescription",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetMonthlyPassDescriptionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetMonthlyPassDescriptionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2fbb363b2206ad45fd84c36a9dc7b0b0",
    "id": null,
    "metadata": {},
    "name": "GetMonthlyPassDescriptionQuery",
    "operationKind": "query",
    "text": "query GetMonthlyPassDescriptionQuery(\n  $amount: String!\n) {\n  getMonthlyPassDescription(amount: $amount)\n}\n"
  }
};
})();

node.hash = "514e5a93814c0f628b20dd2114ec396d";

module.exports = node;
