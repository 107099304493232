import React, { useEffect, useState } from "react";
import "./SubScreens.scss";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

//icons
import activeRadioButton from "../../../assets/icons/active-green-radio-button.svg";
import inactiveRadioButton from "../../../assets/icons/inactive-radio-icon.svg";
import checked from "../../../assets/icons/checked-green.svg";
import unchecked from "../../../assets/icons/unchecked.svg";
import refundPolicyIcon from "../../../assets/icons/refund-policy-icon.svg";
import downArrow from "../../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg";
import PoliciesComponent, {
  CancellationSection,
} from "../../PoliciesComponent/PoliciesComponent";
import {
  PartialCancelBooking,
  cancelSinglePassenger,
} from "../../../services/api/DataUpdating";
import cogoToast from "cogo-toast";
import { getValues } from "../../../utils/utils";
import { CustomToast } from "../../CustomToast/CustomToast";
import { isInsuranceEnabled } from "../../../utils/constants";

const fallbackIllustration =
  process.env.REACT_APP_CDN_LINK +
  `assets/images/Can-not-reschedule-booking.png`;

const CancelBooking = (props) => {
  const {
    cancelDetails,
    activeReason,
    setActiveReason,
    submitHandler,
    setRemarks,
    remarks,
    activePassengerList,
    setActivePassengerList,
    bookingId,
  } = props;

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  let componentConstant;

  const [count, setCount] = useState(0);
  const [showRefundPolicy, setShowRefundPolicy] = useState(false);
  const [cancelFallback, setCancelFallback] = useState(false);

  if (isDesktopOrLaptop) {
    componentConstant = {
      passengerListWrap_class:
        "padding-x-local margin-bottom-32 green-area py-4 mt-3",
      title_class: "open-600w-16s-24h",
      description_class: "open-600w-16s-24h",
      options_class: "open-600w-16s-24h",
      reasonWrap_class: "grey-area py-4",
      refundText_class: "open-600w-16s-24h mb-0",
      submitButtonWrap_class:
        "d-flex justify-content-end open-600w-18s-28h padding-top-side-local",
      submitButton_class: "submit-button",
      mainText_class: "ubuntu-700w-18s-28h",
      tabText_class: "open-700w-18s-28h",
      headingText_class: "open-600w-16s-24h",
      option_class: "open-600w-16s-24h",
      optionRate_class: "open-700w-16s-24h",
      points_class: "open-400w-16s-24h",
      fallbackTitle: "ubuntu-700w-24s-36h mb-2 black-1E1E26-color",
      fallbackParagraph: "open-400w-16s-22h grey-2-78787d-color",
    };
  } else {
    componentConstant = {
      passengerListWrap_class: "padding-x-local green-area py-4 mt-3",
      title_class: "open-600w-16s-24h",
      description_class: "open-600w-16s-24h",
      options_class: "open-600w-16s-24h",
      reasonWrap_class: "",
      refundText_class: "open-600w-16s-24h mb-0",
      submitButtonWrap_class:"d-flex w-100 open-600w-16s-24h padding-top-side-local",
      submitButton_class: "submit-button w-100",
      mainText_class: "ubuntu-700w-18s-28h",
      tabText_class: "open-700w-14s-22h",
      headingText_class: "open-600w-16s-24h",
      description_class: "open-400w-12s-18h",
      option_class: "open-600w-14s-22h",
      optionRate_class: "open-700w-14s-22h",
      points_class: "open-400w-14s-22h",
      fallbackTitle: "ubuntu-500w-20s-32h mb-2 black-1E1E26-color",
      fallbackParagraph: "open-400w-14s-22h grey-2-78787d-color",
    };
  }

  useEffect(() => {
    if (Object.keys(cancelDetails)?.length > 0) {
   
      let passengerIds = cancelDetails?.booking?.passengerdetailSet.map(
        (item) => {
          return item.id;
        })
      setActivePassengerList(passengerIds)
    }
  }, [cancelDetails]);



  // useEffect(() => {}, [activePassengerList]);

  const handlePassengerList = (id) => {
    let passengerId = id;
    let tempPassengerList = [];
    if (activePassengerList.length > 0) {
      activePassengerList.map((item) => {
        tempPassengerList.push(item);
      });
    }

    if (tempPassengerList.includes(passengerId)) {
      tempPassengerList.splice(tempPassengerList.indexOf(passengerId), 1);
    } else {
      tempPassengerList.push(passengerId);
    }

    setActivePassengerList(tempPassengerList);
    setCount(count + 1);
  };

  const continueButtonHandler = () => {
    if (cancelDetails.totalRefund > 0 || cancelDetails.refundAmount > 0) {
      submitHandler();
    } else {
      setCancelFallback(true);
    }
  };

  const forceCancellationHandler = () => {
    let passengerIds = cancelDetails?.booking?.passengerdetailSet.map(
      (item) => {
        return item.id;
      }
    );
    let variable = {
      bookingId: bookingId,
      cancellationReason: "Nil",
      cancellationRemark: "Nil",
      paymentType: getValues("PaymentTypeEnum")[0],
      passengerIds: passengerIds,
      refundType: 1,
    };

    cancelSinglePassenger(
      variable,
      (data) => {
        navigate("/", { replace: false });
        cogoToast.success(
          <CustomToast
            heading={"Success"}
            message={"Booking Cancelled Successfully"}
            type={"success"}
          />
        );
      },
      (err) => {
        cogoToast.error(
          <CustomToast
            heading={"Error"}
            message={err && (err[0]?.message || err.toString())}
            type={"error"}
          />
        );
      }
    );
  };

  return (
    <div className={"cancel-booking-component"}>
      {cancelFallback ? (
        <div className="text-center px-3">
          <img
            src={fallbackIllustration}
            alt={"alt"}
            className={"fallback-illustration"}
          />
          <p className={componentConstant.fallbackTitle}>
            Are you sure you want to Cancel?
          </p>
          <p className={componentConstant.fallbackParagraph}>
            As per NueGo policy, no refund will be given for cancelling a ticket
            which has already been rescheduled
          </p>
          <button
            className={componentConstant.submitButton_class}
            onClick={forceCancellationHandler}
          >
            Continue
          </button>
        </div>
      ) : (
        <>
          <div className={componentConstant.reasonWrap_class}>
            <div className={"padding-x-local"}>
              <p className={componentConstant.title_class}>
                Reason for Cancellation
              </p>
              <p className={componentConstant.description_class}>
                Please let us know reason to help us improve our services
              </p>
            </div>
            <div className={"padding-x-local "}>
              {optionList.map((option, index) => {
                return (
                  <div className={"d-flex"} key={index}>
                    <img
                      src={
                        activeReason === option
                          ? activeRadioButton
                          : inactiveRadioButton
                      }
                      className={"icon-24 mr-2"}
                      onClick={() => setActiveReason(option)}
                      alt={"alt"}
                    />
                    <p
                      className={
                        componentConstant.options_class +
                        (activeReason === option
                          ? " opacity-100"
                          : " opacity-60")
                      }
                    >
                      {option}
                    </p>
                  </div>
                );
              })}
              <div className={"common-input-wrap"}>
                <textarea
                  rows={5}
                  className={"w-100"}
                  disabled={!activeReason?.includes("Other")}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder={"Additional Comments"}
                />
              </div>
            </div>
          </div>

          {cancelDetails?.booking?.passengerdetailSet?.length > 1 && (
            <div className={componentConstant.passengerListWrap_class}>
              <p className={componentConstant.title_class}>Cancel Ticket</p>
              {/* <p className={componentConstant.description_class}>
                Please select Passengers
              </p> */}
              {cancelDetails?.booking?.passengerdetailSet?.map(
                (passengerItem, index) => {
                  return (
                    <div
                      className={"d-flex"}
                      key={index}
                      //onClick={() => handlePassengerList(passengerItem.id)}
                    >
                      {/* <img
                        src={
                          activePassengerList.includes(passengerItem.id)
                            ? checked
                            : unchecked
                        }
                        alt={"alt"}
                        className={"icon-24 mr-2"}
                      /> */}
                      <p
                        className={
                          componentConstant.options_class +
                          (activePassengerList?.includes(passengerItem.id)
                            ? " opacity-100"
                            : " opacity-60")
                        }
                      >
                        {passengerItem.name} ({passengerItem.age},{" "}
                        {passengerItem.gender})
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          )}
          {cancelDetails && (
            <div className={"padding-x-local py-4"}>
              { <div className={"fare-wrap"}>
                {!isDesktopOrLaptop && (
                  <p className={componentConstant.title_class}>Fare Details</p>
                )}
                <div className={"d-flex justify-content-between"}>
                  <p
                    className={
                      componentConstant.options_class +
                      " opacity-60 mb-lg-3 mb-2"
                    }
                  >
                    Total Fare Paid
                  </p>
                  <p className={componentConstant.options_class}>
                    ₹{" "}
                    {cancelDetails.totalFare -
                      (cancelDetails?.insuranceRefundAmount || 0)}
                  </p>
                </div>
                <div className={"d-flex justify-content-between"}>
                  <p
                    className={
                      componentConstant.options_class + " opacity-60 mb-lg-0"
                    }
                  >
                    Cancellation Fee
                  </p>
                  <p
                    className={
                      componentConstant.options_class +
                      " text-danger"
                    }
                  >
                    -₹{" "}
                    {parseFloat(cancelDetails?.cancellationCharges) > 0
                      ? parseFloat(cancelDetails?.cancellationCharges).toFixed(
                          2
                        )
                      : cancelDetails.cancellationCharges}
                  </p>
                </div>
                {isInsuranceEnabled && cancelDetails?.insuranceRefundAmount && (
                  <div className={"d-flex justify-content-between"}>
                    <p
                      className={
                        componentConstant.options_class +
                        " opacity-60 mb-lg-3 mb-2"
                      }
                    >
                      Insurance Refund
                    </p>
                    <p className={componentConstant.options_class}>
                      ₹ {cancelDetails?.insuranceRefundAmount}
                    </p>
                  </div>
                )}
                {isDesktopOrLaptop && <hr className={"horizontal-divider"} />}
                <div className={"d-flex justify-content-between"}>
                  <p
                    className={componentConstant.options_class + " opacity-60"}
                  >
                    Total Refund
                  </p>
                  <p className={componentConstant.options_class}>
                    ₹{" "}
                    {cancelDetails.totalRefund
                  ? Number(cancelDetails.totalRefund)> 0 ?cancelDetails.totalRefund:0
                    : cancelDetails.refundAmount}
                  </p>
                </div>
                {cancelDetails?.nonPromotionalWalletRefund > 0 && (
                  <div className={"d-flex justify-content-between px-2"}>
                    <p
                      className={"open-400w-12s-18h "}
                      style={{ color: "#78787D" }}
                    >
                      Refund to NueGo Wallet
                    </p>
                    <p
                      className={"open-400w-12s-18h"}
                      style={{ color: "#78787D" }}
                    >
                      ₹ {cancelDetails.nonPromotionalWalletRefund}
                    </p>
                  </div>
                )}
                {cancelDetails?.paymentGatewayAmount > 0 && (
                  <div className={"d-flex justify-content-between px-2"}>
                    <p
                      className={"open-400w-12s-18h "}
                      style={{ color: "#78787D" }}
                    >
                      Refund to Payment Gateway
                    </p>
                    <p
                      className={"open-400w-12s-18h"}
                      style={{ color: "#78787D" }}
                    >
                      ₹ {cancelDetails.paymentGatewayAmount}
                    </p>
                  </div>
                )}
                  {/* {cancelDetails?.promotionalWalletRefund > 0 && (
                  <div className={"d-flex justify-content-between px-2"}>
                    <p
                      className={"open-400w-12s-18h "}
                      style={{ color: "#78787D" }}
                    >
                      Refund to Green Miles
                    </p>
                    <p
                      className={"open-400w-12s-18h"}
                      style={{ color: "#78787D" }}
                    >
                      ₹ {cancelDetails.promotionalWalletRefund}
                    </p>
                  </div>
                )} */}
                
              </div> }
            </div>
          )}

          {/* {cancelDetails?.booking?.passengerdetailSet?.length > 1 && (
        <div className={componentConstant.passengerListWrap_class}>
          <p className={componentConstant.title_class}>Cancel Ticket</p>
          <p className={componentConstant.description_class}>
            Please select Passengers
          </p>
          {cancelDetails?.booking?.passengerdetailSet?.map(
            (passengerItem, index) => {
              return (
                <div
                  className={"d-flex"}
                  key={index}
                  onClick={() => handlePassengerList(passengerItem.id)}
                >
                  <img
                    src={
                      activePassengerList.includes(passengerItem.id)
                        ? checked
                        : unchecked
                    }
                    alt={"alt"}
                    className={"icon-24 mr-2"}
                  />
                  <p
                    className={
                      componentConstant.options_class +
                      (activePassengerList.includes(passengerItem.id)
                        ? " opacity-100"
                        : " opacity-60")
                    }
                  >
                    {passengerItem.name} ({passengerItem.age},{" "}
                    {passengerItem.gender})
                  </p>
                </div>
              );
            }
          )}
        </div>
      )} */}
    

          <div className={"padding-x-local"}>
            <div
        onClick={()=>setShowRefundPolicy(!showRefundPolicy)}
              className={
                "refund-policy-wrap common-input-wrap d-flex justify-content-between"
              }
            >
          <div className={"d-flex"} >
                <img
                  src={refundPolicyIcon}
                  alt={"atl"}
                  className={"icon-20 mr-2"}
                />
            <p className={componentConstant.refundText_class}>Refund Policy</p>
              </div>
              <img src={downArrow} alt={"alt"} className={"icon-24"} />
            </div>
        {showRefundPolicy && (<div>
                <CancellationSection componentConstants={componentConstant} />
        </div>)}
          </div>

          <div className={componentConstant.submitButtonWrap_class}>
            <button
              className={componentConstant.submitButton_class}
              onClick={continueButtonHandler}
            >
              {isDesktopOrLaptop ? "Continue" : "Request Cancel"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

// local constant
const optionList = [
  "My travel plans have changed",
  "Found Coach at cheaper rates",
  "Found another way (train/flight/cab)",
  "Other reasons (add details below)",
];

export default CancelBooking;
