import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../environment";

const mutation = graphql`
	  mutation DeleteCoPassengerMutation($id: ID!) {
	    deleteCoPassenger(id: $id) {
	     ok
	    }
	  }`;

export default (data, callback, errCallback) => {
	const variables = {
		id: data.id,
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.deleteCoPassenger !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}