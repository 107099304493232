import React, {useEffect, useState} from "react";
import "./SubScreens.scss";
// imports
import {useMediaQuery} from "react-responsive";
import {getValues} from "../../../utils/utils";
import RefundResultComponent from "./RefundResultComponent";
import Loader from "../../Loader/Loader";


//icons
import checked from "../../../assets/icons/checked-green.svg"
import unchecked from "../../../assets/icons/unchecked.svg"
import grayCloseIcon from "../../../assets/icons/cross-gray.svg"

const CancelBookingPayment = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    let componentConstant;
    const {
        cancelPaymentSubmitHandler,
        setModal,
        cancelDetails,
        activePayment,
        refundStatus,
        setActivePayment,
        refundErrorMessage,
    } = props;

    const paymentMethodList = [
        // {
        //     id: 1,
        //     title: "NueGo Wallet",
        //     description: "Instant refund",
        //     enum: getValues("PaymentTypeEnum")[0],
        //     value: 1,
        // },
        {
            id: 2,
            title: "Original Mode",
            description: "Refund to your original payment source.",
            enum: getValues("PaymentTypeEnum")[1],
            value: 0,
        },
    ];

    const [activeRefundType, setActiveRefundType] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);


    if (isDesktopOrLaptop) {
        componentConstant = {
            mainTitle_class: "ubuntu-700w-24s-32h mb-0",
            title_class: "open-600w-16s-24h",
            description_class: "open-400w-16s-24h",
            options_class: "open-600w-16s-24h",
            reasonWrap_class: "grey-area py-4",
            refundText_class: "open-400w-18s-28h mb-0 opacity-60",
            submitButtonWrap_class:
                "d-flex justify-content-end open-600w-18s-28h padding-top-side-local",
            submitButton_class: "submit-button",
        };
    } else {
        componentConstant = {
            mainTitle_class: "open-600w-16s-24h mb-0",
            title_class: "open-600w-16s-24h",
            description_class: "open-400w-16s-24h",
            options_class: "open-600w-16s-24h",
            reasonWrap_class: "",
            refundText_class: "open-600w-16s-24h mb-0",
            submitButtonWrap_class:
                "d-flex w-100 open-600w-16s-24h padding-top-side-local",
            submitButton_class: "submit-button w-100",
        };
    }

    const refundPaymentTypeHandler = (value, activePaymentValue) => {
        setActivePayment(activePaymentValue);
        setActiveRefundType(value);
    };

    const refundSubmitHandler = () => {
        setIsSubmitting(true);
        cancelPaymentSubmitHandler(activeRefundType);
    };

    useEffect(() => {
            setIsSubmitting(false); 
            refundPaymentTypeHandler(0,"SOURCE")  
    }
    , [refundStatus]);

    return (
        <div className={""}>
            {refundStatus === 'pending' && isDesktopOrLaptop && (
                <Loader/>
            )}
            {refundStatus === "" && (
                <div className={"cancel-booking-payment padding-top-side-local"}>
                    {!isDesktopOrLaptop && (
                        <img
                            src={grayCloseIcon}
                            className={"closing-icon"}
                            alt="close"
                            onClick={() => setModal(false)}
                        />
                    )}
                    <div
                        className={"d-flex justify-content-between align-items-center mb-4"}
                    >
                        <p className={componentConstant.mainTitle_class}>
                            Select Refund Mode
                        </p>
                        {isDesktopOrLaptop && (
                            <img
                                src={grayCloseIcon}
                                alt="close"
                                onClick={() => setModal(false)}
                            />
                        )}
                    </div>
                    {!isDesktopOrLaptop && <hr className={"horizontal-divider"}/>}
                    <div className={"fare-wrap mb-4"}>
                        <div className={"d-flex justify-content-between"}>
                            <p className={componentConstant.options_class + " opacity-80"}>
                                Total Refund
                            </p>
                            <p className={componentConstant.options_class}>
                                ₹   {cancelDetails.totalRefund
                                    ? Number(cancelDetails.totalRefund) > 0 ?cancelDetails.totalRefund:0
                                : cancelDetails.refundAmount}
                            </p>
                        </div>
                    </div>
                    {isDesktopOrLaptop && (
                        <p className={componentConstant.title_class}>
                            How would you like to get your refund?
                        </p>
                    )}
                    <div className={""}>
                        {paymentMethodList.map((paymentMethod, index) => {
                            return (
                                <div className={"d-flex mb-3"}>
                                    <img
                                        src={
                                            // activePayment === paymentMethod.enum ? checked : unchecked
                                            checked
                                        }
                                        // onClick={() =>
                                        //     refundPaymentTypeHandler(
                                        //         paymentMethod.value,
                                        //         paymentMethod.enum
                                        //     )
                                        // }
                                        className={"icon-24 mr-2"}
                                        alt={"alt"}
                                    />
                                    <div>
                                        <p className={componentConstant.options_class + " mb-0"}>
                                            {paymentMethod.title}
                                        </p>
                                        <p
                                            className={componentConstant.description_class + " mb-0"}
                                        >
                                            {paymentMethod.description}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={componentConstant.submitButtonWrap_class}>
                        <button
                            className={componentConstant.submitButton_class}
                            onClick={() => refundSubmitHandler()}
                            disabled={isSubmitting}
                        >
                            Refund
                        </button>
                    </div>
                </div>
            )}
            {refundStatus === "success" && isDesktopOrLaptop && (
                <RefundResultComponent
                    success={true}
                    message={
                        activeRefundType === 0 ? "Refund has been successfully initiated. It should be " + "credited to your original mode of payment within 6-7 business days"
                            : "The amount has been added to your wallet"
                    }
                />
            )}

            {refundStatus === "fail" && isDesktopOrLaptop && (
                <RefundResultComponent success={false} message={refundErrorMessage}/>
            )}
        </div>
    );
};

export default CancelBookingPayment;
