import {
	ACTIVE_BUS_DETAIL,
	CHANGE_DATE_BUS_LISTING,
	CLEAR_BOOKING_DATA_ON_LOGOUT,
	CLEAR_ON_CHECKOUT,
	DESTINATION_POINT,
	GET_CO_PASSENGERS_LIST,
	RAW_DATE,
	SEAT_ARRANGEMENT,
	SELECTED_SEAT_PRICE,
	SELECTED_SEATS,
	SELECTED_SEATS_TYPE,
	SET_AVAILABLE_BUS_LIST,
	SET_BOARDING_POINT,
	SET_BOOKING_DATA,
	SET_CONTACT_DATA,
	SET_COUPON_MESSAGE,
	SET_DATE,
	SET_DESTINATION_POINT_LIST,
	SET_DROPPING_POINT,
	SET_INSURE_AND_DONATE,
	SET_PASSENGER_COUNT,
	SET_PASSENGER_DATA,
	SET_PAYMENT_METHOD,
	SET_PICKUP_DROP_LIST,
	SET_SAVE_DETAIL_AND_INVOICE,
	SET_SOURCE_POINT_LIST,
	SET_USER_DATA,
	SOURCE_DESTINATION_ID,
	SOURCE_DESTINATION_POINTS,
	ACTIVE_BUS_FEMALE_AVAILABLE,
	SOURCE_POINT,
	SET_COUPON_CODE
} from "../Actions";

const initialState = {
	// source and destination where searched on the travel
	sourcePoint: "",
	sourceId: "",
	destinationPoint: "",
	destinationId: "",
	// boarding and dropping point where user will select on the respective
	// -on source or destination
	boardingPoint: {},
	droppingPoint: {},

	date: "",
	rawDate: "",
	passengersCount: 1,
	activeBusDetail: {},
	selectedSeats: [],
	selectedSeatsType:[],
	selectedSeatPrice: [],
	passengerData: [],
	sourcePointList: [],
	destinationPointList: [],
	availableBusList: [],
	seatArrangement: [],
	setContactData: {},
	setUserData: {},
	setBookingData: {},
	boardingAndDroppingPointList: {},
	insureAndDonate: {
		insure: false,
		donateCovid: false
	},
	saveDetailAndInvoice: {
		saveDetail: false,
		gstInvoice: false
	},
	paymentMethod: "",
	coPassengersList: [],
	couponMessage: "",
	activeBusFemaleAvailable:[],
	activeCouponCode: '',
}

const BookingReducer = (state = initialState, action) => {
	switch (action.type) {

		case SOURCE_POINT:
			return {
				...state,
				sourcePoint: action.payload.data
			}
		case DESTINATION_POINT:
			return {
				...state,
				destinationPoint: action.payload.data
			}
		case SOURCE_DESTINATION_POINTS:
			return {
				...state,
				sourcePoint: action.payload.data.sourcePoint,
				destinationPoint: action.payload.data.destinationPoint,
				passengersCount: action.payload.data.passengersCount,
				date: action.payload.data.date,
				rawDate: action.payload.data.rawDate,
				sourceId: action.payload.data.sourceId,
				destinationId: action.payload.data.destinationId
			}
		case CHANGE_DATE_BUS_LISTING:
			return {
				...state,
				date: action.payload.data.date,
				rawDate: action.payload.data.rawDate,
			}
		case ACTIVE_BUS_DETAIL:

			return {
				...state,
				activeBusDetail: action.payload.data
			}

		case SELECTED_SEATS:
			return {
				...state,
				selectedSeats: action.payload.data
			}
		case SELECTED_SEATS_TYPE:
				return {
					...state,
					selectedSeatsTypes: action.payload.data
			}
		case SELECTED_SEAT_PRICE:
			return {
				...state,
				selectedSeatPrice: action.payload.data
			}
		case SET_PASSENGER_DATA:
			return {
				...state,
				passengerData: action.payload.data
			}

		case SET_SOURCE_POINT_LIST:

			return {
				...state,
				sourcePointList: action.payload.data
			}
		case SET_DESTINATION_POINT_LIST:

			return {
				...state,
				destinationPointList: action.payload.data
			}

		case SET_AVAILABLE_BUS_LIST:
			return {
				...state,
				availableBusList: action.payload.data
			}
		case SET_BOARDING_POINT:
			return {
				...state,
				boardingPoint: action.payload.data
			}

		case SET_DROPPING_POINT:
			return {
				...state,
				droppingPoint: action.payload.data
			}

		case SEAT_ARRANGEMENT:
			return {
				...state,
				seatArrangement: action.payload.data
			}
		case SET_CONTACT_DATA:
			return {
				...state,
				setContactData: action.payload.data
			}
		case SET_USER_DATA:
			return {
				...state,
				setUserData: action.payload.data
			}
		case SET_BOOKING_DATA:
			return {
				...state,
				setBookingData: action.payload.data
			}
		case SET_PICKUP_DROP_LIST:
			return {
				...state,
				boardingAndDroppingPointList: action.payload.data
			}
		case SET_INSURE_AND_DONATE:
			return {
				...state,
				insureAndDonate: action.payload.data
			}
		case SET_SAVE_DETAIL_AND_INVOICE:
			return {
				...state,
				saveDetailAndInvoice: action.payload.data
			}
		case SET_PAYMENT_METHOD:
			return {
				...state,
				paymentMethod: action.payload.data
			}
		case SET_COUPON_CODE:
			return {
				...state,
				activeCouponCode: action.payload.data
			}
		case RAW_DATE:
			return {
				...state,
				rawDate: action.payload.data
			}
		case CLEAR_ON_CHECKOUT:
			return {
				...state,
				sourcePoint: "",
				sourceId: "",
				destinationPoint: "",
				destinationId: "",
				boardingPoint: {},
				droppingPoint: {},
				date: "",
				rawDate: "",
				passengersCount: 1,
				activeBusDetail: {},
				selectedSeats: [],
				selectedSeatPrice: [],
				passengerData: [],
				// sourcePointList: [],
				// destinationPointList: [],
				// availableBusList: [],
				// seatArrangement: [],
				setContactData: {},
				setUserData: {},
				setBookingData: {},
				boardingAndDroppingPointList: {},
				insureAndDonate: {
					insure: false,
					donateCovid: false
				},
				saveDetailAndInvoice: {
					saveDetail: false,
					gstInvoice: false
				},
				paymentMethod: "",

			}

		case SOURCE_DESTINATION_ID:
			return {
				...state,
				sourceId: action.payload.data.sourceId,
				destinationId: action.payload.data.destinationId
			}

		case SET_DATE:
			return {
				...state,
				date: action.payload.data.date
			}

		case SET_PASSENGER_COUNT:
			return {
				...state,
				passengersCount: action.payload.data.passengersCount
			}

		case GET_CO_PASSENGERS_LIST:
			return {
				...state,
				coPassengersList: action.payload.data
			}

		case SET_COUPON_MESSAGE:
			return {
				...state,
				couponMessage: action.payload.data
			}
		case ACTIVE_BUS_FEMALE_AVAILABLE:
			return {
				...state,
				activeBusFemaleAvailable: action.payload.data
			}
		case CLEAR_BOOKING_DATA_ON_LOGOUT:
			return {
				...state,
				sourcePoint: "",
				sourceId: "",
				destinationPoint: "",
				destinationId: "",
				// boarding and dropping point where user will select on the respective
				// -on source or destination
				boardingPoint: {},
				droppingPoint: {},

				date: "",
				rawDate: "",
				passengersCount: 1,
				activeBusDetail: {},
				selectedSeats: [],
				selectedSeatPrice: [],
				passengerData: [],
				sourcePointList: [],
				destinationPointList: [],
				availableBusList: [],
				seatArrangement: [],
				setContactData: {},
				setUserData: {},
				setBookingData: {},
				boardingAndDroppingPointList: {},
				insureAndDonate: {
					insure: false,
					donateCovid: false
				},
				saveDetailAndInvoice: {
					saveDetail: false,
					gstInvoice: false
				},
				paymentMethod: "",
				coPassengersList: [],
				couponMessage: "",
				activeBusFemaleAvailable:[],
			}
		default:
			return state
	}
}

export default BookingReducer