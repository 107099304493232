import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
    mutation UpdateChecklistNameMutation(
        $id:ID!,
        $name:String!,
    ){
        updateCheckList(
            id: $id,
            name: $name,
        ){
            status
            message
        }
    }
`;

export default (variables, callback, errCallback) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.updateCheckList !== null) {
                    callback(response);
                } else {
                    errCallback(err);
                }
            },
            onError: err => {
                errCallback(err);
            }
        }
    )
}