import React, { useEffect, useState } from "react";
import "../../AboutUsPage/style.scss";
import { useMediaQuery } from "react-responsive";
import Markdown from 'react-markdown';
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";

//assets
const mobileWelcomeImage = process.env.REACT_APP_CDN_LINK + `assets/images/mobileVisionImage.png`
const desktopWelcomeImage = process.env.REACT_APP_CDN_LINK + `assets/images/desktopVisionImage.png`

const GreenVision = () => {

    const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
    const [GreenVisionData, setGreenVisionData] = useState('')

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }




    useEffect(() => {

        setLoadingSpiner(pendingObject)

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/green-vision?populate=*`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())

            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpiner(errorObject)

                    } else {
                        setLoadingSpiner(sucessObject)
                        setGreenVisionData(res?.data)
                    }

                    return res;

                })

            .catch((err) => {
                setLoadingSpiner(errorObject)
                return err;

            });

    }, [])




    return (
        <>
            {LoadingSpiner?.value == true ? <Loader />
                : <div
                    className={
                        isDesktopOrLaptop
                            ? "green-vision d-flex align-items-center justify-content-center py-5"
                            : "green-vision px-3 pt-4 pb-5"
                    }
                >
                    <div className={isDesktopOrLaptop && "order-2"}>

                        {!isDesktopOrLaptop && (

                            <p className="mobile-title">Green Vision</p>
                        )}
                        {isDesktopOrLaptop && LoadingSpiner?.Response != "error" ? (

                            <img

                                src={GreenVisionData?.attributes?.image}

                                alt="alt"

                                className={"mx-auto img-responsive w-100"}

                            ></img>

                        ) : isDesktopOrLaptop && (<img

                            src={desktopWelcomeImage}

                            alt="alt"

                            className={"mx-auto img-responsive w-100 "}

                        ></img>)}



                        {!isDesktopOrLaptop && LoadingSpiner?.Response != "error" ? (

                            <img

                                src={GreenVisionData?.attributes?.image}

                                className="mx-auto d-block img-fluid mobile-image mb-4 w-100"

                                alt="alt"

                            ></img>

                        ) : !isDesktopOrLaptop && (

                            <img

                                src={mobileWelcomeImage}

                                className="mx-auto d-block img-fluid mobile-image mb-4 w-100"

                                alt="alt"

                            ></img>

                        )}

                    </div>
                    <div className={isDesktopOrLaptop && "order-1"}>
                        {isDesktopOrLaptop && (
                            <p className="desktop-title">Green Vision</p>
                        )}
                        <div className={"welcome-data"}>
                            {LoadingSpiner?.Response != "error" ? <Markdown
  >
                                {GreenVisionData?.attributes?.description}
                            </Markdown>
                                :
                                <>
                                    <p>
                                        It’s time for clean, quiet transportation for all creating a sustainable green future.
                                        Communities are growing and evolving, and with that, our transportation needs are changing. Now
                                        more than ever, we need safer, more reliable and cleaner options. Every day, NueGo works to meet
                                        those needs with the world’s best electric vehicle technology solutions.
                                    </p>
                                    <p>
                                        NueGo is dedicated on building a platform to provide Electric Mobility-as-a-Service (eMaaS)
                                    </p>
                                </>
                            }
                            {/* <p>
                        It’s time for clean, quiet transportation for all creating a sustainable green future.
                        Communities are growing and evolving, and with that, our transportation needs are changing. Now
                        more than ever, we need safer, more reliable and cleaner options. Every day, NueGo works to meet
                        those needs with the world’s best electric vehicle technology solutions.
                    </p>
                    <p>
                        NueGo is dedicated on building a platform to provide Electric Mobility-as-a-Service (eMaaS)
                    </p> */}
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default GreenVision;