/**
 * @generated SignedSource<<3d91ce22994d6bf5b67508676680f9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cancellationReason"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cancellationRemark"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passengerIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paymentType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "refundType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      },
      {
        "kind": "Variable",
        "name": "cancellationReason",
        "variableName": "cancellationReason"
      },
      {
        "kind": "Variable",
        "name": "cancellationRemark",
        "variableName": "cancellationRemark"
      },
      {
        "kind": "Variable",
        "name": "passengerIds",
        "variableName": "passengerIds"
      },
      {
        "kind": "Variable",
        "name": "paymentType",
        "variableName": "paymentType"
      },
      {
        "kind": "Variable",
        "name": "refundType",
        "variableName": "refundType"
      }
    ],
    "concreteType": "PartialCancellation",
    "kind": "LinkedField",
    "name": "confirmPartialBookingCancellation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PartialCancellationType",
        "kind": "LinkedField",
        "name": "confirmPartialCancellation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pnrNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newPnrNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cancelAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "refundAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newBookingAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "newServiceTax",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelBookingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelBookingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82bfde44657c10c910dfa8ecf1a0ed21",
    "id": null,
    "metadata": {},
    "name": "CancelBookingMutation",
    "operationKind": "mutation",
    "text": "mutation CancelBookingMutation(\n  $bookingId: ID\n  $cancellationReason: String!\n  $cancellationRemark: String\n  $passengerIds: [String]\n  $paymentType: PaymentTypeEnum\n  $refundType: Int!\n) {\n  confirmPartialBookingCancellation(bookingId: $bookingId, cancellationReason: $cancellationReason, cancellationRemark: $cancellationRemark, passengerIds: $passengerIds, paymentType: $paymentType, refundType: $refundType) {\n    confirmPartialCancellation {\n      pnrNumber\n      newPnrNumber\n      cancelAmount\n      status\n      refundAmount\n      newBookingAmount\n      newServiceTax\n    }\n  }\n}\n"
  }
};
})();

node.hash = "329c8a5fa03c8d8d39d2e9da2c91ffe1";

module.exports = node;
