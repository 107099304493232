import React ,{useState,useRef}from 'react'
import Navbar from "../../../components/Navbar/Navbar";
import Footer from '../../../components/Footer/Footer';
import {connect} from "react-redux";
import {setDestinationPoint, setSourceAndDestination, setSourcePoint,} from "../../../store/Actions/BookingAction";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import InnerSearchbar from "../../Home/component/SearchBarRevamp/InnerSearchbar";
import MobileTopBus from "../../Home/component/MobileTopBus";
// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import TravelItineraries from './Components/TravelItineraries/TravelItineraries';
import CardGrid from './Components/CradGrid/CardGrid';
import CategoryBlogs from './Components/BlogCategories/BlogCategory';
import FeatureCard from './Components/FeatureCard/FeatureCard';
import "./Blog.scss"
import NueGoScanner from './Components/NueGoScanner/NueGoScanner';


function Blog(props) {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
  return (
    <div className='blog-landing-page'>  
            <Helmet>
                <title>Travel Blogs in India | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog" hreflang="en-IN" />
                <meta name="description" content="NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways." />
                <meta name="keywords" content="travel blogs,travel blog site,tourism blog,travel blog post,short travel blog"></meta>
                <meta name="robots" content="index,follow" />
                <script type='application/ld+json'>
                {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://www.nuego.in/blog",
                "name": "NueGo Blog",
                "description": "NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways.",
                "publisher": {
                    "@type": "Organization",
                    "name": "NueGo",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                    "width": 200,
                    "height": 100
                    }
                }
                })}
                </script>
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <Navbar />
                    <MainContent  isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
                    <Footer/>
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
                        <Footer/>
                    </MobileSubScreenLayout>
            }
    </div>
  )

}

export const MainContent = ({props}) => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const ref = useRef();
    const [selectedSearchFromRecent, setSelectedSearchFromRecent] = useState({});
    const [viewCalendar, setViewCalendar] = useState(false);
    const [
        viewCalendarActivatedBeforeScroll,
        setViewCalendarActivatedBeforeScroll,
    ] = useState(false);

    const {
        setSourceAndDestinationToStore,
        sourcePointListFromStore,
        destinationPointListFromStore,
        userDataFromStore,
        filterDataFromStore,
        filterFromStore
    } = props;

    const openCalendarForMobile = (args) => {
        document.querySelector(".MobileTopBus").style.position = "relative";
        document.querySelector(".MobileTopBus").style.top = "0";

        setViewCalendar(args);
        setViewCalendarActivatedBeforeScroll(true);
    };
    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <div className='blog-main-content'>
               {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Blog</p>
                        <script type='application/ld+json'>
                            {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.nuego.in"
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Blogs",
                                "item": "https://www.nuego.in/blog"
                                }
                            ]
                            })}
                        </script>
                    </div>
                    }
            <FeatureCard/>
            <CategoryBlogs/>
            <TravelItineraries/>
            <CardGrid category={'History'} />
            { isDesktopOrLaptop ?
                    <div className="searchbar-div">
                        <div className="semicircle"></div>
                        <InnerSearchbar
                            navigate={navigate}
                            sourcePointListFromStore={sourcePointListFromStore}
                            destinationPointListFromStore={destinationPointListFromStore}
                            setSourceAndDestinationToStore={(args) =>
                                setSourceAndDestinationToStore(args)
                            }
                            selectedSearchFromRecent={selectedSearchFromRecent}
                            
                            setSelectedSearchFromRecent={(args) =>
                                setSelectedSearchFromRecent(args)
                            }
                            userDataFromStore={userDataFromStore}
                            filterDataFromStore={filterDataFromStore}
                            filterFromStore={filterFromStore}
                            heading="Book Electric AC Bus Tickets Online"
                            />
                    </div> 
                    :
                    <div className="mweb404search">
                        {/* <div className="semicircle"></div> */}
                        {/* <h2 className="mweb-head">Book a ride with us to your favourite destination!</h2> */}
                        <MobileTopBus
                                navigate={navigate}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                                sourcePointListFromStore={sourcePointListFromStore}
                                destinationPointListFromStore={destinationPointListFromStore}
                                setSourceAndDestinationToStore={(args) =>
                                    setSourceAndDestinationToStore(args)
                                }
                               
                                selectedSearchFromRecent={selectedSearchFromRecent}
                               
                                setSelectedSearchFromRecent={(args) =>
                                    setSelectedSearchFromRecent(args)
                                }
                                viewCalendar={viewCalendar}
                                setViewCalendar={(args) => openCalendarForMobile(args)}
                                userDataFromStore={userDataFromStore}
                                filterDataFromStore={filterDataFromStore}
                                filterFromStore={filterFromStore}
                                title= 'Book Electric AC Bus Tickets Online'
                            />
                    </div>
                    }
            <CardGrid category={'Must-Visits'}/>
            <NueGoScanner/>
        </div>

    );
}

const mapStateToProps = (state) => ({
    sourcePointFromStore: state.BookingReducer.sourcePoint,
    destinationPointFromStore: state.BookingReducer.destinationPoint,
    sourcePointListFromStore: state.BookingReducer.sourcePointList,
    destinationPointListFromStore: state.BookingReducer.destinationPointList,
    userDataFromStore: state.AuthReducer,
    filterDataFromStore: state.FilterReducer.filter,
    filterFromStore: state.FilterReducer.filter
});

const mapDispatchToProps = (dispatch) => ({
    setSourcePointToStore: (data) => dispatch(setSourcePoint(data)),
    setDestinationPointToStore: (data) => dispatch(setDestinationPoint(data)),
    setSourceAndDestinationToStore: (data) =>
        dispatch(setSourceAndDestination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (Blog)