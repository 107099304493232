const breathAnalyser = process.env.REACT_APP_CDN_LINK + "assets/images/breathAnalyser.png";
const rergularlySanitised = process.env.REACT_APP_CDN_LINK + "assets/images/RegularlySanitised.png";
const cctvSurveillance = process.env.REACT_APP_CDN_LINK + "assets/images/cctvSurveillance.png";
const speedLimitCheck = process.env.REACT_APP_CDN_LINK + "assets/images/speedLimitCheck.png";

export const api = process.env.REACT_APP_BACKEND_API,
cloudFlareSiteKey = process.env.REACT_APP_CAPTCHA_SITEKEY_DEV
export const isMapEnabled = process.env.REACT_APP_MAP_ENABLED

export const googleClientId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
export const isInsuranceEnabled = process.env.REACT_APP_INSURANCE_ENABLED === "true"
export const isFnbEnabled = process.env.REACT_APP_FNB_ENABLED === "true"

export const NuegoSafetyStories = [
    {
        id: "1",
        title: "Breath Analyser",
        image: breathAnalyser,
        video: "https://www.youtube.com/embed/pnh5IcWff7I",
        subHeading: "NueGo Electric Buses - Promises of Safety, Comfort, and Sustainability.",
        description: "Bringing a new and safe way to travel for India."
    }, {
        id: "2",
        title: "Regularly Sanitised ",
        image: rergularlySanitised,
        video: "https://www.youtube.com/embed/pnh5IcWff7I",
        subHeading: "NueGo Electric Buses - Promises of Safety, Comfort, and Sustainability.",
        description: "Bringing a new and safe way to travel for India."
    }, {
        id: "3",
        title: "CCTV Surveillance",
        image: cctvSurveillance,
        video: "https://www.youtube.com/embed/pnh5IcWff7I",
        subHeading: "NueGo Electric Buses - Promises of Safety, Comfort, and Sustainability.",
        description: "Bringing a new and safe way to travel for India."
    },
    {
        id: "4",
        title: "Speed Limit Checks",
        image: speedLimitCheck,
        video: "https://www.youtube.com/embed/pnh5IcWff7I",
        subHeading: "NueGo buses on 24/7 CCTV surveillance for your safety",
        description: "We aim to solve the challenges related to security."
    }, {
        id: "5",
        title: "Regularly Sanitised ",
        image: rergularlySanitised,
        video: "https://www.youtube.com/embed/pnh5IcWff7I",
        subHeading: "NueGo Electric Buses - Promises of Safety, Comfort, and Sustainability.",
        description: "Bringing a new and safe way to travel for India."
    },
]

