import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../environment";

const mutation = graphql`
	mutation ResendOtpMutation($mobileNumber:String!,$captchaToken:String){
		resendOtp(mobileNumber:$mobileNumber,captchaToken:$captchaToken){
		status
		message
		
		}
	}`


export default (data, callback, errCallback) => {
	const variables = {
		mobileNumber: data.mobileNumber,
		captchaToken:data.captchaToken
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.resendOtp !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			}
		}
	)
}