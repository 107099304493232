import React ,{useState,useRef}from 'react'
import { useSelector , useDispatch } from 'react-redux';
import {setDestinationPoint, setSourceAndDestination, setSourcePoint,} from "../../store/Actions/BookingAction";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import "./SearchBarMweb.scss"

import MobileTopBus from "../../pages/Home/component/MobileTopBus";
const SearchBarMweb = ({props})=>{

    const sourcePointFromStore = useSelector((state) => state.BookingReducer.sourcePoint);
    const destinationPointFromStore = useSelector((state) => state.BookingReducer.destinationPoint);
    const sourcePointListFromStore = useSelector((state) => state.BookingReducer.sourcePointList);
    const destinationPointListFromStore = useSelector((state) => state.BookingReducer.destinationPointList);
    const userDataFromStore = useSelector((state) => state.AuthReducer);
    const filterDataFromStore = useSelector((state) => state.FilterReducer.filter);
    const filterFromStore = useSelector((state) => state.FilterReducer.filter); // Duplicate in the original, keeping for parity

    const dispatch = useDispatch(); // Get the dispatch function

    // Define the dispatch actions using useDispatch
    const setSourcePointToStore = (data) => dispatch(setSourcePoint(data));
    const setDestinationPointToStore = (data) => dispatch(setDestinationPoint(data));
    const setSourceAndDestinationToStore = (data) => dispatch(setSourceAndDestination(data));

    const navigate = useNavigate();


    const ref = useRef();
    const [selectedSearchFromRecent, setSelectedSearchFromRecent] = useState({});
    const [viewCalendar, setViewCalendar] = useState(false);
    const [
        viewCalendarActivatedBeforeScroll,
        setViewCalendarActivatedBeforeScroll,
    ] = useState(false);

    // const {
    //     setSourceAndDestinationToStore,
    //     sourcePointListFromStore,
    //     destinationPointListFromStore,
    //     userDataFromStore,
    //     filterDataFromStore,
    //     filterFromStore
    // } = props;

    const openCalendarForMobile = (args) => {
        document.querySelector(".MobileTopBus").style.position = "relative";
        document.querySelector(".MobileTopBus").style.top = "0";

        setViewCalendar(args);
        setViewCalendarActivatedBeforeScroll(true);
    };

      return (
        <div className="mweb404search">
        {/* <div className="semicircle"></div> */}
        <h2 className="mweb-head">Book Electric AC Bus Tickets Online  </h2>
        <MobileTopBus
                navigate={navigate}
                isDesktopOrLaptop={true}
                sourcePointListFromStore={sourcePointListFromStore}
                destinationPointListFromStore={destinationPointListFromStore}
                setSourceAndDestinationToStore={(args) =>
                    setSourceAndDestinationToStore(args)
                }
               
                selectedSearchFromRecent={selectedSearchFromRecent}
               
                setSelectedSearchFromRecent={(args) =>
                    setSelectedSearchFromRecent(args)
                }
                viewCalendar={viewCalendar}
                setViewCalendar={(args) => openCalendarForMobile(args)}
                userDataFromStore={userDataFromStore}
                filterDataFromStore={filterDataFromStore}
                filterFromStore={filterFromStore}
            />
    </div>
      )


} 



export default SearchBarMweb


