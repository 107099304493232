import React, {useEffect, useState} from 'react'
import './QrPage.scss'
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate} from "react-router";
import QrComponent from "../../components/QRComponent/QrComponent";
import {store} from "../../index";
import {getTicketUrlData} from "../../services/api/DataFetching";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";

const QrPage = (props) => {
	const navigate = useNavigate()
	const postBookingDataFromStore = store.getState().CommonReducer.postBookingData
	const [ticketUrl, setTicketUrl] = useState("")

	useEffect(() => {
		getTicketUrlHandler()
		return () => {

		};
	}, []);

	const getTicketUrlHandler = () => {
		let variable = {
			bookingId: store.getState().CommonReducer.selectedBookingId
		}

		getTicketUrlData(variable, (data) => {

			setTicketUrl(data.url)

		}, (err) => {
			if(err){
				cogoToast.error(
					<CustomToast
						heading={"Error"}
						message={err[0]?.message || err.toString()}
						type={"error"}
					/>,
				);
			}
		})
	}
	return (
		<div className={'qr-page'}>
			<MobileSubScreenLayout title={'Scan QR Ticket'} back={() => navigate(-1)}>
				<div className={'px-3'}>
					<QrComponent
						link={ticketUrl}
						closeModal={() => navigate(-1)}
						qrCode={postBookingDataFromStore.qrCode}
						customerBookingId={postBookingDataFromStore.pnrNo}
					/>
				</div>
			</MobileSubScreenLayout>

		</div>
	)
}

export default QrPage
