/**
 * @generated SignedSource<<a5d806ce87da9af3a2e9ad8ab11cb0d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoPassengerNode",
    "kind": "LinkedField",
    "name": "coPassengers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "age",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobileNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTrusted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCoPassengerListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetCoPassengerListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b435ee76316a618900ff040832b52716",
    "id": null,
    "metadata": {},
    "name": "GetCoPassengerListQuery",
    "operationKind": "query",
    "text": "query GetCoPassengerListQuery {\n  coPassengers {\n    id\n    name\n    age\n    gender\n    mobileNumber\n    isTrusted\n  }\n}\n"
  }
};
})();

node.hash = "6e2da32f568b5ef807817179197cb879";

module.exports = node;
