import React, {useEffect, useState} from 'react'
import '../ProfileDetail.scss'
import {useMediaQuery} from "react-responsive";
import { useSelector } from 'react-redux';
//other imports
import cogoToast from "cogo-toast";
import {motion} from "framer-motion";
import {CustomToast} from "../../../components/CustomToast/CustomToast";
import {UpdateProfileData} from "../../../services/api/DataUpdating";
import {store} from "../../../index";
import {getValues} from "../../../utils/utils";
import {setProfileData} from "../../../store/Actions/AuthActions";

//assets
import profileIcon from "../../../assets/icons/my-profile-icon.svg"
import contactIcon from "../../../assets/icons/call-icon.svg"
import checkedIcon from "../../../assets/icons/checked-green.svg"
import uncheckedIcon from "../../../assets/icons/unchecked.svg"



const ProfilePersonalDetail = ({ Edit}) => {
    let   profileData = useSelector((state) => state.AuthReducer.profileData) 

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const userMobileNumberFromStore = store.getState().AuthReducer.mobileNumber;
    const passengerGenderList = getValues("ProfileGenderEnum!")

    let componentData
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-700w-16s-24h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 "
        }
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 "
        }
    }

    const [gstInvoice, setGstInvoice] = useState(false);
    const [personalDetailData_local, setPersonalDetailData_local] = useState({});
    const [error, setError] = useState({
        name: false,
        age: false
    });
    const [touched, setTouched] = useState([]);


    const userName = localStorage.getItem("userName")
    const userEmail = localStorage.getItem("userEmail")
    const userId = localStorage.getItem("userID")
    const userNmber = localStorage.getItem("userNumber")
    const moeId = localStorage.getItem("moeID")

    // useEffect
    useEffect(() => {

        let tempObj = {
            name: profileData?.user?.username ? profileData?.user?.username : "",
            age: profileData?.age ? profileData.age : "",
            gender: profileData?.gender ? profileData.gender : null,
            email: profileData?.user?.email ? profileData?.user?.email : "",
            gstNumber: profileData?.gstin ? profileData.gstin : null,
            gstName: profileData?.registeredName ? profileData.registeredName : "",
            // gstState: profileData?.gstState ? profileData.gstState : "",
            gstState: "0",

        }
        setPersonalDetailData_local(tempObj)
    }, [])

    // methods
    const onChangeHandler = (e, field) => {
        if (field !== "gender") {
            e.preventDefault()
        }
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const gstNumberRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }

        if (field === 'name') {
            if (e.target.value.trim(' ' ,'') === '' ) {
                setError({...error, [field]: 'error'})
                setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
            } else {
                if (e.target.value.match(/\d+/g) === null) {

                    setError({...error, [field]: null})
                    setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
                }
            }

        }

        if (field === 'age') {
            setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
            if (e.target.value < 6 || e.target.value > 100) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})
                setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
            }
        }

        if (field === "gender") {
            setPersonalDetailData_local({...personalDetailData_local, [field]: e})
        }

        if (field === "email") {
            setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
            if (e.target.value.match(emailRegex) == null) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})

            }
        }

        if (field === "gstNumber") {
            if (e.target.value.length < 16) {
                setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
                if (e.target.value.match(gstNumberRegex) == null) {
                    setError({...error, [field]: 'error'})
                } else {
                    setError({...error, [field]: null})
                }
            }
        }

        if (field === "gstName") {
            setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
            if (personalDetailData_local.gstName === "") {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})
            }
        }

        // if (field === "gstState") {
        // 	setPersonalDetailData_local({...personalDetailData_local, [field]: e.target.value})
        // 	if (personalDetailData_local.gstState === "") {
        // 		setError({...error, [field]: 'error'})
        // 	} else {
        // 		setError({...error, [field]: null})
        // 	}
        // }

    }


    const onSubmitHandler = () => {
        let errorLength = Object.values(error).filter((item) => item === 'error').length;

        if (personalDetailData_local.name.trim(' ' ,'')=== '') {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please Enter the Name"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else if (personalDetailData_local.email === "") {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please Enter the Email"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else if (personalDetailData_local.age === "") {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please Enter the age"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else if (personalDetailData_local.gender === null) {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please update the Gender field"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else {

            if (touched.length === 0) {
                cogoToast.error(<CustomToast
                    type={"error"} message={"Please update any field to save changes"}
                    heading={"Error"}
                />, {position: 'top-center'})
            } else if (errorLength !== 0) {
                cogoToast.error(<CustomToast
                    type={"error"} message={"Please update the highlighted fields"}
                    heading={"Error"}
                />, {position: 'top-center'})
            }

            if (touched.length !== 0 && errorLength === 0) {
                let variables = {
                    userId: localStorage.getItem("userID"),
                    name: personalDetailData_local.name,
                    age: parseInt(personalDetailData_local.age),
                    gender: personalDetailData_local.gender,
                    email: personalDetailData_local.email,
                    gstin: gstInvoice ? personalDetailData_local.gstNumber : "",
                    registeredName: gstInvoice ? personalDetailData_local.gstName : "",
                    // gstState: personalDetailData_local.gstState,
                    gstState: gstInvoice ? "0" : ""
                }

                UpdateProfileData(variables, (response) => {
                    store.dispatch(setProfileData(response))
                    !isDesktopOrLaptop && Edit();
                    window.Moengage.track_event("profile_update", {
                        "account_id": `${moeId === null ? "" : moeId}`,
                        "platform": "web",
                        "page_viewed": "yes",
                        "fullname": `${response?.userDetail?.user?.username}`,
                        "mobile_number": `${response?.userDetail?.user?.mobileNumber}`,
                        "email": `${response?.userDetail?.user?.email}`,
                        "isloggedin": `${userId === null ? 'no' : 'yes'}`,
                         });
                }, (err) => {
                    if(err){
                        cogoToast.error(
                            <CustomToast
                                heading={"Error"}
                                message={err[0]?.message || err.toString()}
                                type={"error"}
                            />,
                        );
                    }
                })
            }
        }

    }

    const handleTouch = (field) => {

        if (field === "age" && (error.name || profileData.name === "")) {
            // cogoToast.error('Please fill Name', {position: 'top-center'})
            cogoToast.error(<CustomToast type={"error"} message={"Please fill Name"}
                                         heading={"Error"}/>, {position: "top-center"})

        }

        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }
    }

    const handleBlur = (field) => {
        if (field === "gstNumber") {
            if (personalDetailData_local.gstNumber.length !== 15) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})
            }
        }
        if (touched.includes(field)) {
            if (personalDetailData_local[field] === "") setError({...error, [field]: 'error'})
        }
    }

    return (
        <div className={'profile-personal-detail-component'}>
            {/* heading */}
            <div className={'d-flex justify-content-between align-items-center'}>
                {/*left */}
                <div className={'d-flex'}>
                    {!isDesktopOrLaptop && <img src={profileIcon} alt={'alt'} className={'profile-icon icon-24'}/>}
                    <div className={'ml-3 ml-lg-0'}>
                        <p className={componentData.title_class}>Personal Details</p>
                        {isDesktopOrLaptop &&
                            <p className={componentData.subTitle_class}>Basic info for better expierence</p>}
                    </div>
                </div>

                {/* right */}
                {isDesktopOrLaptop && <div className={componentData.outlineCta_class} onClick={onSubmitHandler}>
                    Save Changes
                </div>}
            </div>

            {/* FORM	*/}
            <div className={'mb-4'}>

                <div className={'row ' + (isDesktopOrLaptop ? componentData.inputMarginBottom_class : "")}>
                    <div className="col-lg-6 col-12 mb-lg-0 mb-3">
                        {/* name */}
                        <p className={componentData.label_class}>Name </p>
                        <div
                            className={'common-input-wrap input-name ' + (error.name === "error" && "common-input-wrap-error")}
                            onClick={() => handleTouch("name")}>
                            <input className="border-less-input w-100"
                                   value={personalDetailData_local.name}
                                   onBlur={() => handleBlur("name")}
                                   onChange={(e) => {
                                       // case 1 a
                                       onChangeHandler(e, "name")
                                   }}
                                   type={"text"}/>
                        </div>
                        {error.name === "error" && <div>
                            <p className={componentData.error_class}>Invalid Name</p>
                        </div>}
                    </div>
                    <div className="col-lg-6 col-12">
                        {/* phone number */}
                        {isDesktopOrLaptop && <div className="d-flex">
                            <div className={'mr-3'}>
                                <p className={componentData.label_class}>Code</p>

                                <div className={'common-input-wrap '}>
                                    <select className="border-less-input select-element"
                                    >
                                        <option>+91</option>
                                        <option>+90</option>
                                    </select>
                                </div>
                            </div>
                            <div className={'w-100'}>
                                <p className={componentData.label_class}>Phone Number</p>

                                <div onClick={() => handleTouch("phoneNumber")}
                                     className={'common-input-wrap '}>
                                    <input
                                        min="0"
                                        className="border-less-input w-100 "
                                        type={"text"}
                                        readOnly={true}
                                        value={userMobileNumberFromStore}
                                        // pattern="[0-9]{10}"
                                        onKeyPress={(e) => e.target.value.length === 10 ? e.preventDefault() : null}
                                        // onBlur={() => handleBlur("phoneNumber")}
                                        onChange={(e) => onChangeHandler(e, "phoneNumber")}
                                    />

                                </div>
                                {/*{error.phoneNumber === "error" && <div>*/}
                                {/*	<p className={componentData.error_class}>Invalid Mobile Number</p>*/}
                                {/*</div>}*/}
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={'row'}>
                    <div className="col-lg-6 col-12 mb-lg-0 mb-3">
                        {/* email */}
                        {isDesktopOrLaptop && <>
                            <p className={componentData.label_class}>Email ID</p>

                            <div onClick={() => handleTouch("email")}
                                 className={'common-input-wrap ' + (error.email === "error" && 'common-input-wrap-error')}>
                                <input className="border-less-input w-100"
                                       type={"email"}
                                       onBlur={() => handleBlur("email")}
                                       value={personalDetailData_local.email}
                                       onChange={(e) => onChangeHandler(e, "email")}
                                />

                            </div>
                            {error.email === "error" && <div>
                                <p className={componentData.error_class}>Invalid Email ID</p>
                            </div>}
                        </>}

                    </div>
                    <div className="col-lg-6 col-12">
                        <div className={'d-flex justify-content-between '}>
                            <div className={' mb-md-3 '}>
                                <p className={componentData.label_class}>Age</p>
                                <div
                                    className={'common-input-wrap input-age ' + (error.age === "error" && "common-input-wrap-error")}
                                    onClick={() => handleTouch("age")}>
                                    <input className="border-less-input"
                                           pattern="/^-?\d+\.?\d*$/"

                                           value={personalDetailData_local.age}
                                           min="0"
                                           max={"100"}
                                           onChange={(e) => {
                                               // case 1 a
                                               onChangeHandler(e, "age")
                                           }}
                                           type={"number"}/>
                                </div>
                                {error.age === "error" && <div>
                                    <p className={componentData.error_class}>Invalid Age</p>
                                </div>}
                            </div>
                            <div className={'ml-3 mb-md-3 w-100'}>
                                <p className={componentData.label_class}>Gender</p>
                                <div className={'d-flex'}>

                                    <div onClick={() => {
                                        onChangeHandler("MALE", "gender")
                                    }}
                                         className={"gender-box-left " + (personalDetailData_local?.gender === "MALE" ? "teal-22BBB0-color active-tab" : "opacity-60")}>
                                        <p className={'open-600w-16s-24h '}>Male</p>
                                    </div>
                                    <div onClick={() => {
                                        onChangeHandler("FEMALE", "gender")
                                    }}
                                         className={"gender-box-right " + (personalDetailData_local?.gender === "FEMALE" ? "teal-22BBB0-color active-tab" : "opacity-60")}>
                                        <p className={'open-600w-16s-24h '}>Female</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile contact form	*/}
            {!isDesktopOrLaptop && <div className={''}>
                {/* heading */}
                <div>
                    {/*left */}
                    <div className={'d-flex'}>
                        <img src={contactIcon} alt={'alt'} className={'profile-icon icon-24'}/>
                        <div className={'ml-3 ml-lg-0'}>
                            <p className={componentData.title_class}>Contact Details</p>
                        </div>
                    </div>
                </div>
                {/* form */}
                <div>
                    {/* phone number */}
                    <div className="d-flex">
                        <div className={'mr-3'}>
                            <p className={componentData.label_class}>Code</p>

                            <div className={'common-input-wrap ' + componentData.inputMarginBottom_class}>
                                <select className="border-less-input select-element"
                                >
                                    <option>+91</option>
                                    <option>+90</option>
                                </select>
                            </div>
                        </div>
                        <div className={'w-100'}>
                            <p className={componentData.label_class}>Phone Number</p>

                            <div onClick={() => handleTouch("phoneNumber")}
                                 className={'common-input-wrap '}>
                                <input
                                    min="0"
                                    className="border-less-input w-100 "
                                    type={"text"}
                                    readOnly={true}
                                    value={userMobileNumberFromStore}
                                    // pattern="[0-9]{10}"
                                    onKeyPress={(e) => e.target.value.length === 10 ? e.preventDefault() : null}
                                    onBlur={() => handleBlur("phoneNumber")}
                                    onChange={(e) => onChangeHandler(e, "phoneNumber")}
                                />

                            </div>
                            {/*{error.phoneNumber === "error" && <div>*/}
                            {/*	<p className={componentData.error_class}>Invalid Mobile Number</p>*/}
                            {/*</div>}*/}
                        </div>
                    </div>
                    {/* email */}
                    <>
                        <p className={componentData.label_class}>Email ID</p>

                        <div onClick={() => handleTouch("email")}
                             className={'common-input-wrap ' + (error.email === "error" && 'common-input-wrap-error') + componentData.inputMarginBottom_class}>
                            <input className="border-less-input w-100"
                                   type={"email"}
                                   value={personalDetailData_local.email}
                                   onBlur={() => handleBlur("email")}
                                   onChange={(e) => onChangeHandler(e, "email")}
                            />
                        </div>
                        {error.email === "error" && <div>
                            <p className={componentData.error_class}>Invalid Email ID</p>
                        </div>}
                    </>
                </div>
            </div>}

            {/*	 gst details */}
            <div className={''}>
                <div className={'d-flex'}>
                    <img src={gstInvoice ? checkedIcon : uncheckedIcon}
                         onClick={() => setGstInvoice(!gstInvoice)}
                         alt={'alt'} className={' icon-24 mr-2'}/>
                    <p className={componentData.gstCheckboxText_class}>I have a GST Number</p>
                </div>
                <motion.div
                    initial={{height: "0vh", display: 'none'}}
                    animate={{
                        height: gstInvoice ? (isDesktopOrLaptop ? "20vh" : "27vh") : 0,
                        display: gstInvoice ? "block" : "none"
                    }}

                    transition={{type: "tween", duration: 1}}
                >
                    <motion.div
                        variants={gstInvoiceAnimation}
                        intial={"hidden"}
                        delay={1}
                        duration={1}
                        animate={gstInvoice ? "visible" : "hidden"}
                    >
                        <div className={'row'}>
                            <div className={'col-12 col-lg-6'}>
                                <p className={componentData.label_class}>GSTIN</p>
                                <div onClick={() => handleTouch("gstNumber")}
                                     className={'common-input-wrap ' + (error.gstNumber === "error" && 'common-input-wrap-error') + componentData.inputMarginBottom_class}>
                                    <input className="border-less-input w-100"
                                           type={"text"}
                                           value={personalDetailData_local.gstNumber}
                                           onBlur={() => handleBlur("gstNumber")}
                                           onChange={(e) => onChangeHandler(e, "gstNumber")}
                                    />

                                </div>
                                {error.gstNumber === "error" && <div>
                                    <p className={componentData.error_class}>Invalid GST Number</p>
                                </div>}
                            </div>
                            <div className={'col-12 col-lg-6'}>
                                <p className={componentData.label_class}>Registered Name</p>
                                <div onClick={() => handleTouch("gstName")}
                                     className={'common-input-wrap ' + (error.gstName === "error" && 'common-input-wrap-error') + componentData.inputMarginBottom_class}>
                                    <input className="border-less-input w-100"
                                           type={"text"}
                                           onBlur={() => handleBlur("gstName")}
                                           value={personalDetailData_local.gstName}
                                           onChange={(e) => onChangeHandler(e, "gstName")}
                                    />

                                </div>
                                {error.gstName === "error" && <div>
                                    <p className={componentData.error_class}>Invalid Registered Name</p>
                                </div>}
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*	<p className={componentData.label_class}>GST State</p>*/}
                        {/*	<div onClick={() => handleTouch("gstState")}*/}
                        {/*	     className={'common-input-wrap ' + (error.gstState === "error" && 'common-input-wrap-error') + componentData.inputMarginBottom_class}>*/}
                        {/*		<input className="border-less-input w-100"*/}
                        {/*		       type={"text"}*/}
                        {/*		       onBlur={() => handleBlur("gstState")}*/}
                        {/*		       value={personalDetailData_local.gstState}*/}
                        {/*		       onChange={(e) => onChangeHandler(e, "gstState")}*/}
                        {/*		/>*/}

                        {/*</div>*/}
                        {/*{error.gstState === "error" && <div>*/}
                        {/*	<p className={componentData.error_class}>Invalid GST State</p>*/}
                        {/*</div>}*/}
                        {/*</div>*/}
                    </motion.div>
                </motion.div>

            </div>

            {/* submit button mobile	*/}
            {!isDesktopOrLaptop && <div onClick={() => onSubmitHandler()}
                                        className={'submit-button cursor-pointer text-center open-600w-16s-24h'}>
                Save Changes
            </div>}
        </div>
    )
}

const gstInvoiceAnimation = {
    "hidden": {
        display: "none",
        opacity: 0,
        height: "0%"

    },
    "visible": {
        display: "block",
        opacity: 1,
        height: "100%"
    }

}

export default ProfilePersonalDetail
