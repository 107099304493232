import { useState } from "react";
import "./styles.scss";
import cogoToast from "cogo-toast";
import { CustomToast } from "../../../../components/CustomToast/CustomToast";
import { NewsletterUser } from "../../../../services/api/DataUpdating";

const SignUpCard = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    if (email === "") {
      setError("Please enter  email address!");
    } else if (!email || !validateEmail(email)) {
      setError("Please enter a valid email address!");
    } else {
      let variables = {
        email: email,
      };
      NewsletterUser(variables, (response) => {
        cogoToast.success(
          <CustomToast
            message={response.message}
            type={"success"}
            heading={"Success"}
          />,
          { position: "top-center" }
        );
        setEmail("");
      });
    }
  };
  return (
    <div className="sign-up-container">
      <div className="sign-up-inner-container">
        <div>
          <p className="thank-you">
            "Thank you for joining us on our green journey through India.
          </p>
          <p className="support">Your support drives us forward!"</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <p className="sign-up">Sign Up to Get Updates</p>
            <div className="form-part">
              <input
                placeholder="Enter Email Address"
                className="email-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="sign-up-cta">{"Sign Up"}</button>
            </div>
            {error && <p className="error-text">{error}</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpCard;
