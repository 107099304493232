import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../environment'

const mutation = graphql`
	mutation ApplyCouponMutation(
		$discountId: String,
		$bookingId: String!,
		$couponCode:String,
		$email:String,
		$mobileNo:String,
		$passengerDetails: [PassengerDetailsInput],
		){
		applyDiscount(
			discountId:$discountId,
			bookingId:$bookingId,
			couponCode:$couponCode
			email:$email,
			mobileNo:$mobileNo,
			passengerDetails:$passengerDetails,
		){
		status
		extraMessage
		booking{
			id
			    createdAt
			    updatedAt
			    user {
			        id
			        firstName
			        lastName
			        mobileNumber
			        email
			    }
			    contact {
			        id
			        bookingId
			        phone
			        email
			    }
			    passengerdetailSet {
                    id
                    name
                    age
                    gender
			   }
			    sourceCityId
			    sourceCityName
			    destinationCityId
			    destinationCityName
			    dateOfJourney
			    pickUpId
			    pickupName
			    pickupTime
			    dropName
			    dropTime
			    dropId
			    referenceNo
			    routeId
			    pnrNo
			    busNumber
			    seatNo
			    seatAmount
			    paidAmenitiesString
			    totalPassengers
			    seatingType
			    status
			    duration
			    totalBaseFare
			    walletAmount
				gstAmount
				amountAfterDiscount
			    totalPayable
			    getTotalPaidAmount
			    discountAmount
				insuranceCharge
				orderNetValue
				rescheduleCharges
				coupon
				greenCashPromoAmount
			}
		}
	}

`;

export default (variables, callback, errCallback) => {
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.applyDiscount !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}
