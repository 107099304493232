import graphql from 'babel-plugin-relay/macro';

const GetVersionQuery = graphql`
	  query GetVersionQuery {
	  		getVersion{
	  		    version
	  		}
	  	}
	  	`

export default GetVersionQuery;