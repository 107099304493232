import React, { useEffect, useState } from "react";
import "./style.scss";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Navbar from "../../components/Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
//imports
import { connect } from "react-redux";
import uuid from "react-uuid";
import { useLocation, useNavigate } from "react-router";
import {
  clearOnCheckout,
  setRescheduleAction,
  setSelectedBookingIdAction,
  setTermsConditionsModalAction,
} from "../../store/Actions/CommonAction";
import {
  DownloadInvoiceUrlData,
  getTicketUrlData,
  postBookingData,
  sendTicketToMailData,
  sendTicketToSmsData,
} from "../../services/api/DataFetching";
import { setAuthType } from "../../store/Actions/AuthActions";
import { store } from "../../index";
import { bottomNavList } from "./constants";
import {
  capitalizeFirstLetter,
  convertRawDateToDateAndMonth,
} from "../../utils/utils";
import ChangeBookingModalComponent from "../../components/ChangeBookingModalComponent/ChangeBookingModalComponent";
import QrComponent from "../../components/QRComponent/QrComponent";
import QRCodeModalComponent from "../../components/QRCodeModalComponent/QRCodeModalComponent";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import TermsConditionsDetail from "../../components/TermsConditionsModalContent/TermsConditionsDetail/TermsConditionsDetail";
// icons
import luggageOutline from "../../assets/icons/luggage-outline-icon.svg";
import destinationPointIcon from "../../assets/icons/destionation-dot-review-card.svg";
import leftArrowIcon from "../../assets/icons/white-arrow-left.svg";
import rightArrowIcon from "../../assets/icons/white-arrow-right.svg";
import sourcePointIcon from "../../assets/icons/source-dot-review-card.svg";
import cogoToast from "cogo-toast";
import { CustomToast } from "../../components/CustomToast/CustomToast";
import { MDBModal, MDBModalBody } from "mdbreact";
import NueMilesIcon from "../../assets/icons/nue-miles-icon.svg";
import closeIcon from "../../assets/icons/cross-black.svg";

//assets
const messageImage =
  process.env.REACT_APP_CDN_LINK + `assets/images/messageImage.png`;
const whatsappImage =
  process.env.REACT_APP_CDN_LINK + `assets/images/whatsappImage.png`;
const mailImage = process.env.REACT_APP_CDN_LINK + `assets/images/mail.png`;
const TicketConfirmed =
  process.env.REACT_APP_CDN_LINK + `assets/images/Ticket-Confirmed.png`;
const CombinedShapeImage =
  process.env.REACT_APP_CDN_LINK + `assets/images/CombinedShape.png`;
const SeatImage = process.env.REACT_APP_CDN_LINK + `assets/images/Seat.png`;
const downIcon =
  process.env.REACT_APP_CDN_LINK + `assets/images/down-invoice.png`;

const RescheduleConfirmation = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  // const paramsId = useLocation().pathname.split("/reschedule/")[1]
  // const paramsId = useLocation().pathname.split("/reschedule")
  const navigate = useNavigate();
  let location = useLocation();
  let paramsId = location.search !== "" ? location.search.substring(1) : "";

  const [count, setCount] = useState(0);
  const [changeBookingModal, setChangeBookingModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [localCount, setLocalCount] = useState(0);
  const [bottomTray, setBottomTray] = useState("");
  const [filterModal, setFilterModal] = useState("");
  const [ticketUrl, setTicketUrl] = useState("");
  const [fareDetailModal, setFareDetailModal] = useState(false);

  const { postBookingDataFromStore,rescheduleFromStore } = props;

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/login", { replace: false });
    } else {
      if (paramsId !== "") {
        store.dispatch(setSelectedBookingIdAction(paramsId));
        navigate("/reschedule", { replace: false });
      } else {
        if (localCount === 0) {
          let variable = {
            bookingId: store.getState().CommonReducer.selectedBookingId,
          };
          getTicketUrlHandler();
          postBookingData(variable);
          store.dispatch(setAuthType(""));
          store.dispatch(setRescheduleAction(false));
          setLocalCount(1);
        }
      }
    }
  }, [count, paramsId]);

  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
    }, 2000);
  }, []);

  let pageConstants;
  if (isDesktopOrLaptop) {
    pageConstants = {
      mainContentText_class: "ubuntu-700w-24s-36h",
      leftContentText_class: "ubuntu-500w-18s-28h  mb-0 teal-2-00A095-color",
      lastButtonIcon: downIcon,
      luggageContent_class: "justify-content-end",
      submitButton_class: "ubuntu-500w-18s-28h",
    };
  } else {
    pageConstants = {
      mainContentText_class: "ubuntu-700w-18s-28h",
      leftContentText_class: "open-600w-16s-24h mb-0 black-1E1E26-color ",
      lastButtonIcon: downIcon,
      luggageContent_class: "justify-content-center",
      submitButton_class: "open-600w-16s-24h",
    };
  }

  const redirectToBooking = () => {
    clearOnCheckout();
    navigate("/", { replace: false });
  };

  const getTicketUrlHandler = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };

    getTicketUrlData(
      variable,
      (data) => {
        setTicketUrl(data.url);
      },
      (err) => {
        if (err) {
          cogoToast.error(
            <CustomToast
              heading={"Error"}
              message={err[0]?.message || err.toString()}
              type={"error"}
            />
          );
        }
      }
    );
  };

  const navigationHandler = (value) => {
    
      if (value === "change") {
		if(isDesktopOrLaptop){
        setChangeBookingModal(true);
		}else {
			navigate('/change-booking', {
				replace: false,
				state: {bookingId: store.getState().CommonReducer.selectedBookingId}
			})
		}
      }
      if (value === "download") {
        downloadTicket();
      }
      if (value === "digital") {
        setQrModal(true);
      }
	  if(value === "share"){
		shareHandler()
	  }
    
  };

  const downloadTicket = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };

    getTicketUrlData(
      variable,
      (data) => {
        window.open(data.url);
      },
      (err) => {
        if (err) {
          cogoToast.error(
            <CustomToast
              heading={"Error"}
              message={err[0]?.message || err.toString()}
              type={"error"}
            />
          );
        }
      }
    );
  };

  const downloadInvoiceUrl = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };
    DownloadInvoiceUrlData(variable, (response) => {
      window.open(response.url);
    });
  };

  const sendToSms = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };
    sendTicketToSmsData(variable);
  };

  const sendToMail = () => {
    let variable = {
      bookingId: store.getState().CommonReducer.selectedBookingId,
    };
    sendTicketToMailData(variable);
  };
  const shareHandler = () => {
    navigator.share({
      title: "NueGo Bus Ticket",
      url: ticketUrl,
    });
  };

  let componentConstant;
  

  if (isDesktopOrLaptop) {
    componentConstant = {
      city_class: "ubuntu-700w-24s-36h mb-1",
      place_class: "open-600w-16s-24h mb-0",
      time_class: "ubuntu-700w-20s-32h mb-1 text-nowrap",
      date_class: "open-600w-16s-24h",
      passengerDetail_class: "open-600w-18s-28h mb-2",
      seating_class: "open-700w-18s-28h",
      lugagge_class: "open-400w-16s-24h",
      guestCount_class: "open-700w-16s-24h",
      guest_class: "open-400w-16s-24h",
      pnrNumber_text_top_class: "open-400w-16s-24h white-color mb-0",
      pnrNumber_top_class: "ubuntu-700w-18s-28h white-color mb-0",
      refundStatusText_class: "open-600w-16s-24h mb-0",
      refundStatusView_class: "open-700w-14s-22h mb-0",
      refundStatusValue_class: "ubuntu-700w-12s-18h mb-0",
    };
  } else {
    componentConstant = {
      city_class: "open-700w-18s-28h mb-1",
      place_class: "open-600w-12s-18h mb-0",
      time_class: "open-700w-14s-22h mb-1 text-nowrap",
      date_class: "open-600w-12s-18h",
      passengerDetail_class: "open-600w-14s-22h mb-2",
      seating_class: "open-700w-14s-22h",
      lugagge_class: "open-600w-14s-22h",
      guestCount_class: "open-700w-14s-22h",
      guest_class: "open-400w-14s-22h",
      pnrNumber_text_top_class: " open-400w-12s-18h white-color  mb-0",
      pnrNumber_top_class: "open-400w-16s-24h white-color mb-0",
      refundStatusText_class: "open-600w-14s-22h mb-0",
      refundStatusView_class: "open-700w-14s-22h mb-0",
      refundStatusValue_class:
        "ubuntu-700w-12s-18h mb-0 whitespace-nowrap h-fit-content",
    };
  }

  return (
    <div>
      {Object.entries(postBookingDataFromStore).length > 0 ? (
        <AuthModalLayout>
          <div className={"confirmation-page black-1E1E26-bg"}>
            <div className={"navbar-wrapper margin-bottom-100"}>
              <Navbar fixedNavbar={true} isProfileUpdate={true}/>
            </div>

            <div className={"white-fold white-fold-padding"}>
              <div className={"inner-div"}>
                {isDesktopOrLaptop && (
                  <p className={"ubuntu-700w-32s-48h mb-5"}>
                    Ticket Confirmation
                  </p>
                )}
                <div className={"row"}>
                  {/*left and top content*/}
                  <div className={"col-12 col-lg-7 text-center mb-lg-0 mb-4"}>
                    <div>
                      <img
                        className={"main-illustration margin-bottom-32"}
                        alt={"alt"}
                        src={TicketConfirmed}
                      />
                      <p className={pageConstants.mainContentText_class}>
                        Your ride has been booked successfully!
                      </p>
                    </div>

                    <div className={"review-card-v2-component"}>
                      <div
                        className={
                          "pnr-area d-flex justify-content-between align-items-center px-4 pt-4"
                        }
                      >
                        <img
                          src={leftArrowIcon}
                          alt={"left-arrow"}
                          className={"icon-24"}
                        />
                        <div className={"d-flex align-items-end gap-12"}>
                          <p
                            className={
                              componentConstant.pnrNumber_text_top_class
                            }
                          >
                            PNR Number
                          </p>
                          <p className={componentConstant.pnrNumber_top_class}>
                            {postBookingDataFromStore?.pnrNo}
                          </p>
                        </div>
                        <img
                          src={rightArrowIcon}
                          alt={"right-arrow"}
                          className={"icon-24"}
                        />
                      </div>
                      <div className={"main-content-area py-4"}>
                        <div
                          className={
                            "position-relative route-data  pb-2 px-4" +
                            (isDesktopOrLaptop ? " margin-bottom-32" : " mb-3")
                          }
                        >
                          <div className={"left-circle"}>
                            <div className={"left-circle-inner"} />
                          </div>
                          <div className={"right-circle"}>
                            <div className={"right-circle-inner"} />
                          </div>

                          {/* source */}
                          <div className={"d-flex w-100"}>
                            <div className={"py-1 py-lg-2 pr-3"}>
                              <img
                                src={sourcePointIcon}
                                alt={"alt"}
                                className={"icon-24 pointing-icon"}
                              />
                            </div>
                            <div className={"row w-100"}>
                              <div
                                className={"col-6 position-relative"}
                                style={{ textAlign: "start" }}
                              >
                                {/*<img src={sourcePointIcon} alt={'alt'} className={'icon-24 pointing-icon'}/>*/}
                                <hr className={"vertical-dashed-line"} />
                                <p className={componentConstant.city_class}>
                                  {postBookingDataFromStore?.sourceCityName}
                                </p>
                                <p className={componentConstant.place_class}>
                                  {postBookingDataFromStore?.pickupName}
                                </p>
                              </div>
                              <div className={"col-3"}></div>
                              <div className={"col-3 px-0  px-lg-3 text-right"}>
                                <p className={componentConstant.time_class}>
                                  {postBookingDataFromStore?.pickupTime}
                                </p>
                                <p className={componentConstant.date_class}>
                                  {convertRawDateToDateAndMonth(
                                    postBookingDataFromStore?.dateOfJourney
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/*	destination*/}
                          <div className={"d-flex w-100"}>
                            <div className={"py-1 py-lg-2 pr-3"}>
                              <img
                                src={destinationPointIcon}
                                alt={"alt"}
                                className={"icon-24 pointing-icon"}
                              />
                            </div>
                            <div className={"row w-100"}>
                              <div
                                className={"col-6 position-relative"}
                                style={{ textAlign: "start" }}
                              >
                                {/*<img src={destinationPointIcon} alt={'alt'} className={'icon-24 pointing-icon'}/>*/}
                                <p className={componentConstant.city_class}>
                                  {
                                    postBookingDataFromStore?.destinationCityName
                                  }
                                </p>
                                <p className={componentConstant.place_class}>
                                  {postBookingDataFromStore?.dropName}
                                </p>
                              </div>
                              <div className={"col-3"}></div>
                              <div className={"col-3 px-0 px-lg-3 text-right"}>
                                <p className={componentConstant.time_class}>
                                  {postBookingDataFromStore?.dropTime}
                                </p>
                                <p className={componentConstant.date_class}>
                                  {convertRawDateToDateAndMonth(
                                    postBookingDataFromStore?.expectedDropDatetime
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* passenger details */}
                        <div className={"w-100 pt-2 px-4"}>
                          {postBookingDataFromStore?.passengerdetailSet?.map(
                            (item, index) => {
                              return (
                                <div
                                  key={uuid()}
                                  className={
                                    "d-flex justify-content-between align-items-center w-100"
                                  }
                                >
                                  <div className={"d-flex"}>
                                    <p
                                      className={
                                        componentConstant.passengerDetail_class +
                                        " mb-0"
                                      }
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                      className={
                                        componentConstant.passengerDetail_class +
                                        "open-600w-14s-22h text-capitalize mb-0"
                                      }
                                    >
                                      <span className={"ml-3 mr-0"}>
                                        ({item.gender}),
                                      </span>{" "}
                                      {item.age}
                                    </p>
                                  </div>
                                  <div className={"d-flex"}>
                                    <img
                                      src={SeatImage}
                                      alt={"alt"}
                                      className={"icon-24"}
                                    />
                                    <p
                                      className={
                                        componentConstant.seating_class
                                      }
                                    >
                                      
                                      {postBookingDataFromStore?.seatNo[index]}
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className="px-4">
                          <hr className={"line-seperator"} />

                          <div className={"w-100"}>
                            <div
                              style={{ gap: isDesktopOrLaptop ? "20px" : "" }}
                              className={
                                isDesktopOrLaptop
                                  ? "d-flex  align-items-center w-100 mb-3"
                                  : "d-flex justify-content-between align-items-center w-100 mb-3"
                              }
                            >
                              <div className={"d-flex"}>
                                <img
                                  className={"icon-24 mr-2"}
                                  alt={"alt"}
                                  src={luggageOutline}
                                />
                                <p className={"open-600w-14s-22h mb-0 mr-1"}>
                                  Luggage Pieces
                                </p>
                              </div>
                              <div className={"d-flex"}>
                                <p className={"open-700w-16s-24h mb-0 mr-1"}>
                                  2
                                </p>
                                <p className={"open-400w-14s-24h mb-0"}>
                                  per person
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"review-content-area px-4 pb-2 pt-5"}>
                        {isDesktopOrLaptop ? (
                          <div className={"d-flex justify-content-between"}>
                            <div className={"d-flex"}>
                              <p
                                className={
                                  componentConstant.guest_class +
                                  " mb-0 text-nowrap"
                                }
                              >
                                Fare :
                              </p>
                              <p
                                className={
                                  componentConstant.guestCount_class +
                                  " black-1E1E26-color mx-2 mb-0"
                                }
                              >
                                ₹{postBookingDataFromStore?.getTotalPaidAmount}
                              </p>
                              <img
                                src={CombinedShapeImage}
                                alt={"alt"}
                                className="icon-24"
								onClick={() => setFareDetailModal(true)}
                              />
                            </div>
                            {/*{!rescheduleFromStore && (*/}
                            <div
                              className={" cursor-pointer d-flex"}
                              onClick={() => downloadInvoiceUrl()}
                            >
                              <img
                                className={"icon-24 mr-2"}
                                alt={"alt"}
                                src={pageConstants.lastButtonIcon}
                              />
                              <p
                                className={pageConstants.leftContentText_class}
                              >
                                Download Invoice
                              </p>
                            </div>
                            {/*)}*/}
                          </div>
                        ) : (
                          <div className={"d-flex justify-content-between"}>
                            <div className={"d-flex"}>
                              <p
                                className={
                                  componentConstant.guest_class +
                                  " mb-0 text-nowrap"
                                }
                              >
                                Fare :
                              </p>
                              <p
                                className={
                                  componentConstant.guestCount_class +
                                  " black-1E1E26-color mx-2 mb-0"
                                }
                              >
                                ₹{postBookingDataFromStore?.getTotalPaidAmount}
                              </p>
                              <img
                                src={CombinedShapeImage}
                                alt={"alt"}
                                className="icon-16"
								onClick={() => setFareDetailModal(true)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {!isDesktopOrLaptop && (
                      <div
                        className={
                          "down-load-invoice cursor-pointer d-flex justify-content-center"
                        }
                        onClick={() => downloadInvoiceUrl()}
                      >
                        <img
                          className={"icon-24 mr-2"}
                          alt={"alt"}
                          src={pageConstants.lastButtonIcon}
                        />
                        <p className={pageConstants.leftContentText_class}>
                          Download Invoice
                        </p>
                      </div>
                    )}
                    <div style={{ paddingTop: "60px" }}>
                      {isDesktopOrLaptop && (
                        <div
                          className={
                            "navigation-tab d-flex justify-content-around align-items-center margin-bottom-64  w-100"
                          }
                        >
                          {bottomNavList.map((item) => {
                            return (
                              <div
                                key={uuid()}
                                className={
                                  "d-flex align-items-center cursor-pointer"
                                }
                                style={{ gap: "13px" }}
                                onClick={() => navigationHandler(item.id)}
                              >
                                <img
                                  className={"icon-24 mb-1"}
                                  alt={"alt"}
                                  src={item.icon}
                                />
                                <p
                                  className={
                                    " text-capitalize white-color opacity-60 mb-0 " +
                                    "open-600w-12s-18h"
                                  }
                                >
                                  {capitalizeFirstLetter(item.name)}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>

                    {isDesktopOrLaptop && (
                      <div className={"d-flex margin-bottom-32 "}>
                        <div
                          className={
                            " border-radius-12 py-3 w-100 text-center mr-3"
                          }
                        >
                          <p
                            className={
                              "teal-2-00A095-color open-600w-14s-24h mb-0 cursor-pointer"
                            }
                            onClick={() =>
                              store.dispatch(
                                setTermsConditionsModalAction(true)
                              )
                            }
                          >
                            {" "}
                            Terms and conditions
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* right and bottom content*/}
                  <div
                    className={"ticet-confirmation"}
                    style={{ paddingLeft: isDesktopOrLaptop && "20px" }}
                  >
                    <div className={"receive-by-ticket"}>
                      <p className={"receive-title"}>Receive ticket by</p>
                      <div
                        className={
                          isDesktopOrLaptop ? "d-flex" : "d-flex flex-wrap-12"
                        }
                        style={{ gap: "18px" }}
                      >
                        <div
                          onClick={sendToMail}
                          className={
                            isDesktopOrLaptop
                              ? "d-flex align-items-center social-icons-name "
                              : "d-flex align-items-center social-icons-name"
                          }
                        >
                          <img src={mailImage} alt={"alt"} />
                          <p className={"social-name"}>Email</p>
                        </div>
                        <div
                          className={
                            isDesktopOrLaptop
                              ? "d-flex align-items-center social-icons-name "
                              : "d-flex align-items-center social-icons-name"
                          }
                        >
                          <img src={whatsappImage} alt={"alt"} />
                          <p className={"social-name"}>Whatsapp</p>
                        </div>
                        <div
                          onClick={sendToSms}
                          className={
                            isDesktopOrLaptop
                              ? "d-flex align-items-center social-icons-name "
                              : "d-flex align-items-center social-icons-name"
                          }
                        >
                          <img src={messageImage} alt={"alt"} />
                          <p className={"social-name"}>SMS</p>
                        </div>
                      </div>
                    </div>

                    {/*// <div className={'flex-wrap-16 justify-content-center'}>*/}
                    {/*//*/}
                    {/*//     {isDesktopOrLaptop && <div className={'button-border cursor-pointer'}*/}
                    {/*//                                onClick={sendToMail}>*/}
                    {/*//         <img className={'icon-24'} alt={'alt'} src={mailGreenIcon}/>*/}
                    {/*//     </div>}*/}
                    {/*/!*    *!/*/}
                    {/*    {isDesktopOrLaptop &&*/}
                    {/*        <div className={'button-border cursor-pointer d-flex'}*/}
                    {/*             onClick={downloadTicket}*/}
                    {/*        >*/}
                    {/*            <img className={'icon-24 mr-2'} alt={'alt'}*/}
                    {/*                 src={downloadGreenIcon}/>*/}
                    {/*            <p className={pageConstants.leftContentText_class}>Download*/}
                    {/*                Ticket</p>*/}
                    {/*        </div>}*/}
                    {/*    <div className={'button-border cursor-pointer d-flex'}*/}
                    {/*         onClick={() => dualMethodHandler()}>*/}
                    {/*        <img className={'icon-24 mr-2'} alt={'alt'}*/}
                    {/*             src={pageConstants.lastButtonIcon}/>*/}
                    {/*        <p className={pageConstants.leftContentText_class}>View*/}
                    {/*            Invoice</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*	 fare details */}
                    {/*<div className={'w-100 mb-4'}>*/}
                    {/*    <div className={'d-flex justify-content-between w-100 mb-lg-0 mb-3'}>*/}
                    {/*        <p className={'ubuntu-700w-18s-28h mb-0'}>Fare details</p>*/}
                    {/*        {isDesktopOrLaptop &&*/}
                    {/*            <p className={'ubuntu-700w-18s-28h mb-0'}>₹{postBookingDataFromStore?.getTotalPaidAmount}</p>}*/}
                    {/*    </div>*/}
                    {/*    {!isDesktopOrLaptop &&*/}
                    {/*        <div className={'d-flex w-100 d-flex justify-content-between'}>*/}
                    {/*            <p className={'open-400w-16s-24h opacity-60 mb-0'}>Total*/}
                    {/*                Fare </p>*/}
                    {/*            <p className={'open-600w-16s-24h mb-0'}>₹{postBookingDataFromStore?.getTotalPaidAmount}</p>*/}
                    {/*        </div>}*/}
                    {/*</div>*/}

                    {/*	 terms and qr for desktop */}
                    {/*{isDesktopOrLaptop && <div className={'d-flex margin-bottom-32 '}>*/}
                    {/*    <div*/}
                    {/*        className={'local-green-medium-C3EBEA-bg border-radius-12 py-3 w-100 text-center mr-3'}>*/}
                    {/*        <p className={'teal-2-00A095-color open-600w-14s-24h mb-0 cursor-pointer'}*/}
                    {/*           onClick={() => store.dispatch(setTermsConditionsModalAction(true))}*/}
                    {/*        > Terms*/}
                    {/*            and*/}
                    {/*            conditions</p>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        className={'local-green-medium-C3EBEA-bg border-radius-12 py-3 w-100 text-center'}>*/}
                    {/*        <p onClick={() => setQrModal(true)}*/}
                    {/*           className={'teal-2-00A095-color open-600w-14s-24h mb-0 cursor-pointer'}>Show*/}
                    {/*            QR Code</p>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}

                    {/*	download and invoice*/}
                    {/*{!isDesktopOrLaptop && <div className={'d-flex mt-5 margin-bottom-32'}>*/}
                    {/*    <div className={'green-button-border text-center w-100 mr-3'}*/}
                    {/*         onClick={downloadTicket}*/}
                    {/*    >*/}
                    {/*        <img className={'icon-24 mb-2'} src={downloadGreenIcon}*/}
                    {/*             alt={'alt'}/>*/}
                    {/*        <p className={'teal-22BBB0-color open-600w-16s-24h mb-0'}>Download*/}
                    {/*            Ticket</p>*/}
                    {/*    </div>*/}
                    {/*    <div className={'green-button-border text-center w-100'}*/}
                    {/*         onClick={downloadInvoiceUrl}>*/}
                    {/*        <img className={'icon-24 mb-2'} src={viewGreenIcon} alt={'alt'}/>*/}
                    {/*        <p className={'teal-22BBB0-color open-600w-16s-24h mb-0'}>View*/}
                    {/*            Invoice</p>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}

                    {/*{*/}
                    {/*    !isDesktopOrLaptop &&*/}
                    {/*    <div className={'d-flex justify-content-center w-100'}>*/}
                    {/*        <AccommodationCard*/}
                    {/*            cityName={postBookingDataFromStore?.destinationCityName}/>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {/* recieve tickets*/}
                    {/*{!isDesktopOrLaptop && <div className={'p-3 '}>*/}
                    {/*    <p className={'open-600w-16s-24h mb-3'}>Receive ticket by</p>*/}
                    {/*    <div className={'d-flex gap-14'}>*/}
                    {/*        <div className={'common-input-wrap p-2 d-flex'}*/}
                    {/*             onClick={sendToMail}>*/}
                    {/*            <img className={'icon-24 mr-1'} alt={'alt'} src={emailBlack}/>*/}
                    {/*            <p className={'open-600w-14s-24h mb-0'}>Email</p>*/}
                    {/*        </div>*/}
                    {/*        <div className={'common-input-wrap p-2 d-flex'}>*/}
                    {/*            <img className={'icon-24 mr-1'} alt={'alt'}*/}
                    {/*                 src={whatsappBlack}/>*/}
                    {/*            <p className={'open-600w-14s-24h mb-0'}>Whatsapp</p>*/}
                    {/*        </div>*/}
                    {/*        <div className={'common-input-wrap p-2 d-flex'} onClick={sendToSms}>*/}
                    {/*            <img className={'icon-24 mr-1'} alt={'alt'} src={smsBlack}/>*/}
                    {/*            <p className={'open-600w-14s-24h mb-0'}>SMS</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}

                    {/*submit button desktop*/}
                    {/*{isDesktopOrLaptop && <div className={'py-4 px-3 w-100'}>*/}
                    {/*	<button className={'submit-button w-100 justify-content-center py-3'}*/}
                    {/*	        onClick={() => redirectToBooking()}>*/}
                    {/*		<p className={pageConstants.submitButton_class}>View Booking</p>*/}
                    {/*		<img src={whiteRightArrow} className={'ml-2'} alt={'alt'}/>*/}
                    {/*	</button>*/}
                    {/*</div>}*/}

                    {/* navigation button desltop*/}
                  </div>

                  {/* terms condition mobile */}
                  {!isDesktopOrLaptop && (
                    <div
                      className={
                        "local-green-medium-C3EBEA-bg w-100 text-center margin-bottom-100"
                      }
                      style={{ padding: "12px" }}
                    >
                      <p
                        className={"teal-2-00A095-color open-600w-14s-22h"}
                        onClick={() => setFilterModal("Terms & Conditions")}
                      >
                        Terms and conditions
                      </p>
                    </div>
                  )}
                  {!isDesktopOrLaptop && (
                    <div className={"w-100 settle-at-bottom left-to-right"}>
                      <div
                        className={
                          "bottom-nav d-flex justify-content-around py-3 "
                        }
                      >
                        {bottomNavList.map((item) => {
                          return (
                            <div
                              key={uuid()}
                              className={"text-center"}
                              onClick={() => navigationHandler(item.id)}
                            >
                              <img
                                className={"icon-24 mb-1"}
                                alt={"alt"}
                                src={item.icon}
                              />
                              <p
                                className={
                                  " text-capitalize white-color opacity-60 mb-0 " +
                                  "open-600w-12s-18h"
                                }
                              >
                                {capitalizeFirstLetter(item.name)}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ChangeBookingModalComponent
            bookingId={store.getState().CommonReducer.selectedBookingId}
            activeModal={changeBookingModal}
            setActiveModal={setChangeBookingModal}
          />
          <QRCodeModalComponent
            isOpen={qrModal}
            closeModal={() => setQrModal(false)}
          >
            <QrComponent
              closeModal={() => setQrModal(false)}
              link={ticketUrl}
              qrCode={postBookingDataFromStore.qrCode}
              customerBookingId={postBookingDataFromStore.pnrNo}
            />
          </QRCodeModalComponent>
          {!isDesktopOrLaptop && (
            <BottomTrayComponent
              isOpen={bottomTray !== ""}
              hideClearAll={true}
              title={bottomTray}
              closeHandler={() => setBottomTray("")}
            >
              {bottomTray === "Terms & Conditions" && (
                <div className={"terms-condition-details"}>
                  <TermsConditionsDetail />
                </div>
              )}
            </BottomTrayComponent>
          )}
          {isDesktopOrLaptop && fareDetailModal && (
            <div className={"review-card-fare-detail-modal"}>
              <MDBModal
                isOpen={fareDetailModal}
                position={"center"}
                centered={true}
                size={"fluid"}
              >
                <MDBModalBody>
                  <div className="mdb-data">
                    <div className="d-flex justify-content-between align-items-center margin-bottom-32">
                      <p className="ubuntu-700w-24s-32h black-1E1E26-color mb-0">
                        Fare Detail
                      </p>
                      <img
                        src={closeIcon}
                        alt={"alt"}
                        className="cursor-pointer"
                        onClick={() => setFareDetailModal(false)}
                      />
                    </div>
                    <div
                      className={"d-flex justify-content-between w-100  mb-3"}
                    >
                      <p className={" open-400w-16s-24h mb-0"}>Base Fare</p>
                      <p className={" open-400w-16s-24h mb-0"}>
                        ₹{postBookingDataFromStore?.totalBaseFare}
                      </p>
                    </div>

                    {parseFloat(
                      postBookingDataFromStore?.greenCashPromoAmount
                    ) > 0 && (
                      <>
                        <div
                          className={
                            "d-flex justify-content-between w-100  mb-3"
                          }
                        >
                          <div className={"d-flex"}>
                            <img
                              src={NueMilesIcon}
                              alt={"nue-miles-icon"}
                              className={"icon-24 mr-2"}
                            />
                            <p className={" open-400w-16s-24h mb-0"}>
                              Green Miles
                            </p>
                          </div>
                          <p
                            className={
                              " open-600w-16s-24h light-green-3AC070-color mb-0"
                            }
                          >
                            ₹{postBookingDataFromStore?.greenCashPromoAmount}
                          </p>
                        </div>
                        <div
                          className={
                            "d-flex justify-content-between w-100  mb-3"
                          }
                        >
                          <p className={" open-600w-18s-24h mb-0"}>
                            Net Base Fare
                          </p>
                          <p className={" open-600w-16s-24h mb-0"}>
                            ₹
                            {Number(postBookingDataFromStore?.totalBaseFare) -
                              Number(
                                postBookingDataFromStore?.greenCashPromoAmount
                              )}{" "}
                          </p>
                        </div>
                      </>
                    )}
                    <div
                      className={"d-flex justify-content-between w-100  mb-3"}
                    >
                      <p className={" open-400w-16s-24h mb-0"}>GST</p>
                      <p className={" open-400w-16s-24h mb-0"}>
                        ₹{postBookingDataFromStore?.gstAmount}
                      </p>
                    </div>
                    {rescheduleFromStore && (
                      <div
                        className={"d-flex justify-content-between w-100  mb-3"}
                      >
                        <p className={" open-400w-16s-24h mb-0"}>
                          Reschedule Charges
                        </p>
                        <p className={" open-600w-16s-24h  mb-0"}>
                          ₹{postBookingDataFromStore?.rescheduleCharges}
                        </p>
                      </div>
                    )}
                    <hr className="horizontal-line" />
                    <div className={"d-flex justify-content-between w-100 "}>
                      <p className={" open-400w-16s-24h mb-0"}>Total fare</p>
                      <p className={" open-700w-16s-24h mb-0"}>
                        ₹{postBookingDataFromStore?.totalPayable}
                      </p>
                    </div>
                  </div>
                </MDBModalBody>
              </MDBModal>
            </div>
          )}
          {!isDesktopOrLaptop && fareDetailModal && (
            <div className={"review-card-fare-detail-bottom-tray"}>
              <BottomTrayComponent
                isOpen={fareDetailModal}
                closeHandler={() => setFareDetailModal(false)}
                title={"Fare Details"}
                hideClearAll={true}
              >
                <div className="p-3">
                  <div className={"d-flex justify-content-between w-100  mb-3"}>
                    <p className={" open-400w-16s-24h mb-0"}>Base Fare</p>
                    <p className={" open-400w-16s-24h mb-0"}>
                      ₹{postBookingDataFromStore?.totalBaseFare}
                    </p>
                  </div>

                  {parseFloat(postBookingDataFromStore?.greenCashPromoAmount) >
                    0 && (
                    <>
                      <div
                        className={"d-flex justify-content-between w-100  mb-3"}
                      >
                        <div className={"d-flex"}>
                          <img
                            src={NueMilesIcon}
                            alt={"nue-miles-icon"}
                            className={"icon-24 mr-2"}
                          />
                          <p className={" open-400w-16s-24h mb-0"}>
                            Green Miles
                          </p>
                        </div>
                        <p
                          className={
                            " open-600w-16s-24h light-green-3AC070-color mb-0"
                          }
                        >
                          ₹{postBookingDataFromStore?.greenCashPromoAmount}
                        </p>
                      </div>
                      <div
                        className={"d-flex justify-content-between w-100  mb-3"}
                      >
                        <p className={" open-600w-18s-24h mb-0"}>
                          Net Base Fare
                        </p>
                        <p className={" open-600w-16s-24h mb-0"}>
                          ₹
                          {Number(postBookingDataFromStore?.totalBaseFare) -
                            Number(
                              postBookingDataFromStore?.greenCashPromoAmount
                            )}{" "}
                        </p>
                      </div>
                    </>
                  )}
                  <div className={"d-flex justify-content-between w-100  mb-3"}>
                    <p className={" open-400w-16s-24h mb-0"}>GST</p>
                    <p className={" open-400w-16s-24h mb-0"}>
                      ₹{postBookingDataFromStore?.gstAmount}
                    </p>
                  </div>
                  {rescheduleFromStore && (
                    <div
                      className={"d-flex justify-content-between w-100  mb-3"}
                    >
                      <p className={" open-400w-16s-24h mb-0"}>
                        Reschedule Charges
                      </p>
                      <p className={" open-600w-16s-24h  mb-0"}>
                        ₹{postBookingDataFromStore?.rescheduleCharges}
                      </p>
                    </div>
                  )}
                  <hr className="horizontal-line" />
                  <div className={"d-flex justify-content-between w-100 "}>
                    <p className={" open-400w-16s-24h mb-0"}>Total fare</p>
                    <p className={" open-700w-16s-24h mb-0"}>
                      ₹{postBookingDataFromStore?.totalPayable}
                    </p>
                  </div>
                </div>
              </BottomTrayComponent>
            </div>
          )}
        </AuthModalLayout>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  postBookingDataFromStore: state.CommonReducer.postBookingData,
  rescheduleFromStore: state.CommonReducer.reschedule,
});
export default connect(mapStateToProps)(RescheduleConfirmation);
