import React, {useEffect, useState} from "react";
import './TwitterContainer.scss'
import Loader from "../Loader/Loader";

const TwitterContainer = () => {

	const [loading, setLoading] = useState(true);

	useEffect(() => {

		const anchor = document.createElement("a");
		anchor.setAttribute("class", "twitter-timeline");
		anchor.setAttribute("data-theme", "light");
		anchor.setAttribute("data-tweet-limit", "3");
		anchor.setAttribute("data-cards", "hidden");
		anchor.setAttribute("hide_media", "true");
		anchor.setAttribute("data-width", "600");
		anchor.setAttribute("data-height", "fit-content");
		anchor.setAttribute("data-chrome", "noheader nofooter noborders");
		anchor.setAttribute("href", "https://twitter.com/nuegoindia?ref_src=twsrc%5Etfw");
		document.getElementsByClassName("twitterContainer")[0].appendChild(anchor);

		const script = document.createElement("script");
		script.setAttribute("src", "https://platform.twitter.com/widgets.js");
		document.getElementsByClassName("twitterContainer")[0].appendChild(script);

	}, []);


	return (
		<section className="twitterContainer">
	

	  </section>
	);
};

export default TwitterContainer;