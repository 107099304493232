import React, {useEffect} from 'react'
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import ProfileCopassengerDetail from "../ProfileDetail/component/ProfileCopassengerDetail";
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";

const CoPassengerDetailPage = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    useEffect(() => {
        if (isDesktopOrLaptop) {
            navigate('/profile-detail')
        }
        return () => {

        };
    }, [isDesktopOrLaptop]);


    return (
        <div className={'co-passenger-detail-page'}>
            <MobileSubScreenLayout title={"Passenger List"} back={() => navigate(-1)}>
                <ProfileCopassengerDetail/>
            </MobileSubScreenLayout>
        </div>
    )
}

export default CoPassengerDetailPage
