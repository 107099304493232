import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Loader from '../../Loader/Loader'

const TermsConditionsDetail = ({}) => {

	const [LoadingSpiner, setLoadingSpiner] = useState({value: true, Response: "pending" })
	const [TermsAndConditionsData, setTermsAndConditionsData] = useState([])

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }

	const TermsAndConditionsGetApi = () => {
		setLoadingSpiner(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/terms-and-conditions?sort=rank:asc`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.json())
        .then(
          (res) => {
            if (res?.error) {
              setLoadingSpiner(errorObject)
            } else {
              setLoadingSpiner(sucessObject)
              setTermsAndConditionsData(res?.data)
            }
            return res;
          })
        .catch((err) => {
          setLoadingSpiner(errorObject)
          return err;
        });
	}


	useEffect(() => {
		TermsAndConditionsGetApi()
	}, [])
	return (
		<>
			<h4 className={'text-underline'}>TERMS AND CONDITIONS FOR PURCHASE OF TICKETS OR AGREEING TO
				TRAVEL
				/TRAVELLING THROUGH NUEGO </h4>
			<p>By accessing or using this website, including without limitation for the purpose of availing
				service from
				[o], its parent company, successors, subsidiaries and permitted assigns (“NUEGO”), You agree
				to be bound
				by these terms. If You are using the services on behalf of any other person, then You are
				agreeing to
				these terms on behalf of such person and You represent and warrant that You have the
				authority to bind
				the person to these terms.
			</p>
			{LoadingSpiner == true ? <Loader />  : LoadingSpiner?.Response != "error" ? TermsAndConditionsData.map((items, id) => {

				return <div id={`terms-${id}`}>
					<h4  >{id + 1} {items?.attributes?.topic} </h4>  
					<Markdown
 children={items?.attributes?.description} remarkPlugins={[remarkGfm]} />
				</div>

			})
			:<div>
			 
			<h4 id={'terms-1'}>1. WEBSITE </h4>
			<p>The website is owned exclusively by NUEGO and is meant to be used by bonafide user(s),
				customers,
				passengers and/or legal guardians for minors during travel (“You”) for a lawful use. You
				shall not use,
				distribute, exchange, modify, sell or transmit anything from the website including but not
				limited to
				any text, images, audio and video, for any business, commercial or public purpose. Access to
				certain
				features of the website may only available to registered user(s). The process of
				registration may
				require You to answer certain questions or provide certain information that may or may not
				be personal
				in nature. Some such fields may be mandatory or optional. You represent and warrant that all
				information
				supplied on NUEGO is true and accurate. You further represent that You are of sufficient
				legal age to
				use this service, and You possess the legal right and ability to create binding obligations
				for any
				liability You may incur as a result of the use of this service. You understand that You are
				financially
				responsible for all uses of this service by You and those using your login information.
				NUEGO will
				always make its best endeavours to ensure that the content on its websites or other sales
				channels are
				free of any virus or such other malwares. However, our website may contain links to other
				websites as a
				convenience for You, and the same should not be interpreted as endorsement of such websites
				by NUEGO.
				NUEGO requires You to read the privacy statements of each and every website that collects
				personally
				identifiable information, and any data or information downloaded or otherwise obtained
				through the use
				of the Website or any other sales channel will be entirely at Your discretion and risk, and
				You will be
				solely responsible for any damage to Your computer systems or loss of data that may result
				from the
				download of such data.
			</p>
			<h4 id={'terms-2'}>2. Booking And Boarding Of The Passengers</h4>
			<p>You shall be responsible for providing the correct email address/phone number for the purpose
				of sharing
				the booking confirmation email/SMS. Once the booking is done, no change in name,
				destination, phone
				number, etc shall be allowed. If a booking is being done for an individual below 5 years of
				age, no
				ticket needs to be purchased, however, no extra seats will be allocated to children less
				than 5 years
				old unless booked.
			</p>
			<h4 id={'terms-122'}>3. Boarding And Departure</h4>
			<p> No bus will depart before the scheduled departure time. You are advised to reach
				the boarding point at least 15 minutes prior to the scheduled departure. If You fail to
				report at the
				boarding point on time, You will be termed as a “No Show Passenger” and will not be entitled
				for any
				cancellation or refund of the ticket amount. Unless explicitly mentioned on the ticket,
				neither NUEGO
				nor any of its travel partners will take the responsibility of picking up passengers at
				their places of
				choice. The arrival and departure timings as mentioned on the ticket are pre-scheduled,
				however, the
				same may be delayed due to unavoidable circumstances including traffic jam, natural
				calamities, and
				roadblocks etc. You are required to furnish the following documents at the time of boarding
				the bus
				failing which You may not be allowed to board the bus: </p>
			<ul>
				<li>a hard or soft copy of the ticket;</li>
				<li>identity proof (original - driving license, aadhar card, passport, pan card, student ID
					card, voter
					ID card etc.
				</li>
			</ul>
			<p>NUEGO reserves the right to deny boarding passengers who may be under the influence of
				alcohol or owing
				to any misconduct, critically ill, stretcher or any other condition as may be deemed not
				safe for travel
				by NUEGO at the time of boarding, however, any guest with medical condition or history, if
				not denied by
				NUEGO shall be considered to be travelling at his/her own risk and consequences. The bus
				will only halt
				at the designated boarding/drop point and You will only be allowed to only deboard/alight as
				per Your
				booking. Consuming alcohol/smoking/prohibited substances in the buses is strictly prohibited
				as it
				creates inconvenience to the co-passengers and You may liable to be alighted from the Bus.
				NUEGO does
				not allow any pets and/or any other animal on board for the purposes of availing the
				services as being
				provided. NUEGO reserves the right to reorganise the allotted seats under special
				circumstances. NUEGO
				will not provide any service or share confirmed booking details till such time the complete
				consideration is received from You.
			</p>
			<h4 id={'terms-4'}>4. Baggage </h4>
			<p>Any luggage inclusive of Your baggage and any other article and/or belonging that You may
				carry with You
				will be at Your own risk. NUEGO shall not be liable towards any wear and tear, loss, damage
				caused due
				to accident, theft, fire, natural disasters or uncontrollable circumstances pilferage caused
				to Your
				luggage and baggage. Baggage and luggage will be booked at Your risk subjected to the
				acceptance by
				NUEGO and / or its business partners. You shall not carry any weapons, inflammables,
				firearms.
				ammunition, drugs, liquor, smuggled goods etc. and any other articles that are prohibited
				under
				applicable laws (“Prohibited Articles”). The maximum baggage allowed per passenger is 15 Kg
				and NUEGO
				reserves the right to disallow any excess baggage. Any excess baggage allowed at the sole
				discretion of
				NUEGO will be charged at prevailing rates. NUEGO highly recommends that You remove all
				valuables
				including cash, cameras, jewellery, precious metals, computers, personal electronic devices,
				negotiable
				papers, passports and other identification papers etc. along with perishables and medication
				from Your
				luggage before the commencement of the journey. If You decide to carry any valuables and/or
				perishables
				against the above advice they shall be at Your own risk. For further clarifications, You may
				refer to
				our baggage policy available below. In the event there are any inconsistencies in the terms
				provided in
				the Terms and Conditions and the Baggage Policy, then the terms mentioned under the Baggage
				Policy shall
				prevail.
			</p>
			<h4>Baggage Policy
			</h4>
			<p><b>Scope –</b> This is the standard policy applicable to bookings done for travel on NUEGO.
				Any route or
				schedule or service or date specific or promotional policy introduced from time-to-time will
				override
				this policy. NUEGO reserves the right to change the policy and applicable charges from time
				to time,
				without prior notice and liability and without assigning any reason whatsoever. This policy
				should be
				read in conjunction with the terms and conditions available above. This policy shall be
				subject to the
				provisions of the Motor Vehicles Act, 1988 and the relevant rules framed thereunder, as
				amended from
				time to time.
				Free Baggage Allowance – </p>
			<ul>
				<li>Checked-in Free Baggage Allowance (FBA) per passenger – 1 Medium suitcase or 2 Small
					suitcases.
					<ul>
						<li>Medium suitcase - Linear dimensions (L+W+H) should not exceed 158cms / 62 inches
							(including
							wheels). Example – Length 74cms+ Height 54cms + Width 30cms or Length 29inches +
							Height
							21inches + Width 12inches
						</li>
						<li>Small suitcase - Linear dimensions (L+W+H) should not exceed 115cms / 45 inches
							(including
							wheels). Example – Length 50cms + Height 36cms + Width 24cms or Length 20inches
							+ Height
							14inches + Width 9inches
						</li>
					</ul>
				</li>
				<li>Hand baggage – 1 Piece which can fit in overhead storage or under seat in front.</li>
			</ul>
			<h4>Excess Baggage charges – </h4>
			<ul>
				<li>Additional pieces of checked in baggage (2nd piece in case of 1 Medium suitcase or 3rd
					piece in case
					of 2 small suitcases and subsequent pieces) will be charged a flat fee of Rs100 per
					additional
					piece.
				</li>
				<li>Oversized baggage (baggage that does not fit within the maximum allowable dimensions for
					checked-in
					baggage) will be charged a flat fee of Rs.500 for every oversized piece of baggage.
				</li>
				<li>If baggage exceeds in permitted pieces, as well as dimensions, then the sum of
					additional pieces +
					oversized baggage charges will be applicable.
				</li>
				<li>12% GST will be applicable on the excess baggage charges.</li>
			</ul>
			<h4>Policy – </h4>
			<ul>
				<li>Any luggage inclusive of the baggage and any other article and/or belonging that You may
					carry,
					shall be at Your sole risk and shall be subject to the acceptance by NUEGO and / or its
					business
					partners.
				</li>
				<li>NUEGO reserves the right to disallow any excess baggage. If the luggage carried by You
					exceeds the
					permitted free baggage allowance and is still allowed at the sole discretion of NUEGO,
					then excess
					baggage charges shall be payable for the additional luggage.
				</li>
				<li>Excess Baggage charges (if any), shall be payable at the time of check-in.</li>
				<li>Special baggage, such as sports equipment, (cricket bats, golf clubs, hockey sticks,
					etc.) or
					musical instruments must be packed in suitable containers and securely fastened, at Your
					sole risk.
					If it complies within the size dimensions and number of pieces of permitted free baggage
					allowance,
					the item will be counted as one of the checked in baggage entitlements. If not, it will
					be regarded
					as excess or oversized baggage and accordingly excess and/or oversized baggage charges
					may be
					applicable.
				</li>
				<li>Collapsible wheelchair or any walking aids or a baby pram will be permitted free of
					charge over and
					above the free baggage allowance.
				</li>
				<li>If Your checked-in baggage is less than the permitted free baggage allowance, then the
					balance
					cannot be adjusted as hand baggage or vice versa.
				</li>
				<li>If You wish to carry an oversized item on board, which will fit safely in a seat, then
					an additional
					seat must be purchased in advance, subject to availability and applicable fare. In the
					absence of
					the purchase of such additional seat, NUEGO reserves the right to prevent You from
					carrying such
					item on board.
				</li>
				<li>Hand baggage must fit in overhead storage or under seat in front. Luggage must not be
					placed in the
					aisle or in front of emergency exits or in any way that it affects the safety and
					movement of
					passengers or staff.
				</li>
				<li>Hand baggage that does not fit in overhead storage or under seat in front will have to
					be checked
					in. If it complies within the size dimensions and number of pieces of permitted free
					baggage
					allowance, the item will be counted as one of the checked in baggage entitlements. If
					not, it will
					be regarded as excess or oversized baggage and accordingly excess and/or oversized
					baggage charges
					may be applicable.
				</li>
				<li>NUEGO shall not take any responsibility whatsoever in the event of any loss or damage to
					Your
					luggage. NUEGO shall also not be liable for any loss or damage caused due to accident,
					theft, fire,
					natural disasters, or any other circumstances outside the control of NUEGO.
				</li>
				<li>For baggage which is not received, please contact NUEGO and report the same. If a
					delayed baggage
					could not be traced for 21 days from the date of notice, the same may be treated as
					lost.
				</li>
				<li>NUEGO assumes no liability for any wear and tear to luggage.</li>
				<li>All luggage carried must be clearly labelled with Your name, address, contact details
					and
					destination.
				</li>
				<li>Excess and oversize luggage may be carried subject to space availability and payment of
					additional
					charges in accordance with this Policy.
				</li>
				<li>NUEGO reserves the right to disallow any excess baggage or large items carried by You.
				</li>
				<li>NUEGO reserves the right to refuse permission for You to bring any item onto its
					service.
				</li>
				<li>In case You wish to cancel journey due to non-payment of excess baggage charges,
					cancellation
					charges and rescheduling / refund charges as applicable on the ticket purchased shall be
					levied.
				</li>
				<li>NUEGO is the sole authority for interpretation of these terms.</li>
				<li>You should not carry any goods like weapons, inflammables, firearms, ammunition, drugs,
					liquor,
					smuggled goods, items indicated below and any other articles that are prohibited under
					applicable
					law. Prohibited items are not permitted either on-board or in the luggage compartment.
				</li>
			</ul>
			<h4>List of prohibited items: </h4>
			<ol>
				<li>Compressed gases - deeply refrigerated, flammable, non-flammable and poisonous such as
					butane
					oxygen, liquid nitrogen, aqualung cylinders and compressed gas cylinders
				</li>
				<li>Corrosives such as acids, alkalis, mercury and wet cell batteries and apparatus
					containing mercury
				</li>
				<li>Explosives, munitions, fireworks and flares, ammunition including blank cartridges,
					handguns,
					fireworks, pistol caps
				</li>
				<li>Flammable liquids and solids such as lighter refills, lighter fuel, matches, paints,
					thinners,
					firelighters, lighters that need inverting before ignition, matches (these may be
					carried on the
					person), radioactive material, briefcases, and attaché case with installed alarm devices
				</li>
				<li>Oxidizing materials such as bleaching powder and peroxides</li>
				<li>Poisons and infectious substances such as insecticides, weed killers and live virus
					materials
				</li>
				<li>Anything that possesses and/or is capable of possessing and/or emitting a conspicuous
					and/or
					offensive odour.
				</li>
				<li>Other dangerous articles such as magnetized materials, offensive or irritating
					materials
				</li>
				<li>Other articles that are prohibited under law.</li>
			</ol>
			<ul>
				<li>NUEGO strictly advices that You remove all valuables (including but not limited to cash,
					cameras,
					jewelry, precious metals, money, electronics, and devices), perishables and medication
					from Your
					luggage. In case, You decide to carry any valuables against the above advice You shall
					do this at
					solely Your own risk and shall not hold NUEGO responsible for any pilferage, loss, or
					damage to such
					items.
				</li>
				<li>You must never accept luggage or parcels on behalf of someone else for security
					reasons.
				</li>
				<li>You must not leave baggage unobserved at any time. Unattended baggage may be removed by
					NUEGO as an
					object of suspicion.
				</li>
				<li>NUEGO does not allow any pets and/or any other animal on board for the purposes of
					availing the
					services as being provided.
				</li>
				<li>All charges indicated in this policy are exclusive of taxes under applicable laws.</li>
			</ul>
			<h4 id={'terms-5'}>5. Customer’s Responsibility And Express Undertaking
			</h4>
			<p>You are advised to check the description of the services carefully before making a booking or
				travelling
				through NUEGO buses; and You hereby expressly agree and undertake to be bound by all the
				terms and
				conditions as contained in booking confirmation or as laid out in the confirmed booking
				voucher and as
				stated herein. If You intend to make a booking on behalf of another person, it shall be Your
				responsibility to inform such person about the terms hereunder including all rules,
				regulations,
				restrictions applicable thereto. You hereby undertake to abide by all procedures,
				directions, guidelines
				and/or restrictions of as nature whatsoever, as modified from time to time, in connection
				with the use
				of the bus services available through NUEGO. </p>
			<h4 id={'terms-6'}>6. Amounts, Fees, And Payment</h4>
			<p>In addition to the cost of booking charged, NUEGO reserves the right to charge certain
				amounts, fees in
				the nature of convenience fees or service fees. NUEGO further reserves the right to alter
				any and all
				fees from time to time. Any such additional fees, including fee towards any modifications
				thereof, will
				be displayed to You before confirming the booking or collecting the payment from You. In
				cases of short
				charging of the booking amount, taxes, statutory fee, convenience fee etc., owing to any
				technical error
				or other reason, NUEGO shall reserve the right to deduct charge or claim the balance amount
				from You and
				You shall pay such balance amount to NUEGO. In cases where the short charge is claimed prior
				to the
				utilization of the booking, NUEGO will be at the liberty to cancel such bookings if the
				amount is not
				paid before the utilization date, without refund of any amounts. Any increase in the price
				charged by
				NUEGO on account of change in rates of taxes or imposition of new taxes, levies by
				Government shall have
				to be borne by You. Such imposition of taxes, levies may be without prior notice and could
				also be
				retrospective but will always be as per applicable law. In the rare circumstances of a
				booking not
				getting confirmed for any reason whatsoever, NUEGO will process the refund of the booking
				amount paid by
				You and intimate You about the same. NUEGO is not under any obligation to provide an
				alternative booking
				in lieu of or undertaking to compensate or replace the unconfirmed booking. Unless otherwise
				agreed by
				NUEGO, all refunds as processed by NUEGO shall be made to the source mode of purchasing the
				tickets. GST
				on the refunded ticket amounts will be refunded as per the cancellation policy as available
				at [o].
			</p>
			<h4 id={'terms-7'}>7. Right To Refuse Service</h4>
			<p>NUEGO as its sole discretion reserves the right to not accept any booking without assigning
				any reason
				thereof. In addition to other remedies and recourse available to NUEGO under these terms and
				conditions
				and/or under applicable law, NUEGO may limit Your activity, warn other users of Your
				actions,
				immediately suspend or terminate Your registration and/or take any action as NUEGO may deem
				fit. Once
				You have been suspended or terminated, You shall not register or attempt to register
				Yourself with NUEGO
				with different credentials or use the website in any manner whatsoever until You is
				reinstated by NUEGO.
				NUEGO may at the time in its sole discretion reinstate suspended users. You shall not write
				or send any
				content to NUEGO which is, or communicate with NUEGO using language or content which is:</p>
			<ol>
				<li>Abusive, threatening, offensive, defamatory, coercive, obscene, belligerent, glorifying
					violence,
					vulgar, sexually explicit, pornographic, illicit or otherwise objectionable;
				</li>
				<li>Contrary to any applicable law;</li>
				<li>Violates third parties' intellectual property rights;</li>
				<li>A spam; or</li>
				<li>In breach of any other part of these terms and conditions.</li>
			</ol>
			<p>Further consuming any food items except the snacks offered by NUEGO is strictly prohibited.
				If You
				violate any of the aforesaid terms, NUEGO shall be at liberty to take appropriate legal
				action against
				You.
			</p>
			<h4 id={'terms-8'}>8. Cancellation And Refunds
			</h4>
			<p>You may prepone or postpone Your ticket bookings only once in accordance with the
				cancellation policy.
				Discount/Free Coupon used once cannot be used again. No refund will be applicable for the
				free/discount
				coupons used, in case of partial use or cancellation of ticket. No refund, in any form
				whatsoever will
				be provided for cancellation of tickets that have been postponed/preponed. Cancellation
				charges shall be
				applicable on original fare but not on the discounted fare. No cancellation whatsoever shall
				be
				applicable to the special services availed along with the ticket booking. In case of cash
				bookings at
				agent counters, You shall with the agent. A claim if based on the above terms and
				conditions, must be
				lodged within 48 hours of journey failing which the claim shall be considered
				void-ab-initio. For
				further clarifications, You may refer to our cancellation policy available below. In the
				event there are
				any inconsistencies in the terms provided in the Terms and Conditions and the Cancellation
				and
				Rescheduling Policy, then the terms mentioned under the Cancellation and Rescheduling shall
				prevail.</p>
			<h4>Cancellation & Rescheduling Policy
			</h4>
			<p><b>Scope -</b>This is the standard policy applicable to bookings done for travel on NUEGO.
				Any route or
				schedule or service or date specific or promotional policy introduced from time-to-time will
				override
				this policy. NUEGO reserves the right to change the policy and applicable charges from time
				to time,
				without prior notice and liability and without assigning any reason whatsoever. This policy
				should be
				read in conjunction with the terms and conditions available above. This policy shall be
				subject to the
				provisions of the Motor Vehicles Act, 1988 and the relevant rules framed thereunder, as
				amended from
				time to time.
			</p>
			<p><b>Policy -</b>
				<ul>
					<li>For tickets purchased through travel agent, You may cancel/reschedule Your booking
						by contacting
						the ticketing agent through whom ticket was purchased.
					</li>
					<li>Tickets purchased through any NUEGO self-care channels, such as, website, mobile
						app, IVR, etc.
						can be cancelled/rescheduled on any of the NUEGO self-care channels.
					</li>
					<li>Refund of ticket purchased through travel agent/trade partner will have to processed
						through the
						respective travel agent/trade partner only.
					</li>
					<li>Refund of ticket purchased through NUEGO call centre or Self-care channels through
						credit
						card/debit card/any other electronic modes of payment, will be processed within 7 to
						10 days
						from the date of cancellation to the source of payment.
					</li>
					<li>Refund and rescheduling charges as applicable at the time of ticket purchase will
						apply.
					</li>
					<li>Partial cancellation/rescheduling is permitted. In case multiple passengers are
						booked in one
						ticket, then refund/rescheduling charges will be applicable per cancelled/
						rescheduled seat per
						transaction.
					</li>
					<li>In case of partial cancellation of return/multiple leg ticket, the refund/
						rescheduling charges
						will be applicable on the fare paid for the cancelled/rescheduled portion of travel
						and the fare
						will be recalculated for revised journey as applicable at the time of
						cancellation/rescheduling.
					</li>
					<li>Rescheduling charges will be applicable in addition to the difference in fare (if
						any).
					</li>
					<li>Rescheduling to a lower fare is permitted, however difference in fare will not be
						refunded.
						Rescheduling charges will be applicable.
					</li>
					<li>Seat change in same day same schedule permitted. Rescheduling charges will not be
						applicable.
						Difference in fare will be charged if the new seat is at a higher fare, however
						difference in
						fare will not be refunded if the new seat is at a lower fare.
					</li>
					<li>Once rescheduled the ticket is non-refundable.</li>
					<li>Tickets are non-transferrable and only the originally booked passengers can travel
						on the
						rescheduled tickets.
					</li>
					<li>Refund/rescheduling charges may be waived in the unfortunate event of illness or
						death of any
						immediate family member (official supporting documentation is required).
					</li>
					<li>Any other services purchased in addition to the journey ticket cannot be cancelled
						in isolation.
						Such services can be cancelled/rescheduled only in case of cancellation/
						rescheduling of the
						associated journey ticket. Refund and rescheduling charges are also applicable on
						any other
						services purchased, per seat per transaction.
					</li>
					<li>12% GST will apply on refund & rescheduling charges.</li>
					<li>Discount/Free Coupon used once cannot be used again.</li>
					<li>No refund will be applicable for the free/discount coupons used, in case of partial
						use or
						cancellation of ticket.
					</li>
					<li>In the event of any misuse or abuse of any offer by You or the travel agent, NUEGO
						reserves the
						rights to deny the offer or cancel the booking. NUEGO shall not refund the booking
						amount in
						such cases.
					</li>
					<li>A claim if based on the above terms and conditions, must be lodged within 48 hours
						of journey
						failing which the claim shall be considered void-ab-initio.
					</li>
					<li>NUEGO is the sole authority for interpretation of these terms.</li>
				</ul>
			</p>
			<p>
				<b>Cancellation Charges –</b>
				<table>
					<tr>
						<th>More than 48Hrs prior to departure*</th>
						<th>48Hrs – 12Hrs prior to departure*</th>
						<th>12Hrs-4Hrs prior to departure*</th>
						<th>Less than 4Hrs prior to departure*</th>
						<th>No-show</th>
					</tr>
					<tr>
						<td>0%</td>
						<td>0% of fare paid</td>
						<td>14% of fare paid</td>
						<td>14% of fare paid</td>
						<td>100% of fare paid</td>
					</tr>
				</table>
			</p>

			<p>*Scheduled time of departure from boarding point mentioned in ticket
			</p>
			<h4 id={'terms-9'}>9. Breakdown Of The Vehicle Or Insufficient Passenge
			</h4>
			<p>The trips will be subject to cancellation of booking or postponement due to break down of the
				vehicle or
				insufficient passengers for the trip or for any force majeure events mentioned hereinbelow.
				Under such
				circumstances You will be informed through phone / e-mail /sms as soon as possible. NUEGO is
				not
				responsible for any breakdowns of audio, video, and air conditioning equipment en-route or
				in the middle
				of the journey. </p>
			<h4 id={'terms-10'}>10. Force Majeure
			</h4>
			<p>There can be exceptional circumstances where NUEGO may be unable to honour the confirmed
				bookings due to
				various reasons like act of God, labour unrest, insolvency, a pandemic, an epidemic,
				business
				exigencies, government decisions, terrorist activity, any operational and technical issues
				with the
				route or breakdown of the bus etc. or any other reason beyond the control of NUEGO. If NUEGO
				has advance
				knowledge of any such situations where dishonour of bookings may happen, it will make its
				best efforts
				to provide similar alternative to You or refund the booking amount after deducting
				applicable service
				charges. Such refund shall completely discharge NUEGO from all liabilities with respect to
				that
				transaction. Any additional liabilities, if any, shall be borne by You. In case of any
				breakdowns
				en-route, NUEGO will not be responsible to make any alternate arrangements of travel and
				will refund the
				amount on a pro-rata basis at the NUEGO booking counters situated at the origin or
				destination of the
				journey.
			</p>
			<h4 id={'terms-11'}>11. Indemnification </h4>
			<p>You agree to indemnify, defend and hold harmless NUEGO its affiliates and their respective
				officers,
				directors (“Indemnified Persons”), lawful successors and assigns from and against any and
				all losses,
				liabilities, claims, damages, costs and expenses (including legal fees and disbursements in
				connection
				therewith and interest chargeable thereon) asserted against or incurred by such Indemnified
				Persons,
				that arise out of, result from, or may be payable by virtue of, any breach of any
				representation or
				warranty provided by You, or non-performance of any covenant by You or due to any of Your
				actions. You
				shall solely liable for any breach of any specific rules and regulations as
				applicable/framed by NUEGO
				from time to time and NUEGO cannot be held responsible for the same. Notwithstanding
				anything stated,
				NUEGO’s maximum liability arising out of any claims, disputes, damages arising out of or
				related to You
				booking the tickets with NUEGO and/or You undertaking journey through NUEGO buses pursuant
				to You buying
				the tickets in accordance with these terms and conditions, shall be limited only to the
				price of the
				ticket as sold by NUEGO and that not more than 1 (one) claim shall be entertained with
				respect to 1
				(one) ticket.
			</p>
			<h4 id={'terms-12'}>12. Fake Calls And Other Similar Phishing, Spamming Or Fraudulent Activities</h4>
			<p>NUEGO's employees or authorized representatives will never contact You asking for Your credit
				or debit
				card number, expiry date, CVV, net banking login, passwords, OTP etc. nor will NUEGO ever
				request for a
				fund transfer to a personal or an individual bank account. Further, NUEGO will never ask a
				customer to
				install any third-party applications that enable them to view a customer's mobile or
				computer screen.
				Acting on any of these requests may make You a victim of fraud and may potentially lead to
				loss of Your
				valuable money or information.</p>
			<h4 id={'terms-13'}>13. Jurisdiction </h4>
			<p>These terms and conditions are subject to interpretation as per the laws of India, and the
				parties shall
				refer any unresolved disputes to the exclusive jurisdiction of courts in Mumbai.
			</p>
			<h4 id={'terms-14'}>14. Amendment To The Terms And Conditions
			</h4>
			<p>NUEGO reserves the right to change the terms and conditions from time to time. You shall be
				responsible
				for regularly reviewing the terms and conditions including updates/amendments thereto,
				especially before
				commencing to travel through NUEGO’s buses. In case of You taking the journey, it shall be
				deemed that
				all of NUEGO’s the terms and conditions including updates/amendments thereto are acceptable
				to You and
				You agree to be legally bound by the same without any claim or dispute thereof.
			</p>
			<h4 id={'terms-15'}>15. Confidentiality
			</h4>
			<p>Any information which is specifically mentioned by NUEGO as confidential shall be maintained
				confidentially by You and shall not be disclosed unless as required by law. </p>
			<h4 id={'terms-16'}>16. Privacy Policy
			</h4>
			<p>You shall also refer to NUEGO's Privacy Policy available at [o] which governs use of the
				Website. By
				using the Website, You agree to the terms of the Privacy Policy and accordingly consent to
				the use of
				Your personal information by NUEGO and its affiliates in accordance with the terms of the
				Privacy
				Policy. </p>
			{/*<h4>TERMS & CONDITIONS FOR CASHBACK OFFER*/}
			{/*</h4>*/}
			{/*<ul>*/}
			{/*	<li>Offer valid only on bookings done on Nuego.in*/}
			{/*	</li>*/}
			{/*	<li>Offer valid for bookings done from 10th August 2022 onwards and for journey completed by 26th August*/}
			{/*		2022.*/}
			{/*	</li>*/}
			{/*	<li>Offer valid only for such customers who book the tickets for their onward & return journey on the*/}
			{/*		same date . However, the dates for onward & return journeys may be different.*/}
			{/*	</li>*/}
			{/*	<li>Customer should have a wallet on Neugo.in before completion of return journey to be eligible for the*/}
			{/*		offer.*/}
			{/*	</li>*/}
			{/*	<li>Cashback will be credited only to the customer wallet within 24 hours of completion of return*/}
			{/*		journey.*/}
			{/*	</li>*/}
			{/*	<li>Cashback will be credited to the Promotional wallet of the person whose primary mobile number has*/}
			{/*		been updated in the PNR at the time of booking.*/}
			{/*	</li>*/}
			{/*	<li></li>*/}
			{/*	<li>The validity of the Cashback amount credited in the promotional wallet is 30days from the date it is*/}
			{/*		credited. Unused cashback will expire and shall not be available for redemption after 30days.*/}
			{/*	</li>*/}
			{/*	<li>Nuego reserves the right to extend, cancel, discontinue, prematurely withdraw, change, alter or*/}
			{/*		modify this Offer or any part thereof including the eligibility criteria and other pertinent T&C at*/}
			{/*		their sole discretion at any time during its validity as may be required including but not limited*/}
			{/*		to in view of business exigencies and/or changes by a regulatory authority and/or statutory changes*/}
			{/*		and/or any reasons beyond their control and the same shall be binding on the Customer(s).*/}
			{/*	</li>*/}
			{/*	<li>Nuego reserves the right to disqualify any transaction or discontinue promotion any time.*/}
			{/*	</li>*/}
			{/*	<li>In cases of fraudulent/suspicious transactions, Nuego solely reserves the right to revoke the offer.*/}
			{/*	</li>*/}
			{/*	<li>The cashback under this Offer are neither assignable nor transferable under any circumstance.*/}
			{/*	</li>*/}
			{/*	<li>Cashback cannot be withdrawn to a bank account, or exchanged for cash, and is non-transferable.*/}
			{/*	</li>*/}
			{/*	<li>Cashback once used will not be refunded back to wallet on cancellation.*/}
			{/*	</li>*/}
			{/*	<li>All the cashback shall be subject to such other standard T&C and policies as may be applicable from*/}
			{/*		time to time and shall have to be duly complied with/ by the customer(s).*/}
			{/*	</li>*/}
			{/*</ul>*/}
			{/*<p>I HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS FOR PURCHASE OF TICKETS OR AGREEING TO TRAVEL*/}
			{/*	/TRAVELLING THROUGH NUEGO AND EXPRESSLY AGREE AND UNDERTAKE TO ABIDE BY AND ADHERE TO THE SAME.</p>*/}
			{/*<h4>Cancellation Policy*/}
			{/*</h4>*/}
			{/*<table>*/}
			{/*	<th>Cancellation Cut off Time</th>*/}
			{/*	<th>Cancellation Charges</th>*/}
			{/*	<tr>*/}
			{/*		<td>Between 1 To 720 Minutes</td>*/}
			{/*		<td>14 % Amount Deduction*/}
			{/*		</td>*/}
			{/*	</tr>*/}
			{/*	<tr>*/}
			{/*		<td>Between 720 To 1440 Minutes</td>*/}
			{/*		<td>0 % Amount Deduction*/}
			{/*		</td>*/}
			{/*	</tr>*/}
			{/*	<tr>*/}
			{/*		<td>Between 1440 To 2880 Minutes</td>*/}
			{/*		<td>0 % Amount Deduction*/}
			{/*		</td>*/}
			{/*	</tr>*/}
			{/*	<tr>*/}
			{/*		<td>Before 2880 Minutes</td>*/}
			{/*		<td>0 % Amount Deduction*/}
			{/*		</td>*/}
			{/*	</tr>*/}
			{/*	<tr>*/}
			{/*		<td>Stop Cancellation Within 1 Minutes</td>*/}
			{/*	</tr>*/}
			{/*</table>*/}
			{/*<table className={'w-100'}>*/}
			{/*	<td>*/}
			{/*		<ul>*/}
			{/*			<li>Partial cancellation Is Possible</li>*/}
			{/*			<li>Refund of partial cancellation will be processed after your journey date</li>*/}
			{/*			<li>Ticket Cancellation Can Be Before 1 Minutes of Journey From Pickup Time.*/}
			{/*			</li>*/}
			{/*			<li>It Is Rights Of GreenCell Mobility To Change Cancellation Policy Any Time Without Prior To*/}
			{/*				Notice*/}
			{/*			</li>*/}
			{/*			<li>Bookings from this site will be eligible for cancellation through Cancellation Form on this*/}
			{/*				site only. No cancellation request will be entertained by any branch or agent office.*/}
			{/*			</li>*/}
			{/*			<li>On Cancellation, Refund will be credited in 7 To 10 working days in source payment.</li>*/}
			{/*			<li>If any customer choose extra benefit option while cancellation they will get refund amount*/}
			{/*				in their GreenCell Mobility wallet, By any means amount will not be credit in their bank*/}
			{/*				account niether it can be transfer to bank account*/}
			{/*			</li>*/}
			{/*		</ul>*/}
			{/*	</td>*/}
			{/*</table>*/}
			{/*<p>No further arguments will be taken into consideration. UAT B2C may cancel a service without any prior*/}
			{/*	notice in case of circumstances beyond its control.</p>*/}
			{/*<p>In such circumstances UAT B2C shall either reschedule the journey for travel on the next UAT B2C service*/}
			{/*	in the same sector, Subject to availability without any extra charge based on the passenger's conveyance*/}
			{/*	or enable a full refund of the ticket as paid by the passenger and shall be under no further liability*/}
			{/*	to the passenger.*/}
			{/*</p>*/}
			<p>I HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS FOR PURCHASE OF TICKETS OR AGREEING TO
				TRAVEL /TRAVELLING THROUGH NUEGO AND EXPRESSLY AGREE AND UNDERTAKE TO ABIDE BY AND ADHERE TO
				THE SAME.
			</p>

		</div>
		
		}
		</>

		 
	)
}

export default TermsConditionsDetail
