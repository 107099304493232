import React, {useState} from 'react'
import {DownloadMobileAppData} from "../../../../../services/api/DataUpdating";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../../../components/CustomToast/CustomToast";
import {useMediaQuery} from "react-responsive";
import "./NueGoScanner.scss"
import appstore from "../../../../../assets/revamp/appstore.png"
import scanner from "../../../../../assets/revamp/scanner.png"
import iphone13 from "../../../../../assets/revamp/mobileImgforfooterbannerblog.png"
const downloadIcon1 = process.env.REACT_APP_CDN_LINK + `assets/images/downloadIcon1.png`
const downloadIcon2 = process.env.REACT_APP_CDN_LINK + `assets/images/downloadIcon2.png`
const downloadIcon3 = process.env.REACT_APP_CDN_LINK + `assets/images/downloadIcon3.png`
const downloadIcon4 = process.env.REACT_APP_CDN_LINK + `assets/images/downloadIcon4.png`
const playstore = process.env.REACT_APP_CDN_LINK + `assets/images/playstore.png`

function NueGoScanner() {

    const [phoneNumber, setPhoneNumber] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const handlePhoneNumber = (value) => {
        setPhoneNumber(value)
    }

    const phoneNumberValidation = () => {
        if (phoneNumber?.length === 10 && phoneNumber[0] >= 6) {
            setPhoneNumberError(false)
        } else {
            setPhoneNumberError(true)
        }
    }

    const submitHandler = () => {
        let variable = {
            mobileNumber: phoneNumber
        }
        DownloadMobileAppData(variable, (response) => {
        }, (err) => {
            if(err){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={err[0]?.message || err.toString()}
                        type={"error"}
                    />,
                );
            }
        })
    }

  return (
    <div className='nuego-scanner'>

        <div className='scanner-store'>
            <h2 className='download-heading' >Download NueGo App</h2>
            <div className='scanner-app-ios'>
                <div className='scanner'>
                    <p>Scan to Download</p>
                    <img className='scanner-img' src={scanner} alt="" />
                </div>
                <div className='app-ios'>
                    <img src={playstore} alt="" />
                    <img src={appstore} alt="" />
                </div>
            </div>
            {
                isDesktopOrLaptop &&
                <div className={'get-linked'}>
                <input type={"number"}
                        onChange={e => handlePhoneNumber(e.target.value)}
                        min="0"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress={(e) => e.target.value.length === 10 ? e.preventDefault() : null}
                        onBlur={phoneNumberValidation}
                        className={'input-tag'} placeholder={'Enter Phone Number'}/>
                <button className={'submit-button px-4 ml-3'}
                        disabled={phoneNumberError || !phoneNumber}
                        onClick={submitHandler}>
                    Send
                </button>
            </div>
            } 
        </div>
        <div className='amenities'>
        <div className={'special-things d-flex'}>
                        <div className={'icon-parent-banner'}>
                            <img src={downloadIcon1}
                                 alt={'Download NueGo App on Google Play Store and Apple App Store for exclusive offers and benefits on ' +
                                     'inter-city electric bus tickets'} className={'downloadIcon1'}/>
                            <div className={'details-width'}>Access ticket on your phone</div>
                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={downloadIcon2}
                                 alt={'Download NueGo App on Google Play Store and Apple App Store for exclusive offers and ' +
                                     'benefits on inter-city electric bus tickets'} className={'downloadIcon1'}/>
                            <div className={'details-width'}> Great deals for you</div>

                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={downloadIcon3}
                                 alt={'Download NueGo App on Google Play Store and Apple App Store for exclusive offers and benefits ' +
                                     'on inter-city electric bus tickets'} className={'downloadIcon1'}/>
                            <div className={'details-width'}> Notifications and reminders</div>

                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={downloadIcon4}
                                 alt={'Download NueGo App on Google Play Store and Apple App Store for exclusive offers and benefits' +
                                     ' on inter-city electric bus tickets'} className={'downloadIcon1'}/>
                            <div className={'details-width'}>Support during journey</div>

                        </div>
                    </div>
        </div>

        {/* { <div className={'mobile-icon'}>
                    <img src={iphone13}
                         alt={"Download NueGo App on Google Play Store and Apple App Store for exclusive offers" +
                             " and benefits on inter-city electric bus tickets"} className={'homebanner'}/>
        </div>
        } */}

    </div>
  )
}

export default NueGoScanner