import graphql from "babel-plugin-relay/macro";

const GetSourceListingQuery = graphql`
		  query GetSourceListingQuery($searchTerm: String) {
		    source(searchTerm: $searchTerm) {
		        CMCityname
				CMCityid
		    }
		  }
	`
export default GetSourceListingQuery