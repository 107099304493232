/**
 * @generated SignedSource<<c5c76e441f0a4001bb333e0e4e530b3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      }
    ],
    "concreteType": "ReleaseSeatMutation",
    "kind": "LinkedField",
    "name": "releaseSeat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReleaseSeatsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReleaseSeatsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cfa94be3e64acdba8edb0331a421d911",
    "id": null,
    "metadata": {},
    "name": "ReleaseSeatsMutation",
    "operationKind": "mutation",
    "text": "mutation ReleaseSeatsMutation(\n  $bookingId: String!\n) {\n  releaseSeat(bookingId: $bookingId) {\n    message\n  }\n}\n"
  }
};
})();

node.hash = "a5fb73b3cc06c9805a312b397158eebd";

module.exports = node;
