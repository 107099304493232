import React from 'react'
import './ChargingIndia.scss'

function ChargingIndia() {
  return (
    <div className='charging-india'>
        <div className="main-container">
            <div className='image-content'>
                <div className="imagediv">
                    <img src="https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/K2K-Logo.png" alt="" />
                </div>
                <div className="contentdiv">
                    <h3 className='heading'>NueGo's Record Breaking E-K2K Journey - October 2024</h3>
                    <p className="charge">NueGo is excited to launch its Kashmir to Kanyakumari Electric (E-K2K) Bus Expedition! Covering 4,000+ km across 200+ cities, from 3,500 ft to sea level, our all-electric bus is spreading eco-friendly travel vibes across India. And! We’re making history as the first Electric Bus brand to pull off this amazing feat!
</p>
                    {/* <div className="records">
                        <div className="record-img">
                            <img src="https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/worldwide.png" alt="" />
                        </div>
                        <div className="record-img">
                            <img src="https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/asia.png" alt="" />
                        </div>
                        <div className="record-img">
                            <img src="https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Limca.png" alt="" />
                        </div>
                    </div> */}
                </div>
            </div>

            <div className={'special-things d-flex'}>
                        <div className={'icon-parent-banner'}>
                            <img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/destination.png'}
                                 alt={''} className={'downloadIcon1'}/>
                            <div className={'details-width'}>
                                <p className="head">Destination</p>
                                <p className="ans">Kashmir-Kanyakumari</p>
                            </div>
                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/places.png'}
                                 alt={''} className={'downloadIcon1'}/>
                            <div className={'details-width'}>
                                <p className="head">No. of Places</p>
                                <p className="ans">11 States & UT</p>
                            </div>

                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/destination.png'}
                                 alt={''} className={'downloadIcon1'}/>
                            <div className={'details-width'}> 
                                <p className="head">Distance</p>
                                <p className="ans">4,033 Kms</p>
                                </div>

                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/start.png'}
                                 alt={''} className={'downloadIcon1'}/>
                            <div className={'details-width'}>
                                <p className="head">Start Date</p>
                                <p className="ans">4th Oct, 2024</p>
                            </div>

                        </div>
                        <div className={'icon-parent-banner'}>
                            <img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/end.png'}
                                 alt={''} className={'downloadIcon1'}/>
                            <div className={'details-width'}>
                                <p className="head">End Date</p>
                                <p className="ans">18th Oct, 2024</p>
                                </div>

                        </div>
                    </div>

        </div>
    </div>
  )
}

export default ChargingIndia