import React from 'react'
import './../ProfileDetail.scss'
import {useNavigate} from "react-router";

//icons
import arrowIcon from "../../../assets/icons/green-arrow-right.svg"
// assets
const busImage = process.env.REACT_APP_CDN_LINK + `assets/images/bus-image.png`
const walletImage = process.env.REACT_APP_CDN_LINK + `assets/images/wallet-image.png`
const copassengersImage = process.env.REACT_APP_CDN_LINK + `assets/images/co-passengers-image.png`
const checkListImage = process.env.REACT_APP_CDN_LINK + `assets/images/checklist-image.png`
const settingsIcon = process.env.REACT_APP_CDN_LINK + `assets/images/setting-image.png`

const ProfileCardCollectionMobile = () => {

    const cardData = [
        {
            cardIcon: busImage,
            title: "My Booking",
            description: "Upcoming, completed & cancelled trips. ",
            redirection: "/my-booking"
        },
        {
            cardIcon: walletImage,
            title: "Your Wallet",
            redirection: "/wallet"
        },
        {
            cardIcon: copassengersImage,
            title: "Co-passengers",
            redirection: '/co-passengers'
        },
        {
            cardIcon: checkListImage,
            title: "Checklist",
            description: "Create your custom checklist",
            redirection: '/checklist'
        }
    ]

    return (
        <div className={'profile-card-collection'}>
            <div className={'profile-card-wrapper'}>
                {
                    cardData.map((item, index) => {
                        return <Card item={item} key={index}/>
                    })
                }

            </div>
            <div className={'profile-card'}>
                <div className={'d-flex justify-content-between align-items-start mb-2'}>
                    <div className={'d-flex align-items-center'}>
                        <img src={settingsIcon} className={'icon-24 mr-2'} alt={'alt'}/>
                        <p className={'open-600w-14s-22h black-1E1E26-color mb-0'}>Settings</p>
                    </div>
                    <img src={arrowIcon} className={'icon-24'} alt={'alt'}/>
                </div>
            </div>
        </div>
    )
}

const Card = ({item}) => {
    const navigate = useNavigate()

    return <div className={'profile-card small-card'} onClick={() => navigate(item.redirection)}>
        <div className={'d-flex justify-content-between align-items-start mb-2'}>
            <img src={item.cardIcon} alt={'card'} className={'card-image'}/>
            <img src={arrowIcon} alt={'arrow'} className={'arrow-icon'}/>
        </div>
        <p className={'open-600w-14s-24h black-1E1E26-color mb-1'}>{item.title}</p>
        <p className={'open-400w-12s-18h grey-2-78787d-color'}>{item.description}</p>
    </div>
}

export default ProfileCardCollectionMobile
