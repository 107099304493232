import React from 'react'
import '../ProfileDetail.scss'
// icons
import arrowIcon from "../../../assets/icons/green-arrow-right.svg"

//assets
const callIcon = process.env.REACT_APP_CDN_LINK + `assets/images/profile-support-callIcon.png`


const ProfileSupportFeedbackCard = () => {
    return (
        <div className={'support-feedback-card d-flex justify-content-between'}>
            <div className={'d-flex'}>
                <img src={callIcon} className={'icon-48 mr-2'} alt={'alt'}/>
                <div>
                    <p className={'open-600w-14s-22h black-1E1E26-color mb-0'}>Support & Feedback</p>
                    <p className={'open-400w-12s-18h grey-9B9B9B-color mb-0'}>Help us improve services with you and we
                        are here for all your doubts!</p>
                </div>
            </div>
            <img src={arrowIcon} className={'icon-24'} alt={'alt'}/>
        </div>
    )
}

export default ProfileSupportFeedbackCard
