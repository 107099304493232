import React, {useEffect, useState} from 'react'
import './OfferListPageDesktop.scss'
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import { MDBModal, MDBModalBody, MDBModalHeader} from 'mdbreact';
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router";
import uuid from "react-uuid";
import ReactMarkdown from "react-markdown";
//imports
import {connect} from 'react-redux'
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import TermsConditionsDetail
    from "../../components/TermsConditionsModalContent/TermsConditionsDetail/TermsConditionsDetail";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import {offerStaticData} from "../../utils/staticOfferSliderData";
import Footer from "../../components/Footer/Footer";
// import LuckyDrawerWinnnerCard from "../Home/component/LuckyDrawerWinnnerCard";
// icons
import copyIcon from "../../assets/icons/copy-icon.svg"
import tealCloseIcon from "../../assets/icons/teal-close.svg"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// assets
const card1 = process.env.REACT_APP_CDN_LINK + `assets/images/card1.png`
const super_offe = process.env.REACT_APP_CDN_LINK + `assets/images/super_offer.png`
const emptyOfferIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/empty-illustration.png`

const OfferListPageDesktop = (props) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();
    const {authTypeFromStore} = props
    const [offerList, setOfferList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bottomTray, setBottomTray] = useState("");
    const [activeModal, setActiveModal] = useState(false);
    const [activeData, setActiveData] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API }api/offers?populate=deep`)
            .then((res) => res.json())
            .then((res) => {
                // res.data.sort((a,b)=>{
                //     return Number(a?.attributes?.rank)-Number(b?.attributes?.rank)
                // });
                setOfferList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                if(err){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={err[0]?.message || err.toString()}
                            type={"error"}
                        />,
                    );
                }
                setOfferList([]);
                setLoading(false);
            })
        return () => {
        };
    }, []);

    let pageResponsiveConstants

    if (isDesktopOrLaptop) {
        pageResponsiveConstants = {
            mainText: "open-600w-16s-24h",
            mainTextHighlighter: "ubuntu-700w-20s-32h",
            expiresOnText: "open-600w-14s-26h mb-0",
            couponCode: "open-600w-14s-22h mb-0",
            emptyOfferHeader: "open-700w-16s-24h mb-3 black-1E1E26-color",
            emptyOfferText: "open-400w-16s-22h margin-bottom-32 grey-2-78787d-color"
        }
    } else {
        pageResponsiveConstants = {
            mainText: "open-600w-11s-18h",
            mainTextHighlighter: "ubuntu-700w-18s-22h",
            expiresOnText: "open-600w-9s-18h mb-0",
            couponCode: "open-600w-14s-22h mb-0",
            emptyOfferHeader: "open-700w-16s-22h mb-3 black-1E1E26-color",
            emptyOfferText: "open-400w-12s-18h grey-2-78787d-color"
        }
    }

    const copyToClipboardHandler = (value) => {
        if (value !== null) {
            navigator.clipboard.writeText(value);
            cogoToast.success(<CustomToast type={"success"} message={"Copied to clipboard"}
                                           heading={"Success"}/>, {position: "top-center"})
        } else {
            cogoToast.error(<CustomToast type={"error"} message={"Something went wrong, Unable to get the Coupon"}
                                         heading={"Error"}/>, {position: "top-center"})
        }

    }
    const redirectToHome = () => {
        navigate('/', {replace: false})
    }

    
    const offerModalPopup = (data) => {
        setActiveModal(true)
        setActiveData(data.attributes)

    }

    const OfferListingSection = () => {
        return loading ?
            <Loader/>
            :
            <div className={'py-4 d-grid card-listing-wrapper flex-wrap-10 mb-1'}>
                {offerList.length > 0 && offerList.sort((a, b) => a?.attributes?.rank - b?.attributes?.rank).map((item, index) => {

                    return (<div
                        className={"parent-card-1  "}
                        // style={{background:`url(${index%2===0?card2:index%3===0?card3:card1})`}}
                        key={uuid()}
                     onClick={() => offerModalPopup(item)}
                    >
                        <div
                            style={{
                                background: `url(${item?.attributes?.offer_bg_image?.url})`,
                            }}
                            className={" card-inner-wrap position-relative cursor-pointer"}
                        >
                        </div>
                    </div>);
                })}
                {
                    
                        offerList.length === 0 &&
                    <div className={'empty-offer-ilustration d-flex justify-content-center w-100'}>
                        <div className={'text-center'}>
                            <img src={emptyOfferIllustration} alt={'no-offers'} className={''}/>
                            <p className={pageResponsiveConstants.emptyOfferHeader}>No Active Offers</p>
                            <p className={pageResponsiveConstants.emptyOfferText}>Coming soon with some exciting
                                offers
                                for you</p>
                            {isDesktopOrLaptop && <button className={'submit-button text-center'}
                                                          onClick={redirectToHome}
                            ><p> Go To Home</p></button>}
                        </div>
                    </div>
                }
                {activeData.tnc && <div className={'offer-modal-component'}>
                <MDBModal isOpen={activeModal} position={'center'} centered={true}
                          size={"lg"}className="m-auto" toggle={()=>setActiveModal(false)} >
                    <MDBModalBody>
                        <div className="offer-modal-body d-flex flex-column align-items-center">
                        <img className={'close-icon-offer icon-16'} src={tealCloseIcon} alt={'alt'} onClick={() => setActiveModal(false)} /> 
                        <div className="upper-section">
                            <h3 className="offer-heading">{activeData?.title}</h3>
                            <h4 className="offer-sub-heading">{activeData?.description}</h4>
                            { activeData?.code && <div className="copy-coupon-div">
                                <img className="cursor-pointer" onClick={()=>copyToClipboardHandler(activeData?.code)} src={copyIcon} alt="" />
                                <p className="mb-0" >{activeData?.code}</p>
                            </div>
                            }
                            <p className="validity">{`Hurry! Offer expires on ${activeData?.expiry}`}</p>
                        </div> 
                        <div className="tnc-div">
                        <ReactMarkdown>{activeData?.tnc}</ReactMarkdown>
                        </div>
                        </div>
                        
                    </MDBModalBody>
                </MDBModal>
            </div>}
            </div>

    }

    return (
        <div>
            {
                isDesktopOrLaptop ?
                    <AuthModalLayout>
                    <Helmet>
                    <title>Bus Ticket Booking Offers, Discount and Coupons | NueGo</title>
                    <link rel="canonical" href="https://www.nuego.in/offers" hreflang="en-IN" />
                    <meta name="description" content="Save big with bus booking offers online from NueGo. Find discounts, coupons, rewards  and deals. Find the some of the most cheap bus deal online with us. Book Now!"/>
                    <meta name="keywords" content="bus booking offers,bus ticket booking offers,bus ticket offers,bus booking discount,discount on bus booking,online bus booking offers,bus booking discount coupons,bus ticket booking coupons,coupon code for bus booking,NueGo coupon code"></meta>
                    <meta name="robots" content="index,follow"/>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.nuego.in/offers",
                        "name": "NueGo Offers",
                        "description": "Discover the travel itineraries for some of the popular destinations. Make the most of your trips with detailed travel plans, including the best time and places to visit in India.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "NueGo",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                            "width": 200,
                            "height": 100
                            }
                        }
                        })}
                        </script>
                    </Helmet>
                        <div className={'offer-list-page '}>
                            <div className={'navbar-wrapper black-1E1E26-bg margin-bottom-100'}>
                                <Navbar fixedNavbar={true}/>
                            </div>
                            <div className=" breadcrumber bread-crumb d-flex">
                                    <Link to={"/"}><p className={'open-600w-14s-22h grey-2-78787d-color mb-0  cursor-pointer'}>Home</p></Link>
                                    <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                    <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Offers</p>
                                    <script type='application/ld+json'>
                                        {JSON.stringify({
                                        "@context": "https://schema.org",
                                        "@type": "BreadcrumbList",
                                        "itemListElement": [
                                            {
                                            "@type": "ListItem",
                                            "position": 1,
                                            "name": "Home",
                                            "item": "https://www.nuego.in"
                                            },
                                            {
                                            "@type": "ListItem",
                                            "position": 2,
                                            "name": "Offers",
                                            "item": "https://www.nuego.in/offers"
                                            }
                                        ]
                                        })}
                                    </script>
                            </div>
                            <div className={'white-fold white-fold-padding'}>                                                  
                                <div className={'inner-div'}>
                                    <h1 className={'ubuntu-700w-32s-48h'}> Offers</h1>
                                    {/* <LuckyDrawerWinnnerCard/> */}
                                    <OfferListingSection/>
                                </div>
                            </div>
                            <Footer/>
                        </div>
                    </AuthModalLayout>
                    :
                    <MobileSubScreenLayout title={"Offers"} back={() => navigate(-1)}>
                        <div className={'offer-list-page '}>
                        <div className=" mx-auto px-3 bread-crumb d-flex">
                                    <Link to={"/"} ><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                                    <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                    <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Offers</p>
                        </div>
                            <div className={'mx-auto px-3'}>
                                {/* <LuckyDrawerWinnnerCard/> */}
                                <OfferListingSection/>
                            </div>
                            <Footer/>
                        </div>
                    </MobileSubScreenLayout>
            }
        </div>

    )
}

const mapStateToProps = state => ({
    authTypeFromStore: state.AuthReducer.type
})

export default connect(mapStateToProps)(OfferListPageDesktop)
