import graphql from 'babel-plugin-relay/macro';

const DownloadInvoiceUrlQuery = graphql`
	query DownloadInvoiceUrlQuery($bookingId: String!) {
	getInvoiceUrlByBookingId(bookingId: $bookingId) {
		url
	}
}
`;

export default DownloadInvoiceUrlQuery;