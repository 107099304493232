import React, { Suspense, useCallback, useRef, useEffect, useState, useMemo } from "react";
import { Helmet } from 'react-helmet';
import "./Lounge.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom"
import backArrowWhite from "../../assets/icons/back-white-arrow.svg"
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import { Keyboard, Pagination, Navigation, A11y } from 'swiper/modules';
import Loader from "../../components/Loader/Loader";
import CloakRoom from "../../assets/icons/cloack room.png"
import phoneIcon from "../../assets/icons/Call.svg"
import locationPin from "../../assets/icons/pin_Location_Gry_Icon.svg"

// assets


const search = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/search.jpg`
const nuegoLounge = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/home.jpg`
const slide = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/slide.jpg`
const Vector1 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector1.jpg`
const Vector2 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector2.jpg`
const RTImage = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/RT.jpg`
//const phoneIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/phoneIcon.svg`
const washroomIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/washroomIcon.svg`
const wifiIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/wifiIcon.svg`
const chargeIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/chargeIcon.svg`
const acIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/acIcon.svg`
const teaCupIcon = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/teaCupIcon.svg`


const servicesList = [
    {
        name: "Clean Washrooms",
        image: washroomIcon,
        altImage:"NueGo Lounge with Clean Washrooms"
    },
    {
        name: "Free Wifi",
        image: wifiIcon,
        altImage:"NueGo Lounge with Free Wifi"
    },
    {
        name: "Charging Points",
        image: chargeIcon,
        altImage:"NueGo Lounge with Charging Points"
    },
    {
        name: "Air Conditoned",
        image: acIcon,
        altImage:"NueGo Air Conditoned Lounge"
    },
    {
        name: "Cafe",
        image: teaCupIcon,
        altImage:"NueGo Lounge with Cafe & Restaurant"
    },
    {
        name: "Cloakroom",
        image: CloakRoom,
        altImage:"NueGo Lounge with Cafe & Restaurant"
    },
];

const sliderList1 = [
    {
        image: slide,
        Cityname: "Indore",
        address:
            "12C, Warehouse Road, Mechanic Nagar, Scheme #54\n" +
            "Vijaynagar, Indore\n" +
            "MP - 452011",
        contact: "1800 267 9001",
    },
    {
        image: slide,
        place: "Delhi",
        address:
            "Kashmiri Gate Metro station ," +
            " Gate no.1, Ground floor " +
            "Next to Aadhar Seva Kendra , " +
            "Delhi - 110006",
        contact: "1800 267 9001",
    },

];

const Lounge = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const isMobile = useMediaQuery({ maxWidth: 747 });
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const sliderRef = useRef(null);
    const [sizeVariable, setSizeVariabale] = useState({
        slidesPerView: 4,
        grid: 2
    })
    let navigate = useNavigate()

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Remove the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };

    }, []);
    React.useEffect(() => {
        if (windowSize.width < 968 && windowSize.width > 667) {
            setSizeVariabale(
                {
                    slidesPerView: 2,
                    grid: 2
                }
            )
        }
        else if (windowSize.width < 667 && windowSize.width > 0) {
            setSizeVariabale(
                {
                    slidesPerView: 1,
                    grid: 2
                }
            )
        }
        else {
            setSizeVariabale(
                {
                    slidesPerView: 4,
                    grid: 2
                }
            )

        }


    }, [windowSize])

    const [LoadingSpinerMain, setLoadingSpinerMain] = useState({ value: false, Response: "pending" })

    const [FindLoungeData, setFindLoungeData] = useState([])
    const [ComingSoonLoungeData, setComingSoonLoungeData] = useState([])
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredLounges = useMemo(() => {
        if (!searchQuery) {
            return FindLoungeData;
        }

        const lowerCaseQuery = searchQuery.toLowerCase();

        return FindLoungeData.filter((item) =>
            item.attributes.name.toLowerCase().includes(lowerCaseQuery)
        );
    }, [searchQuery, FindLoungeData]);


    const backHandler = () => {
        navigate(-1)
    }

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }


    const LoungeGetApi = () => {
        setLoadingSpinerMain(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/lounges?populate=*&sort=rank:asc`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then(
                (res) => {

                    if (res?.error) {
                        setLoadingSpinerMain(errorObject)

                    } else {
                        setLoadingSpinerMain(sucessObject)
                        setFindLoungeData(res?.data)
                        setSliderList(res?.data)
                    }
                })

            .catch((err) => {
                setLoadingSpinerMain(errorObject)

            });

    }

    const LoungeComingGetApi = () => {
        setLoadingSpinerMain(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/lounge-coming-soons?populate=*`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpinerMain(errorObject)

                    } else {
                        setLoadingSpinerMain(sucessObject)
                        setComingSoonLoungeData(res?.data)
                    }
                })

            .catch((err) => {
                setLoadingSpinerMain(errorObject)
            });

    }

    const [sliderList, setSliderList] = useState([]);

    const fetchSliderData = () => {

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/lounges?populate=*`)
            .then((res) => res.json())
            .then((data) => {
                setSliderList(data);
            })
            .catch((error) => {
                console.error("Error fetching slider data:", error);
            });
    };

    useEffect(() => {
        LoungeGetApi()
        LoungeComingGetApi()
        fetchSliderData()

    }, [])

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
         isDesktopOrLaptop?(<AuthModalLayout>
        <Suspense fallback={<div><Loader/></div>}>
        <Helmet>
                    <title>Bus Travel Lounges in India | NueGo</title>
                    <link rel="canonical" href="https://www.nuego.in/lounge" hreflang="en-IN" />
                    <meta name="description" content="Find comfortable bus travel lounges with clean washrooms, free wifi, cafe, charging points and AC. Find NueGo lounges in jaipur, delhi and indore."/>
                    <meta name="keywords" content="travel lounge,lounges,jaipur lounge,delhi lounge,indore lounge"></meta>
                    <meta name="robots" content="index,follow"/>
                    <script type='application/ld+json'>
                    {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "url": "https://www.nuego.in/lounge",
                    "name": "NueGo Lounge",
                    "description": "Find comfortable bus travel lounges with clean washrooms, free wifi, cafe, charging points and AC. Find NueGo lounges in jaipur, delhi and indore. ",
                    "publisher": {
                        "@type": "Organization",
                        "name": "NueGo",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                        "width": 200,
                        "height": 100
                        }
                    }
                    })}
                    </script>
        </Helmet>

            <div style={{ marginBottom: "100px" }}>
                <Navbar />
            </div>
            <div className={"lounge-page"}>
                <div className={"white-fold mt-0  pb-5"}>
                    <div className="content">
                        <div className="lounge-section">
                            <div className="brand-section">
                                <Link to="/">
                                    <p className="p1">Home</p>
                                </Link>
                                <p className="px-1">/</p>
                                <p className="p2">NueGo Lounge</p>
                                <script type='application/ld+json'>
[                                    {JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "BreadcrumbList",
                                    "itemListElement": [
                                        {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://www.nuego.in"
                                        },
                                        {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Lounge",
                                        "item": "https://www.nuego.in/lounge"
                                        }
                                    ]
                                    })}
                                    </script>
                            </div>
                            <div className="search-section">
                                <div className="text-section">
                                    <h1>NueGo Lounge</h1>
                                </div>
                                <div className="search-bar-section">
                                    <form className="form">
                                        <input
                                            type="text"
                                            placeholder="Search for your city"
                                            className="search-area"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <button>
                                            <img src={search} alt="" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="image-section">
                            <div className="image1">
                                <img src={nuegoLounge} alt="NueGo Premium Lounge" className="w-100" />
                            </div>
                            <div className="para-section">
                                <p>
                                    Safety and Comfort are the two most important focus areas
                                    for NueGo, and our premium Lounges were designed with that
                                    in mind. Now our Guests can enjoy the wide range of
                                    amenities in one of our Lounges while they waiting to board
                                    their NueGo ride!
                                </p>
                            </div>
                            <div className="service-section text-center margin-bottom-64">
                                <h3 className="mb-4">Amenities available</h3>
                                <div className="service-image-section">
                                    {servicesList.map((item, index) => {
                                        return (
                                            <div className="image1" key={index}>
                                                <img src={item.image} alt={item.altImage}/>
                                                <p>{item.name}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="slider-section">
                            <div className="text-area mb-5">
                                <h2>Locations</h2>
                                <script type='application/ld+json'>
                                    {JSON.stringify({
                                    "@context": "https://schema.org",
                                    "@type": "ImageGallery",
                                    "image": [
                                        {
                                        "@type": "ImageObject",
                                        "url": "https://cdn.nuego.co.in/greencell/assets/images/static-images/home.jpg",
                                        "width": "600",
                                        "height": "400",
                                        "caption": "NueGo Bus Lounge Over India "
                                        },
                                        {
                                        "@type": "ImageObject",
                                        "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_1_de1b05420d60cdca9a1e_8e6133ffab.png",
                                        "width": "600",
                                        "height": "400",
                                        "caption": "NueGo Bus Lounge - Delhi (Kashmir Gate)"
                                        },
                                        {
                                        "@type": "ImageObject",
                                        "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_2_005c3d597f8262c47773_01e84745d2.png",
                                        "width": "600",
                                        "height": "400",
                                        "caption": "NueGo Bus Lounge - Jaipur (Mansarovar)"
                                        },
                                        {
                                        "@type": "ImageObject",
                                        "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_2_005c3d597f8262c47773_01e84745d2.png",
                                        "width": "600",
                                        "height": "400",
                                        "caption": "NueGo Bus Lounge - Indore (Vijaynagar)"
                                        }
                                    ]
                                    })}
                                    </script>
                                <p>
                                    Available in Select locations, and expanding to more soon!
                                </p>
                            </div>
                            <div className="slider-content mt-3 margin-bottom-64">
                                <div id="carouselExampleCaptions" className="carousel slide">
                                    {/* <div className="carousel-inner"> */}

                                    {isDesktopOrLaptop ? (
                                        <>
                                            <Swiper
                                                className={"py-2 px-1"}
                                                spaceBetween={30}
                                                ref={sliderRef}
                                                keyboard={{
                                                    enabled: true,
                                                }}
                                                breakpoints={{
                                                    1024: {
                                                        slidesPerView: 3,
                                                    },
                                                    768: {
                                                        slidesPerView: 2,
                                                    },
                                                    640: {
                                                        slidesPerView: 1,
                                                    },
                                                }}
                                            >
                                                {LoadingSpinerMain?.Response != "error" ?

                                                    filteredLounges.map((item, index) => {
                                                        return (
                                                            <SwiperSlide key={index} className="box-1">
                                                                <img
                                                                    src={item?.attributes?.cityLogo}
                                                                    alt={''}
                                                                    className="slider-image"
                                                                />

                                                                <div className="p-4">
                                                                    <h3>{item?.attributes?.name}</h3>
                                                                    <div className="icon d-flex mt-3 link-opacity-100-hover">
                                                                        <img
                                                                            src={locationPin}
                                                                            alt="icon"
                                                                            className={"icon-24"}
                                                                        />
                                                                        <a href={item?.attributes?.cityLogoAltText} target="_blank" className="link-opacity-100-hover" >{item?.attributes?.address}</a>

                                                                    </div>
                                                                    {/* <p className={"mb-3"}>{item?.attributes?.address}</p> */}
                                                                    <div className="icon d-flex mt-3 link-opacity-100-hover">
                                                                        <img
                                                                            src={phoneIcon}
                                                                            alt="icon"
                                                                            className={"icon-24"}
                                                                        />
                                                                        <a href= {`tel:${item?.attributes?.phoneNo}`} >{item?.attributes?.phoneNo}</a>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        );
                                                    })
                                                    :
                                                    sliderList1.map((item, index) => {
                                                        return (
                                                            <SwiperSlide key={index} className="box-1">
                                                                <img
                                                                    src={item.image}
                                                                    alt="about"
                                                                    className="slider-image"
                                                                />
                                                                <div className="p-4">
                                                                    <h2>{item.place}</h2>
                                                                    <p className={"mb-3"}>{item.address}</p>
                                                                    <div className="icon d-flex">
                                                                        <img
                                                                            src={phoneIcon}
                                                                            alt="icon"
                                                                            className={"icon-24"}
                                                                        />
                                                                        <p className={"mb-0"}>{item.contact}</p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        );
                                                    })}
                                            </Swiper>
                                        </>
                                    ) : (
                                        <Swiper
                                            className={"py-2 px-1"}
                                            spaceBetween={30}
                                            ref={sliderRef}
                                            keyboard={{
                                                enabled: true,
                                            }}
                                            navigation={true}
                                            Pagination={true}
                                            modules={[Keyboard, Pagination, Navigation]}
                                        >
                                           {LoadingSpinerMain?.Response != "error" ?

                                                filteredLounges.map((item, index) => {
                                                    return (
                                                        <SwiperSlide key={index} className="box-1">
                                                            <img
                                                                src={item?.attributes?.cityLogo}
                                                                alt={item?.attributes?.cityLogoAltText}
                                                                className="slider-image"
                                                            />

                                                            <div className="p-4">
                                                                <h3>{item?.attributes?.name}</h3>
                                                                <div className="icon d-flex">
                                                                        <img
                                                                            src={locationPin}
                                                                            alt="icon"
                                                                            className={"icon-24"}
                                                                        />
                                                                         <a href={item?.attributes?.cityLogoAltText} target="_blank" className="link-opacity-100-hover" >{item?.attributes?.address}</a>

                                                                    </div>
                                                                <div className="icon d-flex">
                                                                    <img
                                                                        src={phoneIcon}
                                                                        alt="icon"
                                                                        className={"icon-24"}
                                                                    />
                                                                    <a href= {`tel:${item?.attributes?.phoneNo}`} >{item?.attributes?.phoneNo}</a>                               
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    );
                                                })
                                                :
                                                sliderList1.map((item, index) => {
                                                    return (
                                                        <SwiperSlide key={index} className="box-1">
                                                            <img
                                                                src={item.image}
                                                                alt="about"
                                                                className="slider-image"
                                                            />
                                                            <div className="p-4">
                                                                <h1>{item.place}</h1>
                                                                <h2 className={"mb-3"}>{item.address}</h2>
                                                                <div className="icon d-flex">
                                                                    <img
                                                                        src={phoneIcon}
                                                                        alt="icon"
                                                                        className={"icon-24"}
                                                                    />
                                                                    <a href= {`tel:${item?.contact}`} >{item?.contact}</a>
                                                                   
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    );
                                                })}
                                        </Swiper>
                                    )
                                    }
                                    { filteredLounges.length>3 && (
                                        <div className="control-icon gap-24">
                                            {isDesktopOrLaptop ? (
                                                <>
                                                    <button
                                                        onClick={handlePrev}
                                                        className="carousel-control-prev-sec me-2"
                                                        type="button"
                                                        data-bs-target="#carouselExampleCaptions"
                                                        data-bs-slide="prev"
                                                    >
                                                        <div className="right-icon">
                                                            <img src={Vector1} alt="Vector" />
                                                        </div>
                                                    </button>
                                                    <button
                                                        onClick={handleNext}
                                                        className="carousel-control-prev-sec"
                                                        type="button"
                                                        data-bs-target="#carouselExampleCaptions"
                                                        data-bs-slide="next"
                                                    >
                                                        <div className="right-icon">
                                                            <img src={Vector2} alt="Vector" />
                                                        </div>
                                                    </button>
                                                </>
                                            ) : (
                                                <Swiper

                                                    className={"py-2 px-1"}
                                                    spaceBetween={30}
                                                    ref={sliderRef}
                                                    keyboard={{
                                                        enabled: true,
                                                    }}
                                                    breakpoints={{
                                                        1024: {
                                                            slidesPerView: 3,
                                                        },
                                                        768: {
                                                            slidesPerView: 2,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                        },
                                                    }}
                                                >
                                                </Swiper>
                                            )
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                isDesktopOrLaptop ? "location-section" : "location-section "
                            }
                        >
                            <img src={RTImage} alt="RE" />
                            <img src={RTImage} alt="RE" className="bg-image" />
                            <div className="location-section-list">
                                <div className="row gy-3 list-items">
                                    <div
                                        className="col-12 col-lg-3 pr-0 col-md-12 d-flex justify-content-center align-items-center">
                                        <div className="text-area ">
                                            <h2 className={" mb-0"}>
                                                Coming Soon near your location
                                            </h2>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            "col-12 col-lg-9 col-md-12 d-flex justify-content-center"
                                        }
                                    >
                                        {
                                            LoadingSpinerMain?.Response != "error" ?
                                                <div className={isDesktopOrLaptop ? "cominglist-box" : "col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-2 "}>
                                                    {ComingSoonLoungeData.map((item, index) => {
                                                        return <div key={index} className="cominglist text-center">
                                                            <img src={item?.attributes?.cityLogo} alt="Coming Soon NueGo Lounges" />
                                                            <h2 className="mb-0">{item?.attributes?.areaName} </h2>
                                                        </div>
                                                    })}
                                                </div>
                                                :
                                                <div className={"row py-lg-0 pt-3 w-100 "}>
                                                    <div className="col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-2">
                                                        <div className="list1 text-center">
                                                            <h2 className="mb-0">Bhopal</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-3">
                                                        <div className="list1 text-center">
                                                            <h2>Delhi</h2>
                                                            <p>RK Ashram</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 pb-3 pb-lg-0 w-100 list-i-4">
                                                        <div className="list1 text-center">
                                                            <h2>Chennai</h2>
                                                            {/* <p>Kashmiri Gate</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
                </Suspense>
                </AuthModalLayout >  
                ) : (
    <MobileSubScreenLayout title={"Nuego Lounge"} back={() => navigate(-1)}>
         <Helmet>
                    <title>Bus Travel Lounges in India | NueGo</title>
                    <link rel="canonical" href="https://www.nuego.in/lounge" hreflang="en-IN" />
                    <meta name="description" content="Find comfortable bus travel lounges with clean washrooms, free wifi, cafe, charging points and AC. Find NueGo lounges in jaipur, delhi and indore."/>
                    <meta name="keywords" content="travel lounge,lounges,jaipur lounge,delhi lounge,indore lounge"></meta>
                    <meta name="robots" content="index,follow"/>
                    <script type='application/ld+json'>
                    {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "url": "https://www.nuego.in/lounge",
                    "name": "NueGo Lounge",
                    "description": "Find comfortable bus travel lounges with clean washrooms, free wifi, cafe, charging points and AC. Find NueGo lounges in jaipur, delhi and indore. ",
                    "publisher": {
                        "@type": "Organization",
                        "name": "NueGo",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                        "width": 200,
                        "height": 100
                        }
                    }
                    })}
                    </script>
        </Helmet>

        <div className={"lounge-page"}>
            <div className={"white-fold mt-0  pb-5"}>
                <div className="content">
                    <div className="lounge-section">
                        <div className="brand-section">
                            <Link to="/">
                            <p className="p1">Home</p>
                            </Link>
                            <p className="px-1">/</p>
                            <p className="p2">NueGo Lounge</p>
                            <script type='application/ld+json'>
                            {JSON.stringify({
                            "@context": "http://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.nuego.in"
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Lounge",
                                "item": "https://www.nuego.in/lounge"
                                }
                            ]
                            })}
                            </script>
                        </div>
                        <div className="search-section">
                            <div className="text-section">
                                <h1>NueGo Lounge</h1>
                            </div>
                            {/* <div className="search-bar-section">
                                <form className="form">
                                    <input
                                        type="text"
                                        placeholder="Search for your city"
                                        className="search-area"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                    <button>
                                        <img src={search} alt="" />
                                    </button>
                                </form>
                            </div> */}
                        </div>
                    </div>

                    <div className="image-section">
                        <div className="image1">
                            <img src={nuegoLounge} alt="NueGo Premium Lounge" className="w-100" />
                        </div>
                        <div className="para-section">
                            <p>
                                Safety and Comfort are the two most important focus areas
                                for NueGo, and our premium Lounges were designed with that
                                in mind. Now our Guests can enjoy the wide range of
                                amenities in one of our Lounges while they waiting to board
                                their NueGo ride!
                            </p>
                        </div>
                        <div className="service-section text-center margin-bottom-64">
                            <h3 className="mb-4">Amenities available</h3>
                            <div className="service-image-section">
                                {servicesList.map((item, index) => {
                                    return (
                                        <div className="image1" key={index}>
                                            <img src={item.image} alt={item.altImage}/>
                                            <p>{item.name}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="slider-section">
                        <div className="text-area mb-5">
                            <h2>Locations</h2>
                            <script type='application/ld+json'>
                                {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "ImageGallery",
                                "image": [
                                    {
                                    "@type": "ImageObject",
                                    "url": "https://cdn.nuego.co.in/greencell/assets/images/static-images/home.jpg",
                                    "width": "600",
                                    "height": "400",
                                    "caption": "NueGo Bus Lounge Over India "
                                    },
                                    {
                                    "@type": "ImageObject",
                                    "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_1_de1b05420d60cdca9a1e_8e6133ffab.png",
                                    "width": "600",
                                    "height": "400",
                                    "caption": "NueGo Bus Lounge - Delhi (Kashmir Gate)"
                                    },
                                    {
                                    "@type": "ImageObject",
                                    "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_2_005c3d597f8262c47773_01e84745d2.png",
                                    "width": "600",
                                    "height": "400",
                                    "caption": "NueGo Bus Lounge - Jaipur (Mansarovar)"
                                    },
                                    {
                                    "@type": "ImageObject",
                                    "url": "https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/lounge_2_005c3d597f8262c47773_01e84745d2.png",
                                    "width": "600",
                                    "height": "400",
                                    "caption": "NueGo Bus Lounge - Indore (Vijaynagar)"
                                    }
                                ]
                                })}
                            </script>
                            <p>
                                Available in Select locations, and expanding to more soon!
                            </p>
                        </div>
                        <div className="slider-content mt-3 margin-bottom-64">
                            <div id="carouselExampleCaptions" className="carousel slide">
                                {/* <div className="carousel-inner"> */}

                                {isDesktopOrLaptop ? (
                                    <>
                                        <Swiper
                                            className={"py-2 px-1"}
                                            spaceBetween={30}
                                            ref={sliderRef}
                                            keyboard={{
                                                enabled: true,
                                            }}
                                            breakpoints={{
                                                1024: {
                                                    slidesPerView: 3,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                640: {
                                                    slidesPerView: 1,
                                                },
                                            }}
                                        >
                                            {LoadingSpinerMain?.Response != "error" ?

                                                filteredLounges.map((item, index) => {
                                                    return (
                                                        <SwiperSlide key={index} className="box-1">
                                                            <img
                                                                src={item?.attributes?.cityLogo}
                                                                alt={item?.attributes?.cityLogoAltText}
                                                                className="slider-image"
                                                            />
                                                            <div className="p-4">
                                                                <h3>{item?.attributes?.name}</h3>
                                                                <div className="icon d-flex">
                                                                    <img
                                                                            src={phoneIcon}
                                                                            alt="icon"
                                                                            className={"icon-24"}
                                                                    />
                                                                    <a href={item?.attributes?.cityLogoAltText} target="_blank" className="link-opacity-100-hover" >{item?.attributes?.address}</a> 
                                                                </div>       
                                                                <div className="icon d-flex">
                                                                    <img
                                                                        src={phoneIcon}
                                                                        alt="icon"
                                                                        className={"icon-24"}
                                                                    />
                                                                    <a href= {`tel:${item?.attributes?.phoneNo}`} >{item?.attributes?.phoneNo}</a>        
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    );
                                                })
                                                :
                                                sliderList1.map((item, index) => {
                                                    return (
                                                        <SwiperSlide key={index} className="box-1">
                                                            <img
                                                                src={item.image}
                                                                alt="about"
                                                                className="slider-image"
                                                            />
                                                            <div className="p-4">
                                                                <h1>{item.place}</h1>
                                                                <h2 className={"mb-3"}>{item.address}</h2>
                                                                <div className="icon d-flex">
                                                                    <img
                                                                        src={phoneIcon}
                                                                        alt="icon"
                                                                        className={"icon-24"}
                                                                    />
                                                                    <a href= {`tel:${item?.contact}`} >{item?.contact}</a>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    );
                                                })}
                                        </Swiper>
                                    </>
                                ) : (
                                    <Swiper

                                        className={"py-2 px-1"}
                                        spaceBetween={30}
                                        ref={sliderRef}
                                        keyboard={{
                                            enabled: true,
                                        }}
                                        navigation={true}
                                        Pagination={true}
                                        modules={[Keyboard, Pagination, Navigation]}

                                    >
                                        {LoadingSpinerMain?.Response != "error" ?

                                            filteredLounges.map((item, index) => {
                                                return (
                                                    <SwiperSlide key={index} className="box-1">
                                                        {/* {LoadingSpinerMain?.value == true ? <Loader />
                                           */}
                                                        <img
                                                            src={item?.attributes?.cityLogo}
                                                            alt={item?.attributes?.cityLogoAltText}
                                                            className="slider-image"
                                                        />

                                                        <div className="p-4">
                                                            <h3>{item?.attributes?.name}</h3>
                                                            <div className="icon d-flex mt-3">
                                                            <img
                                                                    src={locationPin}
                                                                    alt="icon"
                                                                    className={"icon-24"}
                                                                />
                                                            <a href={item?.attributes?.cityLogoAltText} target="_blank" className="link-opacity-100-hover" >{item?.attributes?.address}</a>
                                                            </div>
                                                            
                                                            {/* <p className={"mb-3"}>{item?.attributes?.address}</p> */}
                                                            <div className="icon d-flex mt-3">
                                                                <img
                                                                    src={phoneIcon}
                                                                    alt="icon"
                                                                    className={"icon-24"}
                                                                />
                                                                <a href= {`tel:${item?.attributes?.phoneNo}`}>{item?.attributes?.phoneNo}</a>
                                                               
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })
                                            :
                                            sliderList1.map((item, index) => {
                                                return (
                                                    <SwiperSlide key={index} className="box-1">
                                                        <img
                                                            src={item.image}
                                                            alt="about"
                                                            className="slider-image"
                                                        />
                                                        <div className="p-4">
                                                            <h1>{item.place}</h1>
                                                            <h2 className={"mb-3"}>{item.address}</h2>
                                                            <div className="icon d-flex">
                                                                <img
                                                                    src={phoneIcon}
                                                                    alt="icon"
                                                                    className={"icon-24"}
                                                                />
                                                                <a href= {`tel:${item?.contact}`} >{item?.contact}</a>
                                                                
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            })}
                                    </Swiper>
                                )

                                }
                                {/* </div> */}

                                {sliderList.length > 3 && (
                                    <div className="control-icon gap-24">
                                        {isDesktopOrLaptop ? (
                                            <>
                                                <button
                                                    onClick={handlePrev}
                                                    className="carousel-control-prev-sec me-2"
                                                    type="button"
                                                    data-bs-target="#carouselExampleCaptions"
                                                    data-bs-slide="prev"
                                                >
                                                    <div className="right-icon">
                                                        <img src={Vector1} alt="Vector" />
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={handleNext}
                                                    className="carousel-control-prev-sec"
                                                    type="button"
                                                    data-bs-target="#carouselExampleCaptions"
                                                    data-bs-slide="next"
                                                >
                                                    <div className="right-icon">
                                                        <img src={Vector2} alt="Vector" />
                                                    </div>
                                                </button>
                                            </>
                                        ) : (
                                            <Swiper

                                                className={"py-2 px-1"}
                                                spaceBetween={30}
                                                ref={sliderRef}
                                                keyboard={{
                                                    enabled: true,
                                                }}
                                                breakpoints={{
                                                    1024: {
                                                        slidesPerView: 3,
                                                    },
                                                    768: {
                                                        slidesPerView: 2,
                                                    },
                                                    640: {
                                                        slidesPerView: 1,
                                                    },
                                                }}
                                            >
                                            </Swiper>
                                        )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={
                            isDesktopOrLaptop ? "location-section" : "location-section "
                        }
                    >
                        <img src={RTImage} alt="RE" />
                        <img src={RTImage} alt="RE" className="bg-image" />
                        <div className="location-section-list">
                            <div className="row gy-3 list-items">
                                <div
                                    className="col-12 col-lg-3 pr-0 col-md-12 d-flex justify-content-center align-items-center">
                                    <div className="text-area ">
                                        <h2 className={" mb-0"}>
                                            Coming Soon near your location
                                        </h2>
                                    </div>
                                </div>

                                <div
                                    className={
                                        "col-12 col-lg-9 col-md-12 d-flex justify-content-center"
                                    }
                                >
                                    {
                                        LoadingSpinerMain?.Response != "error" ?
                                            <div className={isDesktopOrLaptop ? "cominglist-box" : "col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-2 "}>
                                                {ComingSoonLoungeData.map((item, index) => {

                                                    return <div key={index} className="cominglist text-center">


                                                        <img src={item?.attributes?.cityLogo} alt="Coming Soon NueGo Lounges" />
                                                        <h2 className="mb-0">{item?.attributes?.areaName} </h2>

                                                    </div>
                                                })}
                                            </div>
                                            :
                                            <div className={"row py-lg-0 pt-3 w-100 "}>
                                                <div className="col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-2">
                                                    <div className="list1 text-center">
                                                        <h2 className="mb-0">Bhopal</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4  pb-3 pb-lg-0 w-100 list-i-3">
                                                    <div className="list1 text-center">
                                                        <h2>Delhi</h2>
                                                        <p>RK Ashram</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-4 pb-3 pb-lg-0 w-100 list-i-4">
                                                    <div className="list1 text-center">
                                                        <h2>Chennai</h2>
                                                        {/* <p>Kashmiri Gate</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    </MobileSubScreenLayout>
    )         
    );                                
};


export default Lounge;
