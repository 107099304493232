import {
	CLEAR_ON_CHECKOUT,
	CLEAR_USER_DATA,
	FILTER_MODAL_ACTION,
	POST_BOOKING_DATA,
	SET_MOBILE_PAGE,
	SET_RESCHEDULE, SET_SELECTED_BOOKING_ID,
	SET_RECENT_SEARCH, SET_VERSION, SET_TERMS_CONDITIONS_MODAL,
	SET_CHECKLIST_NAME,
	SET_LOADING_STATE,
	SET_GREEN_MILES_STATE
} from "./index";

export const setMobilePage = (data) => ({
	type: SET_MOBILE_PAGE,
	payload: {
		data: data
	}
})

export const clearOnCheckout = () => ({
	type: CLEAR_ON_CHECKOUT,
})

export const clearUserData = () => ({
	type: CLEAR_USER_DATA,
})

export const postBookingDataAction = (data) => ({
	type: POST_BOOKING_DATA,
	payload: {
		data: data
	}
})

export const filterModalAction = (data) => ({
	type: FILTER_MODAL_ACTION,
	payload: {
		data: data
	}
})

export const setRescheduleAction = (data) => ({
	type: SET_RESCHEDULE,
	payload: {
		data: data

	}
})

export const setSelectedBookingIdAction = (data) => ({
	type: SET_SELECTED_BOOKING_ID,
	payload: {
		data: data

	}
})

export const setRecentSearchAction = (data) => ({
	type: SET_RECENT_SEARCH,
	payload: {
		data: data
	}
})

export const setVersionAction = (data) => ({
	type: SET_VERSION,
	payload: {
		data: data
	}
})

export const setTermsConditionsModalAction = (data) => ({
	type: SET_TERMS_CONDITIONS_MODAL,
	payload: {
		data: data
	}
})

export const setCheckListNameAction = (data) => ({
	type: SET_CHECKLIST_NAME,
	payload: {
		data: data
	}
})

export const setLoadingStateAction = (data) => ({
	type: SET_LOADING_STATE,
	payload: {
		data: data
	}
})

export const setGreenMilesStateAction = (data) => ({
	type: SET_GREEN_MILES_STATE,
	payload: {
		data: data
	}
})