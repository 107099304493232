/**
 * @generated SignedSource<<e6290913bf4eda5253da907aa2873a4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gstInvoice"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gstRegisteredName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gstState"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gstin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notifyViaWhatsapp"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passengerDetails"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paymentMode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "phoneNumber"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bookingId",
        "variableName": "bookingId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "gstInvoice",
        "variableName": "gstInvoice"
      },
      {
        "kind": "Variable",
        "name": "gstRegisteredName",
        "variableName": "gstRegisteredName"
      },
      {
        "kind": "Variable",
        "name": "gstState",
        "variableName": "gstState"
      },
      {
        "kind": "Variable",
        "name": "gstin",
        "variableName": "gstin"
      },
      {
        "kind": "Variable",
        "name": "notifyViaWhatsapp",
        "variableName": "notifyViaWhatsapp"
      },
      {
        "kind": "Variable",
        "name": "passengerDetails",
        "variableName": "passengerDetails"
      },
      {
        "kind": "Variable",
        "name": "paymentMode",
        "variableName": "paymentMode"
      },
      {
        "kind": "Variable",
        "name": "phoneNumber",
        "variableName": "phoneNumber"
      }
    ],
    "concreteType": "BookingCompleteMutation",
    "kind": "LinkedField",
    "name": "bookingComplete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isBookingCompleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PaymentType",
        "kind": "LinkedField",
        "name": "payment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingType",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemOrderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jsonData",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookingCompleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookingCompleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c285c74372bfb02cd82501ed86c28d18",
    "id": null,
    "metadata": {},
    "name": "BookingCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookingCompleteMutation(\n  $bookingId: String!\n  $email: String!\n  $gstInvoice: Boolean\n  $gstRegisteredName: String\n  $gstState: String\n  $gstin: String\n  $notifyViaWhatsapp: Boolean\n  $passengerDetails: [PassengerDetailsInput]\n  $paymentMode: Int!\n  $phoneNumber: String!\n) {\n  bookingComplete(bookingId: $bookingId, email: $email, gstInvoice: $gstInvoice, gstRegisteredName: $gstRegisteredName, gstState: $gstState, gstin: $gstin, notifyViaWhatsapp: $notifyViaWhatsapp, passengerDetails: $passengerDetails, paymentMode: $paymentMode, phoneNumber: $phoneNumber) {\n    isBookingCompleted\n    payment {\n      order {\n        id\n      }\n      itemOrderId\n      paymentId\n      jsonData\n      paymentUrl\n    }\n  }\n}\n"
  }
};
})();

node.hash = "336c46b0cda7bea6766f9157d20a59b5";

module.exports = node;
