/**
 * @generated SignedSource<<f193d92aa00dc85b738ed697b10d7a25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceNo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "referenceNo",
        "variableName": "referenceNo"
      }
    ],
    "concreteType": "DefaultCouponDetailsType",
    "kind": "LinkedField",
    "name": "getCouponsByReferenceNo",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountSchemeName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountDetails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fromDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "toDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imagePath",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "partnerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "applyFor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCouponByReferenceNumberQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetCouponByReferenceNumberQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d263c81688699b6e425a0ba2d4b4ed9d",
    "id": null,
    "metadata": {},
    "name": "GetCouponByReferenceNumberQuery",
    "operationKind": "query",
    "text": "query GetCouponByReferenceNumberQuery(\n  $referenceNo: String!\n) {\n  getCouponsByReferenceNo(referenceNo: $referenceNo) {\n    discountId\n    discountSchemeName\n    discountDetails\n    fromDate\n    toDate\n    offerCode\n    discountType\n    imagePath\n    partnerId\n    applyFor\n  }\n}\n"
  }
};
})();

node.hash = "e55c9317c33d543f50c036b9e41561bf";

module.exports = node;
