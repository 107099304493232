import environment from '../../environment';
import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";

const mutation = graphql`
    mutation BookingCompleteMutation(
        $bookingId: String!,
        $email: String!,
        $gstInvoice: Boolean,
        $gstRegisteredName: String ,
        $gstState: String ,
        $gstin: String,
        $notifyViaWhatsapp: Boolean,
        $passengerDetails: [PassengerDetailsInput],
        $paymentMode: Int!,
        $phoneNumber: String!) {
        bookingComplete(
            bookingId:$bookingId,
            email:$email,
            gstInvoice:$gstInvoice,
			gstRegisteredName:$gstRegisteredName ,
            gstState: $gstState,
            gstin:$gstin,
            notifyViaWhatsapp:$notifyViaWhatsapp,
            passengerDetails:$passengerDetails,
            paymentMode:$paymentMode,
            phoneNumber:$phoneNumber ,
        ){
            isBookingCompleted
            payment {
                order{
                    id
                }
                itemOrderId
                paymentId
                jsonData
                paymentUrl
            }
        }

    }`

export default (data, callback, errCallback) => {
    const variables = {
        bookingId: data.bookingId,
        email: data.email,
        gstInvoice: data.gstInvoice,
        notifyViaWhatsapp: data.notifyViaWhatsapp,
        passengerDetails: data.passengerDetails,
        paymentMode: data.paymentMode,
        phoneNumber: data.phoneNumber,
        gstRegisteredName: data.gstRegisteredName,
        gstState: data.gstState,
        gstin: data.gstin,
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.bookingComplete !== null) {
                    callback(response)
                } else {
                    errCallback(err[0].message);
                }

            },
            onError: err => {

                errCallback(err);
            },
        },
    )
}

