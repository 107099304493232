import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
    mutation UpdateCheckListItemAndReminderMutation(
        $checkListId:String!,
        $isAadhaar:Boolean!,
        $isPrescription:Boolean!,
        $checkListItems:[UpdateChecklistItemInput],
        $checkListReminder:[UpdateCheckListReminderInput],
    ){
        updateCheckListItemAndReminder(
            checkListId:$checkListId,
            isAadhaar:$isAadhaar,
            isPrescription:$isPrescription,
            checkListItems:$checkListItems,
            checkListReminder:$checkListReminder,
        ){
            status
            message
            checkList{
            id
            name
            isReminder
            isAadhaar
            isPrescription
            checklistitemSet{
                edges{
                node{
                    id
                    itemName
                    isChecked
                }
                }
            }
            checklistreminderSet{
                edges{
                node{
                    id
                    reminderDate
                    reminderTime
                }
                }
            }
            }
        }
    }
    `;
export default (variables, callback, errCallback) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.updateCheckListItemAndReminder !== null) {
                    callback(response);
                } else {
                    errCallback(response);
                }
            },
            onError: err => {
                errCallback(err);
            },
        },
    );
}