import React, {useEffect, useState} from 'react'
import "./TermConditions.scss"
import {useMediaQuery} from "react-responsive";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate} from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import arrowDown from "../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg"
import Loader from "../../components/Loader/Loader";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const TermConditions = () => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1024px)'
	})
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo(0, 0)
		return () => {

		};
	}, []);
	return (
		<div className={'term-condition'}>

				<MobileSubScreenLayout back={() => navigate(-1)} title={"Terms and Conditions"}>
					 <div className={'px-2'}>
					<MainContent />
					</div>
				</MobileSubScreenLayout>

		</div>
	)
}


export const MainContent = () => {


    const [LoadingSpiner, setLoadingSpiner] = useState({ value: true, Response: "pending" })
    const [PrivacyPoliciesData, setPrivacyPoliciesData] = useState([])
    const [expanded, setExpanded] = useState("");


    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }




    const PrivacyPoliciesGetApi = () => {
        setLoadingSpiner(pendingObject)

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/terms-and-conditions?sort=rank:asc`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpiner(errorObject)
                    } else {
                        setLoadingSpiner(sucessObject)
                        setPrivacyPoliciesData(res?.data)
                    }
                    return res;
                })
            .catch((err) => {
                setLoadingSpiner(errorObject)
                return err;
            });
    }


    useEffect(() => {
        PrivacyPoliciesGetApi()
    }, [])


    return (
        <>
            {LoadingSpiner?.value == true ? <Loader /> : LoadingSpiner?.Response != "error" ?
                <div className={'privacy-policy'}>
                    {PrivacyPoliciesData.map((items, id) => {
                        return <div className={'custom-accordion'}>
                            <motion.div
                                initial={false}
                                className={id + 1 === expanded ? 'header-active' : 'header'}
                                // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                                onClick={() => setExpanded(id + 1 === expanded ? "" : id + 1)}
                            >
                                <p>{id + 1}. {items?.attributes?.topic}</p>
                                <motion.div animate={{ rotate: expanded === id + 1 ? 180 : 0 }}>
                                    <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                                </motion.div>
                            </motion.div>
                            <AnimatePresence initial={false}>
                                {id + 1 === expanded && (
                                    <motion.div
                                        key="content" className={'content'}
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                        variants={{
                                            open: { opacity: 1, height: "auto" },
                                            collapsed: { opacity: 0, height: 0 }
                                        }}
                                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        <p>
										<Markdown
 children={items?.attributes?.description} remarkPlugins={[remarkGfm]} />
                                        </p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    })}
                </div> :
					<>
                	<div className={'custom-accordion'}>
                    <motion.div
                        initial={false}
                        className={"2" === expanded ? 'header-active' : 'header'}
                        // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                        onClick={() => setExpanded("2" === expanded ? "" : "2")}
                    >
                        <p>II. WHAT PERSONAL DATA DO WE COLLECT?</p>
                        <motion.div animate={{ rotate: expanded === "2" ? 180 : 0 }}>
                            <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                        </motion.div>
                    </motion.div>
                    <AnimatePresence initial={false}>
                        {"2" === expanded && (
                            <motion.div
                                key="content" className={'content'}
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 }
                                }}
                                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                            >
                                <p>
                                    <strong>Information Provided to Us:</strong> Unless otherwise agreed with You, we will
                                    collect only the basic
                                    personal data required in connection with our business relationship. This includes Your
                                    contact and personal data (such as name and job title, date of birth, marital status,
                                    anniversary date, UID details, KYC documents, address, mobile number, email address, other
                                    contact details, mobile device unique identifier and the IP address of your computer, if You
                                    use our Sites, online platforms or apps). While doing business with You, we will also
                                    collect business-related data which may include information on purchases, services and other
                                    business activities, product feedback and any other information You may provide to us. We
                                    also collect financial information such as bank account details, medical records and
                                    history, and biometric information from our employees. Please note that we shall not be
                                    liable for any incorrect information provided by You or any misrepresentation at your
                                    end.</p>
                                <p><strong>Information We Collect Automatically When You Use our Sites and
                                    Services:</strong> When you visit our
                                    Sites, we automatically collect some system data and personal data about you, which
                                    includes: </p>
                                <ul>
                                    <li>information about your computer or mobile device you use to access our Sites, including
                                        your IP address, device type, operating system, time zone setting and location, access
                                        time, and browser type and version, browser plug in types and versions, operating system
                                        and platform and other details about the devices you use to access the Sites;
                                    </li>
                                    <li>information necessary to coordinate your bookings;</li>
                                    <li>details of Your interactions with customer service, such as the date, time and reason
                                        for contacting us, transcripts of any chat conversations, and if You call us, Your phone
                                        number and call recordings; and
                                    </li>
                                    <li>video surveillance footage from our buses and lounges.</li>
                                </ul>
                                <p>
                                    <strong>Social Network Plugins:</strong> Our Sites incorporates plugins and/or buttons for
                                    social networks,
                                    in order to allow easy sharing on your favourite social networks. These plugins are
                                    programmed so as not to set any cookies when accessing the page to safeguard the privacy of
                                    users. Cookies may be set, if you make voluntary use of the plugin. The collection and use
                                    of information obtained by means of the plugin are governed by the respective privacy
                                    policies of the social networks.
                                    <br />
                                    <br />
                                    <strong>Links to Third Party Site/Apps:</strong> Our Sites may, from time to time, contain
                                    links to and from
                                    other websites of third parties. Please note that if you follow a link to any of these
                                    websites, such websites will apply different terms to the collection and privacy of your
                                    personal data and we do not accept any responsibility or liability for these policies. When
                                    you leave our Site, we encourage you to read the privacy policy of every website you visit.
                                    <br />
                                    <br />
                                    <strong>Market research & consumer feedback:</strong> Any information that You voluntarily
                                    share with
                                    us about your experience of using our products and services may be used for market
                                    research and consumer feedback related purposes.
                                    <br />
                                    <br />
                                    <strong>Payment and Financial information:</strong> We do not collect or store your
                                    financial information
                                    other than from employees for the purposes of payroll processing. For the purposes of
                                    payment mechanism for fulfilling an order, purchase or booking, we engage only PCI DSS
                                    (Payment Card Industry Data Security Standard) compliant payment gateway providers, who
                                    are authorised to handle all payment and financial transactions in accordance with law.
                                </p>
                            </motion.div>
                        )}
                    </AnimatePresence>
					</div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"3" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("3" === expanded ? "" : "3")}
                        >
                            <p>III. WHAT IS THE PURPOSE OF COLLECTING PERSONAL DATA?
                            </p>
                            <motion.div animate={{ rotate: expanded === "3" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"3" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        The personal data which we collect from you may be collected, used, disclosed and/or
                                        processed for various purposes, depending on the circumstances for which we may/will need to
                                        process your personal data, including</p>
                                    <ul>
                                        <li>to communicate with you</li>
                                        <li>to maintain and improve customer relationship;</li>
                                        <li>to assess, process and provide products, services and/or facilities to You;</li>
                                        <li>to enter into or perform the terms of the contract with You;</li>
                                        <li>to establish your identity and background;</li>
                                        <li>for employment purposes such as payrolling and attendance;</li>
                                        <li>to comply with the requirements stipulated under applicable law and government
                                            regulations
                                        </li>
                                        <li>to ensure the safety and security of our customers while travelling;</li>
                                        <li>to respond to your enquiries or complaints and resolve any issues and disputes which may
                                            arise in connection with any dealings/transactions with us;
                                        </li>
                                        <li>to provide you with information and/or updates on our products, services, upcoming
                                            promotions offered by us and/or events organized by us and selected third parties which
                                            may be of interest to you from time to time;
                                        </li>
                                        <li>to maintain and update internal record keeping (including records of sales, purchases
                                            and any transactions) taxation, accounts and administrative purposes;
                                        </li>
                                        <li>for research and development purposes, including improving our Sites, applications,
                                            services and products offered;
                                        </li>
                                        <li>to monitor, review and improve our events and promotions, products and/or services;</li>
                                        <li>to conduct market research or surveys, internal marketing analysis, customer profiling
                                            activities, analysis of customer patterns and choices, planning and statistical and
                                            trend analysis in relation to our products and/or services;
                                        </li>
                                        <li>to process and analyse your personal data either individually or collectively with other
                                            individuals;
                                        </li>
                                        <li>for detecting, investigating and preventing fraudulent, prohibited or illegal
                                            activities;
                                        </li>
                                        <li>to use information to achieve a legitimate interest and our reasons for using it
                                            outweigh any prejudice to your data protection rights under applicable law;
                                        </li>
                                        <li>facilitate direct marketing, promotional and customer management purposes, including
                                            sending you promotional communications or special offers if you have consented to
                                            receive the same; and
                                        </li>
                                        <li>for any other purposes for which we have Your consent.</li>
                                    </ul>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"4" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("4" === expanded ? "" : "4")}
                        >
                            <p>IV. WHAT ARE COOKIES AND SUCH OTHER TECHNOLOGIES?
                            </p>
                            <motion.div animate={{ rotate: expanded === "4" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"4" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        Cookies are small text files placed on your device to store data that can be recalled by a
                                        web server in the domain that placed the cookie. We use cookies and similar technologies for
                                        storing and honouring your preferences and settings, enabling you to sign in, providing
                                        interest-based advertising, combating fraud, analysing how our products perform and
                                        fulfilling other legitimate purposes. We use cookies for the following purposes:
                                    </p>
                                    <ul>
                                        <li>Necessary cookies – these cookies are required for You to be able to use some important
                                            features on our Sites, such as logging in. These cookies don’t collect any personal
                                            information.
                                        </li>
                                        <li>Functionality cookies – these cookies provide functionality that makes using our service
                                            more convenient and makes providing more personalised features possible. For example,
                                            they might remember your name and e-mail in comment forms, so you don’t have to re-enter
                                            this information next time when commenting.
                                        </li>
                                        <li>Analytics cookies – these cookies are used to track the use and performance of our Sites
                                            and services.
                                        </li>
                                    </ul>
                                    <p>
                                        You have a variety of tools to control the data collected by cookies, web beacons and
                                        similar technologies. If you choose not to accept cookies, you can still access and use our
                                        Sites. Most browsers automatically accept cookies. You can prevent cookies from being stored
                                        on your device by setting your browser to not accept cookies. You can delete cookies already
                                        on your device at any time. However, if you choose not to accept cookies that are strictly
                                        necessary for the provision of our services provided by our Sites, it may result in a
                                        reduced availability of such services.<br />
                                        We will monitor any emails sent to us, including file attachments, for viruses or malicious
                                        software. Please be aware that you have a responsibility to ensure that any email you send
                                        is within the bounds of the applicable law
                                    </p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"5" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("5" === expanded ? "" : "5")}
                        >
                            <p>V. WHO WILL RECEIVE YOUR PERSONAL DATA?</p>
                            <motion.div animate={{ rotate: expanded === "5" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"5" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        We may share your personal data with other organisations in the following circumstances:
                                    </p>
                                    <ul>
                                        <li> if required by the law, court order, notice, demand for disclosure by any government,
                                            quasi-governmental authorities or agencies or a public authority or regulatory bodies;
                                        </li>
                                        <li>if we need to share personal data in order to establish, exercise or defend our legal
                                            rights (this includes providing personal data to others for the purposes of preventing
                                            fraud);
                                        </li>
                                        <li>when we restructure, sell or transfer our business (or a part of it). For example, in
                                            connection with a takeover or merger;
                                        </li>
                                        <li>with authorised personnel working on behalf of NueGo and its subsidiaries and affiliates
                                            including service providers for data processing and storage; providing You with access
                                            to our services; providing customer support; making decisions about service improvements
                                            and content development;
                                        </li>
                                        <li>with legal and tax advisors, auditors for monitoring and compliance purposes;</li>
                                        <li>authorised personnel with NueGo, its subsidiaries and associates, on a need-to-know
                                            basis for any technological updations or business enhancements;
                                        </li>
                                        <li>to the extent permitted by applicable law, to credit reporting agencies and debt
                                            collectors who are external companies that we use to help us to verify your
                                            creditworthiness (in particular for orders with invoice) or to collect outstanding
                                            invoices; and
                                        </li>
                                        <li>with third party service providers, to provide marketing, advertising, communications,
                                            infrastructure, hosting and IT services, payment gateway providers, to personalize and
                                            optimize our service, to process credit card transactions or other payment methods, to
                                            provide customer service, to collect debts, to analyze and enhance data. We do not
                                            authorize them to use or disclose your personal information except in connection with
                                            providing their services.
                                        </li>
                                    </ul>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"6" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("6" === expanded ? "" : "6")}
                        >
                            <p>VI. HOW LONG WILL YOUR PERSONAL DATA BE RETAINED FOR?</p>
                            <motion.div animate={{ rotate: expanded === "6" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"6" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        We process and store your personal data as long as it is required to provide the products or
                                        services requested by you, for employment and recruitment purposes, to administer your
                                        business relationship with us and to meet the legal and statutory obligations or
                                        compliances. If your personal data is no longer required for the performance of the
                                        contractual or statutory obligations or meet business relations, it will be erased on a
                                        regular basis unless further processing is necessary, for instance, for preserving
                                        particular evidence under the applicable data protection laws and regulations, or in the
                                        context of legal statutes of limitation.</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"7" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("7" === expanded ? "" : "7")}
                        >
                            <p>VII. HOW WE PROTECT YOUR PERSONAL DATA?</p>
                            <motion.div animate={{ rotate: expanded === "7" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"7" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        We use technical and organisational security measures in order to protect the data we have
                                        under our control against accidental or intentional manipulation, loss, disclosure,
                                        destruction and against access by unauthorised persons. Our security procedures are
                                        continually enhanced as new technology becomes available. We take reasonable steps to help
                                        protect your personal information in an effort to prevent the loss, misuse, and unauthorized
                                        access, disclosure alteration and destruction. It is your responsibility to protect your
                                        names and passwords to help prevent anyone from accessing or abusing your accounts and
                                        services. You should not use or reuse the same passwords you use with other accounts as your
                                        password for our services. It is important for you to protect against unauthorized access to
                                        your password and your computers, devices, and applications. Be sure to sign off when you
                                        finish using a shared computer</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"8" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("8" === expanded ? "" : "8")}
                        >
                            <p>VIII. WHAT ARE YOUR RIGHTS?
                            </p>
                            <motion.div animate={{ rotate: expanded === "8" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"8" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        You are entitled to the following rights:</p>
                                    <ul>
                                        <li>right of access the personal data which you have provided to us;</li>
                                        <li>right of rectification of Your personal data if the information is incorrect or
                                            incomplete; and
                                        </li>
                                        <li>any other right available to You under applicable law.</li>
                                    </ul>
                                    <p>
                                        You may also withdraw your consent to receive direct marketing communications, or more
                                        generally to our processing of your personal data, at any time, and you may in certain
                                        circumstances ask us to delete your account. However, we may not be able to continue
                                        providing services to you, if you entirely withdraw your consent or ask us to delete your
                                        account. <br />
                                        We may reject requests that are unreasonable or not required by law, including those that
                                        would be impractical, could require disproportionate technical effort, or could expose us to
                                        operational risks such as free trial fraud. We take reasonable measures to destroy or
                                        de-identify personal information in a secure manner when it is no longer required. Please be
                                        informed that You shall be responsible for informing us of any changes, updates and/or error
                                        in Your data and we shall not be liable for any incorrect information provided by you.
                                    </p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"9" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("9" === expanded ? "" : "9")}
                        >
                            <p>IX. CAN THE PRIVACY POLICY CHANGE?</p>
                            <motion.div animate={{ rotate: expanded === "9" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"9" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        NueGo retains the discretion to update this Privacy Policy at any time. When we do, we will
                                        revise the updated date at the bottom of this page. We encourage users to frequently check
                                        this page for any changes to stay informed about how we are helping to protect the personal
                                        information we collect. You are deemed to have acknowledged and agreed to any amended
                                        version of this Privacy Policy. You acknowledge and agree that it is your responsibility to
                                        review this Privacy Policy periodically and become aware of modifications.</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"10" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("10" === expanded ? "" : "10")}
                        >
                            <p>X. CHILDREN</p>
                            <motion.div animate={{ rotate: expanded === "10" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"10" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        If you are under 18, or under the age of majority in the jurisdiction in which you reside or
                                        applicable to you, you may use Our Sites with the involvement of a parent or guardian. In
                                        any case, we will not be liable for any cause of action that arises due to non-compliance
                                        with this section.</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"11" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("11" === expanded ? "" : "11")}
                        >
                            <p>XI. NEWSLETTER</p>
                            <motion.div animate={{ rotate: expanded === "11" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"11" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        Following subscription to our newsletter, your e-mail address is used for our advertising
                                        purposes until you cancel the newsletter. Cancellation is possible at any time. The
                                        following consent has been expressly granted by you separately, or possibly in the course of
                                        an ordering process: (I am accepting to receive newsletter from this website), you may
                                        revoke your consent at any time using the given option with future effect.</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <div className={'custom-accordion'}>
                        <motion.div
                            initial={false}
                            className={"12" === expanded ? 'header-active' : 'header'}
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("12" === expanded ? "" : "12")}
                        >
                            <p>XII. HOW CAN YOU CONTACT US?
                            </p>
                            <motion.div animate={{ rotate: expanded === "12" ? 180 : 0 }}>
                                <img src={arrowDown} alt={'alt'} className={'icon-24'} />
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"12" === expanded && (
                                <motion.div
                                    key="content" className={'content'}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <p>
                                        If you are unhappy with the way in which your personal data has been processed or should you
                                        have questions regarding the processing of your personal data, or you want to exercise any
                                        of your rights in relation to personal data, you may please write for enquiries or
                                        complaints to the Grievance Officer, at the following email address: <a
                                            href={'mailto:support@nuego.in'}>support@nuego.in</a></p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
					</>        

            }
        </>
    )
}

export default TermConditions