import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";

const mutation = graphql`
    mutation GetBookingDetailsByPnrAndNameMutation($name: String!, $pnrNo: String!) {
        getBookingDetailsByPnrAndName(name: $name, pnrNo: $pnrNo) {
            message
            booking {
                id
                passengerdetailSet {
                    name
                    age
                    gender
                    seatNo
                }
                sourceCityName
                sourceCityId
                destinationCityName
                destinationCityId
                dateOfJourney
                pickupName
                pickupTime
                dropTime
                dropName
                referenceNo
                routeId
                pnrNo
                busNumber
                seatNo
                totalAmount
                seatType
                paymentType
                paymentStatus
                paymentId
                gstInvoice
                duration
                gstAmount
                totalPayable
                getTotalPaidAmount
                totalBaseFare
                qrCode
                expectedDropDatetime
                cancellationTransactionId
                cancellationCharges
                totalRefundedAmount
                isRefunded
                isReviewed
                initialPaymentDate
                initialPaymentTime
                savedEmissions
                greenMilesEarned
                contact {
                    bookingContactDetails {
                        contact {
                            phone
                        }
                    }
                }
            }
        }
    }
`;

export default (data, callback, errCallback) => {
    const variables = {
        name: data.name,
        pnrNo: data.pnrNo,
    };
    commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
            if (errors) {
                errCallback(errors);
            } else {
                callback(response);
            }
        },
        onError: (err) => errCallback(err),
    });
};
