import graphql from "babel-plugin-relay/macro";
// import { gql } from '@apollo/client';

const GetBookingByGuestQuery = graphql`
	query GetBookingByGuestQuery($pnrNo: String!, $name: String!) {
		getBookingByGuest(pnrNo: $pnrNo, name: $name) {
			id
			passengerdetailSet {
				name
				age
				gender
				seatNo
			}
			sourceCityName
			sourceCityId
			destinationCityName
			destinationCityId
			dateOfJourney
			pickupName
			pickupTime
			dropTime
			dropName
			referenceNo
			routeId
			pnrNo
			busNumber
			seatNo
			totalAmount
			seatType
			paymentType
			paymentStatus
			paymentId
			gstInvoice
			duration
			gstAmount
			totalPayable
			getTotalPaidAmount
			totalBaseFare
			qrCode
			expectedDropDatetime
			cancellationTransactionId
			cancellationCharges
			totalRefundedAmount
			isRefunded
			isReviewed
			initialPaymentDate
			initialPaymentTime
			savedEmissions
			greenMilesEarned
			contact {
				bookingContactDetails {
					contact {
						phone
					}
				}
			}
		}
	}
`;

export default GetBookingByGuestQuery