import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../environment'

const mutation = graphql`
	mutation BoardingAndDroppingMutation($referenceNo:String!) {
		getPickupDropPoints(referenceNo:$referenceNo) {
				pickupPoints {
			    id
			    rowOrder
			    Latitude
			    Longitude
			    PickupID
			    PickupName
			    PickupAddress
			    PickupPhone
			    PickupTime
			    LandMark
				PickupDropImage
			}
			dropPoints {
			    id
			    rowOrder
			    Latitude
			    Longitude
			    DropID
			    DropName
			    DropAddress
			    DropPhone
			    DropTime
			    DropLandMark
			    DropLandMark
				PickupDropImage
			}
		}
	}
`


export default (data, callback, errCallback) => {
	const variables = {
		referenceNo: data.referenceNo,
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.getPickupDropPoints !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}