import { useState } from "react";
import { createContext, useContext } from "react";

const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
	const [time, setTime] = useState({});
	const [otpEntered, setOtpEntered] = useState(false);
	const [otpValue, setOtpValue] = useState("");
	const [timer, setTimer] = useState(null);
	const [valid, setValid] = useState(false);
	const [inputDirty, setInputDirty] = useState(0);
    const [activeTab, setActiveTab] = useState("signin");
	return (
		<AuthContext.Provider
			value={{
				time,
				setTime,
				otpEntered,
				setOtpEntered,
				otpValue,
				setOtpValue,
				timer,
				setTimer,
				valid,
				setValid,
				inputDirty,
				setInputDirty,
                activeTab,
                setActiveTab
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error(
			"useAuthContext must be used within a AuthContextProvider",
		);
	}
	return context;
};

export default AuthContextProvider;
