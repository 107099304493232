import React from "react";
import "../../PartnerWithUs/PartnerWithUs.scss";
import {useMediaQuery} from "react-responsive";
import {Link} from "react-scroll";

// assets
const joinHandsArrow = process.env.REACT_APP_CDN_LINK + `assets/images/joinHandArrow.png`
const GreenHands = process.env.REACT_APP_CDN_LINK + `assets/images/greenHands.png`

const JoinHands = ({backHandler}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    return (
        <div className={isDesktopOrLaptop ? "join-hands-part pb-5" : "join-hands-part pb-4  px-3"}>
            {!isDesktopOrLaptop && (
                <div className="d-flex join-hands-mobile-device  align-items-center pt-2">
                    <img className="join-hands-arrow" alt={"alt"} src={joinHandsArrow} onClick={backHandler}/>
                    <p className="about-us">Partner with Us</p>
                </div>
            )}
            {isDesktopOrLaptop && (
                <div className="d-flex join-hands-desktop-device  pt-4">
                    <a href="/">
                    <p className="home cursor-pointer">Home</p>
                    </a>
                    <p className="slash ">/</p>
                    <p className="about">Partner with Us</p>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "https://www.nuego.in"
                            },
                            {
                            "@type": "ListItem",
                            "position": 2,
                            "name": "Partner with Us - NueGo",
                            "item": "https://www.nuego.in/partner-with-us"
                            }
                        ]
                        })}
                    </script>
                </div>
            )}
            <div className={isDesktopOrLaptop ? "d-flex green-hands-part align-items-center pt-4" : ""}>
                <div className={isDesktopOrLaptop ? "order-2" : ""}>
                    {!isDesktopOrLaptop && (
                        <h1 className="join-hands-heading py-2">
                            Join hands with us on going{" "}
                            <span className="chage-text-color"> Green</span>
                        </h1>
                    )}
                    <img className="green-hands-image" src={GreenHands} alt={"alt"}/>
                </div>
                <div className={isDesktopOrLaptop ? "order-1" : ""}>
                    {isDesktopOrLaptop && <p className="join-hands-heading">
                        Join hands with us on going{" "}
                        <span className="chage-text-color"> Green</span>
                    </p>}
                    <p className={isDesktopOrLaptop ? "green-hands-data" : "pt-2 green-hands-data"}>
                        The Partners Program at NueGo is designed to encourage growth within
                        the intercity premier AC bus industry by reflecting the needs of an
                        elevated experience for the bus travellers. Our dedicated
                        Value-Added Services and franchisee teams identify and work partners
                        towards delivering an entire suite of tailored services.
                    </p>
                    <Link
                        activeClass="active"
                        to={"partner-application-form"}
                        spy={true}
                        smooth={true}
                        offset={-200}
                        duration={500}
                    >
                        <button className="join-hands-btn mt-3">Partner with Us</button>
                    </Link>
                </div>

            </div>
        </div>
    );
};

export default JoinHands;
