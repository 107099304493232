import {useMediaQuery} from "react-responsive";
import React, {useEffect, useRef, useState} from "react";
import "./FaqDesktop.scss";
import {useNavigate} from "react-router";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Calendar from "react-calendar";
import CreateCallRequest from "../../services/mutation/CreateCallRequestMutation";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from 'react-helmet';
import {addDaysToDate, convertDateWithMonth, convertToDateFormat,} from "../../utils/utils";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast"
// icons
import calendarIcon from "../../assets/icons/calendar.svg"
import nameIcon from "../../assets/icons/name-icon.png"
import waitingCustomer from "../../assets/icons/waiting_customer.svg"
import waitingIllustration from "../../assets/icons/waiting_illustration_01-01.svg"

import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import { Link } from "react-router-dom";

//assets
const callIcon = process.env.REACT_APP_CDN_LINK + `assets/images/call.png`
const LanguageImage = process.env.REACT_APP_CDN_LINK + `assets/images/Language.png`
const TimeImage = process.env.REACT_APP_CDN_LINK + `assets/images/Combined-Shape.png`
const Faqbg = process.env.REACT_APP_CDN_LINK + `assets/images/FAQ/faq_bg.png`

const DesktopFAQForm = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <div className={"faq-page"}>
                <Helmet>
                    <title>Request a Call - Contact NueGo for Personalized Assistance</title>
                    <link rel="canonical" href="https://www.nuego.in/call" hreflang="en-IN" />
                    <meta name="description" content="Request call support from NueGo have you have questions about products, require technical support, or need guidance, our dedicated team is just a call away."/>
                    <meta name="keywords" content="Request a call, Personalized assistance, Contact NueGo, Customer Support, Guidance, Call Support, Dedicated team, Product inquiries, Assistance services, NueGo Contact Number"></meta>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
            {

            isDesktopOrLaptop?<AuthModalLayout>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </AuthModalLayout>
            :
            <MobileSubScreenLayout back={() => navigate(-1)} title={"Talk to us"}>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const ref = useRef();


    const [personalDetailData_local, setPersonalDetailData_local] = useState({});
    const [error, setError] = useState({
        name: false,
        age: false,
    });
    const [touched, setTouched] = useState([]);
    const [calendarValue, setCalendarValue] = useState(new Date());
    const [viewCalendar, setViewCalendar] = useState(false);


    const [requestCall, setRequestCall] = useState(false);

    let userName, userEmail, userId, userNmber , moeId;
    userName = localStorage.getItem("userName");
    userEmail = localStorage.getItem("userEmail");
    userId = localStorage.getItem("userID");
    moeId = localStorage.getItem("moeID");
    userNmber = localStorage.getItem("userNumber");

    // methods

    let today = new Date(calendarValue);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let year = today.getFullYear();
    let yyyy = year.toString();
    let date = dd.concat("/", mm, "/", yyyy);
    console.log("personalDetailData_local",personalDetailData_local)

    const onChangeHandler = (e, field) => {
        if (field === "preferredLanguage") {
            const language = parseInt(e.target.value);
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: language,
            });
        }
        if (!touched.includes(field)) {
            setTouched([...touched, field]);
        }

        if (field === "name") {
            if (e.target.value === "") {
                setError({...error, [field]: "error"});
                setPersonalDetailData_local({
                    ...personalDetailData_local,
                    [field]: e.target.value,
                });
            } else {
                if (e.target.value.match(/\d+/g) === null) {
                    setError({...error, [field]: null});
                    setPersonalDetailData_local({
                        ...personalDetailData_local,
                        [field]: e.target.value,
                    });
                }
            }
        }
        var regPattern = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/;
        if (field === "mobileNumber") {
            console.log("mobileNumber",e.target.value)
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: e.target.value,
                ["preferredDate"]: date,
            });

            if (e.target.value.match(regPattern) == null) {
                setError({...error, [field]: "error"});
            } else {
                setError({...error, [field]: null});
            }
        }
        const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d))$/;
        // const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

        if (field === "preferredTime") {
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: e.target.value,
            });
            if (e.target.value.match(timeRegex) == null) {
                setError({...error, [field]: "error"});
            } else {
                setError({...error, [field]: null});
            }
        }
    };

    const onSubmitHandler = () => {
        if (
            Object.keys(personalDetailData_local).length === 5 &&
            personalDetailData_local.mobileNumber.length === 10
        ) {
            setRequestCall(true);

            let variable = {
                mobileNumber: personalDetailData_local.mobileNumber,
                name: personalDetailData_local.name,
                preferredDate: personalDetailData_local.preferredDate,
                preferredLanguage: personalDetailData_local.preferredLanguage,
                preferredTime: personalDetailData_local.preferredTime,
                // requestfrom value must be 2 by default for B2C to show up in crm
                requestedFrom: 2,
            };

            window.Moengage.track_event("call_requested", {
                account_id: `${moeId === null ? "" : moeId}`,
                platform: `${isDesktopOrLaptop ? "website" : "mobile website"}`,
                page_viewed: "yes",
                fullname: `${userName === null ? "" : userName}`,
                mobile_number: `${userNmber === null ? "" : userNmber}`,
                email: `${userEmail === null ? "" : userEmail}`,
                isloggedin: `${userNmber === null ? "no" : "yes"}`,
                visit_date: convertDateWithMonth(new Date()),
                requester_name:variable.name,
                requester_mobileNo:variable.mobileNumber,

            });
            CreateCallRequest(
                variable,
                (response) => {
                },
                (err) => {
                    if(err){
                        cogoToast.error(
                            <CustomToast
                                heading={"Error"}
                                message={err[0]?.message || err.toString()}
                                type={"error"}
                            />,
                        );
                    }
                }
            );
        } else {
            cogoToast.warn(
                <CustomToast
                    message="Please fill all the column"
                    type={"warn"}
                />,
                {position: "top-center"}
            );
        }
    };

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-700w-16s-24h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (viewCalendar && ref.current && !ref.current.contains(e.target)) {
                setViewCalendar(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [viewCalendar]);

    // call reqest data
    const callRequestData = [
        {
            name: "Name",
            data: personalDetailData_local.name,
            image: nameIcon,
        },
        {
            name: "Mobile Number",
            data: personalDetailData_local?.mobileNumber,
            image: callIcon,
        },
        {
            name: "Preferred Date",
            data: personalDetailData_local?.preferredDate,
            image: calendarIcon,
        },
        {
            name: "Preferred Time",
            data: personalDetailData_local?.preferredTime,
            image: TimeImage,
        },
        {
            name: "Preferred Language",
            data: personalDetailData_local?.preferredLanguage==1?"English":'Hindi',
            image: LanguageImage,
        },
    ];


    return (
        <>
            {isDesktopOrLaptop && <Navbar/>}
            <div className={"main-content"}>
            <div className="bread-crumb px-4 d-flex">
                      <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                      <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                       <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Support</p>
                        </div>
            
                <div>
                    <div className="px-4">
                        <h1 className="call-on-number-mobile">Request a Call</h1>
                    </div>
                    {/* FORM	*/}
                    {requestCall === false && (
                        <div className={"mb-4 px-2"}>
                            <div
                                className={
                                    "row " +
                                    (isDesktopOrLaptop ? componentData.inputMarginBottom_class : "")
                                }
                            >

                                <div className=" col-lg-6 mb-lg-0 mb-2 ">
                                    {/* name */}
                                    <p className={componentData.label_class}>Name </p>
                                    <div
                                        className={
                                            "common-input-wrap input-name " +
                                            (error.name === "error" && "common-input-wrap-error")
                                        }
                                    >
                                        <input
                                            className="border-less-input w-50"
                                            value={personalDetailData_local.name}
                                            onChange={(e) => {
                                                // case 1 a
                                                onChangeHandler(e, "name");
                                            }}
                                            type={"text"}
                                        />
                                    </div>
                                    {error.name === "error" && (
                                        <div>
                                            <p className={componentData.error_class}>Invalid Name</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 mb-lg-0 px-0">
                                    <div className="row ">
                                        <div className="col-lg-4 mb-2">
                                            <p className={componentData.label_class}>Code</p>

                                            <div className={"common-input-wrap "}>
                                                <select className="border-less-input select-element">
                                                    <option>+91</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mb-2">
                                            <p className={componentData.label_class}>Phone Number</p>

                                            <div className={"common-input-wrap "}>
                                                <input
                                                    min="0"
                                                    max="10"
                                                    className="border-less-input w-50 "
                                                    type={"number"}
                                                    // readOnly={true}
                                                    value={personalDetailData_local.mobileNumber}
                                                    pattern="[0-9]{10}"
                                                    required
                                                    onKeyPress={(e) =>
                                                        e.target.value.length === 10
                                                            ? e.preventDefault()
                                                            : null
                                                    }
                                                    onChange={(e) => onChangeHandler(e, "mobileNumber")}
                                                />
                                            </div>
                                            {error.mobileNumber === "error" && (
                                                <div>
                                                    <p className={componentData.error_class}>
                                                        Invalid PhoneNumber
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className={"row"}>
                                <div className="col-lg-4  mb-lg-0 my-3">
                                    <p className={componentData.label_class}>Preferred Date</p>

                                    <div className={"position-relative"}>
                                        <div
                                            onClick={() => setViewCalendar(!viewCalendar)}
                                            className={
                                                "common-input-wrap d-flex justify-content-between date-input align-items-center mb-2 cursor-pointer"
                                            }
                                        >
                                            <div className={"d-flex align-items-center"}>
                                                <img
                                                    src={calendarIcon}
                                                    className={"icon-24 mr-3"}
                                                    alt={"calendar"}
                                                />
                                                <p className={"open-600w-18s-28h mb-0"}>
                                                    {convertDateWithMonth(calendarValue)}
                                                </p>
                                            </div>
                                        </div>
                                        {viewCalendar && (
                                            <div className={"calendar-wrapper"}>
                                                <Calendar
                                                    onChange={(e) => setCalendarValue(e.toString())}
                                                    defaultValue={convertToDateFormat(calendarValue)}
                                                    value={convertToDateFormat(calendarValue)}
                                                    minDate={new Date()}
                                                    inputRef={ref}
                                                    maxDate={addDaysToDate(new Date(), 30)}
                                                    onClickDay={(e) => {
                                                        // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                                                        //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                                                        setTimeout(() => {
                                                            // deliverySlotChecker(formik, convertDateReverse(e))
                                                            setViewCalendar(false);
                                                        }, 1000);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4  mb-lg-0 my-3">
                                    <>
                                        <p className={componentData.label_class}>Preferred Time</p>

                                        <div
                                            className={"common-input-wrap common-input-wrap"}
                                        >
                                            <input
                                                className="border-less-input w-100 "
                                                type={"text"}
                                                required={true}
                                                value={personalDetailData_local.preferredTime}
                                                onChange={(e) => onChangeHandler(e, "preferredTime")}
                                                placeholder="HH:MM"
                                            ></input>
                                        </div>
                                        {error.preferredTime === "error" && (
                                            <div>
                                                <p className={componentData.error_class}>Invalid Time</p>
                                            </div>
                                        )}
                                    </>
                                </div>
                                {/* </div> */}
                                <div className="col-lg-4  mb-lg-0 my-3 ">
                                    <>
                                        <p className={componentData.label_class}>Language</p>


                                        <div className={"common-input-wrap"}>
                                            <select
                                                className="border-less-input select-element"
                                                onChange={(e) =>
                                                    onChangeHandler(e, "preferredLanguage")
                                                }
                                            >
                                                <option value={0}>Select</option>
                                                <option value={1}>English</option>
                                                <option value={2}>Hindi</option>
                                            </select>
                                        </div>

                                    </>
                                </div>
                            </div>
                            <div className="px-3 text-center call-cta">
                                <button className={" call-req-cta-mobile text-nowrap"} onClick={onSubmitHandler}>
                                    Request
                                </button>
                            </div>
                        </div>
                    )}

                    {/* call requested */}
                    {requestCall && (
                        <div className="px-3">
                            <div className="call-requesd">
                                <div className="row">
                                    {callRequestData.map((item, index) => (
                                        <div key={index} className="col-lg-4 requested-details">
                                            <p className="p1"><img src={item.image} alt="alt"/> {item.name}</p>
                                            <div>
                                                <p className="p2">{item.data}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="text-center my-4">
                                <button className={" call-req-disabled-cta-mobile "} disabled>
                                    Call Requested
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div className="">
                    <img src={Faqbg} alt="img" className="w-100"/>
                </div> */}

                <div className="call-waiting" >
                    <div className="leftDiv">
                        <h2 className="DNLW-Heading">Don’t like waiting?</h2>
                        <h3 className="DNLW-Tollfree">Kindly reach out to us on <a href="tel:18002679001">1800 267 9001</a> </h3>
                    </div>
                    <div className="rightDiv">
                        <img src={waitingCustomer} alt="waitingCustomer"/>
                        <img src={waitingIllustration} alt="waiting_illustation"/>
                    </div>
                </div>

            </div>
            <Footer/>
        </>

    );
};
export default DesktopFAQForm;
