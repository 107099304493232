import React , { useState, useEffect , useRef } from 'react'
import './RouteComponent.scss'
const kashmir = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/00_Kashmir.png'
const amritsar = "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/01_Kashmir%20to%20Amritsar.png"
const delhi = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/02_Amritsar%20to%20Delhi.png'
const jaipur = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/03_Delhi%20to%20Jaipur.png'
const bhopal = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/04_Jaipur%20to%20Bhopal.png'
const nagpur = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/05_Bhopal%20to%20Nagpur.png'
const hyderabad = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/06_Nagpur%20to%20Hyderabad.png'
const bangalore = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/07_Hyderabad%20to%20Bangalore.png'
const madurai = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/08_Bangalore%20to%20Madurai.png'
const kanyakumari = 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/09_Madurai%20to%20Kanniyakumari.png'

function RouteComponent() {

  const routeList =[
    {},
    { "stop": 1, "city": "Kashmir", "desc": "Welcome to Heaven, or the next best thing anyway. Its mountains and saffron fields will whisk you into a dream you do not want to wake up from.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Kashmir.jpg" , 'map': kashmir },
    { "stop": 2, "city": "Amritsar", "desc": "Amrit is an Elixir, and so is your state of mind when at Amritsar. Home to the Golden Temple’s grandeur, Amritsar blends spirituality with serendipity.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Amritsar.jpg", 'map': amritsar  },
    { "stop": 3, "city": "Delhi", "desc": "A city where ancient meets avant-garde, Delhi’s bazaars, monuments, and cafes make it a non-stop party for your senses.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Delhi.jpg" ,  'map': delhi },
    { "stop": 4, "city": "Jaipur", "desc": "You’ve heard of the Pink City, but what of “The city you must visit no matter what?” Grandiose Palaces & the serenity of Desert await you in Jaipur.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Jaipur.jpg"  , 'map': jaipur  },
    { "stop": 5, "city": "Bhopal", "desc": "From the charm of the lakes to a treasure that has carried through Indian history, Bhopal will mesmerize you perfectly.", "img": 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Bhopal.jpg', 'map': bhopal  },
    { "stop": 6, "city": "Nagpur", "desc": "The city of Oranges, is like orange zest on the Indian cake, it’s delectable, and a must-have. Its beauty and simplicity are what make Nagpur tick.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Nagpur.jpg", 'map': nagpur  },
    { "stop": 7, "city": "Hyderabad", "desc": "When a city of riches makes one of the best biryanis in the world, you get Hyderabad. Hyderabad will leave you craving a second helping of everything.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Hyderabad.jpg" , 'map': hyderabad  },
    { "stop": 8, "city": "Bangalore", "desc": "The lore of Bangalore is never a bore. With parks, pubs, and pleasant weather, Bangalore is your hip, green playground for work and play, hurray.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Bangalore.jpg" , 'map': bangalore },
    { "stop": 9, "city": "Madurai", "desc": "The “City of Temples,” Madurai is where spiritual beauty and aromatic jasmines swirl around streets filled with tranquil bliss and infectious energy.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Madurai.jpg" , 'map': madurai },
    { "stop": 10, "city": "Kanyakumari", "desc": "Kanyakumari offers stunning ocean views, spiritual vibes, and the thrill of watching three seas intertwine into a beautiful Jugalbandi.", "img": "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/Kanniyakumari.jpg" , 'map': kanyakumari  },
    {}
  ]

  const [activeStep, setActiveStep] = useState(1);
  const stepRefs = useRef([]); 
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return; 

      const container = containerRef.current;
      const containerRect = container.getBoundingClientRect();
      const containerMidPoint = containerRect.top + containerRect.height / 2;

      const closestStep = stepRefs.current.reduce((closest, step, index) => {
        const rect = step.getBoundingClientRect();
        const stepMiddle = rect.top + rect.height / 2; 
        const distanceFromMid = Math.abs(stepMiddle - containerMidPoint);

        
        if (distanceFromMid < closest.distance) {
          return { index: index + 1, distance: distanceFromMid };
        }
        return closest;
      }, { index: 1, distance: Infinity });

      setActiveStep(closestStep.index-1); 
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll); 
    return () => {
      container.removeEventListener('scroll', handleScroll); 
    };
  }, []);
  
  return (
    <div className='route-component'>
      <div className='main-container'>
        <h2 className="route-heading">NueGo Kashmir to Kanyakumari Road Trip Route</h2>
        <div className="route-city-list">
        {routeList.map((el,ind)=><div className={`route-item`} key={ind}>{el.city}</div>)}
        </div>
        <div className='map-and-route'>
          <div className='map-section'>
        <img className='map-image' src={routeList[activeStep].map} alt="" />

        
          </div>
          <div className="route-section" ref={containerRef}>
            {routeList.map((stop, index) => (
              <div 
              className={`stop ${activeStep === index ? 'active' : ''}`} 
              key={stop.stop}
              ref={el => stepRefs.current[index] = el}
              > 
                <div className="stop-content">
                {stop?.img &&<img  src={stop.img} alt={stop?.city} className="city-image" />}
                  <div className="parent">
                 {stop?.stop && <div className="stop-number">Stop {stop.stop}</div>}
                  <div className="city-name">{stop.city}</div>
                  <div className="city-desc">{stop.desc}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RouteComponent