import React, {useState} from "react";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import {useMediaQuery} from "react-responsive";
import "./DownloadApp.scss";

//assets
const DownloadImage = process.env.REACT_APP_CDN_LINK + `assets/images/download-app-image.png`
const AppStoreImage = process.env.REACT_APP_CDN_LINK + `assets/images/app-store.png`
const PlayStoreImage = process.env.REACT_APP_CDN_LINK + `assets/images/play-store-image.png`

const DownloadApp = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [bottomTray, setBottomTray] = useState(true);

    return (
        <div className="download-app">
            <BottomTrayComponent
                isOpen={bottomTray === true}
                hideClearAll={true}
                title={bottomTray}
                closeHandler={() => setBottomTray(false)}
            >
                {bottomTray && (
                    <div>
                        <div className="download-app-bus">
                            <img src={DownloadImage} alt="alt"/>
                        </div>

                        <div className="download-app-section">
                            <h2>Download our app to use this feature</h2>
                            <p>
                                We only have this feature available on our mobile app. Download
                                now to track your bus!
                            </p>
                            <div
                                style={{gap: "12px"}}
                                className="d-flex justify-content-center "
                            >
                                <img src={AppStoreImage} alt="alt"/>
                                <img src={PlayStoreImage} alt="alt"/>
                            </div>
                        </div>
                    </div>
                )}
            </BottomTrayComponent>
        </div>
    );
};

export default DownloadApp;
