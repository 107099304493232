import React from "react";
import "./AllBusDestination.scss";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

const AllBusDestination = () => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();

  return isDesktopOrLaptop ? (
    <AuthModalLayout>
      <div className={"all-bus-destination"}>
        <div className={"navbar-wrapper margin-bottom-100"}>
          <Navbar fixedNavbar={true} />
        </div>

        <div className={"white-fold white-fold-padding pt-4"}>
          <div className={"inner-div"}>
            <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
          </div>
        </div>
        <Footer />
      </div>
    </AuthModalLayout>
  ) : (
    <MobileSubScreenLayout
      title={"All Routes & Timings"}
      back={() => navigate(-1)}
    >
      <div className={"all-bus-destination"}>
        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
      </div>
    </MobileSubScreenLayout>
  );
};

const MainContent = ({ isDesktopOrLaptop }) => {
  let pageConstant;
  if (isDesktopOrLaptop) {
    pageConstant = {
      pageTitle_class: "ubuntu-700w-32s-48h",
      tab_class: "open-600w-16s-24h mb-0",
      tabWrap_class: "pb-4",
      in_activeTab_class: "ubuntu-500w-20s-32h mb-0",
      activeTab_class: "ubuntu-700w-20s-32h teal-2-00A095-color mb-0",
      route_class: "ubuntu-700w-20s-32h",
      normal_textclass: "open-400w-16s-22h ",
    };
  } else {
    pageConstant = {
      tab_class: "open-600w-16s-24h mb-0",
      tabWrap_class: "pb-0",
      route_class: "ubuntu-700w-18s-22h ",
      normal_textclass: "open-400w-14s-24h ",
    };
  }
  return (
    <div className="main-content">
      <div className="form-group panel panel-default">
        <div>
          <div className="main-part px-3">
            <p className={pageConstant.pageTitle_class + " text-center "}>
              All Routes & Timings
            </p>
            <p className={pageConstant.route_class}>
              India's first pan-nation intercity electric coaches providing a
              world-class experience
            </p>
            <div className="box-area justify-content-lg-center">
              <div className="routebox01">
                <h4 className={pageConstant.route_class}>Indore - Bhopal</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>07:30 AM</p>
                      <p className={pageConstant.normal_textclass}>08:00 AM</p>
                      <p className={pageConstant.normal_textclass}>08:30 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:30 AM</p>
                      <p className={pageConstant.normal_textclass}>10:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:30 PM</p>
                      <p className={pageConstant.normal_textclass}>02:00 PM</p>
                      <p className={pageConstant.normal_textclass}>02:30 PM</p>
                      <p className={pageConstant.normal_textclass}>03:00 PM</p>
                      <p className={pageConstant.normal_textclass}>03:30 PM</p>
                      <p className={pageConstant.normal_textclass}>04:00 PM</p>
                      <p className={pageConstant.normal_textclass}>05:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:30 PM</p>
                      <p className={pageConstant.normal_textclass}>07:00 PM</p>
                      <p className={pageConstant.normal_textclass}>07:30 PM</p>
                      <p className={pageConstant.normal_textclass}>08:00 PM</p>
                      <p className={pageConstant.normal_textclass}>08:30 PM</p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>09:30 PM</p>
                      <p className={pageConstant.normal_textclass}>10:30 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                      <p className={pageConstant.normal_textclass}>11:55 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>09:20 AM</p>
                      <p className={pageConstant.normal_textclass}>10:20 AM</p>
                      <p className={pageConstant.normal_textclass}>10:50 AM</p>
                      <p className={pageConstant.normal_textclass}>11:20 AM</p>
                      <p className={pageConstant.normal_textclass}>11:50 AM</p>
                      <p className={pageConstant.normal_textclass}>12:20 PM</p>
                      <p className={pageConstant.normal_textclass}>12:50 PM</p>
                      <p className={pageConstant.normal_textclass}>01:20 PM</p>
                      <p className={pageConstant.normal_textclass}>01:50 PM</p>
                      <p className={pageConstant.normal_textclass}>02:20 PM</p>
                      <p className={pageConstant.normal_textclass}>03:20 PM</p>
                      <p className={pageConstant.normal_textclass}>04:20 PM</p>
                      <p className={pageConstant.normal_textclass}>04:50 PM</p>
                      <p className={pageConstant.normal_textclass}>05:20 PM</p>
                      <p className={pageConstant.normal_textclass}>05:50 PM</p>
                      <p className={pageConstant.normal_textclass}>06:20 PM</p>
                      <p className={pageConstant.normal_textclass}>06:50 PM</p>
                      <p className={pageConstant.normal_textclass}>07:20 PM</p>
                      <p className={pageConstant.normal_textclass}>08:20 PM</p>
                      <p className={pageConstant.normal_textclass}>09:20 PM</p>
                      <p className={pageConstant.normal_textclass}>09:50 PM</p>
                      <p className={pageConstant.normal_textclass}>10:20 PM</p>
                      <p className={pageConstant.normal_textclass}>10:50 PM</p>
                      <p className={pageConstant.normal_textclass}>11:20 PM</p>
                      <p className={pageConstant.normal_textclass}>11:50 PM</p>
                      <p className={pageConstant.normal_textclass}>12:20 AM</p>
                      <p className={pageConstant.normal_textclass}>12:50 AM</p>
                      <p className={pageConstant.normal_textclass}>01:50 AM</p>
                      <p className={pageConstant.normal_textclass}>02:50 AM</p>
                      <p className={pageConstant.normal_textclass}>03:15 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox02">
                <h4 className={pageConstant.route_class}>Bhopal - Indore</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>06:30 AM</p>
                      <p className={pageConstant.normal_textclass}>07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>07:30 AM</p>
                      <p className={pageConstant.normal_textclass}>08:00 AM</p>
                      <p className={pageConstant.normal_textclass}>08:30 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>12:30 PM</p>
                      <p className={pageConstant.normal_textclass}>01:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:30 PM</p>
                      <p className={pageConstant.normal_textclass}>02:00 PM</p>
                      <p className={pageConstant.normal_textclass}>02:30 PM</p>
                      <p className={pageConstant.normal_textclass}>03:00 PM</p>
                      <p className={pageConstant.normal_textclass}>03:30 PM</p>
                      <p className={pageConstant.normal_textclass}>04:00 PM</p>
                      <p className={pageConstant.normal_textclass}>05:00 PM</p>
                      <p className={pageConstant.normal_textclass}>05:30 PM</p>
                      <p className={pageConstant.normal_textclass}>06:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:30 PM</p>
                      <p className={pageConstant.normal_textclass}>07:00 PM</p>
                      <p className={pageConstant.normal_textclass}>07:30 PM</p>
                      <p className={pageConstant.normal_textclass}>08:00 PM</p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>09:30 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:55 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>09:45 AM</p>
                      <p className={pageConstant.normal_textclass}>10:30 AM</p>
                      <p className={pageConstant.normal_textclass}>10:50 AM</p>
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:50 AM</p>
                      <p className={pageConstant.normal_textclass}>12:30 PM</p>
                      <p className={pageConstant.normal_textclass}>01:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:30 PM</p>
                      <p className={pageConstant.normal_textclass}>02:00 PM</p>
                      <p className={pageConstant.normal_textclass}>02:55 PM</p>
                      <p className={pageConstant.normal_textclass}>04:00 PM</p>
                      <p className={pageConstant.normal_textclass}>04:25 PM</p>
                      <p className={pageConstant.normal_textclass}>05:00 PM</p>
                      <p className={pageConstant.normal_textclass}>05:25 PM</p>
                      <p className={pageConstant.normal_textclass}>06:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:25 PM</p>
                      <p className={pageConstant.normal_textclass}>07:00 PM</p>
                      <p className={pageConstant.normal_textclass}>07:30 PM</p>
                      <p className={pageConstant.normal_textclass}>08:30 PM</p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>09:30 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:30 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                      <p className={pageConstant.normal_textclass}>12:30 AM</p>
                      <p className={pageConstant.normal_textclass}>01:40 AM</p>
                      <p className={pageConstant.normal_textclass}>02:00 AM</p>
                      <p className={pageConstant.normal_textclass}>03:00 AM</p>
                      <p className={pageConstant.normal_textclass}>04:00 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-area box-area01 justify-content-lg-center">
              <div className="routebox01 routebox03">
                <h4 className={pageConstant.route_class}>Delhi - Chandigarh</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>12:30 AM</p>
                      <p className={pageConstant.normal_textclass}>05:00 AM</p>
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>08:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:00 AM</p>
                      <p className={pageConstant.normal_textclass}>11:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:15 PM</p>
                      <p className={pageConstant.normal_textclass}>02:30 PM</p>
                      <p className={pageConstant.normal_textclass}>03:30 PM</p>
                      <p className={pageConstant.normal_textclass}>04:30 PM</p>
                      <p className={pageConstant.normal_textclass}>05:30 PM</p>
                      <p className={pageConstant.normal_textclass}>07:15 PM</p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:15 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:55 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>12:30 PM</p>
                      <p className={pageConstant.normal_textclass}>01:40 PM</p>
                      <p className={pageConstant.normal_textclass}>02:45 PM</p>
                      <p className={pageConstant.normal_textclass}>03:45 PM</p>
                      <p className={pageConstant.normal_textclass}>04:45 PM</p>
                      <p className={pageConstant.normal_textclass}>05:45 PM</p>
                      <p className={pageConstant.normal_textclass}>06:55 PM</p>
                      <p className={pageConstant.normal_textclass}>08:10 PM</p>
                      <p className={pageConstant.normal_textclass}>09:10 PM</p>
                      <p className={pageConstant.normal_textclass}>10:25 PM</p>
                      <p className={pageConstant.normal_textclass}>11:25 PM</p>
                      <p className={pageConstant.normal_textclass}>12:45 AM</p>
                      <p className={pageConstant.normal_textclass}>02:55 AM</p>
                      <p className={pageConstant.normal_textclass}>04:15 AM</p>
                      <p className={pageConstant.normal_textclass}>04:55 AM</p>
                      <p className={pageConstant.normal_textclass}>05:50 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox04">
                <h4 className={pageConstant.route_class}>Chandigarh - Delhi</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>12:30 AM</p>
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}> 07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>08:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>10:00 AM</p>
                      <p className={pageConstant.normal_textclass}>11:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}> 01:00 PM</p>
                      <p className={pageConstant.normal_textclass}>02:30 PM</p>
                      <p className={pageConstant.normal_textclass}>03:30 PM</p>
                      <p className={pageConstant.normal_textclass}>04:30 PM</p>
                      <p className={pageConstant.normal_textclass}>05:30 PM </p>
                      <p className={pageConstant.normal_textclass}>06:30 PM</p>
                      <p className={pageConstant.normal_textclass}>08:00 PM </p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:45 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:10 AM</p>
                      <p className={pageConstant.normal_textclass}>11:35 AM</p>
                      <p className={pageConstant.normal_textclass}>12:50 PM</p>
                      <p className={pageConstant.normal_textclass}>01:50 PM</p>
                      <p className={pageConstant.normal_textclass}>02:50 PM</p>
                      <p className={pageConstant.normal_textclass}>03:50 PM</p>
                      <p className={pageConstant.normal_textclass}>04:50 PM</p>
                      <p className={pageConstant.normal_textclass}>05:50 PM</p>
                      <p className={pageConstant.normal_textclass}>07:00 PM</p>
                      <p className={pageConstant.normal_textclass}>08:30 PM</p>
                      <p className={pageConstant.normal_textclass}>09:30 PM</p>
                      <p className={pageConstant.normal_textclass}>10:40 PM</p>
                      <p className={pageConstant.normal_textclass}>11:40 PM</p>
                      <p className={pageConstant.normal_textclass}>11:45 PM</p>
                      <p className={pageConstant.normal_textclass}>01:40 AM</p>
                      <p className={pageConstant.normal_textclass}>02:40 AM</p>
                      <p className={pageConstant.normal_textclass}>03:35 AM</p>
                      <p className={pageConstant.normal_textclass}>04:35 AM</p>
                      <p className={pageConstant.normal_textclass}>05:25 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-area box-area01 justify-content-lg-center">
              <div className="routebox01 routebox03">
                <h4 className={pageConstant.route_class}>Delhi - Jaipur</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>07:20 PM</p>
                      <p className={pageConstant.normal_textclass}>10:50 PM</p>
                      <p className={pageConstant.normal_textclass}>12:20 PM</p>
                      <p className={pageConstant.normal_textclass}>01:50 PM</p>
                      <p className={pageConstant.normal_textclass}>07:20 AM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>12:55 PM</p>
                      <p className={pageConstant.normal_textclass}>04:25 PM</p>
                      <p className={pageConstant.normal_textclass}>05:55 PM</p>
                      <p className={pageConstant.normal_textclass}>07:25 PM</p>
                      <p className={pageConstant.normal_textclass}>12:55 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox04">
                <h4 className={pageConstant.route_class}>Jaipur - Delhi</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>07:30 PM</p>
                      <p className={pageConstant.normal_textclass}>09:30 PM</p>
                      <p className={pageConstant.normal_textclass}> 10:30 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>12:30 PM</p>
                      <p className={pageConstant.normal_textclass}>01:10 AM</p>
                      <p className={pageConstant.normal_textclass}>03:10 AM</p>
                      <p className={pageConstant.normal_textclass}>04:25 AM</p>
                      <p className={pageConstant.normal_textclass}>05:25 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-area box-area01 justify-content-lg-center">
              <div className="routebox01 routebox03">
                <h4 className={pageConstant.route_class}>
                  Hyderabad - Vijayawada
                </h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>05:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:30 PM</p>
                      <p className={pageConstant.normal_textclass}>04:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>11:15 AM</p>
                      <p className={pageConstant.normal_textclass}>06:15 PM</p>
                      <p className={pageConstant.normal_textclass}>07:45 PM</p>
                      <p className={pageConstant.normal_textclass}>10:15 PM</p>
                      <p className={pageConstant.normal_textclass}>05:15 AM</p>
                      <p className={pageConstant.normal_textclass}>05:45 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox04">
                <h4 className={pageConstant.route_class}>
                  Vijayawada - Hyderabad
                </h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>05:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>01:30 PM</p>
                      <p className={pageConstant.normal_textclass}> 03:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>11:20 AM</p>
                      <p className={pageConstant.normal_textclass}>06:20 PM</p>
                      <p className={pageConstant.normal_textclass}>07:50 PM</p>
                      <p className={pageConstant.normal_textclass}>09:20 PM</p>
                      <p className={pageConstant.normal_textclass}>05:20 AM</p>
                      <p className={pageConstant.normal_textclass}>05:50 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-area box-area01 justify-content-lg-center">
              <div className="routebox01 routebox03">
                <h4 className={pageConstant.route_class}>Delhi - Dehradun</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>03:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>02:30 AM</p>
                      <p className={pageConstant.normal_textclass}>05:30 AM</p>
                      <p className={pageConstant.normal_textclass}>08:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>04:00 AM</p>
                      <p className={pageConstant.normal_textclass}>05:00 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox04">
                <h4 className={pageConstant.route_class}>Dehradun - Delhi</h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>06:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>12:00 PM</p>
                      <p className={pageConstant.normal_textclass}>03:00 PM</p>
                      <p className={pageConstant.normal_textclass}>06:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:30 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>11:15 AM</p>
                      <p className={pageConstant.normal_textclass}>02:15 AM</p>
                      <p className={pageConstant.normal_textclass}>05:15 AM</p>
                      <p className={pageConstant.normal_textclass}>08:30 AM</p>
                      <p className={pageConstant.normal_textclass}>11:30 AM</p>
                      <p className={pageConstant.normal_textclass}>03:15 AM</p>
                      <p className={pageConstant.normal_textclass}>04:45 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="box-area box-area01 justify-content-lg-center">
              <div className="routebox01 routebox03">
                <h4 className={pageConstant.route_class}>
                  Bangalore - Tirupati
                </h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>01:00 PM</p>
                      <p className={pageConstant.normal_textclass}>09:00 PM</p>
                      <p className={pageConstant.normal_textclass}>10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:50 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>02:45 PM</p>
                      <p className={pageConstant.normal_textclass}>06:45 PM</p>
                      <p className={pageConstant.normal_textclass}>02:45 AM</p>
                      <p className={pageConstant.normal_textclass}>03:45 AM</p>
                      <p className={pageConstant.normal_textclass}>05:35 AM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="routebox01 routebox04">
                <h4 className={pageConstant.route_class}>
                  Tirupati - Bangalore
                </h4>
                <div className="route-time">
                  <div className="departure">
                    <span className={pageConstant.route_class}>
                      Departure Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>07:00 AM</p>
                      <p className={pageConstant.normal_textclass}>09:00 AM</p>
                      <p className={pageConstant.normal_textclass}>01:00 PM</p>
                      <p className={pageConstant.normal_textclass}> 10:00 PM</p>
                      <p className={pageConstant.normal_textclass}>11:50 PM</p>
                    </div>
                  </div>
                  <div className="arrival">
                    <span className={pageConstant.route_class}>
                      Arrival Time
                    </span>
                    <div className="all-timing">
                      <p className={pageConstant.normal_textclass}>12:50 PM</p>
                      <p className={pageConstant.normal_textclass}>02:50 PM</p>
                      <p className={pageConstant.normal_textclass}>06:50 PM</p>
                      <p className={pageConstant.normal_textclass}>03:50 AM</p>
                      <p className={pageConstant.normal_textclass}>05:40 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBusDestination;
