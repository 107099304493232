import "./MobileBottomNav.scss"
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {useEffect} from "react";
import {store} from "../..";

//assets
const icons_home = process.env.REACT_APP_CDN_LINK + `assets/images/akar-icons_home.svg`
const Chat = process.env.REACT_APP_CDN_LINK + `assets/images/Chat.svg`
const Profile = process.env.REACT_APP_CDN_LINK + `assets/images/Profile.svg`
const Ticket = process.env.REACT_APP_CDN_LINK + `assets/images/Ticket.svg`

const MobileBottomNav = (props) => {

    const navigate = useNavigate()
    const params = useLocation()

    const {loginDetailsFromStore} = props


    useEffect(() => {
        // checkBookingTicket()
    }, [loginDetailsFromStore])

    let localStorageToken = localStorage.getItem("userToken");
    let isToken;
    if (store) {
        isToken =
            (localStorageToken !== undefined && localStorageToken?.length > 0)
        // ||
        // (store &&
        //     Object.keys(store.getState().AuthReducer?.tokenData)?.length > 0);
    }

    const checkBookingTicket = () => {
        if (isToken) {
            navigate('/my-booking')
        } else {
            // store.dispatch(setAuthType("guest"));
            navigate('/guest-user')
        }
    }


    return (
        <div className={"mobilebottomnav"}>
            <div className={'dark-fold'}>
                <div className={'d-flex gap-18'}>
                    <div className={'home  cursor-pointer'} onClick={() => navigate('/', {replace: false})}>
                        {params.pathname === "/" && <hr className={'mark_hr'}/>}
                        <img src={icons_home} className={'icons_home'} alt={'icons_home'}/>
                        <div className={'para '}
                             style={params.pathname === "/" ? {color: "#22BBB0"} : {color: "white"}}>Home
                        </div>
                    </div>
                    <div className={'booking  cursor-pointer'}
                        // onClick={() => navigate('/my-booking', {replace: false})}
                         onClick={() => checkBookingTicket()}
                    >
                        {params.pathname === "/my-booking" && <hr className={'mark_hr'}/>}
                        <img src={Ticket} className={'icons_home'} alt={'icons_home'}/>
                        <div className={'para'}
                             style={params.pathname === "/my-booking" ? {color: "#22BBB0"} : {color: "white"}}
                        >My Booking
                        </div>
                    </div>
                    {/*<div className={'calender'}>*/}
                    {/*    <img src={Calendar} className={'calender_icon'} alt={'icons_home'}/>*/}
                    {/*</div>*/}
                    <div className={'support  cursor-pointer'}>
                        {params.pathname === "/support" && <hr className={'mark_hr'}/>}
                        <img src={Chat} className={'icons_home'} alt={'icons_home'}/>
                        <div className={'para'}>Support</div>
                    </div>
                    <div className={'profile cursor-pointer'}
                         onClick={() => navigate('/profile-detail', {replace: false})}>
                        {params.pathname === "/profile-detail" && <hr className={'mark_hr'}/>}
                        <img src={Profile} className={'icons_home'} alt={'icons_home'}/>
                        <p className={'para'}
                           style={params.pathname === "/profile-detail" ? {color: "#22BBB0"} : {color: "white"}}
                        >Profile</p>
                    </div>
                </div>
            </div>
        </div>

    )
}


const mapStateToProps = (state) => (
    {
        loginDetailsFromStore: state.AuthReducer.tokenData,
    }
)
export default connect(mapStateToProps)(MobileBottomNav)