import graphql from 'babel-plugin-relay/macro';

const GetCoPassengerListQuery = graphql`
	  query GetCoPassengerListQuery {
		   coPassengers {
			    id
			    name
			    age
			    gender
			    mobileNumber
			    isTrusted
			}
		}`

export default GetCoPassengerListQuery;