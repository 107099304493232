import React, {useCallback, useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
//impoet swiper
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {Navigation, Pagination} from "swiper/modules";
import Loader from "../../../components/Loader/Loader";


const Leaders = [
    {
        Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/VivekSahni.png",
        name: "Vivek Sahni",
        position: "CFO",
        linkedIn: "https://in.linkedin.com/in/viveksahni"
    },
    {
        Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/DhirenGajjar.jpg",
        name: "Dhiren Gajjar",
        position: "Head - Information Technology",
        linkedIn: "https://www.linkedin.com/in/dhiren-gajjar-a0a9897"
    },
    {
        Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/RanaSachdeva.jpg",
        name: "Rana Sachdeva",
        position: "Head - Contract Manufacturing",
        linkedIn: "https://in.linkedin.com/in/rana-r-s-sachdeva-69b76132"
    },
];

const NuegoTeam = () => {


  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });


  const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
  const [OurLeadersData, setOurLeadersData] = useState([])



  let pendingObject = { value: true, Response: "pending" }
  let sucessObject = { value: false, Response: "success" }
  let errorObject = { value: false, Response: "error" }




  useEffect(() => {

    setLoadingSpiner(pendingObject)

    fetch(`${process.env.REACT_APP_CMS_PROD_API}api/our-teams?populate=*&sort=rank:asc`,
      {
        headers: {
          'Content-Type': 'application/json',
        },

      })
      .then((res) => res.json())

      .then(
        (res) => {
          if (res?.error) {
            setLoadingSpiner(errorObject)

          } else {
            setLoadingSpiner(sucessObject)
            setOurLeadersData(res?.data)
          }

          return res;

        })

      .catch((err) => {
        setLoadingSpiner(errorObject)
        return err;

      });

  }, [])

  const linkedin = "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/linkedin.svg"



  return (
    <>
      {LoadingSpiner?.value == true ? <Loader />
        : <div className="our-leaders">
          <p className={isDesktopOrLaptop ? "text-center our-leader-title pt-5" : " our-leader-title px-3 pt-3"}>NueGo Leadership</p>

          {LoadingSpiner?.Response != "error" ? <div className={isDesktopOrLaptop ? "row our-leaders-part pb-5 d-flex p-2 m-0  justify-content-center" : "our-leaders-part d-flex  px-3 pb-5"}>
            {OurLeadersData?.map((data, index) => {
              return <div key={index} className={isDesktopOrLaptop ? " our-leader-box p-2" : "our-leader-box d-flex flex-column p-3 "}>
                <img src={data?.attributes?.image} alt={data?.attributes?.imageAltText} loading="lazy"/>
                <a href={data?.attributes?.linkedin} target="_blank" rel="nofollow" className="lindedIn"><img

                  src={linkedin}
                  alt="Linkedin"
                  loading="lazy"
                /></a>
                <div className={isDesktopOrLaptop ? "" : ""}>
                  <p className={isDesktopOrLaptop ? "leaders-name pt-2" : "leaders-name"}>{data?.attributes?.name}</p>
                  <p className="leaders-position">{data?.attributes?.designation}</p>
                </div>
              </div>
            })}
          </div> :
            <div className={isDesktopOrLaptop ? "row our-leaders-part pb-5 d-flex p-2 m-0  justify-content-center" : "our-leaders-part d-flex flex-column px-3 pb-5"}>
              {Leaders.map((data, index) => (
                <div key={index} className={isDesktopOrLaptop ? "our-leader-box p-2" : "our-leader-box d-flex p-3 "}>
                  <img src={data.Image} loading="lazy" alt="alt" />
                  <a href={data.linkedin} rel="nofollow"  target="_blank" className="lindedIn"><img

                    src={linkedin}
                    alt="Linkedin"
                    loading="lazy"
                  /></a>
                  <div className={isDesktopOrLaptop ? "" : ""}>
                    <p className={isDesktopOrLaptop ? "leaders-name pt-2" : "leaders-name"}>{data.name}</p>
                    <p className="leaders-position  ">{data.position}</p>
                  </div>
                </div>
              ))}
            </div>}
        </div>
      }
    </>
  );
};

export default NuegoTeam;
