import React, {useEffect, useState} from 'react'
import './ChangeBookingPage.scss'
//  imports
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useLocation, useNavigate} from "react-router";
import CancelBooking from "../../components/ChangeBookingModalComponent/SubScreens/CancelBooking";
import {useMediaQuery} from "react-responsive";
import {MDBModal, MDBModalBody} from "mdbreact";
import CancelBookingPayment from "../../components/ChangeBookingModalComponent/SubScreens/CancelBookingPayment";
import {getCancelDetailsData, getCancelPartialDetailsData} from "../../services/api/DataFetching";
import {store} from "../../index";
import {cancelSinglePassenger, ModifyBookingData, PartialCancelBooking} from "../../services/api/DataUpdating";
import RescheduleBooking from "../../components/ChangeBookingModalComponent/SubScreens/RescheduleBooking";
import {convertToDateFormat, reconvertionDate} from "../../utils/utils";
import {setDateAction, setRawDate} from "../../store/Actions/BookingAction";
import {setRescheduleAction} from "../../store/Actions/CommonAction";
import RefundResultComponent from "../../components/ChangeBookingModalComponent/SubScreens/RefundResultComponent";
import ModifyBooking from "../../components/ChangeBookingModalComponent/SubScreens/ModifyBooking";
import ModifyResultComponent from "../../components/ChangeBookingModalComponent/SubScreens/ModifyResultComponent";
import Loader from '../../components/Loader/Loader';

const ChangeBookingPage = (props) => {

    const navigate = useNavigate();
    let state = useLocation().state;
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    const [activeTab, setActiveTab] = useState("cancel");
    const [count, setCount] = useState(0);

    // cancel
    const [cancelDetails, setCancelDetails] = useState({});
    const [refundModal, setRefundModal] = useState(false);
    const [activeReason, setActiveReason] = useState("");
    const [activePassengerList, setActivePassengerList] = useState([]);
    const [activePayment, setActivePayment] = useState("");
    const [remarks, setRemarks] = useState("");
    const [refundStatus, setRefundStatus] = useState("");
    const [modifyStatus, setModifyStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

// use effects
    useEffect(() => {
        if (isDesktopOrLaptop) {
            navigate(-1)
        }
    }, [isDesktopOrLaptop]);

    useEffect(() => {
   
        if (activeTab === "cancel" && Object.keys(cancelDetails).length === 0 && activePassengerList?.length === 0) {
            let variable = {
                bookingId: state.bookingId,
                passengerIds: activePassengerList
            }
            getCancelDetailsData(variable, (data) => {
                setCancelDetails(data)
            })
        }

    }, [activeTab]);

    // useEffect(() => {

    //     if (activeTab === "cancel" && activePassengerList?.length > 0) {
    //         let variable = {
    //             bookingId: state.bookingId,
    //             passengerIds: activePassengerList
    //         }
    //         getCancelPartialDetailsData(variable, (data) => {
    //             setCancelDetails(data)
    //         })
    //     }
    // }, [activePassengerList?.length]);

    let pageConstant
    if (isDesktopOrLaptop) {
        pageConstant = {
            tab_class: 'open-600w-16s-24h mb-0',
            tabWrap_class: 'pb-4',
            successTitle_class: "ubuntu-500w-20s-32h mb-2",
            successDescription_class: "open-400w-14s-22h"
        }
    } else {
        pageConstant = {
            tab_class: 'open-600w-16s-24h mb-0',
            tabWrap_class: 'pb-4',
            successTitle_class: "ubuntu-500w-20s-32h mb-2",
            successDescription_class: "open-400w-14s-22h"
        }
    }

    const cancelSubmitHandler = () => {
        setRefundModal(true)
    }

    const cancelPaymentSubmitHandler = (refundTypeValue) => {
        setRefundModal(false)
        let variable
        // if (cancelDetails.booking?.passengerdetailSet.length > 1 && activePassengerList?.length !== cancelDetails.booking?.passengerdetailSet?.length) {
        //     variable = {
        //         "bookingId": state.bookingId,
        //         "cancellationReason": activeReason,
        //         "cancellationRemark": remarks,
        //         "paymentType": activePayment,
        //         "passengerIds": activePassengerList,
        //         "refundType": refundTypeValue
        //     }
        //     PartialCancelBooking(variable, (data) => {

        //         setRefundStatus("success")
        //     }, (error) => {
        //         setRefundStatus("fail")
        //         setErrorMessage(error)
        //     })

        // } else {
        setRefundStatus('pending');
            variable = {
                "bookingId": state.bookingId,
                "cancellationReason": activeReason,
                "cancellationRemark": remarks,
                "paymentType": activePayment,
                "refundType": refundTypeValue
            }
            cancelSinglePassenger(variable, (data) => {
                setRefundStatus("success")
            }, (error) => {
                setRefundStatus("fail")
                setErrorMessage(error)
            })
        //}

    }

    const rescheduleSubmitHandler = (date) => {

        let actualDateFormat = (convertToDateFormat(reconvertionDate(date)))
        store.dispatch(setDateAction({date: date}))
        store.dispatch(setRawDate(actualDateFormat.toISOString()));
        store.dispatch(setRescheduleAction(true));
        setTimeout(() => {
            navigate('/booking', {replace: false})
        }, 1000)

    }

    const modifySubmitHandler = (data) => {
        ModifyBookingData(data, (data) => {
            setModifyStatus("success")
        }, (error) => {
            setModifyStatus("fail")
            setErrorMessage(error)
        })
    }

    return (
        <div>
            {refundStatus === "" &&
                <MobileSubScreenLayout back={() => navigate(-1)} title={"Change Booking"}>
                    <div className={'change-booking-page'}>
                        {/* common */}
                        <div className={'d-flex justify-content-between gap-12 px-3 ' + (pageConstant.tabWrap_class)}>
                            <div onClick={() => setActiveTab("cancel")}
                                 className={activeTab === "cancel" ? "active-tab-local" : "inactive-tab-local"}>
                                <p className={pageConstant.tab_class}>Cancel</p>
                            </div>
                            <div onClick={() => setActiveTab("reschedule")}
                                 className={activeTab === "reschedule" ? "active-tab-local" : "inactive-tab-local"}>
                                <p className={pageConstant.tab_class}>Reschedule</p>
                            </div>
                            <div onClick={() => setActiveTab("modify")}
                                 className={activeTab === "modify" ? "active-tab-local" : "inactive-tab-local"}>
                                <p className={pageConstant.tab_class}>Modify</p>
                            </div>
                        </div>
                        <div className={'margin-bottom-32'}>
                            {
                                activeTab === "cancel" &&
                                <CancelBooking cancelDetails={cancelDetails} submitHandler={cancelSubmitHandler}
                                               activeReason={activeReason}
                                               setActiveReason={setActiveReason}
                                               remarks={remarks}
                                               setRemarks={setRemarks}
                                               activePassengerList={activePassengerList}
                                               setActivePassengerList={setActivePassengerList}
                                               bookingId={state.bookingId}
                                />
                            }
                            {
                                activeTab === "reschedule" &&
                                <RescheduleBooking bookingId={state.bookingId}
                                                   submitHandler={(date) => rescheduleSubmitHandler(date)}
                                />
                            }

                            {
                                activeTab === "modify" &&
                                <ModifyBooking referenceNo={state.referenceNo} bookingId={state.bookingId}
                                               modifyStatus={modifyStatus} errorMessage={errorMessage}
                                               submitHandler={(data) => modifySubmitHandler(data)}/>
                            }
                        </div>
                        <div className={'payment-type-modal'}>
                            <MDBModal isOpen={refundModal} position={'bottom'}>
                                <MDBModalBody>
                                    <div className={'p-3'}>
                                        <CancelBookingPayment
                                            cancelDetails={cancelDetails}
                                            activePayment={activePayment}
                                            modifyStatus={modifyStatus}
                                            setActivePayment={setActivePayment}
                                            setModal={setRefundModal}
                                            refundStatus={""}
                                            cancelPaymentSubmitHandler={(args) => cancelPaymentSubmitHandler(args)}/>
                                    </div>

                                </MDBModalBody>
                            </MDBModal>
                        </div>

                    </div>

                </MobileSubScreenLayout>
            }
            {
                refundStatus === "success" &&
                <MobileSubScreenLayout back={() => navigate('/')} title={"Refund Successfull"}>
                    <RefundResultComponent success={true}
                                           message={"Refund has been successfully initiated. It should be " +
                                               "credited to your original mode of payment within 6-7 business days"}/>
                </MobileSubScreenLayout>
            }
            {
                refundStatus == 'pending' && <Loader/>
            }
            {
                refundStatus === "fail" &&
                <MobileSubScreenLayout back={() => navigate('/')} title={"Refund Successfull"}>
                    <RefundResultComponent success={false} message={errorMessage}/>

                </MobileSubScreenLayout>
            }
            {
                modifyStatus === "success" &&
                <MobileSubScreenLayout back={() => navigate('/')} title={"Modify Successfull"}>
                    <ModifyResultComponent success={false} message={"We will update the Coach Host with new details "}/>

                </MobileSubScreenLayout>
            }
            {
                modifyStatus === "fail" &&
                <MobileSubScreenLayout back={() => navigate('/')} title={"Modify Successfull"}>
                    <ModifyResultComponent success={false} message={errorMessage}/>

                </MobileSubScreenLayout>
            }
        </div>

    )
}

export default ChangeBookingPage
