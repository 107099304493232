import {
	FILTER_MODAL_ACTION,
	POST_BOOKING_DATA,
	SET_MOBILE_PAGE,
	SET_RESCHEDULE,
	SET_SELECTED_BOOKING_ID,
	SET_RECENT_SEARCH, SET_VERSION,
	SET_TERMS_CONDITIONS_MODAL,
	SET_CHECKLIST_NAME,
	SET_LOADING_STATE,
	SET_GREEN_MILES_STATE,
} from "../Actions";

const initialState = {
	activeMobilePage: "",
	postBookingData: {},
	filterModal: "",
	reschedule: false,
	selectedBookingId: "",
	recentSearch: [],
	version: "",
	termsConditionModal: false,
	checklistCurrentName: "",
	loadingState:false,
	greenMilesState:false,
}

const CommonReducer = (state = initialState, action) => {
	switch (action.type) {

		case SET_MOBILE_PAGE:
			return {
				...state,
				activeMobilePage: action.payload.data
			}

		case POST_BOOKING_DATA:
			return {
				...state,
				postBookingData: action.payload.data
			}
		case FILTER_MODAL_ACTION:
			return {
				...state,
				filterModal: action.payload.data
			}
		case SET_RESCHEDULE:
			return {
				...state,
				reschedule: action.payload.data
			}
		case SET_SELECTED_BOOKING_ID:
			return {
				...state,
				selectedBookingId: action.payload.data
			}

		case SET_RECENT_SEARCH:
			return {
				...state,
				recentSearch: action.payload.data
			}
		case SET_VERSION:
			return {
				...state,
				version: action.payload.data
			}

		case SET_TERMS_CONDITIONS_MODAL:
			return {
				...state,
				termsConditionModal: action.payload.data
			}
		case SET_CHECKLIST_NAME:
			return {
				...state,
				checklistCurrentName: action.payload.data
			}
		case SET_LOADING_STATE:
			return {
				...state,
				loadingState: action.payload.data
			}
		case SET_GREEN_MILES_STATE:
			return {
				...state,
			    greenMilesState: action.payload.data
			}

		default:
			return state
	}
}

export default CommonReducer
