import graphql from 'babel-plugin-relay/macro';

const GetWalletDetailsQuery = graphql`
	  query GetWalletDetailsQuery{
	  getWalletDetails {
		    PromotionalBalance
		    NonPromotionalBalance
		}
	  }
	  `
export default GetWalletDetailsQuery;