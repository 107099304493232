import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";

const mutation = graphql`
  mutation NewsletterUserMutation($email: String!) {
    newsletterUser(email: $email) {
      status
      message
    }
  }
`;

export default (data, callback, errCallback) => {
  const variables = {
    email: data.email,
  };
  commitMutation(environment, {
    mutation,
    variables,

    onCompleted: (response, err) => {
      if (response.newsletterUser !== null) {
        callback(response);
      } else {
        errCallback(err[0].message);
      }
    },
    onError: (err) => {
      errCallback(err);
    },
  });
};
