import React,{useState} from 'react'
import {useMediaQuery} from "react-responsive";
import {  MDBModal, MDBModalBody } from 'mdbreact';

import referModalImage from "../../../assets/images/referModalImage.png"
import appstore from "../../../assets/revamp/appstore.png"
import tealCloseIcon from "../../../assets/icons/teal-close.svg"

//assets
const referAndEarnIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/refer-and-earn-illustration.png`
const playstore = process.env.REACT_APP_CDN_LINK + `assets/images/playstore.png`

const ReferAndEarnCard = (props) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [modal, setModal ] = useState(false)
    let componentData
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "open-700w-24s-36h",
            submitButton_class: "open-400w-16s-24h"
        }
    } else {
        componentData = {
            title_class: "open-600w-16s-24h",
            submitButton_class: "open-600w-12s-18h"
        }
    }

    const toggleOpen = (toOpen) => {
        isDesktopOrLaptop? setModal(toOpen): props?.setReferState(true)
    }

    return (<div className={'refer-and-earn-card'}>
        <div className={'mb-4'}>
            <p className={componentData.title_class + '  mb-0'}>Enjoy a discount on your</p>
            <p className={componentData.title_class + ' mb-0'}>next trip!</p>
            <button className={componentData.submitButton_class + ' submit-button    mt-4'} onClick={()=>toggleOpen(true)}>Refer & Earn</button>
        </div>
        <img src={referAndEarnIllustration} alt={'alt'} className={'refer-and-earn-illustration'}/>
{modal && isDesktopOrLaptop && <div className={'refer-modal-component'}>
        <MDBModal isOpen={modal} position={'center'} toggle={()=>toggleOpen(false)} centered={true}
                size={"md"} >
            <MDBModalBody>
                <div className="refer-modal-body d-flex flex-column align-items-center">
                    <img className={'close-icon-refer icon-16'} src={tealCloseIcon} alt={'alt'} onClick={()=>toggleOpen(false)} /> 
                    <img className='refer-image' src={referModalImage} alt="refer and earn from NueGo App" />
                    <h2 className='refer-heading'>Download NueGo App to use this feature</h2>
                    <p className='refer-desc'>We have this feature available only on our Mobile App. Download now to refer your friends and earn rewards</p>
                    <div className='app-icons-div'>
                    <a href="https://play.google.com/store/apps/details?id=com.gcm.nuego" target='_blank'>
                    <img src={playstore} alt="" className="playstore" />
                    </a>
                    <a href="https://apps.apple.com/in/app/nuego/id1643122909" target='_blank'>
                    <img src={appstore} alt="" className="playstore" />
                    </a>
                    </div>
                </div>
            
            </MDBModalBody>
        </MDBModal>
</div>}
    </div>)


}

export default ReferAndEarnCard