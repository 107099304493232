import React from 'react';
import JoinHands from './Component/JoinHands';
import AdvertiseWithUs from './Component/AdvertiseWithUs';
import GetInTouch from './Component/GetInTouch';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Helmet } from 'react-helmet';
import AuthModalLayout from '../../layout/AuthModalLayout/AuthModalLayout';

const PartnerWithUs = () => {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const navigate = useNavigate();

	const backHandler = () => {
		navigate(-1)
	}
	return (
		<AuthModalLayout>
			<Helmet>
				<title>Partner With Nuego for All Your Bus Necessities</title>
				<link rel="canonical" href="https://www.nuego.in/partner-with-us" hreflang="en-IN" />
				<meta name="description" content="In NueGo's partner with us programm we aim to tailor our service according to the partners requirement and spread the use of ecofriendly intercity AC buses." />
				<meta name="keywords" content="Partner with NueGo,Collaborate with NueGo"></meta>
				<meta name="robots" content="index,follow"/>
				<script type='application/ld+json'>
					{JSON.stringify({
					"@context": "http://schema.org",
					"@type": "WebPage",
					"url": "https://www.nuego.in/partner-with-us",
					"name": "Partner With Us - NueGo",
					"description": "Create potential synergies with NueGo in the advertising space. Collaborate with us for digital space selling, in-bus advertisements and bilateral promotions.",
					"publisher": {
						"@type": "Organization",
						"name": "NueGo",
						"logo": {
						"@type": "ImageObject",
						"url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
						"width": 200,
						"height": 100
						}
					}
					})}
				</script>
			</Helmet>
			<div className={'partner-with-us-page'}>
				{/* navbar */}
				{isDesktopOrLaptop && <div style={isDesktopOrLaptop ? { marginBottom: '100px' } : { marginBottom: "77px" }}>
					<Navbar />
				</div>}
				<div className={'white-fold mt-0 ' + "mb-lg-0"}>

					<JoinHands backHandler={backHandler} />
					<AdvertiseWithUs />
					<GetInTouch />
				</div>
				{/* {isDesktopOrLaptop && <Footer/>} */}
				<Footer />
			</div>
			</AuthModalLayout>
	)
}

export default PartnerWithUs;