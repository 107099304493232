import React from 'react'
import './styles.scss'
import AuthModalLayout from "../AuthModalLayout/AuthModalLayout";
//assets
import downWhiteArrow from "../../assets/icons/keyboard_arrow_downdown-facing-cavet.png"
import backWhiteArrow from "../../assets/icons/back-white-arrow.svg"

const MobileSubScreenLayout = ({children, title, back, isDropDown}) => {
    return (
        <AuthModalLayout>
            <div className={'mobile-subscreen-layout'}>
                <div className={'dark-fold'}>
                    <div className={'d-flex justify-content-center position-relative'}>
                        <p className={'open-600w-16s-24h white-color mb-0'}>{title}</p>
                        {isDropDown && <img src={downWhiteArrow} className={'icon-24'} alt={'alt'}/>}
                        <img src={backWhiteArrow} className={'icon-24 back-arrow'}
                             alt={'alt'} onClick={back}/>
                    </div>
                </div>
                <div className={'light-fold '+(title === "Passenger Details" && "position-relative overflow-hidden pt-0")}>
                    {children}
                </div>

            </div>
        </AuthModalLayout>
    )
}

export default MobileSubScreenLayout
