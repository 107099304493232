import graphql from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import environment from "../../environment";

const mutation = graphql`
	mutation CancelBookingSinglePassengerMutation(
		$bookingId: String!
		$cancellationReason: String!
		$cancellationRemark: String
		$paymentType:PaymentTypeEnum
		$refundType: Int!
	) {
		cancelBooking(
	    bookingId:$bookingId
	    cancellationReason:$cancellationReason
	    cancellationRemark:$cancellationRemark
	    paymentType:$paymentType
	    refundType:$refundType
	) {
		    cancelBooking {
		        totalFare
		        status
		        refundAmount
		        seatNumbers
		    }
		}
	}
	`

export default (data, callback, errCallback) => {
	const variables = {
		bookingId:data.bookingId,
		cancellationReason:data.cancellationReason,
		cancellationRemark:data.cancellationRemark,
		paymentType:data.paymentType,
		refundType:data.refundType
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.cancelBooking !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}