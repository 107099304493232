import graphql from "babel-plugin-relay/macro";

const GetTicketUrlQuery = graphql`
 query GetTicketUrlQuery($bookingId: String!) {
		getTicketUrl(bookingId:$bookingId){
			url
			status
			message
		}
	}
	`

export default GetTicketUrlQuery