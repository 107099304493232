import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TourImageOne from "../../../../../src/assets/images/tour-img-1.jpg";
import TourImageTwo from "../../../../../src/assets/images/tour-img-2.jpg";
import TourImageThree from "../../../../../src/assets/images/tour-img-3.jpg";
import TourImageFour from "../../../../../src/assets/images/tour-img-4.jpg";
import TourBtmImage from "../../../../../src/assets/images/tour-btm.png";
import RoadDsImage from "../../../../../src/assets/images/road-ds-img.png";
import RoadTopMbImg from "../../../../../src/assets/images/road-mb-top.png";
import RoadBtmMbImg from "../../../../../src/assets/images/road-mb-btm.png";
import BusDsImg from "../../../../../src/assets/images/ds-bus.png";
import RoadBtmDsImge from "../../../../../src/assets/images/road-btm-ds-img.png";
import VideoPlayerIcon from "../../../../../src/assets/images/video-player-icon.png";
import image51 from "../../../../../src/assets/images/5.1.jpg";
import image52 from "../../../../../src/assets/images/5.2.jpg";
import image53 from "../../../../../src/assets/images/5.3.jpg";
import image54 from "../../../../../src/assets/images/5.4.jpg";
import image61 from "../../../../../src/assets/images/6.1.jpg";
import image62 from "../../../../../src/assets/images/6.2.jpg";
import image63 from "../../../../../src/assets/images/6.3.jpg";
import image64 from "../../../../../src/assets/images/6.4.jpg";
import Nag1 from "../../../../../src/assets/images/Nag 1.jpg";
import Nag2 from "../../../../../src/assets/images/Nag 2.jpg";
import Nag3 from "../../../../../src/assets/images/Nag 3.jpg";
import Nag4 from "../../../../../src/assets/images/Nag 4.jpg";
import bhp1 from "../../../../../src/assets/images/Bhp 1.jpg";
import bhp2 from "../../../../../src/assets/images/Bhp 2.jpg";
import bhp3 from "../../../../../src/assets/images/Bhp 3.jpg";
import bhp4 from "../../../../../src/assets/images/Bhp 4.jpg";
import jai1 from "../../../../../src/assets/images/Jai 1.jpg";
import jai2 from "../../../../../src/assets/images/Jai 2.jpg";
import jai3 from "../../../../../src/assets/images/Jai 3.jpg";
import jai4 from "../../../../../src/assets/images/Jai 4.jpg";
import gzb1 from "../../../../../src/assets/images/GZB 1.jpg";
import gzb2 from "../../../../../src/assets/images/GZB 2.jpg";
import gzb3 from "../../../../../src/assets/images/GZB 3.jpg";
import gzb4 from "../../../../../src/assets/images/GZB 4.jpg";

const TourData = [
  {
    title: "“Starting our journey with our NueGo EV bus from peaks to coasts”",
    tourContent: [
      {
        image: TourImageOne,
        video: "",
        description:
          "NueGo Bus ready to Electrically pioneer through all of India",
        id: 1,
      },
      {
        image: TourImageTwo,
        video: "",
        description: "The NueGo team pledging safety and sustainability",
        id: 2,
      },
      {
        image: TourImageThree,
        video: "",
        description:
          "Naryal Breaking Ceremony facilitated by the CEO of Jammu Smart City - Dr Devansh Yadav ",
        id: 3,
      },
      {
        image: TourImageFour,
        video: "",
        description:
          "Flag Off to start our 4,000+ KMs journey with pride and joy",
        id: 4,
      },
    ],
  },
  {
    title:
      "“Meeting & Paying Tribute to the Guardians of Our Nation on the Road!”",
    tourContent: [
      {
        image: image51,
        video: "",
        description:
          "Journeying through the small towns, into the heart of India ",
        id: 5,
      },
      {
        image: image52,
        video: "",
        description:
          "Celebrating with the Brave Souls of our Indian Army in the NueGo bus",
        id: 6,
      },
      {
        image: image53,
        video: "",
        description:
          "Honored to have the DSP of Pathankot - Sh. Sukhjinder Singh visit us",
        id: 7,
      },
      {
        image: image54,
        video: "",
        description:
          "Visiting for some rest & recharge at the abode of the Golden Holiness, Amritsar",
        id: 8,
      },
    ],
  },
  {
    title: "“Making Awareness and Education Fun at the Capital Of India!”",
    tourContent: [
      {
        image: image61,
        video: "",
        description:
          "Crossing the Haryana border, we journey into the vibrant heart of Delhi",
        id: 9,
      },
      {
        image: image62,
        video: "",
        description:
          "Fun and Interactive Road Safety Training Session at our B2G Depot",
        id: 10,
      },
      {
        image: image63,
        video: "",
        description:
          "Testing the youth on Awareness About Electric Buses in India",
        id: 11,
      },
      {
        image: image64,
        video: "",
        description:
          "Honoring the India Gate for its timeless strength and grace in the heart of the capital",
        id: 12,
      },
    ],
  },
  {
    title:
      "“Media Buzz Builds as NueGo Prepares for the Major Announcement with Dignitaries and Special Guests”",
    tourContent: [
      {
        image: gzb1,
        video: "",
        description:
          "The inquisitive media awaiting the big announcement by NueGo",
        id: 13,
      },
      {
        image: gzb2,
        video: "",
        description:
          "Dignitaries on the Dias gracing it with their presence:Shri. Sudhendhu Jyoti Sinha (NITI Aayog, GOI), Dr. Sanjeev Kumar Lohia (Senior Advisor, The World Bank), Mr. Abhijeet Sinha (Project Director, National Highways for EV), and Mr. Amit Bhatt (Managing Director - ICCT, India) with our MD & CEO - Mr. Devendra Chawla",
        id: 14,
      },
      {
        image: gzb3,
        video: "",
        description:
          "Sapling planting ceremony by our esteemed dignitaries symbolizing Environmental commitment, Growth, Leadership, and a lasting positive impact.",
        id: 15,
      },
      {
        image: gzb4,
        video: "",
        description:
          "Our LGBTQ partner, Basera Samajik Sansthan, graced the ceremony, sharing thoughtful insights and offering best wishes to NueGo on its latest milestone.",
        id: 16,
      },
    ],
  },
  {
    title: "“Celebrating Connection, Learning, and Heritage on Our Journey!”",
    tourContent: [
      {
        image: jai1,
        video: "",
        description:
          "Giving a friendly nod to our fellow Indians and their moo-ving companions!",
        id: 17,
      },
      {
        image: jai2,
        video: "",
        description:
          " Who says teaching is dull? We're making Road Safety fun with a lively game",
        id: 18,
      },
      {
        image: jai3,
        video: "",
        description:
          "What a pleasure it was to meet the curious minds and dedicated teachers at the inspiring heritage institution - Maharani Gayatri Devi School!",
        id: 19,
      },
      {
        image: jai4,
        video: "",
        description:
          "Making our way past the iconic Hawa Mahal - & celebrating the beauty of our past as we explore the future!",
        id: 20,
      },
    ],
  },
  {
    title:
      "“Moo-ving Through India: A Journey of Sustainability, Safety, and Heritage!”",
    tourContent: [
      {
        image: bhp1,
        video: "",
        description:
          "Moo-ving Through India and Spreading the Word on Sustainability",
        id: 21,
      },
      {
        image: bhp2,
        video: "",
        description:
          "Welcoming Bhopal Traffic DCP Shri Sanjay Singh and DSP Shri Pramod Dubey, accompanied by our wonderful staff dressed in Kashmir-Kanyakumari!",
        id: 22,
      },
      {
        image: bhp3,
        video: "",
        description:
          "Showing off our Safety features to the DCP and DSP - because our commitment to safety shines brighter than our Bus headlights!",
        id: 23,
      },
      {
        image: bhp4,
        video: "",
        description:
          "Continuing our journey, with heritage monuments guiding us along the way!",
        id: 24,
      },
    ],
  },
  {
    title: "“Celebrating Connection, Learning, and Heritage on Our Journey!”",
    tourContent: [
      {
        image: Nag1,
        video: "",
        description:
          "Getting all decked up and spruced up to meet the esteemed Union Minister, dedicated to transforming India’s Road infrastructure!",
        id: 25,
      },
      {
        image: Nag2,
        video: "",
        description:
          "Blessed to have Hon. Shri Nitin Gadkari, the man steering India's Roadways & Highways, grace us with his presence!",
        id: 26,
      },
      {
        image: Nag3,
        video: "",
        description:
          "Flag off by Hon. Shri Nitin Gadkari as we continue our journey from the Orange Capital of India!",
        id: 27,
      },
      {
        image: Nag4,
        video: "",
        description:
          "Tranquil skies welcoming us for the journey beyond, reflecting the vibrant tapestry of India’s diverse colors and cultures",
        id: 28,
      },
    ],
  },
];

const TourSection = () => {
  const [isStickyActive, setIsStickyActive] = useState(false);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [play, setPlay] = useState(false);
  // const [currentLocation, setCurrentLocation] = useState("Kashmir");
  const [kmsCovered, setKmsCovered] = useState(0);
  const [percentageCovered, setPercentageCovered] = useState(0);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 992px)" });
  const isBelow992 = useMediaQuery({ query: "(max-width: 991px)" });
  const isBetween992And1300 = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1299px)",
  });
  const isAbove1300 = useMediaQuery({ query: "(min-width: 1300px)" });

  useEffect(() => {
    const handleScroll = () => {
      const tourSection = document.querySelector(".tour-container");
      let rect = tourSection.getBoundingClientRect();
      let isInView;
      if (tourSection && !isDesktopOrLaptop) {
        isInView = rect.top < window.innerHeight && rect.bottom - 500 >= 0; // Element is within the viewport
        setIsStickyActive(isInView);
      } else if (isDesktopOrLaptop) {
        isInView = rect.top - 500 <= 0 && rect.bottom >= window.innerHeight;
        setIsStickyActive(isInView);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const locationData = [
    { location: "Kashmir", kms: 0, percentage: 0 },
    { location: "Amritsar", kms: 211, percentage: 5 },
    { location: "Delhi", kms: 694, percentage: 17 },
    { location: "Ghaziabad", kms: 694, percentage: 17 },
    { location: "Jaipur", kms: 974, percentage: 24 },
    { location: "Bhopal", kms: 1578, percentage: 39 },
    { location: "Nagpur", kms: 1748, percentage: 43},
  ];

  useEffect(() => {
    const handleScroll = () => {
      const tourSection = document.querySelector(".tour-container");
      let rect = tourSection.getBoundingClientRect();

      if (rect.top <= 0 && rect.bottom >= window.innerHeight) {
        const currentScroll = window.scrollY;

        // Check which section is in view based on scroll
        locationData.forEach((data, index) => {
          const locationElement = document.getElementById(
            data.location.toLowerCase()
          );
          if (locationElement) {
            const locationRect = locationElement.getBoundingClientRect();
            // Detect when the location section is in view
            if (
              locationRect.top + 300 < window.innerHeight &&
              locationRect.bottom >= 0
            ) {
              // setCurrentLocation(data.location);
              setKmsCovered(data.kms);
              setPercentageCovered(data.percentage);
            }
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [locationData]);

  const playHandler = (id) => {
    const videoElement = document.getElementById(id);

    if (playingVideoId !== id) {
      if (playingVideoId) {
        const previousVideoElement = document.getElementById(playingVideoId);
        if (previousVideoElement) {
          previousVideoElement.pause();
          setPlay(false);
        }
      }

      setPlayingVideoId(id);
      setPlay(true);
      videoElement.play();
    } else {
      if (play) {
        setPlay(false);
        videoElement.pause();
      } else {
        setPlay(true);
        videoElement.play();
      }
    }
  };

  const getVideoDimensions = () => {
    if (isBelow992) {
      return { width: 243, height: 200 };
    } else if (isBetween992And1300) {
      return { width: 200, height: 164 };
    } else if (isAbove1300) {
      return { width: 243, height: 200 };
    }
    return { width: 243, height: 200 };
  };

  const videoDimensions = getVideoDimensions();

  return (
    <div className={`tour-container ${isStickyActive ? "sticky-active" : ""}`}>
      {isDesktopOrLaptop && (
        <div className="km-part">
          <p className="km-cover">Kms Covered</p>
          <p className="km">
            <span>{kmsCovered}+</span>
            <span>{percentageCovered}%</span>
          </p>
        </div>
      )}
      <div className="tour-part">
        <p className="title">Kickstarting the ultimate India Tour</p>
        {!isDesktopOrLaptop && (
          <div className="km-part">
            <p className="km-cover">Kms Covered</p>
            <p className="km">
              <span>{kmsCovered}+</span>
              <span>{percentageCovered}%</span>
            </p>
          </div>
        )}

        <div className="bus-sticky">
          <img src={BusDsImg} alt="bus" className="bus-img" />
        </div>

        <div>
          {TourData.map((data, index) => {
            return (
              <div className="sub-container" key={index}>
                <p
                  className={
                    index % 2 === 0
                      ? "sub-container-title"
                      : "sub-container-right-title"
                  }
                >
                  {data.title}
                </p>
                <div className="tour-img-section">
                  {data.tourContent.map((data, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className={
                          subIndex === 1
                            ? "right-space video-player-part"
                            : "video-player-part"
                        }
                        style={{ cursor: data.video !== "" ? "pointer" : "" }}
                      >
                        <video
                          onClick={() => {
                            if (data.video) {
                              playHandler(data.id);
                            }
                          }}
                          poster={data.image}
                          preload="none"
                          width={videoDimensions?.width}
                          height={videoDimensions?.height}
                          id={data.id}
                          className={"video-tag"}
                        >
                          <source src={data.video} type="video/mp4" />
                        </video>
                        {data.video !== "" &&
                          (!play || playingVideoId !== data.id) && (
                            <div>
                              <img
                                onClick={() => playHandler(data.id)}
                                alt="player-icon"
                                src={VideoPlayerIcon}
                                className="player-icon"
                                height={40}
                                width={40}
                              />
                            </div>
                          )}
                        <p className="mb-0 img-below-text">
                          {data.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="road-img-part">
          {isDesktopOrLaptop && (
            <img src={RoadTopMbImg} alt="road-img" className="road-top-img" />
          )}
          <p className="number">01 </p>
          <p className="location-name-first" id="kashmir">
            {" "}
            Kashmir
          </p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">02 </p>
          <p className="location-name" id="amritsar">
            Amritsar
          </p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">03 </p>
          <p className="location-name" id="delhi">
            New Delhi
          </p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">04</p>
          <p className="location-name" id="ghaziabad">
            Ghaziabad
          </p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">05 </p>
          <p className="location-name" id="jaipur">
            Jaipur
          </p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">06 </p>
          <p className="location-name" id="bhopal">
            Bhopal
          </p>
          <img src={RoadDsImage} alt="road-img" className={"road-ds-img"} />
          <p className="number">07 </p>
          <p className="location-name" id="nagpur">
            Nagpur
          </p>
          <img
            src={isDesktopOrLaptop ? RoadBtmDsImge : RoadBtmMbImg}
            alt="road-img"
            className={isDesktopOrLaptop ? "road-ds-diff-img" : "tour-mb-btm"}
          />
        </div>

        <div>
          <p className="reaching">Were Reaching Soon</p>
          <p className="location">To our next stop Adilabad!</p>
          <div className="tour-place-img">
            <img
              src={TourBtmImage}
              alt="tour-btm-img"
              className={"tour-btm-img"}
            />
            <p>Adilabad</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourSection;
