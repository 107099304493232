import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import RecommendedBlogs from "../BlogLandingPage/Components/RecommendedBlogs/RecommendedBlogs";
import ExtraBlogPart from "../BlogLandingPage/Components/ExtraBlogPart";
// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

function TenFamousWaterfallsinIndia() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();



    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>10 Waterfalls in India You Must See in 2024 | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog/famous-waterfall-in-india" hreflang="en-IN" />
                <meta name="description" content="Discover India's best famous waterfalls in India to visit in 2024. Explore beautiful waterfall destinations, must-try foods, timings, and nearby attractions for an unforgettable experience" />
                <meta name="keywords" content=""></meta>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);



    const dataContent = [
        {

            title: "10 Famous Waterfalls in India that You Cannot Miss in 2024",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Hero%20Image.jpg",
            imgAlt: "Best Places to Visit in India During August",
            desc:

                `Where nature calls, is where water falls. So, when water falls in India, it falls as waterfalls most of the time. No amount of wordplay, however, can prepare you for how exotic the sight of a waterfall can be, especially when it is very close to lush green vegetation.
\n\n
Waterfalls are magnificent performances by nature that mesmerize most people who watch them in awe. India is home to several beautiful waterfalls and is by many accounts home to some of the most mysterious and secret waterfalls found in forests that have legends behind them. Many a waterfall in India will be found hidden in the dense ghats, where there is ample scope for sudden altitude changes and an abundance of rivers and other sources of water.
\n\n
Waterfalls are some of the most visited places in India for family picnics and photography sessions. Visiting the waterfalls during monsoon when it is slightly adventurous and dangerous, can still be very rewarding as it is the time when you are most likely to see the waterfalls in their full magnanimity. You will witness a real competition for the rank of the best waterfall in India on our list as each waterfall has its unique traits.
\n\n
## Famous Waterfalls in India - A Summary
\n\n
### Most Popular Waterfall in India
Jog Falls, Dudhsagar Falls, Athirappilly Falls, Nohkalikai Falls, Shivanasamudra Falls
\n\n
### The Highest Waterfall in India (single plunge)
Nohkalikai Falls
\n\n
### The Widest Waterfall in India
Chitrakote Falls
\n\n
### Best Time to Visit Waterfalls in India
June to September
\n\n
### Fun Things to Do at a Waterfall in India
Trekking, Photography, Swimming, Picnicking, Bird watching
\n\n
## 1. Jog Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Jog%20Falls.jpg "Jog Falls")
\n\n
Jog Falls in the Shimoga district of Karnataka is one of the highest waterfalls in India, dropping about 830 feet in a single fall. Originating from the Sharavathi River, this waterfall creates a scenic appearance, especially during the monsoon season when the river is at its full capacity. The falls are created by bodies of water named Raja, Rani, Roarer, and Rocket, creating a splurge of water that falls into the abyss indefinitely. Jog Falls is considered by many as the most popular and best waterfall in India.
\n\n
Jog Falls creates a breathtaking image in people's minds due to its humongous depth. It is also an adventurous spot for trekking and boating, making it a place that should not be missed by nature lovers and thrill-seekers alike. Jog Falls is more than just spectacular beauty, it embeds itself deep within the local culture and local traditions. The valley around the fall is lush with small villages, and one can feel the warmth and hospitality of the people. There are countless legends involving the fall, which adds a certain mystique to the visit. Jog Falls is certainly one of the most popular waterfalls in India.
\n\n  
**Height of the Waterfall:** 830 feet  
**Width of the Waterfall:** 1,550 feet  
**Foods to Try near the Waterfall:** Akki Rotti, Jolada Rotti, Bisi Bele Bath  
**Other Places to Visit Nearby:** Linganamakki Dam, Honnemaradu, Dabbe Falls  
**Distance from the Nearest Metro City:** 400 km from Bangalore  
**Location:** Shimoga District, Karnataka  
**Timings:** 6:00 AM - 8:00 PM  
**Average Time Spent:** 2-3 hours
\n\n
## 2. Dudhsagar Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Dudhsagar%20Falls.jpg "Dudhsagar Falls")
\n\n
Dudhsagar Falls which can be translated to the Sea of Milk, is considered by many as the loftiest and most powerful waterfall in India. The scenic waterfall is located in the Bhagwan Mahavir Wildlife Sanctuary at Collem in Goa. Four tiers cascade down from a height of about 1,020 feet into the Mandovi River below. Renowned for presenting a milky white sight to on-lookers, Dudhsagar Falls contrasts nicely with the green surroundings. Walking to the Dudhsagar is an adventurous trek through the forest or a jeep ride on the rugged drive.
\n\n
With an awe-inspiring dimension and force, Dudhsagar Falls has a rich surrounding biodiversity which gives a wonderful opportunity to a tourist to be near to nature. Within the Bhagwan Mahavir Sanctuary lies a wide range of wildlife - leopards, deer, and huge varieties of birds. Indeed, the very place is noted for being a sanctuary for nature and wildlife lovers and it is water falls in India like Dudhsagar that make the surrounding regions more attractive to tourists.
\n\n  
**Height of the Waterfall:** 1,020 feet  
**Width of the Waterfall:** 100 feet  
**Foods to Try near the Waterfall:** Goan Fish Curry, Bebinca, Feni  
**Other Places to Visit Nearby:** Tambdi Surla Temple, Bhagwan Mahavir Wildlife Sanctuary  
**Distance from the Nearest Metro City:** 72 km from Panaji  
**Location:** Sonaulim, Goa  
**Timings:** 6:00 AM - 5:00 PM  
**Average Time Spent:** 4-5 hours
\n\n
## 3. Athirappilly Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Athirappilly%20Falls.jpg "Athirappilly Falls")
\n\n
Athirappilly Falls is the largest waterfall in Kerala, measuring 80 feet in height and more than 330 feet in width. Located at the Chalakudy River, Athirappilly Falls exists in a forest zone of wildlife that includes elephants, leopards, as well as myriad other beautiful specimens of birds. Generally, the best time to visit the falls is in the monsoon season, so the river remains filled and the waterfall has a beautiful flow. The Vazhachal Falls, another popular waterfall, and the Charpa Falls are also close, which add to the beauty of this place thereby making the surrounding areas one of the best waterfall places in India.
\n\n
Beyond being a marvel of nature, Athirappilly Falls is one of the top waterfalls in India and constitutes an inextricable component of the surrounding ecosystem. The surrounding forests are included in the Western Ghats, one of the world's biodiversity hotspots, and therefore harbor an exquisite wealth for biologists and nature lovers. Iconic Indian films have also featured the falls, and have appeared in some, making the place slightly more attractive on the cinematic side. It is considered by many as the most beautiful waterfall in India.
\n\n  
**Height of the Waterfall:** 80 feet  
**Width of the Waterfall:** 330 feet  
**Foods to Try near the Waterfall:** Kerala Sadya, Puttu and Kadala Curry, Appam with Stew  
**Other Places to Visit Nearby:** Vazhachal Falls, Charpa Falls, Thumboormuzhy Dam  
**Distance from the Nearest Metro City:** 55 km from Kochi  
**Location:** Thrissur District, Kerala  
**Timings:** 8:00 AM - 6:00 PM  
**Average Time Spent:** 3-4 hours
\n\n
## 4. Nohkalikai Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Nohkalikai%20Falls.jpg "Nohkalikai Falls")
\n\n
A plunge drop at a height of about 1,115 feet, Nohkalikai Falls near Cherrapunji, Meghalaya, is the tallest plunge waterfall in India. The name Nohkalikai is derived from an unfortunate local legend of a tragic woman by the name of Likai. Enclosed by the possessions of the lush green forests and mist-covered hills, the absolute beauty found in Nohkalikai Falls is unquestionably a leading tourist spot in the whole of the Northeast area. The emerald pool at the base further increases the charm of these waterfalls and is perfect for photography and meditation. The best time to visit these falls is during the post-monsoon season when the falls are in full glory and the surroundings are much fresh and verdant.
\n\n
The Nohkalikai Falls are not just something that pleases a very good vision of the eyes but a place to be, filled with colloquially famous beliefs and traditions. The legend of tragic Likai from which the falls take its name might add to making such an experience quite poignant instead of just being scenic. The falls are located in one of the wettest places on Earth, Cherrapunji, where the annual rainfall is amongst the highest in the world. Many people consider Nohkalikai Falls the best waterfall in India owing to its long plunge height.
\n\n  
**Height of the Waterfall:** 1,115 feet  
**Width of the Waterfall:** 75 feet  
**Foods to Try near the Waterfall:** Jadoh, Tungrymbai, Dohneiiong  
**Other Places to Visit Nearby:** Mawsmai Cave, Seven Sisters Falls, Eco Park  
**Distance from the Nearest Metro City:** 55 km from Shillong  
**Location:** Cherrapunji, Meghalaya  
**Timings:** 8:00 AM - 5:00 PM  
**Average Time Spent:** 1-2 hours
\n\n
## 5. Bhagsu Waterfalls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Bhagsu%20Waterfalls.jpg "Bhagsu Waterfalls")
\n\n
The Bhagsu Waterfall lies very near to the famous hill station McLeod Ganj in Himachal Pradesh and is a quite serene and engaging site for tourists and trekkers. The small waterfall gushes down from a height of about 30 feet, providing a very riveting contrast with the rest of the rugged terrain of the Dhauladhar range. The small Bhagsunath Temple nearby makes the ambiance spiritual, hence making the place attractive not only to nature lovers but pilgrims as well. Bhagsu Waterfall creates the best view in the monsoon, when the water gushes beautifully apart at the optimum speed, with its fringes always coupled with bright greens. Bhagsu is one of the most beautiful waterfalls in India.
\n\n
Bhagsu Waterfall is not only a location to be observed with one's eyes but also through spirituality, culture, and nature. The nearby Bhagsunath Temple, also dedicated to Lord Shiva, makes it a historic place associated with penance and prayer. It is a vibrant place where tourists and pilgrims come to take a light shower in the temple. The waterfall serves as a nice place for picnicking, doing yoga, and meditating, a peaceful place amidst the contrasting busy life of McLeod Ganj. Since it is situated near the mountains and the Himalayas, it is widely considered the best waterfall in India by sightseeing enthusiasts.
\n\n  
**Height of the Waterfall:** 30 feet  
**Width of the Waterfall:** 10 feet  
**Foods to Try near the Waterfall:** Tibetan Momos, Thukpa, Butter Tea  
**Other Places to Visit Nearby:** Bhagsunath Temple, Triund, Dal Lake  
**Distance from the Nearest Metro City:** 9 km from Dharamshala  
**Location:** McLeod Ganj, Himachal Pradesh  
**Timings:** 7:00 AM - 7:00 PM  
**Average Time Spent:** 1-2 hours
\n\n
## 6. Meenmutty Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Meenmutty%20Falls.jpg "Meenmutty Falls")
\n\n
Meenmutty Falls is one of the largest and most spectacular waterfalls in the Wayanad district of Kerala. The place has a triple-decker fall, with a drop of around 980 feet, but it is all tucked away in dense green forests holding on to the Western Ghat range. However, it's not among the easily accessible falls. Going to Meenmutty involves a tough and impressive trail through thick forests. The name 'Meenmutty' itself means 'where fish are blocked,' denoting the splendorous number of rocky pools the fall creates along its descent. It is considered one of the most beautiful waterfalls in India.
\n\n
The area surrounding Meenmutty Falls is stunningly beautiful and provides adventure and tranquility in equal measure. Rich biodiversity beckons you as you trot along to see piles of wild orchids, groups of birds, varieties of butterflies, and other species that could be wild elephants or wild boars. The fall is awe-inspiring while in the monsoons, the water splashes with tremendous force, thereby creating a misty drizzle all around. Meenmutty Falls is one of the most famous waterfalls in India.
\n\n  
**Height of the Waterfall:** 980 feet  
**Width of the Waterfall:** 40 feet  
**Foods to Try near the Waterfall:** Kerala Parotta, Malabar Biryani, Fish Curry  
**Other Places to Visit Nearby:** Banasura Sagar Dam, Edakkal Caves, Chembra Peak  
**Distance from the Nearest Metro City:** 130 km from Kozhikode  
**Location:** Wayanad District, Kerala  
**Timings:** 9:00 AM - 5:30 PM  
 **Average Time Spent:** 2-3 hours
\n\n
## 7. Barehipani Falls, Odisha
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Barehipani%20Falls,%20Odisha.jpg "Barehipani Falls, Odisha")
\n\n
Situated at the Simlipal National Park in Odisha, Barehipani Falls is claimed to be one of the highest waterfall in India, falling from a height of 1,312 ft. This waterfall is divided into two sections and flows down from Meghasani Mountain, which is among one of the highest peaks in Eastern Ghats. The falls form an integral part of the national park, which is a UNESCO World Heritage site famous for being home to tigers, elephants, and various other rare plants and animals.
\n\n
The trail to Barehipani Falls is as beautiful as the sight itself. Nestled within the interiors of Simlipal amidst the fresh greenery lies this site which contains myriads of such rare and endangered species. The Budhabalanga River of the Simlipal hills forms the source of the Barehipani Falls. This kind of view, where water plummets from such a height, is an amazing sight because the mist created rises to meet the dense, high-canopy forest. The monsoon season will be the best season to view Barehipani Waterfalls since the gushing water will add magnificence to these falls. Some consider Barehipani the best waterfall in India due to its sheer height and surrounding vividity.
\n\n  
**Height of the Waterfall:** 1,312 feet  
**Width of the Waterfall:** 30 feet  
**Foods to Try near the Waterfall:** Pakhala Bhata, Dalma, Chhena Poda  
**Other Places to Visit Nearby:** Joranda Falls, Simlipal National Park, Ramatirtha  
**Distance from the Nearest Metro City:** 260 km from Bhubaneswar  
**Location:** Mayurbhanj District, Odisha  
**Timings:** 6:00 AM - 6:00 PM  
**Average Time Spent:** 2-3 hours
\n\n
## 8. Shivanasamudra Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Shivanasamudra%20Falls.jpg "Shivanasamudra Falls")
\n\n
Shivanasamudra Falls is located in the district of Mandya, in eastern Karnataka. It is one of the oldest hydroelectric power stations in the whole of Asia and is believed to be one of the most beautiful waterfalls in India. It is here that the waters of the Kaveri River break into rapids and form two distinct segments: the twin waterfalls of Gaganachukki and Bharachukki. These falls plunge into drops 320 feet high, creating a spectacular view that is breathtaking to visitors making Shivanasamudra one of the top waterfalls in India.
\n\n
The area around Shivanasamudra is rich in history and beauty. Thick greenery surrounds the falls, making this another great spot to enjoy picnics and photography. Though its best season is during the monsoon, with high water flow and at this time of year one can see the falls at their best, they can be visited and enjoyed at other times too. Shivanasamudra is also comparatively close to other attractions like ancient temples in partially sand-covered Talakad, and the beautiful town of Somanathapura noted for the intricately carved Keshava Temple.[Book a bus with NueGo to Bangalore and](https://www.nuego.in/all-routes?city=Bangalore) travel to Shivanasamudra from Bangalore.
\n\n  
 **Height of the Waterfall:** 320 feet  
**Width of the Waterfall:** 850 feet  
**Foods to Try near the Waterfall:** Ragi Mudde, Maddur Vada, Bisi Bele Bath  
 **Other Places to Visit Nearby:** Talakad, Somanathapura Temple, Gaganachukki Viewpoint  
 **Distance from the Nearest Metro City:** 120 km from Bangalore  
**Location:** Mandya District, Karnataka  
 **Timings:** 8:00 AM - 5:00 PM  
**Average Time Spent:** 3-4 hours
\n\n
## 9. Chitrakote Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Chitrakote%20Falls.jpg "Chitrakote Falls")
\n\n
Also referred to as the "Niagara of India" sometimes, for its wide span, Chitrakote Falls is considered the widest and best waterfall in India, with an estimated width of around 985 feet. Chitrakote Falls, located not far from the city of Jagdalpur in the Bastar district of Chhattisgarh, is formed by the Indravati River sharply falling over a horseshoe-shaped cliff lending itself to creating a jaw-dropping sight.
\n\n
The falls are in their splendor during the monsoon season; it is then that the full volume of water cascades, and the mist creates a spray from the falling rainbows in the sun's rays. Chitrakote Falls is not only an eyesore but also a great cultural hub. There lie small villages of various indigenous tribes in the vicinity, which avow an insight into their customs and traditions to the tourists. The sound of the water crashing down into the pool below is both deafening and awe-inspiring, making it a perfect spot for meditation and relaxation. Adjacent to this is the very famous Danteshwari Temple, which houses the idol of Goddess Danteshwari and thus renders your visit spiritual.
\n\n  
 **Height of the Waterfall:** 95 feet  
**Width of the Waterfall:** 985 feet  
**Foods to Try near the Waterfall:** Chana Samosa, Aamat, Dubh Cake  
**Other Places to Visit Nearby:** Danteshwari Temple, Tirathgarh Waterfalls, Kanger Valley National Park  
**Distance from the Nearest Metro City:** 300 km from Raipur  
**Location:** Bastar District, Chhattisgarh  
**Timings:** 6:00 AM - 6:00 PM  
**Average Time Spent:** 2-3 hours
\n\n
## 10. Nuranang Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024/Nuranang%20Falls.jpg "Nuranang Falls")
\n\n
Located in Tawang district, the sheltered picturesque land of Arunachal Pradesh, the Nuranang Falls, also known as Bong Bong Falls is one of the most gorgeous and less-explored waterfalls in India. The waterfall drops at a height of about 100 feet, offering a wonderful sight amidst the deep forests and rough mountains of the Eastern Himalayas. The falls are fed by the Nuranang River, which originates from the Suelek Pass, from its name, through the Tawang region. Nuranang Falls is certainly one of the most famous waterfalls in India.
\n\n
Nuranang Falls is one of those hidden treasures that epitomize tranquility, nestled far away from the hullabaloo of life. The space around the area of the waterfalls is sparsely populated, further justifying the peace and isolation. These falls are named after a local girl belonging to the Monpa tribe, named Nura. It is also predicated that the name gives credit to an Indian soldier from the 1962 Sino-Indian War. The most appropriate time to visit Nuranang Falls is during the summer and post-monsoon when the weather is appropriate. Nuranang is considered the best waterfall in India by many people.
\n\n  
**Height of the Waterfall:** 100 feet  
**Width of the Waterfall:** 50 feet  
**Foods to Try near the Waterfall:** Zan, Thukpa, Gyapa Khazi  
**Other Places to Visit Nearby:** Tawang Monastery, Sela Pass, Madhuri Lake  
**Distance from the Nearest Metro City:** 450 km from Guwahati  
**Location:** Tawang District, Arunachal Pradesh  
**Timings:** 6:00 AM - 6:00 PM  
**Average Time Spent:** 1-2 hours
\n\n
These were some of the most famous waterfalls in India. Since waterfalls are at their best during monsoon, consider going to any of these waterfalls during monsoon season, especially July and August. Furthermore, India is home to several places to visit during August to enjoy the rainy weather, so do not miss out on these places as well. Some of these places are waterfall places in India as well. A visit to any one of these places or waterfalls is bound to mesmerize your senses and create new memories that you will never forget for the rest of your lives. So, without further ado, pack your bags and let us go to the waterfalls of India that’ll make your tears fall after you see how beautiful they are.
`
        }
    ]



    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org/",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Home",
                                    "item": "https://www.nuego.in/"

                                }, {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "blog",
                                    "item": "https://www.nuego.in/blog/"

                                }, {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "famous waterfall in india",
                                    "item": "https://www.nuego.in/blog/famous-waterfall-in-india"

                                }]
                            })}
                        </script>
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>Blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>famous-waterfall-in-india</p>
                    </div>

                    }


                    <div className="containers d-flex flex-column">
                        <div className="main-blog-extra-blog">
                            {
                                dataContent.map((item, indx) => {
                                    return (
                                        <div key={indx} className="main-blog-card">
                                            <h1>{item.title}</h1>
                                            <br />
                                            <img src={item.img} alt={item.imgAlt} />
                                            <br />
                                            <div className="blog-content">
                                                <ReactMarkdown>{item.desc}</ReactMarkdown>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <ExtraBlogPart dataContentTitle={dataContent?.title} />
                        </div>
                        <RecommendedBlogs />
                    </div>

                </div>
            }
            <Footer />
        </>
    );
}

export default TenFamousWaterfallsinIndia