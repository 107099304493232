import graphql from "babel-plugin-relay/macro";

const MyBookingQuery = graphql`
	query MyBookingQuery($bookingType:MyBookingFilterEnum){
		myBooking(bookingType:$bookingType){
			id
			sourceCityName
			destinationCityName
			pnrNo
			pickupTime
			dropTime
			qrCode
			dateOfJourney
			isPaymentSuccessful
			passengers {
			    id
			    name
			    age
			    gender
			}
		}
	}	
	
`

export default MyBookingQuery