import React from 'react'
//assets
const LoadingAnimation = process.env.REACT_APP_CDN_LINK + `assets/images/Loading_Amination-white.gif`


const Loader = () => {
    return (
        <div style={loaderStyle} className="d-flex justify-content-center align-items-center">
            <video
                autoPlay
                loop
                muted
                playsInline
                width="100%"
                height="200px" 
            >
                <source src="https://nuego-cms.blr1.cdn.digitaloceanspaces.com/whatsapp-creatives/LoaderWebM.webm" type="video/webm" />
                {/* <source src="/assets/videos/loading-animation-white.mp4" type="video/mp4" /> */}
                {/* Optional message if video format isn't supported */}
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

const loaderStyle = {
    height: '50vh',
    width: '100%'

}

export default Loader
