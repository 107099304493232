import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../environment'

const mutation = graphql`
	mutation RemovePromoCashMutation($bookingId:String!){
		removePromoCash(bookingId:$bookingId){
			message
			bookingCheckout {
	        id
	        createdAt
	        updatedAt
	        user {
	          id
	          firstName
	          lastName
	          mobileNumber
	          email
	        }
	        contact {
	          id
	          bookingId
	          phone
	          email
	        }
	        sourceCityId
	        sourceCityName
	        destinationCityId
	        destinationCityName
	        dateOfJourney
	        pickUpId
	        pickupName
	        pickupTime
	        dropName
	        dropTime
	        dropId
	        referenceNo
	        routeId
	        pnrNo
	        busNumber
	        seatNo
	        seatAmount
	        paidAmenitiesString
	        totalPassengers
	        seatingType
	        status
	        duration
	        totalBaseFare
	        walletAmount
	        gstAmount
	        amountAfterDiscount
	        totalPayable
	        getTotalPaidAmount
	        discountAmount
	        insuranceCharge
	        expectedDropDatetime
	        orderNetValue
	        coupon
	        greenCashPromoAmount
	        passengerdetailSet {
	          name
	          age
	          gender
	        }
	      }
        }
    }`

export default (data, callback, errCallback) => {
	const variables = {
		bookingId: data.bookingId,
	};
	commitMutation(environment, {
		mutation,
		variables,
		onCompleted: (response, errors) => {
			if (errors) {
				errCallback(errors);
			} else {
				callback(response);
			}
		},
		onError: (err) => errCallback(err),
	});
};
