import React, {Suspense, useEffect, useState} from "react";
import { Helmet } from "react-helmet"
import "./ProfileDetail.scss";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import {useMediaQuery} from "react-responsive";
import Navbar from "../../components/Navbar/Navbar";
import {connect} from "react-redux";
import {  MDBModal, MDBModalBody } from 'mdbreact';
//other imports
import {getProfileData} from "../../services/api/DataFetching";
import ProfileReferAndEarnCard from "./component/ProfileReferAndEarnCard";
import ProfilePersonalDetail from "./component/ProfilePersonalDetail";
import {motion} from "framer-motion";
import ProfileCardCollectionMobile from "./component/ProfileCardCollectionMobile";
import ProfileSupportFeedbackCard from "./component/ProfileSupportFeedbackCard";
import ProfileOurVision from "./component/ProfileOurVision";
import MobileBottomNav from "../../components/MobileBottomNav/MobileBottomNav";
import {ModifyProfilePicData} from "../../services/api/DataUpdating";
import Footer from "../../components/Footer/Footer";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";
import {store} from "../../index";
import {setAuthType, setProfileData} from "../../store/Actions/AuthActions";
import {useNavigate} from "react-router";
import ProfileCopassengerDetail from "./component/ProfileCopassengerDetail";
import ProfileChecklist from "./component/ProfileChecklist";
import ProfileEcoContribution from "./component/ProfileEcoContribution";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import {Link} from "react-router-dom";

// icons
import editIcon from "../../assets/icons/penciledit.svg"
import walletIcon from "../../assets/icons/wallet-icon-yellow.svg"
import greenmilesIcon from "../../assets/icons/greenmils-icon-active.svg"
import mobileIcon from "../../assets/icons/call-icon.svg"
import mobileIconDesktop from "../../assets/icons/mobile-icon-gray.svg"
import walletIconDesktop from "../../assets/icons/wallet-icon-gray.svg"
import greenmilesIconDesktop from "../../assets/icons/green-miles-inactive.svg"
import backArrow from "../../assets/icons/back-white-arrow.svg"
import referModalImage from "../../assets/images/referModalImage.png"
import appstore from "../../assets/revamp/appstore.png"
import tealCloseIcon from "../../assets/icons/teal-close.svg"

//assets
const profileImageDefault = process.env.REACT_APP_CDN_LINK + `assets/images/default-profile-pic.svg`
const WorldImage = process.env.REACT_APP_CDN_LINK + `assets/images/world-image.png`
const playstore = process.env.REACT_APP_CDN_LINK + `assets/images/playstore.png`


const ProfileDetail = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [profileDataLocal, setProfileDataLocal] = useState({});
    const [editProfile, setEditProfile] = useState(false);
    const [file, setFile] = useState(null);
    const [state, setState] = useState(0);
    const [Toggle, setToggle] = useState(false);
    const [referModal , setReferModal] = useState(false);
    const [checklistName_modal, setChecklistName_modal] = useState(false);
    


    let userName, userEmail, userId, userNmber , moeId
    userName = localStorage.getItem("userName")
    userEmail = localStorage.getItem("userEmail")
    userId = localStorage.getItem("userID")
    userNmber = localStorage.getItem("userNumber")
    moeId = localStorage.getItem("moeID")


    const navigate = useNavigate();

    useEffect(() => {
        setState(state + 1);
    }, [editProfile]);

    useEffect(()=>{

        window.Moengage.track_event("profile_viewed", {
            "account_id": `${moeId === null ? "" : moeId}`,
            "platform": "web",
            "page_viewed": "yes",
            "fullname": `${userName === null ? "" : userName}`,
            "mobile_number": `${userNmber === null ? "" : userNmber}`,
            "email": `${userEmail === null ? "" : userEmail}`,
            "isloggedin": `${userId === null ? 'no' : 'yes'}`,
            "contact_no": `${profileDataLocal?.user?.mobileNumber}`,
            "pwallent": `${profileDataLocal?.greenMilesAmount}`,
            "nwallet":  `${profileDataLocal?.walletAmount}`,

             });

    },[profileDataLocal])

    useEffect(() => {
        if (props.profileData && Object.entries(props.profileData)?.length > 0  && localStorage.getItem("userToken")) {
            setProfileDataLocal(props.profileData);
        } else {
            if (isDesktopOrLaptop) {
                store.dispatch(setAuthType("d-signin"));
            } else {
                store.dispatch(setAuthType("m-signin"));
            }
        }
        return () => {
        };
    }, [props.profileData]);
    //
    const fetchProfileDataLocal = () => {
        // fetch profile data
        let variable = {
            userId: localStorage.getItem("userID"),
            walletDetails:false
        };
        getProfileData(variable, (response) => {
            store.dispatch(setProfileData(response));
            setProfileDataLocal(response);
        }, (error) => {
            if(error){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={error[0]?.message || error.toString()}
                        type={"error"}
                    />,
                );
            }
        });
    };

    const uploadProfilePic = (e) => {
        setFile(e.target.files[0]);

        if (e.target.files[0]) {
            let variables = {
                userId: localStorage.getItem("userID"),
                file: e.target.files[0],
            };
            if (e.target.files[0].size > 2097152) {
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        type={"error"}
                        message={"File size should be less than 2MB"}
                    />,
                    {position: "top-center"}
                );
            } else {
                ModifyProfilePicData(
                    variables,
                    (response) => {
                        cogoToast.success(
                            <CustomToast
                                heading={"Success"}
                                type={"success"}
                                message={"Profile Pic Updated Successfully"}
                            />,
                            {position: "top-center"}
                        );
                        fetchProfileDataLocal();
                    },
                    (error) => {
                        cogoToast.error(
                            <CustomToast
                                heading={"Error"}
                                type={"error"}
                                message={error}
                            />,
                            {position: "top-center"}
                        );
                    }
                );
            }

        } else {
            console.log("failed");
        }
    };

    const redirectToWallet = (url) => {
        navigate(url, {replace: true});
    };

    const toggleOpen = (toOpen) => {
        setReferModal(toOpen);
    }


    return (
        <div>
            {isDesktopOrLaptop ? (
                <AuthModalLayout>
                        <Helmet>                    
                        <meta name="robots" content="noindex,nofollow"/>
                        </Helmet>
                        <Navbar isProfileUpdate={true}/>
                    <Suspense fallback={<div>Loading...</div>}>
                        <div className={"profile-detail-page"}>
                            {/* navbar */}
                            <div style={{marginBottom: "100px"}}>
                                <Navbar isProfileUpdate={true}/>
                            </div>

                            <div className={"white-fold pb-4"}>
                                <div className={"global-container pt-5"}>
                                    {/* breadcrumb */}
                                    <p className={"open-400w-14s-22h margin-bottom-32"}>
                                        <Link to="/"><span className={"teal-22BBB0-color"}>Home/</span></Link>
                                        <span className={"grey-53565A-color"}> Profile </span>
                                    </p>

                                    {/* page title */}
                                    <p className={"ubuntu-700w-24s-32h margin-bottom-32"}>
                                        Profile
                                    </p>

                                    {/* profile details section*/}
                                    <div
                                        className={
                                            "d-flex justify-content-between margin-bottom-32"
                                        }
                                    >
                                        <div className={"profile-wrapper d-flex"}>
                                            <div className={"profile-image-wrapper"}>
                                                <img
                                                    src={
                                                        profileDataLocal.profilePicUrl
                                                            ? profileDataLocal?.profilePicUrl
                                                            : profileImageDefault
                                                    }
                                                    alt={"profile"}
                                                />
                                                <div className={"edit-icon"}>
                                                    <div className={"profile-icon-triggerer"}>
                                                        {/*<img src={editIconDesktop} alt={'edit'}*/}
                                                        {/*	// onClick={() => editProfileImage()}*/}
                                                        {/*	 className={''}/>*/}
                                                        <input
                                                            type="file"
                                                            id="upload-profile-pic"
                                                            onChange={(e) => uploadProfilePic(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"ml-3"}>
                                                <p className={"ubuntu-700w-20s-32h mb-1"}>
                                                    {profileDataLocal?.user?.username}
                                                </p>
                                                <div className={"d-flex"}>
                                                    <img
                                                        src={mobileIconDesktop}
                                                        alt={"mobile"}
                                                        className={"icon-24"}
                                                    />
                                                    <p className={"open-400w-16s-24h mx-2"}>
                                                        {profileDataLocal?.user?.mobileNumber}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"d-flex"}>
                                            <div className={"wallet-wrapper-box cursor-pointer"}
                                            onClick={() => redirectToWallet("/wallet?id=wallet")}
                                            >
                                                <div
                                                    className={"d-flex mb-2 "}
                                                    
                                                >
                                                    <img
                                                        src={walletIconDesktop}
                                                        alt={"wallet"}
                                                        className={"icon-24"}
                                                    />
                                                    <p className={"open-700w-14s-22h ml-2 mb-0"}>
                                                        NueGo Wallet{" "}
                                                    </p>
                                                </div>
                                                <p className={"ubuntu-400w-20s-32h"}>
                                                    ₹{profileDataLocal.walletAmount}
                                                </p>
                                                {/*<p className={'ubuntu-400w-20s-32h'}>₹{0}</p>*/}
                                            </div>
                                            <div className={"wallet-wrapper-box cursor-pointer"}
                                             onClick={() => redirectToWallet("/wallet?id=green_miles")}
                                            >
                                                <div className={"d-flex mb-2"}>
                                                    <img
                                                        src={greenmilesIconDesktop}
                                                        alt={"gm"}
                                                        className={"icon-24"}
                                                    />
                                                    <p className={"open-700w-14s-22h ml-2 mb-0"}>
                                                        Green Miles{" "}
                                                    </p>
                                                </div>
                                                <p className={"ubuntu-400w-20s-32h"}>
                                                    {profileDataLocal.greenMilesAmount}
                                                </p>
                                                {/*<p className={'ubuntu-400w-20s-32h'}>₹{0}</p>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {/* refer and earn section */}
                                    <div className={"  margin-bottom-32"}>
                                        <ProfileReferAndEarnCard/>
                                    </div>
                                    <div className={"  margin-bottom-32"}>
                                        <ProfileEcoContribution profileDataLocal={profileDataLocal}/>
                                    </div>

                                    {/* personal details */}
                                    <div
                                        className={
                                            "personal-details-wrapper " +
                                            (isDesktopOrLaptop && " margin-bottom-32 ")
                                        }
                                    >
                                        <ProfilePersonalDetail profileData={props.profileData}/>
                                    </div>

                                    {/* co passenger details */}
                                    <div
                                        className={
                                            "co-passenger-details-wrapper " +
                                            (isDesktopOrLaptop && " margin-bottom-32 ")
                                        }
                                    >
                                        <ProfileCopassengerDetail/>
                                    </div>

                                    {/* checklist */}
                                    <div className={""}>
                                        <ProfileChecklist  checklistName_modal={checklistName_modal} setChecklistName_modal={setChecklistName_modal}/>
                                    </div>
                                </div>
                            </div>

                            <Footer/>
                        </div>
                    </Suspense>
                </AuthModalLayout>) : (<AuthModalLayout>
                    <div className={"profile-detail-mobile"}>
                        <div className={"dark-fold px-3"}>
                            <div className={"profile-mobile-dark-wrapper global-container"}>
                                {!editProfile ? (<img
                                    src={editIcon}
                                    alt={"alt"}
                                    className={"icon-24 profile-detail-icon"}
                                    onClick={() => setEditProfile(true)}
                                />) : (<img
                                    src={backArrow}
                                    alt={"alt"}
                                    className={"icon-34 back-icon"}
                                    onClick={() => setEditProfile(false)}
                                />)}
                                {/*profile image center aligned */}
                                <div className={" text-center mb-4"}>
                                    <div className={"profile-image-wrapper"}>
                                        <img
                                            src={profileDataLocal?.profilePicUrl ? profileDataLocal.profilePicUrl : profileImageDefault}
                                            alt={"alt"}
                                            className={"profile-image mb-2 " + (profileDataLocal?.profilePic ? "user-profile" : "guest-user")}
                                        />
                                        {editProfile && (<div className={"edit-icon mt-2"}>
                                            <div className={"profile-icon-triggerer"}>
                                                {/*<img src={editIconDesktop} alt={'edit'}*/}
                                                {/*	// onClick={() => editProfileImage()}*/}
                                                {/*	 className={''}/>*/}
                                                <input
                                                    type="file"
                                                    id="upload-profile-pic"
                                                    onChange={(e) => uploadProfilePic(e)}
                                                />
                                            </div>
                                        </div>)}
                                    </div>

                                    {!editProfile && (<p className={"open-600w-16s-24h white-color mb-2"}>
                                        {profileDataLocal?.user?.firstname}
                                    </p>)}
                                    {editProfile && (<p className={"open-400w-14s-22h teal-2-00A095-color mb-2"}>
                                        Upload profile picture
                                    </p>)}
                                    {!editProfile && (<div
                                        className={"mobile-number-pill d-flex justify-content-center align-items-center "}
                                    >
                                        <img
                                            src={mobileIcon}
                                            alt={"alt"}
                                            className={"icon-16 mr-2"}
                                        />
                                        <p className={"open-400w-14s-22h white-color mb-0"}>
                                            {profileDataLocal?.user?.mobileNumber}
                                        </p>
                                    </div>)}
                                </div>
                                <div className={"d-flex gap-12"}>
                                    <div className={"profile-wallet-box"}
                                     onClick={() => redirectToWallet("/wallet?id=wallet")}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={walletIcon}
                                                alt={"alt"}
                                                className={"icon-24 mr-2"}
                                            />
                                            <div>
                                                <p className={"open-400w-16s-24h white-color mb-0"}>
                                                    ₹{profileDataLocal?.walletAmount}
                                                </p>
                                                {/*<p className={'open-400w-16s-24h white-color mb-0'}>₹{0}</p>*/}
                                                <p
                                                    className={"open-400w-12s-20h white-color opacity-60 mb-0"}
                                                >
                                                    Nuego Wallet
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"profile-wallet-box"}
                                     onClick={() => redirectToWallet("/wallet?id=green_miles")}
                                     >
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={greenmilesIcon}
                                                alt={"alt"}
                                                className={"icon-24 mr-2"}
                                            />
                                            <div>
                                                <p className={"open-400w-16s-24h white-color mb-0"}>
                                                    {profileDataLocal.greenMilesAmount}
                                                </p>
                                                {/*<p className={'open-400w-16s-24h white-color mb-0'}>₹{0}</p>*/}
                                                <p
                                                    className={"open-400w-12s-20h white-color opacity-60 mb-0"}
                                                >
                                                    Green miles
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"light-fold pt-3 "}>
                        {editProfile ?
                            <motion.div
                                variants={slideTowardsRight}
                                initial={"hidden"}
                                animate={editProfile ? "visible" : "hidden"}
                            >
                                <div className={"px-3"}>
                                    <div className={"margin-bottom-32"}>
                                        {Object.entries(profileDataLocal).length > 0 && (<ProfilePersonalDetail
                                            profileDataLocal={profileDataLocal} Edit = {setEditProfile}
                                        />)}
                                    </div>
                                </div>
                            </motion.div>
                        :
                            <motion.div
                                className={""}
                                variants={slideTowardsLeft}
                                initial={"visible"}
                                animate={!editProfile ? "visible" : "hidden"}
                            >
                                {/*	refer and earn */}
                                <div className={"px-3"}>
                                    <ProfileReferAndEarnCard setReferState={setReferModal}/>
                                </div>
                                <div className={"px-3 pt-4"}>
                                    <ProfileEcoContribution profileDataLocal={profileDataLocal} Toggle={Toggle}
                                                            setToggle={setToggle}/>
                                </div>
                                <div className={"px-3 my-3"}>
                                    <ProfileCardCollectionMobile/>
                                </div>
                                <div className={"px-3"}>
                                    <ProfileSupportFeedbackCard/>
                                </div>
                                <div className={"mt-3"}>
                                    <ProfileOurVision/>
                                </div>
                            </motion.div>
                        }
                        </div>

                        <div className={"margin-bottom-100"}/>
                        <MobileBottomNav/>
                    </div>
                    <div className="profile-page-bottom-tray">
                        <BottomTrayComponent
                            isOpen={Toggle === true}
                            hideClearAll={true}
                            title={"Information"}
                            closeHandler={() => setToggle(false)}
                        >
                            <hr/>
                            <div className={'green-miles-tray-content-part d-flex  gap-24'}>
                                <img className={'world-image'} src={WorldImage} alt={'alt'}/>
                                <div>
                                    <p className={" saved-pollution-title mb-0"}>Saved
                                        Emissions: {profileDataLocal?.savedEmissions} kgs</p>
                                    <p className={" saved-pollution-subtext mb-0"}>
                                        You have saved {profileDataLocal?.savedEmissions} kgs of carbon emissions since
                                        December, 2022 </p>
                                </div>
                            </div>

                        </BottomTrayComponent>
                    </div>
                    {referModal && <div className={'refer-modal-component'}>
        <MDBModal isOpen={referModal} position={'center'} toggle={()=>toggleOpen()} centered={true}
                size={"md"} >
            <MDBModalBody>
                <div className="refer-modal-body d-flex flex-column align-items-center">
                    <img className={'close-icon-refer icon-16'} src={tealCloseIcon} alt={'alt'} onClick={()=>toggleOpen()} /> 
                    <img className='refer-image' src={referModalImage} alt="refer and earn from NueGo App" />
                    <h2 className='refer-heading'>Download NueGo App to use this feature</h2>
                    <p className='refer-desc'>We have this feature available only on our Mobile App. Download now to refer your friends and earn rewards</p>
                    <div className='app-icons-div'>
                    <a href="https://play.google.com/store/apps/details?id=com.gcm.nuego" target='_blank'>
                    <img src={playstore} alt="" className="playstore" />
                    </a>
                    <a href="https://apps.apple.com/in/app/nuego/id1643122909" target='_blank'>
                    <img src={appstore} alt="" className="playstore" />
                    </a>
                    </div>
                </div>
            
            </MDBModalBody>
        </MDBModal>
</div>}
                </AuthModalLayout>


            )}
        </div>);
};

const slideTowardsLeft = {
    hidden: {
        transform: "translateX(-120%)",

        display: "none",
    },
    visible: {
        display: "block",
        transform: "translateX(0%)",
    },
};

const slideTowardsRight = {
    hidden: {
        display: "none",
        transform: "translateX(-120%)",
    },
    visible: {
        display: "block",
        transform: "translateX(0%)",
    },
};

const mapStateToProps = (state) => ({
    profileData: state.AuthReducer.profileData,
});

export default connect(mapStateToProps)(ProfileDetail);
