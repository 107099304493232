/**
 * @generated SignedSource<<73ee0aaa509456a926754e6c06e10950>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WalletType",
    "kind": "LinkedField",
    "name": "getWalletDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "PromotionalBalance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "NonPromotionalBalance",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetWalletDetailsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetWalletDetailsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "406bd64cdeeca790e4665a119fb4af00",
    "id": null,
    "metadata": {},
    "name": "GetWalletDetailsQuery",
    "operationKind": "query",
    "text": "query GetWalletDetailsQuery {\n  getWalletDetails {\n    PromotionalBalance\n    NonPromotionalBalance\n  }\n}\n"
  }
};
})();

node.hash = "3b4a04a2fc183df9ac789fa931799821";

module.exports = node;
