import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";


const mutation = graphql`
  mutation CreateCallRequestMutation(
    $mobileNumber: String!
    $name: String
    $preferredDate: String!
    $preferredLanguage: Int!
    $preferredTime: String!
    $requestedFrom: Int!
  ) {
    createCallRequest(
      mobileNumber: $mobileNumber
      name: $name
      preferredDate: $preferredDate
      preferredLanguage: $preferredLanguage
      preferredTime: $preferredTime
      requestedFrom: $requestedFrom
    ) {
      message
      status
      requestedData {
        id
        name
      }
    }
  }
`;


export default (data, callback, errCallback) => {
	const variables = {
        mobileNumber: data.mobileNumber ,
        name: data.name ,
        preferredDate: data.preferredDate ,
        preferredLanguage: data.preferredLanguage ,
        preferredTime: data.preferredTime ,
        requestedFrom: data.requestedFrom
	};

	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.createCallRequest !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}
