import {
  AUTH_MODAL_TYPE,
  CLEAR_USER_DATA,
  LOGIN_DATA,
  SET_TOKEN_DATA,
  SET_USER_AUTH_DETAILS,
  SIGN_UP_DATA,
  SET_PROFILE_DATA,
} from "../Actions";

const initialState = {
  type: "",
  mobileNumber: "",
  name: "",
  userAuthData: {},
  tokenData: {},
  profileData: {},
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_MODAL_TYPE:
      return {
        ...state,
        type: action.payload.data,
      };
    case LOGIN_DATA:
      return {
        ...state,
        mobileNumber: action.payload.data.mobileNumber,
      };
    case SIGN_UP_DATA:
      return {
        ...state,
        mobileNumber: action.payload.data.mobileNumber,
        name: action.payload.data.name,
      };
    case SET_USER_AUTH_DETAILS:
      return {
        ...state,
        userAuthData: action.payload.data,
      };
    case SET_TOKEN_DATA:
      return {
        ...state,
        tokenData: action.payload.data,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload.data,
      };
    case CLEAR_USER_DATA:
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userNumber");
      return {
        ...state,
        mobileNumber: "",
        name: "",
        userAuthData: {},
        tokenData: {},
        profileData: {},
      };

    default:
      return state;
  }
};

export default AuthReducer;
