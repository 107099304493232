import React, {useState, useEffect} from 'react'
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate, useParams} from "react-router";
import {useMediaQuery} from "react-responsive";
import {connect} from "react-redux";
import ChecklistDataWidget from "../../components/ChecklistNameWidget/components/ChecklistDataWidget";
import {store} from "../../index";
import {setCheckListNameAction} from "../../store/Actions/CommonAction";
import { getChecklistData } from '../../services/api/DataFetching';

const ChecklistPageMobile = (props) => {
	const navigate = useNavigate();
	const {checklistCurrentName} = props;
	const checklistId = useParams().id;
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
	const [checklistName_local, setChecklistName_local] = useState("");
	const [selectedChecklistData, setSelectedChecklistData] = useState(null)

	useEffect(() => {
		if (isDesktopOrLaptop) {
			navigate('/profile-detail')
		} else {
			getChecklistDataById()
			setChecklistName_local(checklistCurrentName);
		}
		return () => {

		};
	}, [isDesktopOrLaptop]);

	const getChecklistDataById = () => {
		getChecklistData((response)=>{
			if(response){
				response.filter((item)=>{
					if(item.id === checklistId){
						setSelectedChecklistData({
							name: item.name,
							id: item.id,
							checklistitemSet: item.checklistitemSet.edges,
							checklistreminderSet: item.checklistreminderSet.edges,
							isAadhaar: item.isAadhaar,
							isPrescription: item.isPrescription,
							isReminder: item.isReminder
						})
					}
				}
				)
			}
		}
		)
	}
	

	const componentData = {
		title_class: "ubuntu-700w-18s-28h black-1E1E26-color mb-1",
		subTitle_modal_class: "open-700w-16s-24h black-1E1E26-color mb-0",
		subTitle_class: "open-400w-12s-18h grey-2-78787d-color",
		outlineCta_class: "d-flex align-items-center " +
			" open-400w-12s-18h teal-2-00A095-color cursor-pointer ",
		filledCta_class: "",
		secondary_text_class: "open-400w-14s-22h grey-2-78787d-color mb-0",
		name_class: "open-700w-16s-24h black-1E1E26-color mb-0",
		icon_size: "icon-20"
	}

	const submissionHandler = () => {

	}

	const checklistNameHandler = (name) => {
		setChecklistName_local(name)
		store.dispatch(setCheckListNameAction(name))
	}

	return (
		<div className={'checklist-page-mobile'}>
			<MobileSubScreenLayout title={'Checklist Detail'} back={()=>navigate(-1)}
			                       isDropDown={false}
			>

				{selectedChecklistData && <ChecklistDataWidget
					dataModal={false}
					setDataModal={() => navigate(-1)}
					checklistName={checklistCurrentName}
					setChecklistName={checklistNameHandler}
					componentData={componentData}
					checklistDataSubmissionHandler={submissionHandler}
					selectedChecklistData={selectedChecklistData}
				/>}
			</MobileSubScreenLayout>

		</div>
	)
}

const mapStateToProps = (state) => ({
	checklistCurrentName: state.CommonReducer.checklistCurrentName
})

export default connect(mapStateToProps)(ChecklistPageMobile)
