import graphql from "babel-plugin-relay/macro";

const CancelBasedOnCompanyQuery = graphql`
query CancelBasedOnCompanyQuery{
	cancellationPolicyBasedOnCompany {
	    fromMinutes
	    toMinutes
	    deductPercentage
	    refundPercentage
	    createdDate
	    updatedDate
	    remarks
	}
}
	`
export default CancelBasedOnCompanyQuery;